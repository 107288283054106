import { Table } from "components/Table/Table.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableTableService } from "observables/TableServiceObservable";
import { useTranslation } from "react-i18next";
import { refundsPageService } from "service/shared/singletones/refundsPageService/refundsPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { STableComponentQi, STableContainerQi } from "utils/styled/STableQi.styled";

import { SRefundsTable } from "./RefundsTableStyled/SRefundsTable.styled";
import { SRefundsTableContainer } from "./RefundsTableStyled/SRefundsTableContainer.styled";

export const RefundsTable = createComponent("RefundsTable", () => {
  const loaded = useDefaultObservableBoolean(refundsPageService.state.loaded);
  const tableService = useDefaultObservableTableService(refundsPageService.tableService);
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return isQi ? (
    <STableComponentQi isArabic={isArabic}>
      <STableContainerQi isArabic={isArabic}>
        <Table loaded={loaded} service={tableService} />
      </STableContainerQi>
    </STableComponentQi>
  ) : (
    <SRefundsTable>
      <SRefundsTableContainer>
        <Table loaded={loaded} service={tableService} />
      </SRefundsTableContainer>
    </SRefundsTable>
  );
});
