/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
// import { Loader } from "components/Loader/Loader.component";
import { Loader } from "components/Loader/Loader.component";
import { VerificationForm } from "components/VerificationForm/VerificationForm.component";
import { SButton } from "components/VerificationForm/VerificationFormStyled/SVerificationForm.styled";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { mfaController } from "service/shared/singletones/mfaService/mfa.controller";

import {
  Container,
  QrImage,
  SActionInfoContainer,
  SActionInfoMessage,
} from "./SetupMFAFormStyled/SSetupMFAForm.styled";

export const SetupMFAForm: React.FC = () => {
  const { t } = useTranslation();
  const handleVerifyMFA = (otpCode: string): Promise<void> =>
    mfaController.verifyOtp(otpCode);

  const [qrCode, setQrCode] = React.useState<string | null>(null);

  useEffect(() => {
    const subscription = mfaController.state.qrCode.subscribe((value) => {
      setQrCode(value);
    });

    mfaController.generateQrCode();

    return () => subscription.unsubscribe();
  }, []);

  return (
    <Container>
      <SActionInfoContainer>
        <SActionInfoMessage>{t("mfa.enterQrCodeFirstStap")}</SActionInfoMessage>
      </SActionInfoContainer>
      {qrCode ? (
        <QrImage src={`data:image/png;base64,${qrCode}`} alt="QR Code" />
      ) : (
        <div
          style={{ display: "flex", justifyContent: "center", margin: "30px" }}
        >
          <Loader />
        </div>
      )}
      <VerificationForm
        key="SetupMFAForm"
        stapMessage={t("mfa.enterCodeSecondStap")}
        onVerifyClick={handleVerifyMFA}
      >
        <SButton>{t("mfa.button.submit")}</SButton>
      </VerificationForm>
    </Container>
  );
};
