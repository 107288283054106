/* eslint-disable prettier/prettier */
import { Outlet } from "packages/react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";
import { isFinOn, isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { AppSelectLang } from "App/AppSelectLang.component";

import { AppSwitchLang } from "../../App/AppSwitchLang.component";
import MainQiLogo from "../../assets/qiImg/MainQiLogo.png";
import { SvgImage } from "../SvgImage/SvgImage.component";
import {
  SContentContainerQi,
  SFormContainer,
  SFormLayout,
  SFormLayoutQi,
  SImageContainerQi,
} from "./FormLayoutStyled/SFormLayout.styled";
import { SFormLayoutFooter } from "./FormLayoutStyled/SFormLayoutFooter.styled";

const QiLayout: React.FC = () => {
  return (
    <SFormLayoutQi>
      <SContentContainerQi>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <SvgImage name="LogoQi" />
            <p style={{ fontSize: "24px", fontWeight: "600" }}>
              Payment Gateway Portal
            </p>
          </div>
          <AppSelectLang />
        </div>
        <SFormContainer>
          <Outlet />
        </SFormContainer>
      </SContentContainerQi>
      <SImageContainerQi>
        <img src={MainQiLogo} alt="Main Qi Logo" width="100%" height="100%" />
      </SImageContainerQi>
    </SFormLayoutQi>
  );
};

const DefaultLayout: React.FC = () => {
  const { t } = useTranslation();
  const widthImage = isFinOn ? 344 : 250;
  const heightImage = isFinOn ? 50 : 80;

  return (
    <SFormLayout>
      <div
        style={{
          width: "100%",
          padding: "34px",
          display: "flex",
          flexDirection: "column",
          gap: "30%",
          height: "100%",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <AppSwitchLang />
        </div>
        <SFormContainer>
          <SvgImage
            width={widthImage}
            height={heightImage}
            name={isFinOn ? "LogoFin" : "Logo"}
          />
          <Outlet />
          <SFormLayoutFooter>
            {isFinOn ? t("formLayout.topTitle") : t("formLayoutSNGB.topTitle")}
            <br />
            {isFinOn
              ? t("formLayout.bottomTitle")
              : t("formLayoutSNGB.bottomTitle")}
          </SFormLayoutFooter>
        </SFormContainer>
      </div>
    </SFormLayout>
  );
};

export const FormLayout = createComponent("FormLayout", () => {
  return isQi ? <QiLayout /> : <DefaultLayout />;
});
