/* eslint-disable prettier/prettier */
import ClipboardJS from "clipboard";
import { SCopyButton } from "components/HeaderSecondary/HeaderSecondaryStyled/SHeaderSecondary.styled";
import { SvgImage } from "components/SvgImage/SvgImage.component";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SvgNames } from "types/business/SvgNames";

interface CopyToClipboardProps {
    copyId: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ copyId }) => {
    const [copyStatus, setCopyStatus] = useState<"idle" | "success">("idle");
    const { i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    useEffect(() => {
        const clipboard = new ClipboardJS(".copy-btn");

        clipboard.on("success", () => {
            setCopyStatus("success");
            setTimeout(() => setCopyStatus("idle"), 3000);
        });

        clipboard.on("error", (err) => {
            console.error("Failed to copy: ", err);
        });

        return () => {
            clipboard.destroy();
        };
    }, [copyId]);

    const getIconName = (): SvgNames => {
        return copyStatus === "success" ? "Check" : "CopyIcon";
    };

    return (
        <SCopyButton
            className="copy-btn"
            isArabic={isArabic}
            data-clipboard-text={copyId}
        >
            <SvgImage name={getIconName()} />
        </SCopyButton>
    );
};

export default CopyToClipboard;
