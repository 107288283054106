/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
import { createIcon } from "utils/libExtend/createIcon";

export const AddNewFilter = createIcon("AddNewFilter", (props) => {
    const { width = 9, height = 14, color = "#69809A" } = props;

    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2072_1408)">
                <path d="M8.59896 13.3334L6.59896 14V8.33335L3.61229 5.04802C3.38926 4.80264 3.26566 4.48295 3.26562 4.15135V2.66669H13.9323V4.11469C13.9322 4.46828 13.7917 4.80736 13.5416 5.05735L10.599 8.00002V10" stroke="#0F7A54" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.2656 12.6667H15.2656" stroke="#0F7A54" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.2656 10.6667V14.6667" stroke="#0F7A54" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2072_1408">
                    <rect width="16" height="16" fill="white" transform="translate(0.599609)" />
                </clipPath>
            </defs>
        </svg>
    );
});

