/* eslint-disable prettier/prettier */
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { SButtonsContainerQi } from "./ButtonsStyled/SButtonsModal.styled";

type ButtonsModalProps = {
    children: ReactNode;
};

export const ButtonsModal = createComponent<ButtonsModalProps>("ButtonsModal", ({ children }) => {
    const { i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    return <SButtonsContainerQi isArabic={isArabic}>{children}</SButtonsContainerQi>;
});

