import { routerPaths } from "appConstants/routerPaths";
import { DetailsLayout } from "components/DetailsLayout/DetailsLayout.component";
import HeaderSecondary from "components/HeaderSecondary/HeaderSecondary.component";
import { SDetailsBox, SDetailsLayoutContainerQi } from "components/HeaderSecondary/HeaderSecondaryStyled/SHeaderSecondary.styled";
import { Loader } from "components/Loader/Loader.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { invoiceDetailsPageService } from "service/shared/singletones/invoiceDetailsPageService/invoiceDetailsPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";
import { useParams } from "utils/libReplace/useParams";

import { InvoiceDetailsPageContent } from "./InvoiceDetailsPageContent/InvoiceDetailsPageContent.component";
import { SInvoiceDetailsPageLoader } from "./InvoiceDetailsPageStyled/SInvoiceDetailsPageLoader.styled";

export const InvoiceDetailsPage = createComponent("InvoiceDetailsPage", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const { invoiceId } = useParams<{ invoiceId: string }>();

  useEffect(async () => {
    invoiceDetailsPageService.controller.setInvoiceId(invoiceId);
    await invoiceDetailsPageService.controller.getInvoice();
    return (): void => {
      invoiceDetailsPageService.controller.unMounted();
    };
  }, []);

  const loaded: boolean = useDefaultObservableBoolean(invoiceDetailsPageService.state.loaded);

  return isQi ? (
    <SDetailsLayoutContainerQi isArabic={isArabic}>
      <SDetailsBox>
        <HeaderSecondary isArabic={isArabic} headerTitle={t("invoiceDetailsPage.headerTitle")} id={invoiceId || ""} />
        {loaded ? (
          <InvoiceDetailsPageContent />
        ) : (
          <SInvoiceDetailsPageLoader>
            <Loader />
          </SInvoiceDetailsPageLoader>
        )}
      </SDetailsBox>
    </SDetailsLayoutContainerQi>
  ) : (
    <DetailsLayout header={[{ title: t("invoiceDetailsPage.headerTitle"), src: routerPaths.invoices }]} title={`# ${invoiceId}`}>
      {loaded ? (
        <InvoiceDetailsPageContent />
      ) : (
        <SInvoiceDetailsPageLoader>
          <Loader />
        </SInvoiceDetailsPageLoader>
      )}
    </DetailsLayout>
  );
});
