import React from "react";
import { css, setFlexDF, setProps, styled } from "theme";

type SFilterButtonClearProps = {
  open?: boolean;
  isArabic: boolean;
};

const openSet = setProps<SFilterButtonClearProps>(({ open }) => !open)(css`
  cursor: default;
  opacity: 0;
`);

const isArabicLang = setProps<SFilterButtonClearProps>(({ isArabic }) => isArabic)(css`
  margin-right: 8px;
`);
const isArabicLangQi = setProps<SFilterButtonClearProps>(({ isArabic }) => isArabic)(css`
  margin-right: 48px;
`);
const isNotArabicLang = setProps<SFilterButtonClearProps>(({ isArabic }) => !isArabic)(css`
  margin-left: 8px;
`);
const isNotArabicLangQi = setProps<SFilterButtonClearProps>(({ isArabic }) => !isArabic)(css`
  margin-left: 0px;
`);

export const SFilterButtonClear = styled.button<SFilterButtonClearProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  height: 22px;
  ${isNotArabicLang};
  ${isArabicLang};
  ${openSet};
`;

export const SFilterButtonClearQi = styled.button<SFilterButtonClearProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${isNotArabicLangQi};
  ${isArabicLangQi};
  ${setFlexDF};
  align-items: center;
  gap: 4px;
  width: 90px;
  height: 40px;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ffd400;
`;
