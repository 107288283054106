import { FlattenSimpleInterpolation } from "packages/styled-components";
import React from "react";
import { css } from "theme/default/css";
import { styled } from "theme/default/styled";
import { setColor_00346e } from "theme/setter/setColorsValues/setColor_00346e";
import { setFontSize_14px } from "theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight400 } from "theme/setter/setFontWeightValues/setFontWeight400";
import { setFontWeight500 } from "theme/setter/setFontWeightValues/setFontWeight500";

type SBodyDataCellProps = {
  width: number | null;
};

const setWidth = ({ width }: SBodyDataCellProps): FlattenSimpleInterpolation => {
  return css`
    width: ${width ?? 0}px;
  `;
};

export const SBodyDataCell = styled.div<SBodyDataCellProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  overflow: hidden;
  padding-right: 26px;
  padding-left: 22px;

  ${setFontSize_14px};
  ${setFontWeight400};
  ${setColor_00346e};
  ${setWidth};
`;

export const SBodyDataCellQi = styled.div<SBodyDataCellProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  overflow: hidden;
  padding-right: 16px;
  padding-left: 12px;
  ${setFontSize_14px};
  ${setFontWeight500};
  color: #43464c;
  ${setWidth};
  text-align: center;
  align-items: center;
  height: 100%;
  display: flex;
  &:not(:first-child) {
    justify-content: center;
  }
  &:not(:last-child) {
    border-right: 1px solid #939598;
  }
`;

export const SDataCellContainerQi = styled.div<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  display: flex;
  align-items: center;
  gap: 10px;
`;
