import React from "react";
import { css, setFlexDFAIC, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);

export const SSupportPageQuestionHeader = styled.button<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  gap: 8px;
  ${setFlexDFAIC};
  ${isArabicLang};
`;
