import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useFiltersContext } from "context/Filter.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableString } from "observables/StringObservable";
import { useTranslation } from "react-i18next";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { SFilterInputInputQi } from "utils/styled/SInputSelectFiltersQi.styled";
import { SInputTitleContainerQi, SInputTitleQi } from "utils/styled/SInputTitle.styled";

import { FilterInputProps } from "./FilterInput.props";
import { SFilterInput, SFilterInputQi, SInputContainerQi } from "./FilterInputStyled/SFilterInput.styled";
import { SFilterInputClear, SFilterInputClearQi } from "./FilterInputStyled/SFilterInputClear.styled";
import { SFilterInputContainer, SFilterInputContainerQi } from "./FilterInputStyled/SFilterInputContainer.styled";
import { SFilterInputDelete } from "./FilterInputStyled/SFilterInputDelete.styled";
import { SFilterInputInput } from "./FilterInputStyled/SFilterInputInput.styled";
import { SFilterInputInputContainer } from "./FilterInputStyled/SFilterInputInputContainer.styled";
import { SFilterInputTitle } from "./FilterInputStyled/SFilterInputTitle.styled";

export const FilterInput = createComponent<FilterInputProps>("FilterInput", (props) => {
  const { service } = props;
  const { service: filterService } = useFiltersContext();

  const { t } = useTranslation();
  const value = useDefaultObservableString(service.value);
  const isArabic = useDefaultObservableBoolean(filterService.state.isArabic);

  return isQi ? (
    <SInputContainerQi isArabic={isArabic}>
      <SFilterInputQi>
        <SInputTitleContainerQi isArabic={isArabic}>
          <SInputTitleQi isArabic={isArabic}>{t(service.filterModel.langPath)}</SInputTitleQi>
          <SFilterInputDelete isArabic={isArabic} onClick={service.onClickDelete}>
            <SvgImage name="Cross" />
          </SFilterInputDelete>
        </SInputTitleContainerQi>

        <SFilterInputContainerQi isArabic={isArabic}>
          <SFilterInputInputContainer>
            <SFilterInputInputQi isArabic={isArabic} type={service.filterModel.isNumber ? "number" : "text"} value={value} onChange={service.onChange} />

            <SFilterInputClearQi isArabic={isArabic} onClick={service.onClickClear}>
              {!service.filterModel.isNumber && <SvgImage name="Reset" />}
            </SFilterInputClearQi>
          </SFilterInputInputContainer>
        </SFilterInputContainerQi>
      </SFilterInputQi>
    </SInputContainerQi>
  ) : (
    <SFilterInput>
      <SFilterInputTitle isArabic={isArabic}>{t(service.filterModel.langPath)}</SFilterInputTitle>

      <SFilterInputContainer isArabic={isArabic}>
        <SFilterInputInputContainer>
          <SFilterInputInput isArabic={isArabic} type={service.filterModel.isNumber ? "number" : "text"} value={value} onChange={service.onChange} />

          <SFilterInputClear isArabic={isArabic} onClick={service.onClickClear}>
            <SvgImage width={20} height={20} name="Close" />
          </SFilterInputClear>
        </SFilterInputInputContainer>

        <SFilterInputDelete isArabic={isArabic} onClick={service.onClickDelete}>
          <SvgImage width={20} height={20} name="DeleteFilter" />
        </SFilterInputDelete>
      </SFilterInputContainer>
    </SFilterInput>
  );
});
