import { useDefaultObservableStatisticPartArray } from "observables/StatisticPartArrayObservable";
import ReactECharts from "packages/echarts-for-react";
import { useTranslation } from "react-i18next";
import { statisticPageService } from "service/shared/singletones/statisticPageService/statisticPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { getRatioOptions } from "./getRatioOptions";
import { produceRatioStatistic } from "./produceRatioStatistic/produceRatioStatistic";
import { SStatisticConversionsContent, SStatisticConversionsContentQi } from "./StatisticConversionsContentStyled/SStatisticConversionsContent.styled";
import {
  SStatisticConversionsContentTitle,
  SStatisticConversionsContentTitleQi,
} from "./StatisticConversionsContentStyled/SStatisticConversionsContentTitle.styled";

export const StatisticConversionsContent = createComponent("StatisticConversionsContent", () => {
  const { t, i18n } = useTranslation();
  const statistics = useDefaultObservableStatisticPartArray(statisticPageService.state.statistics);

  const { dateArray, successQuantityArray, failQuantityArray } = produceRatioStatistic(statistics);

  const option = getRatioOptions(dateArray, successQuantityArray, failQuantityArray);
  const isArabic = i18n.language === "ar";

  return isQi ? (
    <SStatisticConversionsContentQi isArabic={isArabic}>
      <SStatisticConversionsContentTitleQi isArabic={isArabic}>{t("statisticPage.conversionRateTitle")}</SStatisticConversionsContentTitleQi>
      <ReactECharts option={option} style={{ height: 400 }} opts={{ renderer: "svg" }} />
    </SStatisticConversionsContentQi>
  ) : (
    <SStatisticConversionsContent>
      <SStatisticConversionsContentTitle>{t("statisticPage.conversionsTitle")}</SStatisticConversionsContentTitle>
      <ReactECharts option={option} style={{ height: 400 }} opts={{ renderer: "svg" }} />
    </SStatisticConversionsContent>
  );
});
