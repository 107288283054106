import { t } from "i18n";
import { HeaderCellModel } from "model/HeaderCell.model";
import { Children } from "types/commonExtend/Children";

type HeaderCellOptions = {
  Element?: Children;
  isDetails?: boolean;
  isId?: boolean;
  isStatus?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  width?: number;
  showAsc?: boolean;
  showDesc?: boolean;
  callBack?: () => void;
  borderRight?: string;
  height?: string;
};

export const getInitialHeaderCell = (key: string, title: string, langPath: string, options?: HeaderCellOptions): HeaderCellModel => {
  const isDetails = options?.isDetails || false;
  const isId = options?.isId || false;
  const isStatus = options?.isStatus || false;
  const isFirst = options?.isFirst || false;
  const isLast = options?.isLast || false;
  const width = options?.width || null;
  const initialWidth = options?.width || null;
  const Element = options?.Element || null;
  const callBack = options?.callBack || null;
  const showAsc = options?.showAsc || false;
  const showDesc = options?.showDesc || false;
  const borderRight = options?.borderRight || "";
  const height = options?.height || "auto";
  return {
    key,
    width,
    initialWidth,
    title: t(title),
    isDetails,
    isId,
    isStatus,
    isFirst,
    isLast,
    Element,
    callBack,
    langPath,
    showAsc,
    showDesc,
    borderRight,
    height,
  };
};
