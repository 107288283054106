import { emailRegExp, phoneRegExp } from "appConstants/regexp";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectString } from "observables/StringObservable";
import { BehaviorSubjectSvgNames } from "observables/SvgNamesObservable";
import { SvgNames } from "types/business/SvgNames";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

type InputType = "phoneNumber" | "email" | "number" | "common";

type UsersChangeRowServiceParams = {
  type?: InputType;
};

export class UsersChangeRowState {
  public readonly initialValue: BehaviorSubjectString;

  public readonly value: BehaviorSubjectString;

  public readonly active: BehaviorSubjectBoolean;

  public readonly valueIsEqual: BehaviorSubjectBoolean;

  public readonly icon: BehaviorSubjectSvgNames;

  public readonly valid: BehaviorSubjectBoolean;

  public readonly updateUserCallBack: () => void;

  private readonly type: InputType;

  public constructor(initialValue: string, updateUserCallBack: () => void, params?: UsersChangeRowServiceParams) {
    this.type = params?.type ?? "common";

    this.updateUserCallBack = updateUserCallBack;
    this.initialValue = new BehaviorSubjectString(initialValue);
    this.value = new BehaviorSubjectString(this.initialValue.value);
    this.active = new BehaviorSubjectBoolean(false);
    this.valueIsEqual = setCombinedBehaviorSubject(this.setValueIsEqual, this.value, this.initialValue);
    this.icon = setCombinedBehaviorSubject(this.setIcon, this.active, this.valueIsEqual);

    this.valid = setCombinedBehaviorSubject(this.setValid, this.value);
  }

  private readonly validate = (value: string): boolean => {
    const newValue = value.toString();

    if (this.type === "number") {
      return newValue.trim() !== "" && !Number.isNaN(+newValue);
    }

    if (this.type === "phoneNumber") {
      return phoneRegExp.test(newValue);
    }

    if (this.type === "email") {
      return emailRegExp.test(newValue);
    }

    return newValue.trim() !== "";
  };

  private readonly setValid = (value: string): boolean => {
    return this.validate(value);
  };

  private readonly setValueIsEqual = (value: string, initialValue: string): boolean => {
    return value === initialValue;
  };

  private readonly setIcon = (active: boolean, valueIsEqual: boolean): SvgNames => {
    if (active) {
      if (valueIsEqual) {
        return "Close";
      }

      return "Check";
    }

    return "Edit";
  };
}
