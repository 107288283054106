import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useTranslation } from "react-i18next";
import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { SButtonRefundQi } from "utils/styled/SButtonQi";

import { SRefundButton } from "./RefundButtonStyled/SRefundButton.styled";

export const RefundButton = createComponent("RefundButton", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return isQi ? (
    <SButtonRefundQi isArabic={isArabic} onClick={paymentDetailsPageService.controller.onClickRefund}>
      {t("paymentDetailsPageContent.refundButtonTitle")}
      <SvgImage name="RefundIcon" color="#707a8f" />
    </SButtonRefundQi>
  ) : (
    <SRefundButton onClick={paymentDetailsPageService.controller.onClickRefund}>{t("paymentDetailsPageContent.refundButtonTitle")}</SRefundButton>
  );
});
