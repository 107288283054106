/* eslint-disable prettier/prettier */
import { VerificationForm } from "components/VerificationForm/VerificationForm.component";
import { SButton } from "components/VerificationForm/VerificationFormStyled/SVerificationForm.styled";
import React from "react";
import { useTranslation } from "react-i18next";
import { mfaController } from "service/shared/singletones/mfaService/mfa.controller";

import { Container } from "./ValidateOTPFormStyled/SValidateOTPForm.styled";

export const ValidateOTPForm: React.FC = () => {
  const { t } = useTranslation();
  const handleValidateMFA = (otpCode: string): void => {
    mfaController.validateOtp(otpCode);
  };

  return (
    <Container>
      <VerificationForm
        key="ValidateMFAForm"
        stapMessage={t("mfa.enterCodeThirdStap")}
        onVerifyClick={handleValidateMFA}
      >
        <SButton>{t("mfa.button.submit")}</SButton>
      </VerificationForm>
    </Container>
  );
};
