import { styled } from "theme/default/styled";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

export const SSupportPage = styled.div`
  max-width: 800px;

  ${setFlexDFFDC};
`;

export const SSupportPageQi = styled.div`
  ${setFlexDFFDC};
`;
