import React from "react";
import { setTextRightArabic } from "theme";
import { styled } from "theme/default/styled";
import { setFontSize_20px } from "theme/setter/setFontSizeValues/setFontSize_20px";
import { setFontWeight500 } from "theme/setter/setFontWeightValues/setFontWeight500";
import { setFontWeight700 } from "theme/setter/setFontWeightValues/setFontWeight700";
import { setColor_rgba_0_0_0_87 } from "theme/setter/setOpacityColorsValues/setColor_rgba_0_0_0_87";
import { SPropsIsArabic } from "types/business/SPropsType";

export const SStatisticChartContentTitle = styled.div`
  margin-bottom: 24px;
  ${setFontSize_20px};
  ${setFontWeight700};
  ${setColor_rgba_0_0_0_87};
`;

export const SStatisticChartContentTitleQi = styled.div<SPropsIsArabic & React.HTMLAttributes<HTMLDivElement>>`
  margin: 10px 24px;
  color: #14181f;
  ${setFontSize_20px};
  ${setFontWeight500};
  ${setTextRightArabic()};
`;
