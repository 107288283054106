import { styled } from "theme/default/styled";
import { color_rgba_0_0_0_95 } from "theme/setter/opacityColorsValues/color_rgba_0_0_0_95";
import { setFlexDF } from "theme/setter/setFlexValues/setFlexDF";

export const STerminalsListTableHeader = styled.div`
  padding: 10px 20px;

  border-bottom: 1px solid ${color_rgba_0_0_0_95};

  ${setFlexDF};
`;

export const STerminalsListTableHeaderQi = styled.div`
  padding: 0px 0px;
  border-bottom: 1px solid #0000004d;
  min-height: 50px;
  align-items: center;
  ${setFlexDF};
  position: sticky;
  background: white;
  top: 0;
`;
