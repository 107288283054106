import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableOptionModelArray } from "observables/OptionModelArrayObservable";
import { useTranslation } from "react-i18next";
import { Children } from "types/commonExtend/Children";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";
import { SInputTitleQi } from "utils/styled/SInputTitle.styled";

import { CompareTerminalProps } from "./CompareTerminal.props";
import { SCompareTerminal, SCompareTerminalQi } from "./CompareTerminalStyled/SCompareTerminal.styled";
import { SCompareTerminalSelector, SCompareTerminalSelectorQi } from "./CompareTerminalStyled/SCompareTerminalSelector.styled";
import { SCompareTerminalSkeleton, SCompareTerminalSkeletonQi } from "./CompareTerminalStyled/SCompareTerminalSkeleton.styled";

export const CompareTerminal = createComponent<CompareTerminalProps>("CompareTerminal", (props) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const { service } = props;

  const loaded = useDefaultObservableBoolean(service.state.loaded);
  const optionList = useDefaultObservableOptionModelArray(service.state.optionList);
  const value = useDefaultObservableOptionModelArray(service.state.value);
  const valid = useDefaultObservableBoolean(service.state.valid);
  const showError = useDefaultObservableBoolean(service.state.showError);

  useEffect(async () => {
    await service.controller.onMount();
  }, []);

  const getSelector = (): Children => {
    if (!loaded) {
      return isQi ? <SCompareTerminalSkeletonQi /> : <SCompareTerminalSkeleton />;
    }

    return isQi ? (
      <SCompareTerminalSelectorQi
        isError={showError && !valid}
        onChange={service.controller.onChange}
        onClickAll={service.controller.onClickAll}
        value={value}
        optionList={optionList}
      />
    ) : (
      <SCompareTerminalSelector
        isError={showError && !valid}
        onChange={service.controller.onChange}
        onClickAll={service.controller.onClickAll}
        value={value}
        optionList={optionList}
      />
    );
  };

  return isQi ? (
    <SCompareTerminalQi isArabic={isArabic}>
      <SInputTitleQi isArabic={isArabic}>{t("dynamicPage.terminalInputTitle")}</SInputTitleQi>
      {getSelector()}
    </SCompareTerminalQi>
  ) : (
    <SCompareTerminal isArabic={isArabic}>
      <span>{t("dynamicPage.terminalInputTitle")}</span>
      {getSelector()}
    </SCompareTerminal>
  );
});
