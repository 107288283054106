/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubject } from "types/libReplace/BehaviorSubject";

class State {
  public readonly isActive = new BehaviorSubjectBoolean(true);

  public readonly isMfaCompleted = new BehaviorSubjectBoolean(false);

  public readonly qrCode = new BehaviorSubject<string | null>(null);

  public readonly isError = new BehaviorSubjectBoolean(false);

  public readonly error = new BehaviorSubject<string | null>(null);

  private mfaController: any;

  public constructor(mfaController: any) {
    this.mfaController = mfaController;
    this.isMfaActive = this.mfaController.getIsMfaActive();
    this.isMfaForced = new BehaviorSubjectBoolean(this.isMfaActive);
  }

  public isMfaActive: boolean;

  public readonly isMfaForced: BehaviorSubjectBoolean;

  public setMfaForced(required: boolean): void {
    this.isMfaForced.next(required);
  }

  public setError(message: string): void {
    this.error.next(message);
  }
}

export const createMfaState = (mfaController: any) => new State(mfaController);
