import React from "react";
import { css, setBackgroundColor_0083f5, setBorderRadius_8px, setFlexDFAIC, setFlexJCC, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  right: 30px;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  left: 30px;
`);

export const SNavigationMenuIconLabel = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  position: absolute;
  z-index: 2;
  top: 30px;
  padding: 8px;
  ${setFlexDFAIC};
  ${setFlexJCC};
  ${setBorderRadius_8px};
  ${setBackgroundColor_0083f5};
  ${isArabicLang};
  ${isNotArabicLang};
`;
