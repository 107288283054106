import React from "react";
import { setFlexDFJCSB, setRowReverse, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

export const SCompareTerminal = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 400px;

  ${setFlexDFJCSB};
  ${setRowReverse()};
`;

export const SCompareTerminalQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 100%;
  ${setRowReverse()};
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;
