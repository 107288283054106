import { SResetButtonQi } from "components/Filters/FilterMenu/ResetButton/ResetButtonStyled/SResetButton.styled";
import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useTranslation } from "react-i18next";
import { terminalsPageService } from "service/shared/singletones/terminalsPageService/terminalsPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { STerminalsClearFilterButton } from "./TerminalsClearFilterButtonStyled/STerminalsClearFilterButton.styled";

export const TerminalsClearFilterButton = createComponent("TerminalsClearFilterButton", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const filterListIsEmpty = true;

  return isQi ? (
    <SResetButtonQi filterListIsEmpty={filterListIsEmpty} onClick={terminalsPageService.controller.onClickTerminalsClearFilterButton}>
      {t("filters.clearButton")} <SvgImage name="ClearIcon" />
    </SResetButtonQi>
  ) : (
    <STerminalsClearFilterButton type="button" onClick={terminalsPageService.controller.onClickTerminalsClearFilterButton}>
      {t("terminalsPage.terminalsClearFilterButtonTitle")}
    </STerminalsClearFilterButton>
  );
});
