import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useTranslation } from "react-i18next";
import { invoiceCreateFormService } from "service/shared/singletones/invoiceCreateFormService/invoiceCreateForm.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { SButtonQi } from "utils/styled/SButtonQi";
import { SCanselButtonQi } from "utils/styled/SCanselButtonQi";

import { SCreateFormButtons, SCreateFormButtonsQi } from "./ButtonsStyled/SCreateFormButtons.styled";
import { SCreateFormButtonsButton } from "./ButtonsStyled/SCreateFormButtonsButton.styled";

export const Buttons = createComponent("Buttons", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return isQi ? (
    <SCreateFormButtonsQi isArabic={isArabic}>
      <SCanselButtonQi isArabic={isArabic} onClick={invoiceCreateFormService.controller.onClickCancel}>
        {t("invoiceCreateForm.cancelButtonTitle")}
        <SvgImage name="Close" color="#707a8f" />
      </SCanselButtonQi>
      <SButtonQi isArabic={isArabic} onClick={invoiceCreateFormService.controller.onClickCreate}>
        {t("invoiceCreateForm.createButtonTitle")}
        <SvgImage name="Check" />
      </SButtonQi>
    </SCreateFormButtonsQi>
  ) : (
    <SCreateFormButtons isArabic={isArabic}>
      <SCreateFormButtonsButton onClick={invoiceCreateFormService.controller.onClickCancel}>
        {t("invoiceCreateForm.cancelButtonTitle")}
      </SCreateFormButtonsButton>
      <SCreateFormButtonsButton onClick={invoiceCreateFormService.controller.onClickCreate}>
        {t("invoiceCreateForm.createButtonTitle")}
      </SCreateFormButtonsButton>
    </SCreateFormButtons>
  );
});
