import React from "react";
import { css, setColor_8bc3f5, setFontSize_14px, setFontWeight600, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  margin-right: 8px;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  margin-left: 0px;
`);

export const SFilterButtonTitle = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${isNotArabicLang};
  ${isArabicLang};
  ${setFontSize_14px};
  ${setFontWeight600};
  ${setColor_8bc3f5};
`;

export const SFilterButtonTitleQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${isNotArabicLang};
  ${isArabicLang};
  ${setFontSize_14px};
  ${setFontWeight600};
  color: #ffd400;
`;
