import { styled } from "theme/default/styled";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

export const SCreateUserFetchedMultipleSelect = styled.div`
  width: 200px;

  ${setFlexDFFDC};
`;

export const SCreateUserFetchedMultipleSelectQi = styled.div`
  width: 100%;
  margin-top: 16px;
  ${setFlexDFFDC};
`;
