import React from "react";
import styled, { css } from "styled-components";
import { setBorderRadius_8px, setFlexDF, setProps } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);

export const SHeaderTitleQi = styled.div`
  color: #14181f;
  font-size: 18px;
  font-weight: 500;
  ${setFlexDF};
  align-items: center;
  gap: 16px;
`;

export const SHeaderContainerQi = styled.div<SPropsIsArabic & React.HTMLAttributes<HTMLDivElement>>`
  ${setFlexDF};
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e9;
  padding-bottom: 20px;
  margin-bottom: 16px;
  ${isArabicLang};
`;

export const SButtonCloseQi = styled.button<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  display: flex;
  align-items: center;
  border: 1px solid #e8e8e9;
  padding: 10px;
  ${setBorderRadius_8px};
`;
