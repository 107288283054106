import { styled } from "theme/default/styled";

export const STableEmpty = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const STableEmptyQi = styled.div`
  position: absolute;
  top: 15vh;
  left: 50%;
  transform: translate(-50%, -50%);
`;
