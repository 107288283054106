/* eslint-disable prettier/prettier */
/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const Users = createIcon("Users", (props) => {
    const { width = 24, height = 24, color = "#ffffff" } = props;

    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2072_3790)">
                <path
                    d="M7.16602 5.83333C7.16602 6.71739 7.5172 7.56524 8.14233 8.19036C8.76745 8.81548 9.61529 9.16667 10.4993 9.16667C11.3834 9.16667 12.2313 8.81548 12.8564 8.19036C13.4815 7.56524 13.8327 6.71739 13.8327 5.83333C13.8327 4.94928 13.4815 4.10143 12.8564 3.47631C12.2313 2.85119 11.3834 2.5 10.4993 2.5C9.61529 2.5 8.76745 2.85119 8.14233 3.47631C7.5172 4.10143 7.16602 4.94928 7.16602 5.83333Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M13.834 15.833H18.834" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16.334 13.333V18.333" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path
                    d="M5.5 17.5V15.8333C5.5 14.9493 5.85119 14.1014 6.47631 13.4763C7.10143 12.8512 7.94928 12.5 8.83333 12.5H12.1667"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2072_3790">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
});
