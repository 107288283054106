/* eslint-disable prettier/prettier */
import { mfaController } from "./mfa.controller";

class Service {
  public readonly state = mfaController.state;

  public readonly controller = mfaController;
}

export const mfaService = new Service();
