/* eslint-disable prettier/prettier */
/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const BlockUser = createIcon("BlockUser", (props) => {
    const { width = 25, height = 16, color = "#25476d" } = props;

    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2072_10182)">
                <path
                    d="M5.95312 5.45961C6.08142 5.87034 6.30695 6.24402 6.61058 6.54892C6.9142 6.85383 7.28694 7.08093 7.69712 7.21094M10.0351 6.84761C10.3533 6.62315 10.6184 6.33174 10.8119 5.99386C11.0054 5.65598 11.1226 5.27982 11.1552 4.89181C11.1878 4.50381 11.135 4.11338 11.0006 3.74795C10.8661 3.38252 10.6533 3.05097 10.3771 2.77658C10.1008 2.5022 9.7678 2.29164 9.40146 2.1597C9.03513 2.02777 8.64434 1.97766 8.25657 2.01289C7.8688 2.04813 7.49345 2.16786 7.15689 2.36366C6.82033 2.55946 6.53074 2.82659 6.30846 3.14628"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.5 14V12.6667C4.5 11.9594 4.78095 11.2811 5.28105 10.781C5.78115 10.281 6.45942 10 7.16667 10H9.83333C10.108 10 10.3733 10.0413 10.622 10.1187M12.3773 11.864C12.4573 12.1173 12.5 12.3873 12.5 12.6667V14"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M2.5 2L14.5 14" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2072_10182">
                    <rect width="16" height="16" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
});
