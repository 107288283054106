import { setBackgroundColor_ffffff } from "theme";
import { styled } from "theme/default/styled";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

export const STerminalsListTable = styled.div`
  ${setFlexDFFDC};
`;

export const STerminalsListTableQi = styled.div`
  ${setFlexDFFDC};
  ${setBackgroundColor_ffffff};
  border: 1px solid #939598;
  border-radius: 12px;
  max-height: 60%;
  margin-top: 16px;
  overflow: scroll;
`;

export const STerminalsListTableFooterQi = styled.div`
  padding: 8px;
  border-top: 1px solid #ccc;
  text-align: left;
  min-height: 50px;
  display: flex;
  align-items: center;
`;

export const ScrollableTableBodyQi = styled.div`
  flex-grow: 1;
  overflow-y: auto;

  height: fit-content;
`;
