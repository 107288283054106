import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";
import { SForm } from "utils/styled/SForm.styled";
import { SFormHeader, SFormHeaderContainer } from "utils/styled/SFormHeader.styled";

import { SendButton } from "./SendButton/SendButton.component";
import { SignInCapsLockErrorForm } from "./SignInCapsLockErrorForm/SignInCapsLockErrorForm.component";
import { SignInEmailInput } from "./SignInEmailInput/SignInEmailInput.component";
import { SignInErrorForm } from "./SignInErrorForm/SignInErrorForm.component";
import { SignInForgotPasswordButton } from "./SignInForgotPasswordButton/SignInForgotPasswordButton.component";
import { SignInPasswordInput } from "./SignInPasswordInput/SignInPasswordInput.component";
import { SignInRussianLetterErrorForm } from "./SignInRussianLetterErrorForm/SignInRussianLetterErrorForm.component";

export const SignInPage = createComponent("SignInPage", () => {
  const { t, i18n } = useTranslation();
  return (
    <SForm>
      <SFormHeaderContainer $isArabic={i18n.language === "ar"}>
        <SFormHeader>{t("signInPage.title")}</SFormHeader>
      </SFormHeaderContainer>
      <SignInEmailInput />
      <SignInPasswordInput />
      <SignInRussianLetterErrorForm />
      <SignInCapsLockErrorForm />
      <SignInErrorForm />
      <SignInForgotPasswordButton />
      <SendButton />
    </SForm>
  );
});
