/* eslint-disable prettier/prettier */
import { SetupMFAForm } from "components/SetupMFAForm/SetupMFAForm.component";
import { ValidateOTPForm } from "components/ValidateOTPForm/ValidateOTPForm.component";
import { useEffect } from "react";
import { mfaController } from "service/shared/singletones/mfaService/mfa.controller";
import { createComponent } from "utils";
import { useDefaultObservable } from "utils/libExtend/useDefaultObservable";

import { AppSelectLang } from "App/AppSelectLang.component";

import { PositionedDiv, Wrapper } from "./MfaPageStyled/SMfaPage.styled";

export const MfaPage = createComponent("MfaPage", () => {
  const isActive = useDefaultObservable(mfaController.state.isActive);

  useEffect(() => {
    mfaController.checkMfaReady();
  }, []);

  return (
    <Wrapper>
      <PositionedDiv>
        <AppSelectLang />
      </PositionedDiv>
      {isActive ? <ValidateOTPForm /> : <SetupMFAForm />}
    </Wrapper>
  );
});
