import { useTableContext } from "context/Table.context";
import { useDefaultObservableHeaderCellWithIdArray } from "observables/HeaderCellWithIdArrayObservable";
import { HeaderCellWithId } from "types/business/HeaderCellWithId";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { STableHeaderQi } from "utils/styled/STableQi.styled";

import { HeaderCell } from "./HeaderCell/HeaderCell.component";
import { STableHeader } from "./HeaderStyled/STableHeader.styled";
import { STableHeaderContainer } from "./HeaderStyled/STableHeaderContainer.styled";

export const Header = createComponent("Header", () => {
  const { service } = useTableContext();

  const headerCellList = useDefaultObservableHeaderCellWithIdArray(service.state.headerCellList);

  const renderHeaderCell: RenderFunctionType<HeaderCellWithId> = (headerCell) => <HeaderCell headerCell={headerCell} key={headerCell.id} />;

  return isQi ? (
    <STableHeaderQi>
      <STableHeaderContainer>{headerCellList.map(renderHeaderCell)}</STableHeaderContainer>
    </STableHeaderQi>
  ) : (
    <STableHeader>
      <STableHeaderContainer>{headerCellList.map(renderHeaderCell)}</STableHeaderContainer>
    </STableHeader>
  );
});
