import React from "react";
import styled, { css } from "styled-components";
import { setProps } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";
import { isQi } from "utils/business/environment";

type SProps = {
  isError: boolean;
};

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  align-items: center;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  align-items: center;
`);

export const SForm = styled.form<React.FormHTMLAttributes<HTMLFormElement>>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;
`;

export const SFormContainer = styled.div<SPropsIsArabic & React.HTMLAttributes<HTMLDivElement>>`
  display: flex;
  flex-direction: column;
  ${isArabicLang};
  ${isNotArabicLang};
`;

export const SHeading = styled.h4`
  font-size: 20px;
  margin: 12px 0;
  text-align: left;
  width: 220px;
`;

export const SDescription = styled.p`
  color: #6c757d;
  text-align: center;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 15px;
  font-weight: 400;
  width: 360px;
`;

export const SInputContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const SInput = styled.input<SProps & React.InputHTMLAttributes<HTMLInputElement>>`
  width: 30px;
  height: 40px;
  text-align: center;
  font-size: 16px;
  border-radius: 4px;
  outline: none;
  color: ${({ isError }: SProps): string => (isError ? "#FE5B65" : "black")};
  background-color: ${({ isError }: SProps): string => (isError ? "#FFEFF0" : "#fff")};
  border: 1px solid ${({ isError }: SProps): string => (isError ? "#FFCCCF" : "#ddd")};

  &:focus {
    border: 1px solid #ffd400;
    outline: none;
  }
`;

export const SButton = styled.button<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  background-color: ${(): string => (isQi ? "#FFD400" : "#1890ff")};
  color: ${(): string => (isQi ? "black" : "white")};
  border: none;
  padding: 8px 16px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 8px;
  width: 220px;
  height: 40px;

  &:hover {
    opacity: 0.9;
  }
`;

export const SButtonOutline = styled.button<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  background-color: transparent;
  color: ${(): string => (isQi ? "#FFD400" : "#1890ff")};
  border: 1px solid ${(): string => (isQi ? "#FFD400" : "#1890ff")};
  padding: 8px 16px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 8px;
  width: 100%;
  height: 40px;

  &:hover {
    opacity: 0.9;
  }
`;
