/* eslint-disable prettier/prettier */
import React from "react";
import { setBorderRadius_8px } from "theme";
import { styled } from "theme/default/styled";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { setBackgroundColor_ffffff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_ffffff";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";

export const SCustomDateTimeInput = styled.input.attrs({ type: "text" }) <React.InputHTMLAttributes<HTMLInputElement>>`
  height: 28px;
  padding: 0 4px;
  border: 1px solid ${color_a0b9d2};
  ${setBorderRadius_4px};
  ${setBackgroundColor_ffffff};
  cursor: pointer;
  width: 100%;
`;

export const SCustomDateTimeInputQi = styled.input.attrs({ type: "text" }) <React.InputHTMLAttributes<HTMLInputElement>>`
  ${setBorderRadius_8px};
  ${setBackgroundColor_ffffff};
  width: 240px;
  height: 40px;
  border: 1px solid #e8e8e9;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;
