/* eslint-disable prettier/prettier */
import { userService } from "api/userService";
import { apiPaths } from "appConstants/apiPaths";
import { InternalAxiosRequestConfig } from "axios";
import { t } from "i18n";
import axios, { AxiosError, AxiosRequestConfig } from "packages/axios";
import { authenticatedService } from "service/common/authenticatedService/authenticated.service";
import { toastContainerService } from "service/shared/singletones/toastContainerService/toastContainer.service";

const baseURL = "/api/v1";

export const client = axios.create({
  baseURL,
});

client.interceptors.request.use(
  (config: AxiosRequestConfig): InternalAxiosRequestConfig => {
    const isLoggedFlag = userService.getIsLoggedFlag();
    if (isLoggedFlag && config.headers) {
      config.headers.Authorization = isLoggedFlag;
    }
    return config as InternalAxiosRequestConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const createErrorMessage = (error: any, title: string): Promise<void> => {
  return Promise.reject(error).catch((axiosError: AxiosError) => {
    toastContainerService.controller.createErrorToast(title);
    throw axiosError;
  });
};

const checkUserAdminUpdateByIdEqual = (url: string): boolean => {
  const urlArray = url.split("/");
  const newUrl = urlArray.slice(0, urlArray.length - 1).join("/");

  const userAdminUpdateByIdArray = apiPaths.userAdminUpdateById.split("/");
  const newUserAdminUpdateById = userAdminUpdateByIdArray
    .slice(0, userAdminUpdateByIdArray.length - 1)
    .join("/");

  return newUrl === newUserAdminUpdateById;
};

const checkPaymentInfoIdEqual = (url: string): boolean => {
  const urlArray = url.split("/");
  const newUrl = urlArray.slice(0, urlArray.length - 1).join("/");

  const userAdminUpdateByIdArray = apiPaths.paymentInfoId.split("/");
  const newUserAdminUpdateById = userAdminUpdateByIdArray
    .slice(0, userAdminUpdateByIdArray.length - 1)
    .join("/");

  return newUrl === newUserAdminUpdateById;
};

const checkPaymentRefundEqual = (url: string): boolean => {
  const urlArray = url.split("/");
  const newUrl = urlArray.slice(0, urlArray.length - 1).join("/");

  const userAdminUpdateByIdArray = apiPaths.paymentRefund.split("/");
  const newUserAdminUpdateById = userAdminUpdateByIdArray
    .slice(0, userAdminUpdateByIdArray.length - 1)
    .join("/");

  return newUrl === newUserAdminUpdateById;
};

client.interceptors.response.use(undefined, async (error) => {
  if (error.response) {
    const { url } = error.response.config;
    const { data } = error.response;
    const { status } = error.response;

    if (checkPaymentRefundEqual(url)) {
      if (data.code === -1) {
        return createErrorMessage(
          error,
          t("userInfoPageService.errorNotSuccess")
        );
      }
    }

    if (checkUserAdminUpdateByIdEqual(url)) {
      if (data.code === 6) {
        return createErrorMessage(error, data.message);
      }

      if (data.code === -1) {
        return createErrorMessage(
          error,
          t("userInfoPageService.errorEmailExistTitle")
        );
      }
    }

    if (url === apiPaths.createUser) {
      if (data.code === 3) {
        return createErrorMessage(
          error,
          t("userInfoPageService.errorUserWithEmailNotExistTitle")
        );
      }
    }

    if (url === apiPaths.adminUserCreate) {
      if (data.code === -1) {
        return createErrorMessage(
          error,
          t("userInfoPageService.errorEmailExistTitle")
        );
      }
    }

    if (url === apiPaths.activateUser) {
      if (data.code === 0) {
        return createErrorMessage(
          error,
          t("userInfoPageService.errorInvitationCodeTitle")
        );
      }
    }

    if (url === apiPaths.login) {
      if (data.code === 3) {
        return createErrorMessage(error, data.message);
      }

      if (data.code === 13) {
        return createErrorMessage(
          error,
          t("userInfoPageService.errorPasswordExpireTitle")
        );
      }
    }

    if (url === apiPaths.rewardsList) {
      if (data.code === 2) {
        return createErrorMessage(
          error,
          t("userInfoPageService.errorTerminalsNotAvailableTitle")
        );
      }
    }

    if (checkPaymentInfoIdEqual(url)) {
      if (data.code === 0) {
        return createErrorMessage(
          error,
          t("userInfoPageService.errorNotExistPaymentTitle")
        );
      }
    }

    if (url === apiPaths.userPasswordChange) {
      if (data.code === 10) {
        return createErrorMessage(
          error,
          t("userInfoPageService.errorPasswordUnEqualTitle")
        );
      }

      if (data.code === 11) {
        return createErrorMessage(
          error,
          t("userInfoPageService.errorPasswordEqualTitle")
        );
      }

      if (data.code === 12) {
        return createErrorMessage(
          error,
          t("userInfoPageService.errorPasswordWeakTitle")
        );
      }

      return createErrorMessage(
        error,
        t("userInfoPageService.errorPasswordTitle")
      );
    }

    if (status === 400) {
      if (data.code) {
        return createErrorMessage(error, data.message);
      }
      return createErrorMessage(error, "Bad Request");
    }

    if (status === 403) {
      if (data.code) {
        return createErrorMessage(error, data.message);
      }
      return createErrorMessage(error, "Forbidden");
    }

    if (status === 500) {
      if (data.code) {
        return createErrorMessage(error, data.message);
      }
      return createErrorMessage(error, "Internal Server Error");
    }

    if (status === 401 && url !== apiPaths.mfaValidate) {
      await authenticatedService.controller.logout();
      userService.removeToken();

      if (data.code === 8) {
        return createErrorMessage(
          error,
          t("userInfoPageService.errorBadCredentialsTitle")
        );
      }

      if (data.code === 9) {
        return createErrorMessage(
          error,
          t("userInfoPageService.errorSessionExpiredTitle")
        );
      }

      return createErrorMessage(error, "Unauthorized");
    }

    if (data.code === -1) {
      return createErrorMessage(error, data.message);
    }
  }

  return createErrorMessage(error, "Unknown Error");
});
