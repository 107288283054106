import React from "react";
import { css } from "styled-components";
import { setProps } from "theme";
import { styled } from "theme/default/styled";
import { color_rgba_183_200_220_80 } from "theme/setter/opacityColorsValues/color_rgba_183_200_220_80";
import { setBackgroundColor_ffffff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_ffffff";
import { setBorderRadius_8px } from "theme/setter/setBorderRadiusValues/setBorderRadius_8px";
import { setFlexDFFDCF1 } from "theme/setter/setFlexValues/setFlexDFFDCF1";
import { SPropsIsArabic } from "types/business/SPropsType";

export const SDynamicPage = styled.div`
  margin-right: 24px;
  margin-left: 12px;
  padding: 24px;
  overflow: auto;

  box-shadow: 0 0 7px ${color_rgba_183_200_220_80};

  ${setFlexDFFDCF1};
  ${setBorderRadius_8px};
  ${setBackgroundColor_ffffff};
`;

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  margin-right: 34px;
  margin-left: 34px;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  margin-right: 34px;
  margin-left: 34px;
`);

export const SDynamicPageQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding: 24px;
  overflow: auto;
  ${setFlexDFFDCF1};
  ${setBackgroundColor_ffffff};
  border-radius: 12px;
  border: 1px solid #e8e8e9;
  ${isArabicLang};
  ${isNotArabicLang};
  margin-top: 100px;
  margin-bottom: 34px;
`;
