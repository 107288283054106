import React from "react";
import { css } from "styled-components";
import { setProps } from "theme";
import { styled } from "theme/default/styled";
import { SPropsIsArabic } from "types/business/SPropsType";

export const SUsersChangeRowGroupInputIcon = styled.button<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-left: 32px;
`;

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  position: absolute;
  left: 34px;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  position: absolute;
  right: 34px;
`);
export const SUsersChangeRowGroupInputIconQi = styled.button<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${isArabicLang};
  ${isNotArabicLang};
`;
