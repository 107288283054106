/* eslint-disable prettier/prettier */
/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const Export = createIcon("Export", (props) => {
    const { width = 24, height = 24, color = "#00346E" } = props;

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2072_1142)">
                <path
                    d="M11.666 2.5V5.83333C11.666 6.05435 11.7538 6.26631 11.9101 6.42259C12.0664 6.57887 12.2783 6.66667 12.4993 6.66667H15.8327"
                    stroke="white"
                    strokeWidth="1.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.58268 17.5H5.83268C5.39065 17.5 4.96673 17.3244 4.65417 17.0118C4.34161 16.6993 4.16602 16.2754 4.16602 15.8333V4.16667C4.16602 3.72464 4.34161 3.30072 4.65417 2.98816C4.96673 2.67559 5.39065 2.5 5.83268 2.5H11.666L15.8327 6.66667V10.8333M11.666 15.8333H17.4993M17.4993 15.8333L14.9993 13.3333M17.4993 15.8333L14.9993 18.3333"
                    stroke="white"
                    strokeWidth="1.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2072_1142">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
});
