import React from "react";
import { css, setFlexDFFDC, setFlexDFJCSB, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  justify-content: flex-end;
`);

export const SComparisonSectionGroups = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding: 0 24px;
  gap: 48px;
  flex-wrap: wrap;

  ${setFlexDFJCSB};
  ${isArabicLang};
`;

export const SComparisonSectionGroupsQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding: 0px;
  gap: 48px;
  width: 100%;
  ${setFlexDFJCSB};
  ${isArabicLang};
  margin-top: 24px;
  @media (max-width: 768px) {
    ${setFlexDFFDC};
  }
`;
