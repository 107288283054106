import React from "react";
import { css, setFlexDF, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  margin-left: 16px;
  flex-direction: row-reverse;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  margin-left: 16px;
`);

export const STerminalsFiltersContainer = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding: 16px 0;
  gap: 32px;
  ${setFlexDF};
  ${isNotArabicLang};
  ${isArabicLang};
`;

const isArabicLangQi = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  margin-left: 16px;
  flex-direction: row-reverse;
`);

const isNotArabicLangQi = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css``);

export const STerminalsFiltersContainerQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  gap: 32px;
  ${setFlexDF};
  ${isNotArabicLangQi};
  ${isArabicLangQi};
`;
