import { styled } from "theme/default/styled";
import { setFlexF1 } from "theme/setter/setFlexValues/setFlexF1";

export const STableBodyContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  ${setFlexF1};
`;

export const STableBodyContainerQi = styled.div`
  width: 100%;
  ${setFlexF1};
`;
