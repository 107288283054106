import { styled } from "theme/default/styled";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";
import { setFlexJCC } from "theme/setter/setFlexValues/setFlexJCC";
import { isQi } from "utils/business/environment";

export const SNavigationMenuIconImg = styled.div`
  width: ${isQi ? "50px" : "40px"};
  height: 40px;

  ${setFlexDFAIC};
  ${setFlexJCC};
`;
