export const apiPaths = {
  invoiceCreate: "/invoice/create",
  invoiceInfo: "/invoice/info",
  invoiceInfoId: "/invoice/info/:invoiceId",
  invoiceMail: "/invoice/mail/:invoiceId",
  invoiceSms: "/invoice/sms/:invoiceId",
  invoiceCancel: "/invoice/cancel/:invoiceId",
  rewardsList: "/settlement/list",
  rewardDetails: "/settlement/operations",
  adminGroupInfo: "/admin/group/info",

  paymentCancel: "/payment/cancel/:paymentId",
  paymentConfirm: "/payment/confirm/:paymentId",
  paymentInfo: "/payment/info",
  h2h: "/h2h/list",
  merchantList: "/merchant/list",
  paymentInfoId: "/payment/info/:paymentId",
  paymentRefund: "/payment/refund/:paymentId",

  refundInfo: "/refund/info",
  refundInfoId: "/refund/info/:refundId",

  terminalList: "/terminal/list",

  transferCancelId: "/transfer/cancel/:transferId",
  transferInfo: "/transfer/info",
  transferInfoId: "/transfer/info/:transferId",
  transfersId: "/transfers/:invoiceId",

  login: "/login",
  logout: "/logout",
  auditInfo: "/admin/audit",

  statistics: "/statistics",
  createUser: "/invitation/create",
  activateUser: "/invitation/activate",
  invitationStatus: "/invitation/status",
  adminUserCreate: "/admin/user/create",

  userEmailPhoneChange: "/internal/user/update/:userId",
  userPasswordChange: "/user/password/change",
  userInfo: "/user/info",
  invitationCreateForce: "/invitation/create/force",

  blockUserById: "/admin/user/block/:userId",
  unBlockUserById: "/admin/user/unblock/:userId",

  userAdminInfoById: "/admin/user/info/:userId",
  userAdminUpdateById: "/admin/user/update/:userId",
  userPasswordForgotChange: "/user/password/forgot/change",
  adminGroupUsers: "/admin/group/users",
  adminGroupSubs: "/admin/group/subs",
  dropSession: "/admin/session/drop/:email",
  sessionList: "/admin/session/list",
  dictionaries: "/dictionaries",

  qna: "/qna",
  assistRequest: "/assist/request",
  assistTemplate: "/assist/template/all",
  sbpMembers: "/dictionaries/sbp-members?scenario=C2BRfnd",

  mfaReady: "mfa/ready",
  mfaValidate: "mfa/validate",
  mfaGenerate: "mfa/generate",
  mfaVerify: "mfa/verify",
};
