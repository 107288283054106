import React from "react";
import { color_rgba_183_200_220_80, css, setBackgroundColor_ffffff, setBorderRadius_8px, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`);

export const SRewardDetailsInformationContainer = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding: 12px;
  box-shadow: 0 0 7px ${color_rgba_183_200_220_80};
  ${setBorderRadius_8px};
  ${setBackgroundColor_ffffff};
  ${isArabicLang};
`;

export const SRewardDetailsInformationContainerQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding: 12px;
  border-radius: 12px;
  border: 1px solid #e8e8e9;
  ${setBackgroundColor_ffffff};
  ${isArabicLang};
`;
