import { Link } from "components/Link/Link.component";
import { css } from "packages/styled-components";
import { setFontSize_12px } from "theme";
import { styled } from "theme/default/styled";
import { setColor_1e70bf } from "theme/setter/setColorsValues/setColor_1e70bf";
import { setColor_4183c4 } from "theme/setter/setColorsValues/setColor_4183c4";
import { setFontSize_14px } from "theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight400 } from "theme/setter/setFontWeightValues/setFontWeight400";
import { isFinOn, isQi } from "utils/business/environment";

const finOn = css`
  text-decoration: none;
  margin-bottom: 10px;
  ${setFontSize_14px};
`;
const sngb = css`
  text-decoration: none;
  ${setFontSize_14px};
`;

const qi = css`
  text-decoration: none;
  ${setFontSize_12px};
`;

export const SSignInForgotPasswordButton = styled(Link)`
  ${isFinOn ? finOn : isQi ? qi : sngb}
  ${setFontWeight400};
  ${setColor_4183c4};

  &:hover {
    ${setColor_1e70bf};
  }
`;
