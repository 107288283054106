/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableString } from "observables/StringObservable";
import { EyeIcon, EyeOffIcon, SToggleVisibilityButton } from "pages/SignInPage/SignInPasswordInput/SignInPasswordInputStyled/SSignInPasswordInput.styled";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { SLabel } from "utils/styled/SLabel.styled";

import { ChangePasswordInputProps } from "./ChangePasswordInput.props";
import { SChangePasswordInput } from "./ChangePasswordInputStyled/SChangePasswordInput.styled";
import { SChangePasswordInputInput, SChangePasswordInputInputQi } from "./ChangePasswordInputStyled/SChangePasswordInputInput.styled";
import { SChangePasswordInputTitle } from "./ChangePasswordInputStyled/SChangePasswordInputTitle.styled";

export const ChangePasswordInput = createComponent<ChangePasswordInputProps>("ChangePasswordInput", (props) => {
  const { title, service } = props;
  const { t, i18n } = useTranslation();
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const value = useDefaultObservableString(service.state.value);
  const showError = useDefaultObservableBoolean(service.state.showError);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  return isQi ? (
    <>
      {isQi && (
        <SLabel htmlFor="password" $isArabic={i18n.language === "ar"}>
          {title}
        </SLabel>
      )}

      <div style={{ position: "relative" }}>
        <SChangePasswordInputInputQi
          showError={showError}
          onKeyUp={service.controller.onKeyUp}
          type={isPasswordVisible ? "text" : "password"}
          value={value}
          onChange={service.controller.onChange}
        />
        {isQi && (
          <SToggleVisibilityButton onClick={togglePasswordVisibility} type="button" $isArabic={i18n.language === "ar"}>
            {isPasswordVisible ? <EyeOffIcon /> : <EyeIcon />}
          </SToggleVisibilityButton>
        )}
      </div>
    </>
  ) : (
    <SChangePasswordInput>
      <SChangePasswordInputTitle>{title}</SChangePasswordInputTitle>
      <div>
        <SChangePasswordInputInput
          showError={showError}
          onKeyUp={service.controller.onKeyUp}
          type="password"
          value={value}
          onChange={service.controller.onChange}
        />
      </div>
    </SChangePasswordInput>
  );
});
