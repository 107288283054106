import { Table } from "components/Table/Table.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableTableService } from "observables/TableServiceObservable";
import { useTranslation } from "react-i18next";
import { invoicesPageService } from "service/shared/singletones/invoicesPageService/invoicesPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { STableComponentQi, STableContainerQi } from "utils/styled/STableQi.styled";

import { SInvoicesTable } from "./InvoicesTableStyled/SInvoicesTable.styled";
import { SInvoicesTableContainer } from "./InvoicesTableStyled/SInvoicesTableContainer.styled";

export const InvoicesTable = createComponent("InvoicesTable", () => {
  const loaded = useDefaultObservableBoolean(invoicesPageService.state.loaded);
  const tableService = useDefaultObservableTableService(invoicesPageService.tableService);
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return isQi ? (
    <STableComponentQi isArabic={isArabic}>
      <STableContainerQi isArabic={isArabic}>
        <Table loaded={loaded} service={tableService} />
      </STableContainerQi>
    </STableComponentQi>
  ) : (
    <SInvoicesTable>
      <SInvoicesTableContainer>
        <Table loaded={loaded} service={tableService} />
      </SInvoicesTableContainer>
    </SInvoicesTable>
  );
});
