import { ChangeUserInfoModal } from "components/ChangeUserInfoModal/ChangeUserInfoModal.component";
import { useTranslation } from "react-i18next";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { OpenUserFormButton } from "./OpenUserFormButton/OpenUserFormButton.component";
import { UsersInfoPageLayoutProps } from "./UsersInfoPageLayout.props";
import { SUsersInfoPageLayout, SUsersInfoPageLayoutQi } from "./UsersInfoPageLayoutStyled/SUsersInfoPageLayout.styled";
import { SUsersInfoPageLayoutContainer } from "./UsersInfoPageLayoutStyled/SUsersInfoPageLayoutContainer.styled";
import { SUsersInfoPageLayoutContent, SUsersInfoPageLayoutContentQi } from "./UsersInfoPageLayoutStyled/SUsersInfoPageLayoutContent.styled";

export const UsersInfoPageLayout = createComponent<UsersInfoPageLayoutProps>("UsersInfoPageLayout", (props) => {
  const { children } = props;
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return isQi ? (
    <SUsersInfoPageLayoutQi isArabic={isArabic}>
      <OpenUserFormButton />
      <SUsersInfoPageLayoutContentQi>
        <SUsersInfoPageLayoutContainer>{children}</SUsersInfoPageLayoutContainer>
      </SUsersInfoPageLayoutContentQi>

      <ChangeUserInfoModal />
    </SUsersInfoPageLayoutQi>
  ) : (
    <SUsersInfoPageLayout>
      <OpenUserFormButton />

      <SUsersInfoPageLayoutContent>
        <SUsersInfoPageLayoutContainer>{children}</SUsersInfoPageLayoutContainer>
      </SUsersInfoPageLayoutContent>
    </SUsersInfoPageLayout>
  );
});
