import styled from "styled-components";

export const SDivider = styled.div`
  border-bottom: 1px solid #e0e0e0;
  margin: 8px -16px 18px -16px;
`;

export const SDividerVertical = styled.div`
  width: 1px;
  background-color: #0000004d;
  margin: 0 8px;
`;

export const SDividerVerticalQi = styled.div`
  width: 1px;
  height: 100%;
  background-color: #0000004d;
  margin: 0 8px;
  position: absolute;
  left: 49.24%;
`;
