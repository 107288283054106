import React from "react";
import { css, setFlexDFAIC, setFontSize_18px, setFontSize_20px, setFontWeight400, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";
import { isQi } from "utils/business/environment";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  justify-content: flex-start;
  flex-direction: row-reverse;
`);

export const SDetailsLayoutHeaderContainer = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-bottom: ${!isQi && "20px"};
  ${setFlexDFAIC};
  ${setFontSize_18px};
  ${setFontWeight400};
  ${isArabicLang};
`;

export const SDetailsLayoutHeaderContainerQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-bottom: 20px;
  ${setFlexDFAIC};
  ${setFontSize_20px};
  ${setFontWeight400};
  ${isArabicLang};
`;
