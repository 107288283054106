/* eslint-disable prettier/prettier */
/* eslint-disable max-len */

import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const ResetIcon = createIcon("ResetIcon", (props) => {
    const { width = 26, height = 14, color = props.isFinOn ? colorsValuesFin.color_0083f5 : "#82B8E7" } = props;

    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2072_6327)">
                <path d="M9.5 12.75V15.75" stroke="#1C75D7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 15L11 13.5" stroke="#1C75D7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 13.5L11 15" stroke="#1C75D7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4.25 12.75V15.75" stroke="#1C75D7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.75 15L5.75 13.5" stroke="#1C75D7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.75 13.5L5.75 15" stroke="#1C75D7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14.75 12.75V15.75" stroke="#1C75D7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.25 15L16.25 13.5" stroke="#1C75D7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.25 13.5L16.25 15" stroke="#1C75D7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.25 4.5C7.25 5.09674 7.48705 5.66903 7.90901 6.09099C8.33097 6.51295 8.90326 6.75 9.5 6.75C10.0967 6.75 10.669 6.51295 11.091 6.09099C11.5129 5.66903 11.75 5.09674 11.75 4.5C11.75 3.90326 11.5129 3.33097 11.091 2.90901C10.669 2.48705 10.0967 2.25 9.5 2.25C8.90326 2.25 8.33097 2.48705 7.90901 2.90901C7.48705 3.33097 7.25 3.90326 7.25 4.5Z" stroke="#1C75D7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.75 10.5C5.75 10.1022 5.90804 9.72064 6.18934 9.43934C6.47064 9.15804 6.85218 9 7.25 9H11.75C12.1478 9 12.5294 9.15804 12.8107 9.43934C13.092 9.72064 13.25 10.1022 13.25 10.5" stroke="#1C75D7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2072_6327">
                    <rect width="18" height="18" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
});