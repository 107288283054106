/* eslint-disable prettier/prettier */
/* eslint-disable max-len */

import { colorsValues } from "theme/values/colorsValues";
import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const ClearIcon = createIcon("ClearIcon", (props) => {
    const { width = 22, height = 22, color = props.isFinOn ? colorsValuesFin.main.icon_fill : colorsValues.main.icon_fill } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2072_1432)">
                <path
                    d="M9.17135 12.9427L5.99935 14V8.33335L3.01268 5.04802C2.78965 4.80264 2.66605 4.48295 2.66602 4.15135V2.66669H13.3327V4.11469C13.3326 4.46828 13.1921 4.80736 12.942 5.05735L9.99935 8.00002V9.00002"
                    stroke="#D0AE09"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M14.6673 14.6666L11.334 11.3333" stroke="#D0AE09" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.334 14.6666L14.6673 11.3333" stroke="#D0AE09" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2072_1432">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
});
