import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useFiltersContext } from "context/Filter.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableOptionModel } from "observables/OptionModelObservable";
import { useTranslation } from "react-i18next";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { SFilterSelectQi, SFilterSelectSelectQi } from "utils/styled/SInputSelectFiltersQi.styled";
import { SInputTitleContainerQi, SInputTitleQi } from "utils/styled/SInputTitle.styled";

import { SInputContainerQi } from "../FilterInput/FilterInputStyled/SFilterInput.styled";
import { FilterSelectProps } from "./FilterSelect.props";
import { SFilterSelect } from "./FilterSelectStyled/SFilterSelect.styled";
import { SFilterSelectClear, SFilterSelectClearQi } from "./FilterSelectStyled/SFilterSelectClear.styled";
import { SFilterSelectContainer } from "./FilterSelectStyled/SFilterSelectContainer.styled";
import { SFilterSelectDelete } from "./FilterSelectStyled/SFilterSelectDelete.styled";
import { SFilterSelectSelect } from "./FilterSelectStyled/SFilterSelectSelect.styled";
import { SFilterSelectSelectContainer } from "./FilterSelectStyled/SFilterSelectSelectContainer.styled";
import { SFilterSelectTitle } from "./FilterSelectStyled/SFilterSelectTitle.styled";

export const FilterSelect = createComponent<FilterSelectProps>("FilterSelect", (props) => {
  const { t } = useTranslation();
  const { service } = props;
  const { service: filterService } = useFiltersContext();

  const value = useDefaultObservableOptionModel(service.value);
  const isArabic = useDefaultObservableBoolean(filterService.state.isArabic);

  return isQi ? (
    <SInputContainerQi isArabic={isArabic}>
      <SFilterSelectQi>
        <SInputTitleContainerQi isArabic={isArabic}>
          <SInputTitleQi isArabic={isArabic}>{t(service.filterModel.langPath)}</SInputTitleQi>
          {filterService.state.showCloseIcon && (
            <SFilterSelectDelete isArabic={isArabic} onClick={service.onClickTrash}>
              <SvgImage name="Cross" />
            </SFilterSelectDelete>
          )}
        </SInputTitleContainerQi>
        <SFilterSelectContainer isArabic={isArabic}>
          <SFilterSelectSelectContainer>
            <SFilterSelectSelectQi
              isTitle={value.isTitle}
              ref={service.inputRef}
              onChange={service.onChange}
              value={value}
              selectTitle={service.filterModel.selectTitle}
              selectTitleValue={service.filterModel.selectTitle}
              optionList={service.filterModel.optionList ?? []}
            />

            <SFilterSelectClearQi isArabic={isArabic} onClick={service.onClickClear}>
              <SvgImage name="ArrowDownSelect" />
            </SFilterSelectClearQi>
          </SFilterSelectSelectContainer>
        </SFilterSelectContainer>
      </SFilterSelectQi>
    </SInputContainerQi>
  ) : (
    <SFilterSelect>
      <SFilterSelectTitle isArabic={isArabic}>{t(service.filterModel.langPath)}</SFilterSelectTitle>

      <SFilterSelectContainer isArabic={isArabic}>
        <SFilterSelectSelectContainer>
          <SFilterSelectSelect
            isTitle={value.isTitle}
            ref={service.inputRef}
            onChange={service.onChange}
            value={value}
            selectTitle={service.filterModel.selectTitle}
            selectTitleValue={service.filterModel.selectTitle}
            optionList={service.filterModel.optionList ?? []}
          />

          <SFilterSelectClear isArabic={isArabic} onClick={service.onClickClear}>
            <SvgImage width={20} height={20} name="Close" />
          </SFilterSelectClear>
        </SFilterSelectSelectContainer>

        {filterService.state.showCloseIcon && (
          <SFilterSelectDelete isArabic={isArabic} onClick={service.onClickTrash}>
            <SvgImage width={20} height={20} name="DeleteFilter" />
          </SFilterSelectDelete>
        )}
      </SFilterSelectContainer>
    </SFilterSelect>
  );
});
