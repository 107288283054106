import { Loader } from "components/Loader/Loader.component";
import { useTableContext } from "context/Table.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { isQi } from "utils/business/environment";
import { doesPathContain } from "utils/commonExtend/doesPathContain";
import { createComponent } from "utils/libExtend/createComponent";

import { Body } from "./Body/Body.component";
import { Header } from "./Header/Header.component";
import { STable, STableQi } from "./TableContentStyled/STable.styled";
import { STableChildQi } from "./TableContentStyled/STableChild.styled";
import { STableContainer, STableContainerEmptyQi } from "./TableContentStyled/STableContainer.styled";
import { STableEmpty, STableEmptyQi } from "./TableContentStyled/STableEmpty.styled";
import { STableLoader } from "./TableContentStyled/STableLoader.styled";

export const TableContent = createComponent("TableContent", () => {
  const { t, i18n } = useTranslation();
  const { loaded, service } = useTableContext();
  const isArabic = i18n.language === "ar";

  const isEmpty = useDefaultObservableBoolean(service.state.isEmpty);
  const location = useLocation();

  const isNotStatistic = !doesPathContain(location.pathname, "statistic");
  const isChildInvoicesPage =
    doesPathContain(location.pathname, "invoices/") || doesPathContain(location.pathname, "payments/") || doesPathContain(location.pathname, "refunds/");

  if (!loaded) {
    return isQi ? (
      <STableQi isArabic={isArabic} isNotStatistic={isNotStatistic}>
        <STableLoader>
          <Loader />
        </STableLoader>
      </STableQi>
    ) : (
      <STable>
        <STableLoader>
          <Loader />
        </STableLoader>
      </STable>
    );
  }

  if (isEmpty) {
    return isQi ? (
      <STableQi isArabic={isArabic} isNotStatistic={isNotStatistic}>
        <STableContainerEmptyQi>
          <Header />
          <STableEmptyQi>{t("tableContent.notDataTitle")}</STableEmptyQi>
        </STableContainerEmptyQi>
      </STableQi>
    ) : (
      <STable>
        <STableContainer>
          <Header />
          <STableEmpty>{t("tableContent.notDataTitle")}</STableEmpty>
        </STableContainer>
      </STable>
    );
  }

  if (isQi && isChildInvoicesPage) {
    return (
      <STableChildQi isArabic={isArabic} isNotStatistic={isNotStatistic}>
        <STableContainer>
          <Header />
          <Body />
        </STableContainer>
      </STableChildQi>
    );
  }

  return isQi ? (
    <STableQi isArabic={isArabic} isNotStatistic={isNotStatistic}>
      <STableContainer>
        <Header />
        <Body />
      </STableContainer>
    </STableQi>
  ) : (
    <STable>
      <STableContainer>
        <Header />
        <Body />
      </STableContainer>
    </STable>
  );
});
