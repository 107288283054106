import React from "react";
import { borderRadius_5px, color_rgba_183_200_220_80, css, setBackgroundColor_f7faff, setFlexDFAIC, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  border-top-left-radius: ${borderRadius_5px};
  border-bottom-left-radius: ${borderRadius_5px};
  justify-content: flex-start;
`);
const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  border-top-right-radius: ${borderRadius_5px};
  border-bottom-right-radius: ${borderRadius_5px};
  justify-content: flex-end;
`);

export const SNavigationMenuToggler = styled.button<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  height: 40px;
  margin-bottom: 12px;

  box-shadow: 0 0 5px ${color_rgba_183_200_220_80};
  ${isArabicLang};
  ${isNotArabicLang};
  ${setFlexDFAIC};
  ${setBackgroundColor_f7faff};
`;

const isArabicLangQi = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  justify-content: flex-start;
  left: -4px;
`);
const isNotArabicLangQi = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  justify-content: flex-end;
  right: -4px;
`);

export const SNavigationMenuTogglerQi = styled.button<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  height: 40px;
  margin-top: 8px;
  ${isArabicLangQi};
  ${isNotArabicLangQi};
  ${setFlexDFAIC};
  width: 100%;
  position: absolute;
`;
