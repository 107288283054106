import { styled } from "theme/default/styled";
import { isQi } from "utils/business/environment";

export const SPopUpContainer = styled.div`
  position: relative;
  z-index: 20;
  ${isQi &&
  `
      > div {
        background-color: rgba(0, 0, 0, 0.1);
      }
    `}
`;
