import { ButtonsModal } from "components/ButtonsModal/ButtonsModal";
import { Header } from "components/HeaderModal/HeaderModal.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { createUserServiceFormService } from "service/shared/singletones/createUserServiceFormService/createUserServiceForm.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { SModalContainer, SModalPopUp } from "utils/styled/SModalQi";

import { CreateUserFetchedMultipleSelect } from "./CreateUserFetchedMultipleSelect/CreateUserFetchedMultipleSelect.component";
import { CreateUserFetchedSelect } from "./CreateUserFetchedSelect/CreateUserFetchedSelect.component";
import { SCreateUserForm } from "./CreateUserFormStyled/SCreateUserForm.styled";
import { SCreateUserFormPopUp } from "./CreateUserFormStyled/SCreateUserFormPopUp.styled";
import { SCreateUserFormTitle } from "./CreateUserFormStyled/SCreateUserFormTitle.styled";
import { CreateUserInviteButton } from "./CreateUserInviteButton/CreateUserInviteButton.component";
import { CreateUserSelect } from "./CreateUserSelect/CreateUserSelect.component";

export const CreateUserForm = createComponent("CreateUserForm", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const open = useDefaultObservableBoolean(createUserServiceFormService.state.open);

  if (!open) {
    return null;
  }

  return isQi ? (
    <SModalPopUp closeCallBack={createUserServiceFormService.controller.onClickCloseForm}>
      <SModalContainer isArabic={isArabic}>
        <Header title={`${t("usersInfoPage.createUserFormTitle")}`} onClose={createUserServiceFormService.controller.onClickCloseForm} />
        <CreateUserFetchedSelect service={createUserServiceFormService.state.merchantGroupIdService} />
        <CreateUserFetchedMultipleSelect />
        <CreateUserSelect service={createUserServiceFormService.state.emailService} />
        <CreateUserSelect service={createUserServiceFormService.state.nameService} />
        <CreateUserSelect service={createUserServiceFormService.state.phoneNumberService} />
        <ButtonsModal>
          <CreateUserInviteButton />
        </ButtonsModal>
      </SModalContainer>
    </SModalPopUp>
  ) : (
    <SCreateUserFormPopUp closeCallBack={createUserServiceFormService.controller.onClickCloseForm}>
      <SCreateUserForm>
        <SCreateUserFormTitle>{t("usersInfoPage.createUserFormTitle")}</SCreateUserFormTitle>
        <CreateUserFetchedSelect service={createUserServiceFormService.state.merchantGroupIdService} />
        <CreateUserFetchedMultipleSelect />
        <CreateUserSelect service={createUserServiceFormService.state.emailService} />
        <CreateUserSelect service={createUserServiceFormService.state.nameService} />
        <CreateUserSelect service={createUserServiceFormService.state.phoneNumberService} />
        <CreateUserInviteButton />
      </SCreateUserForm>
    </SCreateUserFormPopUp>
  );
});
