import React from "react";
import { css, setProps, styled } from "theme";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";
import { SPropsIsArabic } from "types/business/SPropsType";

export const SUsersInfoPageLayout = styled.div`
  margin-bottom: 8px;
  overflow: hidden;
  padding-left: 15px;
  padding-right: 24px;
  ${setFlexDFFDC};
`;

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  padding-left: 34px;
  padding-right: 34px;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  padding-right: 34px;
  padding-left: 34px;
`);

export const SUsersInfoPageLayoutQi = styled.div<SPropsIsArabic & React.HTMLAttributes<HTMLDivElement>>`
  margin-bottom: 8px;
  overflow: hidden;
  width: 100%;
  ${setFlexDFFDC};
  ${isNotArabicLang};
  ${isArabicLang};
  height: fit-content;
  margin-top: 100px;
`;
