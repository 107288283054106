import { useFiltersContext } from "context/Filter.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useLocation } from "react-router-dom";
import { isQi } from "utils/business/environment";
import { doesPathContain } from "utils/commonExtend/doesPathContain";
import { createComponent } from "utils/libExtend/createComponent";

import { AddFilterButton } from "../FilterMenu/AddFilterButton/AddFilterButton.component";
import { ActiveFilterList } from "./ActiveFilterList/ActiveFilterList.component";
import { SFilterList } from "./FilterListStyled/SFilterList.styled";
import { FilterSelector } from "./FilterSelector/FilterSelector.component";

export const FilterList = createComponent("FilterList", () => {
  const { service } = useFiltersContext();
  const isArabic = useDefaultObservableBoolean(service.state.isArabic);
  const location = useLocation();

  const isPaymentsPage = !doesPathContain(location.pathname, "rewards") && !doesPathContain(location.pathname, "statistic");
  return (
    <SFilterList isArabic={isArabic}>
      <ActiveFilterList />
      <FilterSelector />
      {isQi && isPaymentsPage && <AddFilterButton />}
    </SFilterList>
  );
});
