/* eslint-disable prettier/prettier */
import { CustomSelect } from "components";
import React from "react";
import styled, { css } from "styled-components";
import { setFontSize_20px, setProps, setTextRightArabic } from "theme";
import { color_e0b4b4 } from "theme/setter/colorsValues/color_e0b4b4";
import { setBorderRadius_8px } from "theme/setter/setBorderRadiusValues/setBorderRadius_8px";
import { setFontWeight500 } from "theme/setter/setFontWeightValues/setFontWeight500";
import { SPropsIsArabic } from "types/business/SPropsType";

type SProps = {
  isError: boolean;
};

const isErrorSet = setProps<SProps>(({ isError }) => isError)(css`
  border: 1px solid ${color_e0b4b4};
`);

const isErrorNotSet = setProps<SProps>(({ isError }) => !isError)(css`
 border: 1px solid #e8e8e9;
`);

export const SCompareInputQi = styled(CustomSelect) <SProps>`
  width: 100%;
  height: 40px;
  background-color: transparent;
  appearance: none;
  border: 1px solid #e8e8e9;
  ${isErrorSet};
  ${isErrorNotSet};
  ${setBorderRadius_8px};
`;

export const SComparisonSectionTitleQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  color: #14181f;
  ${setFontSize_20px};
  ${setFontWeight500};
  ${setTextRightArabic()};
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
    justify-content: flex-start;
    flex-direction: row-reverse;
  `);

export const SDetailsContainerQi = styled.div<SPropsIsArabic & React.HTMLAttributes<HTMLDivElement>>`
    display: flex; 
    alignItems: center;
    margin-left: 8px;
    margin-right: 8px;
    ${isArabicLang};
  `;
