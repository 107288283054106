import React from "react";
import { css, setFlexDF, setFlexDFFDC, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);

export const SRangeInputContainer = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFlexDF};
  ${isArabicLang};
  gap: 16px;
  @media (max-width: 768px) {
    ${setFlexDFFDC};
  }
`;

const isArabicLangQi = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  text-align: end;
`);
const isNotArabicLangQi = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  text-align: start;
`);

export const SInputTitleContainerQi = styled.div<SPropsIsArabic & React.HTMLAttributes<HTMLDivElement>>`
  margin-bottom: 8px;
  ${setFlexDF};
  ${isArabicLangQi};
  ${isNotArabicLangQi};
`;
