import React from "react";
import { css } from "theme/default/css";
import { keyframes } from "theme/default/keyframes";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";

type SToastProps = {
  isOpen: boolean;
  isClose: boolean;
};

const bounceOutAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-30px);
  }
  100% {
    transform: translateX(600px);
  }
`;

const bounceInAnimation = keyframes`
  0% {
    transform: translateX(600px);
  }
  100% {
    transform: translateX(0);
  }
`;

const isOpenSet = setProps<SToastProps>(({ isOpen }) => isOpen)(css`
  transform: translateX(0);

  animation: ${bounceInAnimation} 1s ease forwards;
`);

const isCloseSet = setProps<SToastProps>(({ isClose }) => isClose)(css`
  animation: ${bounceOutAnimation} 1s ease forwards;
`);

export const SToast = styled.button<SToastProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  transform: translateX(600px);

  ${isOpenSet};
  ${isCloseSet};
  z-index: 10;
`;
