import React from "react";
import { css } from "styled-components";
import { styled } from "theme/default/styled";
import { setColor_22211f_black } from "theme/setter/setColorsValues/qiColors/setColor_22211f_black";
import { setColor_0083f5 } from "theme/setter/setColorsValues/setColor_0083f5";
import { setFontSize_14px } from "theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";
import { isFinOn, isQi } from "utils/business/environment";

const finOn = css`
  padding: 4px 12px;
  border-radius: 14px;
  border: solid;
  ${setColor_0083f5};
`;

const sngb = css`
  margin-top: 15px;
  padding: 4px 0;
  ${setColor_0083f5};
`;

const qi = css`
  margin-top: 25px;
  padding: 4px 0;
  background-color: #ffd400;
  height: 40px;
  border-radius: 8px;
  color: black;
  ${setColor_22211f_black}
`;

export const SForgotPasswordSendButton = styled.button<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${isFinOn ? finOn : isQi ? qi : sngb};
  ${setFontSize_14px};
  ${setFontWeight600};
`;
