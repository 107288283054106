import { styled } from "theme/default/styled";
import { setBackgroundColor_93a5b8 } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_93a5b8";

export const SHeaderCellDraggable = styled.div`
  width: 1px;
  height: 18px;

  ${setBackgroundColor_93a5b8};
`;

export const SHeaderCellDraggableQi = styled.div`
  height: 50px;
  background-color: #0000004d;
`;
