import React from "react";
import { setFontSize_14px } from "theme";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { setColor_0083f5 } from "theme/setter/setColorsValues/setColor_0083f5";
import { setFontSize_13px } from "theme/setter/setFontSizeValues/setFontSize_13px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";

type SResetButtonProps = {
  filterListIsEmpty?: boolean;
  isArabic?: boolean;
};

const filterListIsEmptySet = setProps<SResetButtonProps>(({ filterListIsEmpty }) => !filterListIsEmpty)(css`
  opacity: 0.45;
`);
const isArabicLang = setProps<SResetButtonProps>(({ isArabic }) => isArabic)(css`
  justify-content: flex-start;
  flex-direction: row-reverse;
`);

export const SResetButton = styled.button<SResetButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFontSize_13px};
  ${setFontWeight600};
  ${setColor_0083f5};
  ${filterListIsEmptySet};
`;

export const SResetButtonQi = styled.button<SResetButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFontSize_14px};
  ${setFontWeight600};
  ${filterListIsEmptySet};
  ${isArabicLang}
  display: flex;
  align-items: center;
  padding: 10px 14px;
  background-color: white;
  color: #d0ae09;
  border: 2px dashed #d0ae09;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: auto;
  height: 40px;
  gap: 4px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
