import React from "react";
import { borderRadius_5px, color_93a5b8, css, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  border-top-left-radius: ${borderRadius_5px};
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  border-top-right-radius: ${borderRadius_5px};
`);

export const SPaymentCancelTableTable = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-top: -14px;
  margin-left: 12px;
  padding: 10px;

  border: 1px solid ${color_93a5b8};
  border-bottom-right-radius: ${borderRadius_5px};
  border-bottom-left-radius: ${borderRadius_5px};
  ${isNotArabicLang};
  ${isArabicLang};
`;

export const SPaymentCancelTableTableQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-top: 16px;
  ${isNotArabicLang};
  ${isArabicLang};
`;
// padding: 10px;
