import { Loader } from "components/Loader/Loader.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { supportPageService } from "service/shared/singletones/supportPageService/supportPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { FeedbackFormSelector } from "./FeedbackFormSelector/FeedbackFormSelector.component";
import { FeedbackFormSendButton } from "./FeedbackFormSendButton/FeedbackFormSendButton.component";
import { FeedbackFormTextField } from "./FeedbackFormTextField/FeedbackFormTextField.component";
import { SSupportPageFeedbackForm } from "./SupportPageFeedbackFormStyled/SSupportPageFeedbackForm.styled";
import { SSupportPageFeedbackFormContent, SSupportPageFeedbackFormContentQi } from "./SupportPageFeedbackFormStyled/SSupportPageFeedbackFormContent.styled";
import { SSupportPageFeedbackFormForm, SSupportPageFeedbackFormFormQi } from "./SupportPageFeedbackFormStyled/SSupportPageFeedbackFormForm.styled";
import { SSupportPageFeedbackFormTitle } from "./SupportPageFeedbackFormStyled/SSupportPageFeedbackFormTitle.styled";

export const SupportPageFeedbackForm = createComponent("SupportPageFeedbackForm", () => {
  const { t, i18n } = useTranslation();
  const loadedForm = useDefaultObservableBoolean(supportPageService.state.loadedForm);
  const isArabic = i18n.language === "ar";

  if (!loadedForm) {
    return <Loader />;
  }

  return (
    <SSupportPageFeedbackForm isArabic={isArabic}>
      {!isQi && <SSupportPageFeedbackFormTitle>{t("supportPage.feedbackFormTitle")}</SSupportPageFeedbackFormTitle>}
      {isQi ? (
        <SSupportPageFeedbackFormContentQi isArabic={isArabic}>
          <FeedbackFormSelector />
          <SSupportPageFeedbackFormFormQi isArabic={isArabic}>
            <FeedbackFormTextField />
            <FeedbackFormSendButton />
          </SSupportPageFeedbackFormFormQi>
        </SSupportPageFeedbackFormContentQi>
      ) : (
        <SSupportPageFeedbackFormContent isArabic={isArabic}>
          <FeedbackFormSelector />
          <SSupportPageFeedbackFormForm>
            <FeedbackFormTextField />
            <FeedbackFormSendButton />
          </SSupportPageFeedbackFormForm>
        </SSupportPageFeedbackFormContent>
      )}
    </SSupportPageFeedbackForm>
  );
});
