import React from "react";
import { css, setFlexDF, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  align-self: flex-end;
`);

export const SCreateFormButtons = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-top: 20px;
  padding: 0 20px;
  gap: 10px;
  ${setFlexDF};
  ${isArabicLang};
`;

export const SCreateFormButtonsQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-top: 20px;
  padding: 20px 0px 0px 0px;
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e8e8e9;
  ${isArabicLang};
`;
