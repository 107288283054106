export const colorsValues = {
  color_0083f5: "#0083f5",
  color_00346e: "#00346e",
  color_69809a: "#69809a",
  color_25476d: "#25476d",
  color_9f3a38: "#9f3a38",
  color_8bc3f5: "#8bc3f5",
  color_4183c4: "#4183c4",
  color_7b7b7b: "#7b7b7b",
  color_fff6f6: "#fff6f6",
  color_4d4d4d: "#4d4d4d",
  color_f7faff: "#f7faff",
  color_93a5b8: "#93a5b8",
  color_ffffff: "#ffffff",
  color_2b3e50: "#2b3e50",
  color_2d3033: "#2d3033",
  color_c4edff: "#c4edff",
  color_3a8fda: "#3a8fda",
  color_1839d5: "#1839d5",
  color_16e527: "#16e527",
  color_f0fbff: "#f0fbff",
  color_e74c3c: "#e74c3c",
  color_1e70bf: "#1e70bf",
  color_a0b9d2: "#a0b9d2",
  color_b7c8dc: "#b7c8dc",
  color_458cce: "#458cce",
  color_f6b57a: "#f6b57a",
  color_e7ecf2: "#e7ecf2",
  color_e0b4b4: "#e0b4b4",
  color_47a0ef: "#47a0ef",
  color_f5f5f5: "#f5f5f5",
  color_e8e8e8: "#e8e8e8",
  main: {
    menu_text: "#0000EE",
    icon_fill: "#208CEB",
    container_text: "#25476d",
  },
  qi: {
    color_ffd400_yellow: "#ffd400",
    color_22211f_black: "#22211f",
  },
};
