import React from "react";
import { css, setFlexDFAICJCSB, setProps, setRowReverse, styled } from "theme";

type SRowProps = {
  isMedium: boolean;
  isIntermediate: boolean;
  isBig: boolean;
  isArabic: boolean;
};

const isMediumSet = setProps<SRowProps>(({ isMedium }) => isMedium)(css`
  width: 440px;
`);

const isBigSet = setProps<SRowProps>(({ isBig }) => isBig)(css`
  width: 500px;
`);

export const SRow = styled.div<SRowProps & React.HTMLAttributes<HTMLDivElement>>`
  margin-bottom: 10px;

  ${setFlexDFAICJCSB};
  ${isMediumSet};
  ${isBigSet};
  ${setRowReverse()};
`;

const isMediumSetQi = setProps<SRowProps>(({ isMedium }) => isMedium)(css`
  width: 50%;
`);
const isIntermediateSetQi = setProps<SRowProps>(({ isIntermediate }) => isIntermediate)(css`
  width: 70%;
`);

const isBigSetQi = setProps<SRowProps>(({ isBig }) => isBig)(css`
  width: 100%;
`);

export const SRowQi = styled.div<SRowProps & React.HTMLAttributes<HTMLDivElement>>`
  margin-bottom: 10px;
  ${setFlexDFAICJCSB};
  ${isMediumSetQi};
  ${isBigSetQi};
  ${isIntermediateSetQi};
  ${setRowReverse()};
`;
