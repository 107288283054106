/* eslint-disable prettier/prettier */
import { SvgImage } from "components/SvgImage/SvgImage.component";
import React from "react";
import { useTranslation } from "react-i18next";
import { SvgNames } from "types/business/SvgNames";

import { SButtonCloseQi, SHeaderContainerQi, SHeaderTitleQi } from "./HeaderStyled/Header.styled";

interface HeaderProps {
    title: string;
    onClose: () => void;
    icon?: SvgNames;
}

export const Header: React.FC<HeaderProps> = ({ title, onClose, icon }) => {
    const { i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    return (
        <SHeaderContainerQi isArabic={isArabic}>
            <SHeaderTitleQi>
                {icon && <SvgImage name={icon} color="#FE5B65" />}
                {title}
            </SHeaderTitleQi>
            <SButtonCloseQi isArabic={isArabic} onClick={onClose}>
                <SvgImage name="Close" color="#707a8f" />
            </SButtonCloseQi>
        </SHeaderContainerQi>
    );
};
