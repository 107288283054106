/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
import { createIcon } from "utils/libExtend/createIcon";

export const Apply = createIcon("Apply", (props) => {
    const { width = 9, height = 14, color = "#69809A" } = props;

    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2072_1425)">
                <path d="M8.52 15.1597L6.75 15.75V9.375L3.39 5.679C3.13909 5.40294 3.00004 5.0433 3 4.67025V3H15V4.629C14.9999 5.02679 14.8418 5.40826 14.5605 5.6895L11.25 9" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.25 13.5C11.25 14.0967 11.4871 14.669 11.909 15.091C12.331 15.5129 12.9033 15.75 13.5 15.75C14.0967 15.75 14.669 15.5129 15.091 15.091C15.5129 14.669 15.75 14.0967 15.75 13.5C15.75 12.9033 15.5129 12.331 15.091 11.909C14.669 11.4871 14.0967 11.25 13.5 11.25C12.9033 11.25 12.331 11.4871 11.909 11.909C11.4871 12.331 11.25 12.9033 11.25 13.5Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.1504 15.15L16.5004 16.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2072_1425">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
});

