import { routerPaths } from "appConstants/routerPaths";
import { DetailsCell } from "detailsCell/DetailsCell/DetailsCell.component";
import { UserDetailsCell } from "detailsCell/UserDetailsCell/UserDetailsCell.component";
import { isQi } from "utils/business/environment";
import { replacePath } from "utils/commonExtend/replacePath";
import { createComponent } from "utils/libExtend/createComponent";

import { GroupUserCellProps } from "./GroupUserCell.props";
import { SGroupUserCellTitle, SGroupUserCellTitleQi } from "./GroupUserCellStyled/SGroupUserCellTitle.styled";
import { SGroupUserCellTitleButton, SGroupUserCellTitleButtonQi } from "./GroupUserCellStyled/SGroupUserCellTitleButton.styled";

export const GroupUserCell = createComponent<GroupUserCellProps>("GroupUserCell", (props) => {
  const { isFirst, groupUserModel, groupUserCellModel } = props;

  const style = {
    width: groupUserCellModel.width,
  };

  const isDetailsCell = groupUserCellModel.name === "details";

  if (isFirst) {
    return <div style={style} />;
  }

  if (isDetailsCell) {
    const to = replacePath(routerPaths.usersDetailsPage, {
      userId: groupUserModel.id,
    });

    return isQi ? (
      <SGroupUserCellTitleButtonQi style={style}>
        <UserDetailsCell id={String(groupUserModel.id)} />
      </SGroupUserCellTitleButtonQi>
    ) : (
      <SGroupUserCellTitleButton style={style}>
        <DetailsCell to={to} />
      </SGroupUserCellTitleButton>
    );
  }

  return isQi ? (
    <SGroupUserCellTitleQi style={style}>{(groupUserModel as any)[groupUserCellModel.name]}</SGroupUserCellTitleQi>
  ) : (
    <SGroupUserCellTitle style={style}>{(groupUserModel as any)[groupUserCellModel.name]}</SGroupUserCellTitle>
  );
});
