import { css } from "styled-components";

export const finOn = css`
  margin-bottom: 10px;
  font-size: 18px;
`;
export const sngb = css`
  margin-bottom: 10px;
`;
export const qi = css`
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid #e8e8e9;
  height: 40px;
  border-radius: 8px;
  width: 100%;
  padding: 0 10px;
`;
