import { Table } from "components/Table/Table.component";
import { RefundDetailsModel } from "model/RefundDetails.model";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableRefundDetailsModelNull } from "observables/RefundDetailsModelNullObservable";
import { useDefaultObservableTableService } from "observables/TableServiceObservable";
import { useTranslation } from "react-i18next";
import { refundDetailsPageService } from "service/shared/singletones/refundDetailsPageService/refundDetailsPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { SDetailsPageContentColumnQi, STitleDetailsQi } from "utils/styled/SDetailsPageContentQi.styled";
import { SSecondaryDetailsTitleQi } from "utils/styled/SSecondaryDetailsTitle.styled";

import { SRefundDetailsPageContent } from "./RefundDetailsPageContentStyled/SRefundDetailsPageContent.styled";
import { SRefundDetailsPageContentColumn } from "./RefundDetailsPageContentStyled/SRefundDetailsPageContentColumn.styled";
import { SRefundDetailsPageContentRow, SRefundDetailsPageContentRowQi } from "./RefundDetailsPageContentStyled/SRefundDetailsPageContentRow.styled";
import { SRefundDetailsPageContentTable, SRefundDetailsPageContentTableQi } from "./RefundDetailsPageContentStyled/SRefundDetailsPageContentTable.styled";
import { SRefundDetailsPageContentTableHeader } from "./RefundDetailsPageContentStyled/SRefundDetailsPageContentTableHeader.styled";
import { RefundRow } from "./RefundRow/PaymentSwitchedRow.component";

export const RefundDetailsPageContent = createComponent("RefundDetailsPageContent", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const refund: RefundDetailsModel = useDefaultObservableRefundDetailsModelNull(refundDetailsPageService.state.refund)!;
  const tableService = useDefaultObservableTableService(refundDetailsPageService.state.tableService)!;

  const loaded: boolean = useDefaultObservableBoolean(refundDetailsPageService.state.loaded);

  return isQi ? (
    <SRefundDetailsPageContent>
      <SRefundDetailsPageContentRowQi>
        <SDetailsPageContentColumnQi>
          <STitleDetailsQi>{t("paymentDetailsPageContent.essentialInformationTitle")}</STitleDetailsQi>
          <RefundRow title={t("refundDetailsPageContent.amount")} value={refund.amount} />
          <RefundRow title={t("refundDetailsPageContent.currency")} value={refund.currency} />
          <RefundRow title={t("refundDetailsPageContent.creationDate")} value={refund.creationDate} />
          <RefundRow title={t("refundDetailsPageContent.resultCode")} value={refund.resultCode} />
          <RefundRow title={t("refundDetailsPageContent.authId")} value={refund.authId} />
          <RefundRow title={t("refundDetailsPageContent.rrn")} value={refund.rrn} />
        </SDetailsPageContentColumnQi>
        <SDetailsPageContentColumnQi>
          <STitleDetailsQi>{t("paymentDetailsPageContent.additionalInformationTitle")}</STitleDetailsQi>
          <RefundRow title={t("refundDetailsPageContent.terminalId")} value={refund.terminalId} />
          <RefundRow title={t("refundDetailsPageContent.maskedPan")} value={refund.maskedPan} />
          <RefundRow title={t("refundDetailsPageContent.paymentSystem")} value={refund.paymentSystem} />
          <RefundRow title={t("refundDetailsPageContent.successful")} value={refund.status} />
          <RefundRow title={t("refundDetailsPageContent.requestId")} value={refund.requestId} />
        </SDetailsPageContentColumnQi>
      </SRefundDetailsPageContentRowQi>

      <SRefundDetailsPageContentTableQi>
        <SSecondaryDetailsTitleQi isArabic={isArabic}>{t("refundDetailsPageContent.tableTitle")}</SSecondaryDetailsTitleQi>
        <Table loaded={loaded} service={tableService} />
      </SRefundDetailsPageContentTableQi>
    </SRefundDetailsPageContent>
  ) : (
    <SRefundDetailsPageContent>
      <SRefundDetailsPageContentRow>
        <SRefundDetailsPageContentColumn>
          <RefundRow title={t("refundDetailsPageContent.amount")} value={refund.amount} />
          <RefundRow title={t("refundDetailsPageContent.currency")} value={refund.currency} />
          <RefundRow title={t("refundDetailsPageContent.creationDate")} value={refund.creationDate} />
          <RefundRow title={t("refundDetailsPageContent.resultCode")} value={refund.resultCode} />
          <RefundRow title={t("refundDetailsPageContent.authId")} value={refund.authId} />
          <RefundRow title={t("refundDetailsPageContent.rrn")} value={refund.rrn} />
        </SRefundDetailsPageContentColumn>
        <div>
          <RefundRow title={t("refundDetailsPageContent.terminalId")} value={refund.terminalId} />
          <RefundRow title={t("refundDetailsPageContent.maskedPan")} value={refund.maskedPan} />
          <RefundRow title={t("refundDetailsPageContent.paymentSystem")} value={refund.paymentSystem} />
          <RefundRow title={t("refundDetailsPageContent.successful")} value={refund.status} />
          <RefundRow title={t("refundDetailsPageContent.requestId")} value={refund.requestId} />
        </div>
      </SRefundDetailsPageContentRow>

      <SRefundDetailsPageContentTable>
        <SRefundDetailsPageContentTableHeader>{t("refundDetailsPageContent.tableTitle")}</SRefundDetailsPageContentTableHeader>
        <Table loaded={loaded} service={tableService} />
      </SRefundDetailsPageContentTable>
    </SRefundDetailsPageContent>
  );
});
