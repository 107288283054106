import React from "react";
import { css, setColor_e74c3c, setFontSize_13px, setFontSize_14px, setFontWeight600, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  text-align: left;
`);

export const SSessionDropButton = styled.button<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-top: 32px;
  text-align: left;
  ${setFontSize_13px};
  ${setFontWeight600};
  ${setColor_e74c3c};
  ${isNotArabicLang};
  ${isArabicLang};
`;

export const SSessionDropButtonQi = styled.button<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  text-align: left;
  display: flex;
  align-items: center;
  gap: 4px;
  border: 2px solid #fe7c84;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 142px;
  height: 40px;
  padding: 10px 14px;
  color: #fe7c84;
  ${setFontSize_14px};
  ${setFontWeight600};
  ${isNotArabicLang};
  ${isArabicLang};
`;
