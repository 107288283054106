import React from "react";
import { css, setColor_25476d, setFlexF1, setFontSize_13px, setFontSize_14px, setFontWeight600, setProps, styled } from "theme";
import { setFontWeight500 } from "theme/setter/setFontWeightValues/setFontWeight500";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  text-align: right;
  padding-right: 10px;
`);

const isNotArabicLang = setProps<Props>(({ isArabic }) => !isArabic)(css`
  text-align: left;
`);

export const SGroupHeaderCellTitle = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFlexF1};
  ${setFontSize_13px};
  ${setFontWeight600};
  ${setColor_25476d};
  ${isArabicLang};
  ${isNotArabicLang};
`;

export const SGroupHeaderCellTitleQi = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFlexF1};
  color: #505766;
  ${setFontSize_14px};
  ${setFontWeight500};
  ${isArabicLang};
  ${isNotArabicLang};
  display: flex;
  align-items: center;
  justify-content: center;
`;
