import { modalChangeUserInfoState } from "./modalChangeUserInfo.state";

class Controller {
  public readonly state = modalChangeUserInfoState;

  public openModal = (): void => {
    this.state.open.next(true);
  };

  public closeModal = (): void => {
    this.state.open.next(false);
  };
}

export const modalChangeUserInfoController = new Controller();
