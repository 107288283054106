/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prettier/prettier */
import styled from "styled-components";

export interface StatusColor {
    text: string;
    background: string;
    border: string;
}

export const STATUS_STYLES: Record<string, StatusColor> = {
    SUCCESS: {
        text: "#28a745",
        background: "#d4edda",
        border: "#c3e6cb",
    },
    CREATED: {
        text: "#17a2b8",
        background: "#d1ecf1",
        border: "#bee5eb",
    },
    PROCESSING: {
        text: "#ffc107",
        background: "#fff3cd",
        border: "#ffeeba",
    },
    AUTHENTICATION_FAILED: {
        text: "#dc3545",
        background: "#f8d7da",
        border: "#f5c6cb",
    },
    ERROR: {
        text: "#dc3545",
        background: "#f8d7da",
        border: "#f5c6cb",
    },
    SENT: {
        text: "#856404",
        background: "#fff3cd",
        border: "#ffeeba",
    },
    FORM_SHOWED: {
        text: "#0d6efd",
        background: "#cfe2ff",
        border: "#b6d4fe",
    },
    AUTHENTICATION_REQUIRED: {
        text: "#6c757d",
        background: "#e2e3e5",
        border: "#d6d8db",
    },
    EXPIRED: {
        text: "#dc3545",
        background: "#f8d7da",
        border: "#f5c6cb",
    },
    PENDING: {
        text: "#ffc107",
        background: "#fff3cd",
        border: "#ffeeba",
    },
};


export const StatusWrapper = styled.div<{ color: StatusColor } & any>`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ color }) => color.text};
  background-color: ${({ color }) => color.background};
  border: 1px solid ${({ color }) => color.border};
  text-align: center;
  min-width: 80px;
  margin: 0px 10px;
`;
