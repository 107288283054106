/* eslint-disable prettier/prettier */
import { initialHeaderCellKey } from "appConstants/initialHeaderCellKey";
import { tableWidths } from "appConstants/tableWidths";
import { InvoicesDetailsCell, InvoicesStatus } from "detailsCell/InvoicesDetailsCell/InvoicesDetailsCell.component";
import { t } from "i18n";
import { SetPageTableHandlerType } from "types/business/SetPageTableHandlerType";
import { isQi } from "utils/business/environment";
import { getInitialHeaderCell } from "utils/business/getInitialHeaderCell";

export const invoicesPage: SetPageTableHandlerType = (sort, desc, setNewSort) => {
  return isQi
    ? [
      getInitialHeaderCell(initialHeaderCellKey.invoiceId, t("invoicesPageConfig.invoiceId"), "invoicesPageConfig.invoiceId", {
        width: tableWidths.width350,
        borderRight: "1px solid #939598",
        height: "100%",
        callBack: () => setNewSort("ORDER_ID"),
        showAsc: sort.fieldName === "ORDER_ID" && !desc,
        showDesc: sort.fieldName === "ORDER_ID" && desc,
        isDetails: true,
        isId: true,
        Element: InvoicesDetailsCell,
      }),
      getInitialHeaderCell(initialHeaderCellKey.terminalId, t("invoicesPageConfig.terminalId"), "invoicesPageConfig.terminalId", {
        width: tableWidths.width150,
        borderRight: "1px solid #939598",
        height: "100%",
      }),
      getInitialHeaderCell(initialHeaderCellKey.email, t("invoicesPageConfig.email"), "invoicesPageConfig.email", {
        width: tableWidths.width200,
        borderRight: "1px solid #939598",
        height: "100%",
        callBack: () => setNewSort("CUSTOMER_EMAIL"),
        showAsc: sort.fieldName === "CUSTOMER_EMAIL" && !desc,
        showDesc: sort.fieldName === "CUSTOMER_EMAIL" && desc,
      }),
      getInitialHeaderCell(initialHeaderCellKey.phone, t("invoicesPageConfig.phone"), "invoicesPageConfig.phone", {
        width: tableWidths.width200, borderRight: "1px solid #939598",
        height: "100%",
      }),
      getInitialHeaderCell(initialHeaderCellKey.amount, t("invoicesPageConfig.amount"), "invoicesPageConfig.amount", {
        width: tableWidths.width120,
        borderRight: "1px solid #939598",
        height: "100%",
        callBack: () => setNewSort("AMOUNT"),
        showAsc: sort.fieldName === "AMOUNT" && !desc,
        showDesc: sort.fieldName === "AMOUNT" && desc,
      }),

      getInitialHeaderCell(initialHeaderCellKey.creationDate, t("invoicesPageConfig.creationDate"), "invoicesPageConfig.creationDate", {
        width: tableWidths.width180,
        height: "100%",
        borderRight: "1px solid #939598",
        callBack: () => setNewSort("CREATION_DATE"),
        showAsc: sort.fieldName === "CREATION_DATE" && !desc,
        showDesc: sort.fieldName === "CREATION_DATE" && desc,
      }),
      getInitialHeaderCell(initialHeaderCellKey.status, t("invoicesPageConfig.status"), "invoicesPageConfig.status", {
        width: tableWidths.width120,
        height: "100%",
        Element: InvoicesStatus,
        isStatus: true,
      }),
    ]
    : [
      getInitialHeaderCell(initialHeaderCellKey.details, t("invoicesPageConfig.details"), "invoicesPageConfig.details", {
        isDetails: true,
        Element: InvoicesDetailsCell,
        width: tableWidths.width120,
      }),
      getInitialHeaderCell(initialHeaderCellKey.terminalId, t("invoicesPageConfig.terminalId"), "invoicesPageConfig.terminalId", {
        width: tableWidths.width150,
      }),
      getInitialHeaderCell(initialHeaderCellKey.invoiceId, t("invoicesPageConfig.invoiceId"), "invoicesPageConfig.invoiceId", {
        width: tableWidths.width350,
        callBack: () => setNewSort("ORDER_ID"),
        showAsc: sort.fieldName === "ORDER_ID" && !desc,
        showDesc: sort.fieldName === "ORDER_ID" && desc,
      }),
      getInitialHeaderCell(initialHeaderCellKey.email, t("invoicesPageConfig.email"), "invoicesPageConfig.email", {
        width: tableWidths.width200,
        callBack: () => setNewSort("CUSTOMER_EMAIL"),
        showAsc: sort.fieldName === "CUSTOMER_EMAIL" && !desc,
        showDesc: sort.fieldName === "CUSTOMER_EMAIL" && desc,
      }),
      getInitialHeaderCell(initialHeaderCellKey.phone, t("invoicesPageConfig.phone"), "invoicesPageConfig.phone", { width: tableWidths.width200 }),
      getInitialHeaderCell(initialHeaderCellKey.amount, t("invoicesPageConfig.amount"), "invoicesPageConfig.amount", {
        width: tableWidths.width120,
        callBack: () => setNewSort("AMOUNT"),
        showAsc: sort.fieldName === "AMOUNT" && !desc,
        showDesc: sort.fieldName === "AMOUNT" && desc,
      }),
      getInitialHeaderCell(initialHeaderCellKey.status, t("invoicesPageConfig.status"), "invoicesPageConfig.status", { width: tableWidths.width120 }),
      getInitialHeaderCell(initialHeaderCellKey.creationDate, t("invoicesPageConfig.creationDate"), "invoicesPageConfig.creationDate", {
        width: tableWidths.width180,
        callBack: () => setNewSort("CREATION_DATE"),
        showAsc: sort.fieldName === "CREATION_DATE" && !desc,
        showDesc: sort.fieldName === "CREATION_DATE" && desc,
      }),
    ];
};
