import React from "react";
import { css } from "styled-components";
import { setProps } from "theme";
import { styled } from "theme/default/styled";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

interface SProps {
  isArabic?: boolean;
}

export const SRewardDetailsInformation = styled.div`
  margin: 0 8px 24px 12px;

  ${setFlexDFFDC};
`;

const isNotArabicLang = setProps<SProps>(({ isArabic }) => !isArabic)(css`
  margin: 0 34px 24px 302px;
`);

const isArabicLang = setProps<SProps>(({ isArabic }) => isArabic)(css`
  margin: 0 302px 24px 0px;
`);

export const SRewardDetailsInformationQi = styled.div<SProps & React.HTMLAttributes<HTMLDivElement>>`
  ${isNotArabicLang}
  ${isArabicLang}
  ${setFlexDFFDC};
`;
