import React from "react";
import { css, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-right: 24px;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  margin-left: 24px;
`);

const isArabicLangQi = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-right: 4px;
`);

const isNotArabicLangQi = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  margin-left: 4px;
`);

export const SSupportPageFeedbackFormContent = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${isArabicLang};
  ${isNotArabicLang};
`;

export const SSupportPageFeedbackFormContentQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${isArabicLangQi};
  ${isNotArabicLangQi};
  width: 100%;
  margin-top: 24px;
`;
