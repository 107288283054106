import { SvgImage } from "components/SvgImage/SvgImage.component";
import { modalChangeUserInfoService } from "service/shared/singletones/modalChangeUserInfoService/modalChangeUserInfo.service";
import { usersDetailsPageController } from "service/shared/singletones/usersDetailsPageService/usersDetailsPage.controller";
import { createComponent } from "utils/libExtend/createComponent";
import { useState } from "utils/libReplace/useState";

import { UserDetailsCellProps } from "./UserDetailsCell.props";
import { SDetailsCellQi } from "./UserDetailsCellStyled/SUserDetailsCell.styled";

export const UserDetailsCell = createComponent<UserDetailsCellProps>("DetailsCell", (props) => {
  const { id } = props;

  const [hovered, setHovered] = useState<boolean>(false);

  const onMouseOut = (): void => {
    setHovered(false);
  };

  const onMouseOver = (): void => {
    setHovered(true);
  };

  const openModal = async (): Promise<void> => {
    await usersDetailsPageController.mount(id);
    modalChangeUserInfoService.controller.openModal();
  };

  const name = hovered ? "DetailsIconActive" : "DetailsIcon";

  return (
    <SDetailsCellQi onMouseOut={onMouseOut} onMouseOver={onMouseOver} onClick={openModal}>
      <SvgImage name={name} />
    </SDetailsCellQi>
  );
});
