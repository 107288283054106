import { setFlexDFFDC } from "theme";
import { styled } from "theme/default/styled";
import { setFlexDF } from "theme/setter/setFlexValues/setFlexDF";

export const SRefundDetailsPageContentRow = styled.div`
  gap: 20px;
  ${setFlexDF};
`;

export const SRefundDetailsPageContentRowQi = styled.div`
  gap: 20px;
  ${setFlexDF};
  @media (max-width: 768px) {
    ${setFlexDFFDC};
  }
`;
