/* eslint-disable prettier/prettier */
import React from "react";
import { css } from "styled-components";
import { setProps } from "theme";
import { styled } from "theme/default/styled";
import { setBackgroundColor_ffffff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_ffffff";
import { setFlexDF } from "theme/setter/setFlexValues/setFlexDF";

type SProps = {
    isArabic: boolean;
    isNotStatistic?: boolean;
};

const isArabicLang = setProps<SProps>(({ isArabic }) => isArabic)(css`
  margin-left: 0px;
  margin-right: 310px;
`);
const isNotArabicLang = setProps<SProps>(({ isArabic }) => !isArabic)(css`
  margin-left: 0px;
  margin-right: 0px;
`);

export const STableChildQi = styled.div<SProps & React.HTMLAttributes<HTMLDivElement>>`
  position: relative;
  overflow-x: scroll;
  width: 100%; 
  ${setFlexDF};
  ${setBackgroundColor_ffffff};
  ${isArabicLang};
  ${isNotArabicLang};
  border: 1px solid #939598;
  border-radius: 12px;
`;

//  width: 100%;