import { ReactNode } from "react";
import { styled } from "theme/default/styled";
import { setBackgroundColor_f7faff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_f7faff";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";
import { setFlexFDC } from "theme/setter/setFlexValues/setFlexFDC";
import { isQi } from "utils/business/environment";

export const SFormLayout = styled.div<{ children?: ReactNode }>`
  width: 100vw;
  height: 100%;
  ${setFlexDFAIC};
  ${setBackgroundColor_f7faff};

  ${isQi
    ? `
        flex-direction: row;
        justify-content: space-between;
        background-color: white;
      `
    : `${setFlexFDC};
        ${setFlexDFAIC};
      `}
`;

export const SFormLayoutQi = styled.div<{ children?: ReactNode }>`
  width: 100vw;
  height: 100%;
  ${setFlexDFAIC};
  ${setBackgroundColor_f7faff};
  flex-direction: row;
  justify-content: space-between;
  background-color: white;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const SContentContainerQi = styled.div`
  width: 50%;
  padding: 34px;
  display: flex;
  flex-direction: column;
  gap: 30%;
  height: 100%;
  @media (max-width: 768px) {
    width: 100%;
    padding: 24px;
    gap: 10%;
  }
`;

export const SFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SImageContainerQi = styled.div`
  width: 50%;
  height: 100%;
  padding: 16px;
  @media (max-width: 768px) {
    width: 100%;
    padding: 24px;
  }
`;
