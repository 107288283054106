import { useTableContext } from "context/Table.context";
import { useDefaultObservableHeaderCellWithIdArray } from "observables/HeaderCellWithIdArrayObservable";
import { HeaderCellWithId } from "types/business/HeaderCellWithId";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { BodyDataCellProps } from "./BodyDataCell.props";
import { SBodyDataCell, SBodyDataCellQi, SDataCellContainerQi } from "./BodyDataCellStyled/SBodyDataCell.styled";

export const BodyDataCell = createComponent<BodyDataCellProps>("BodyDataCell", (props) => {
  const { value, cellKey, cellData } = props;
  const { service } = useTableContext();

  const headerCellList = useDefaultObservableHeaderCellWithIdArray(service.state.headerCellList);

  const headerCell: HeaderCellWithId = headerCellList.find((cell) => cell.key === cellKey)!;

  const { width, Element, isDetails, isId, isStatus } = headerCell;

  if (isStatus) {
    return <Element cellData={cellData} headerCell={headerCell} />;
  }

  return isQi ? (
    <SBodyDataCellQi width={width}>
      {isDetails && isId ? (
        <SDataCellContainerQi>
          <Element cellData={cellData} headerCell={headerCell} />
          {value}
        </SDataCellContainerQi>
      ) : isDetails ? (
        <Element cellData={cellData} headerCell={headerCell} />
      ) : (
        value
      )}
    </SBodyDataCellQi>
  ) : (
    <SBodyDataCell width={width}>{isDetails ? <Element cellData={cellData} headerCell={headerCell} /> : value}</SBodyDataCell>
  );
});
