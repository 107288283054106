/* eslint-disable prettier/prettier */
import { userService } from "api/userService";
import { tableWidths } from "appConstants/tableWidths";
import { PaymentsDetailsCell } from "detailsCell/PaymentsDetailsCell/PaymentsDetailsCell.component";
import { t } from "i18n";
import { HeaderCellModel } from "model/HeaderCell.model";
import { isQi } from "utils/business/environment";
import { getInitialHeaderCell } from "utils/business/getInitialHeaderCell";

export const getInitialHeaderCellList = (): HeaderCellModel[] => {
  const initialHeaderCellList = isQi
    ? [
      getInitialHeaderCell("paymentId", t("refundDetailsPageService.paymentId"), "refundDetailsPageService.paymentId", {
        width: tableWidths.width400,
        height: "100%",
      }),
    ]
    : [
      getInitialHeaderCell("paymentId", t("refundDetailsPageService.paymentId"), "refundDetailsPageService.paymentId", {
        width: tableWidths.width400,
      }),
    ];

  const canGetPaymentInfo = userService.canGetPaymentInfo();

  if (canGetPaymentInfo && !isQi) {
    initialHeaderCellList.unshift(
      getInitialHeaderCell("details", t("refundDetailsPageService.details"), "refundDetailsPageService.details", {
        isDetails: true,
        Element: PaymentsDetailsCell,
      })
    );
  }
  if (canGetPaymentInfo && isQi) {
    initialHeaderCellList.unshift(
      getInitialHeaderCell("details", t("refundDetailsPageService.details"), "refundDetailsPageService.details", {
        isDetails: true,
        Element: PaymentsDetailsCell,
        borderRight: "1px solid #939598",
        height: "100%",
      })
    );
  }

  return initialHeaderCellList;
};
