import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useTranslation } from "react-i18next";
import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { SCanselButtonQi } from "utils/styled/SCanselButtonQi";

import { SCancelButton } from "./CancelButtonStyled/SCancelButton.styled";

export const CancelButton = createComponent("CancelButton", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return isQi ? (
    <SCanselButtonQi isArabic={isArabic} onClick={paymentDetailsPageService.controller.onClickCancel}>
      {t("invoiceCreateForm.cancelButtonTitle")}
      <SvgImage name="Close" color="#707a8f" />
    </SCanselButtonQi>
  ) : (
    <SCancelButton onClick={paymentDetailsPageService.controller.onClickCancel}>{t("paymentDetailsPageContent.cancelButtonTitle")}</SCancelButton>
  );
});
