import React from "react";
import { css } from "styled-components";
import { setFontSize_28px, setFontWeight600 } from "theme";
import { styled } from "theme/default/styled";
import { setColor_22211f_black } from "theme/setter/setColorsValues/qiColors/setColor_22211f_black";
import { setColor_4d4d4d } from "theme/setter/setColorsValues/setColor_4d4d4d";
import { isFinOn, isQi } from "utils/business/environment";

const finOn = css`
  ${setColor_4d4d4d};
`;

const sngb = css`
  ${setColor_4d4d4d};
`;

const qi = css`
  margin-bottom: 24px;
  ${setColor_22211f_black}
`;

export const SFormHeader = styled.div`
  ${isFinOn ? finOn : isQi ? qi : sngb};
  ${setFontSize_28px};
  ${setFontWeight600};
`;

export const SFormHeaderContainer = styled.div<{ $isArabic?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  display: flex;
  justify-content: ${({ $isArabic }): any => $isArabic && "flex-end"};
`;
