import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { navigationMenuService } from "service/shared/singletones/navigationMenuService/navigationMenu.service";
import { isFinOn, isQi } from "utils/business/environment";
import { parsePathPage } from "utils/commonExtend/doesPathContain";
import { createComponent } from "utils/libExtend/createComponent";

import { AppSelectLang } from "App/AppSelectLang.component";

import { AppSwitchLang } from "../../../App/AppSwitchLang.component";
import { SHeader, SHeaderQi, SHeaderTitleQi, STitleSpanQi } from "./HeaderStyled/SHeader.styled";
import { SHeaderLogo } from "./HeaderStyled/SHeaderLogo.styled";
import { Menu } from "./Menu/Menu.component";

export const Header = createComponent("Header", () => {
  const { t, i18n } = useTranslation();

  const location = useLocation();

  const open = useDefaultObservableBoolean(navigationMenuService.state.open);

  const { mainPath, id } = parsePathPage(location.pathname);
  const isArabic = i18n.language === "ar";

  return isQi ? (
    <SHeaderQi
      isArabic={isArabic}
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {id ? (
        <SHeaderTitleQi open={open} isArabic={isArabic}>
          {t(`navigationMenuService.${mainPath}`)} {t(`navigationMenuService.details`)} <STitleSpanQi>#{id}</STitleSpanQi>
        </SHeaderTitleQi>
      ) : (
        <SHeaderTitleQi open={open} isArabic={isArabic}>
          {t(`navigationMenuService.${mainPath}`)}
        </SHeaderTitleQi>
      )}
      <AppSelectLang />
    </SHeaderQi>
  ) : (
    <SHeader isArabic={isArabic}>
      <SHeaderLogo isFinOnDelivery={isFinOn || isQi} isArabic={isArabic}>
        <SvgImage name={isFinOn ? "LogoFin" : isQi ? "LogoQi" : "Logo"} />
      </SHeaderLogo>
      <AppSwitchLang />
      <Menu />
    </SHeader>
  );
});
