/* eslint-disable max-len */

import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const Payments = createIcon("Payments", (props) => {
  const { width = 26, height = 14, color = props.isFinOn ? colorsValuesFin.color_0083f5 : "#82B8E7" } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2072_2493)">
        <path d="M17 10H3L7 6" stroke="#505766" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17 10H3L7 6" stroke="black" strokeOpacity="0.2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 14H21L17 18" stroke="#505766" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 14H21L17 18" stroke="black" strokeOpacity="0.2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_2072_2493">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});

// TODO: Старая иконка
// <svg width={width} height={height} viewBox="0 0 26 14" fill="none">
//   <path
//     d="M1.12598 2.33337C1.12598 1.78109 1.57369 1.33337 2.12598 1.33337H16.8283C17.3806 1.33337 17.8283 1.78109 17.8283 2.33337V11.3077C17.8283 11.86 17.3806 12.3077 16.8283 12.3077H2.12597C1.57369 12.3077 1.12598 11.86 1.12598 11.3077V2.33337Z"
//     fill="white"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M0.375977 2.33337C0.375977 1.36688 1.15948 0.583374 2.12598 0.583374H16.8283C17.7948 0.583374 18.5783 1.36688 18.5783 2.33337V11.3077C18.5783 12.2742 17.7948 13.0577 16.8283 13.0577H2.12597C1.15947 13.0577 0.375977 12.2742 0.375977 11.3077V2.33337ZM2.12598 2.08337C1.98791 2.08337 1.87598 2.1953 1.87598 2.33337V11.3077C1.87598 11.4458 1.98791 11.5577 2.12597 11.5577H16.8283C16.9664 11.5577 17.0783 11.4458 17.0783 11.3077V2.33337C17.0783 2.1953 16.9664 2.08337 16.8283 2.08337H2.12598Z"
//     fill={color}
//   />
//   <path fillRule="evenodd" clipRule="evenodd" d="M17.8281 9.39856H0.955566V8.39856H17.8281V9.39856Z" fill={color} />
//   <path
//     d="M3.0542 4.44019C3.0542 4.16404 3.27806 3.94019 3.5542 3.94019H6.20057C6.47671 3.94019 6.70057 4.16404 6.70057 4.44019V6.07029C6.70057 6.34643 6.47671 6.57029 6.20057 6.57029H3.5542C3.27806 6.57029 3.0542 6.34643 3.0542 6.07029V4.44019Z"
//     fill={color}
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M24.0645 6.62958C24.168 6.54951 24.168 6.39323 24.0645 6.31317L20.9761 3.92461C20.8446 3.82295 20.6537 3.91664 20.6537 4.08282V5.56111H15.0986V7.38166H20.6537V8.85993C20.6537 9.02611 20.8446 9.1198 20.9761 9.01814L24.0645 6.62958Z"
//     fill={color}
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M20.6537 7.38168H15.0986V5.56112H20.6537V4.08283C20.6537 3.91665 20.8446 3.82296 20.9761 3.92462L23.4518 5.8393L24.0645 6.31318C24.168 6.39325 24.168 6.54953 24.0645 6.62959L20.9761 9.01815C20.8446 9.11981 20.6537 9.02612 20.6537 8.85995V7.38168ZM23.4527 5.83856L23.4518 5.8393ZM19.6537 4.56112V4.08283C19.6537 3.08577 20.7991 2.52362 21.5878 3.13359L24.6763 5.52215C25.2974 6.00255 25.2974 6.94023 24.6763 7.42062L21.5878 9.80918C20.7991 10.4192 19.6537 9.85701 19.6537 8.85995V8.38168H14.0986V4.56112H19.6537Z"
//     fill="white"
//   />
// </svg>
