import React from "react";
import { setBorderRadius_8px } from "theme";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { color_e0b4b4 } from "theme/setter/colorsValues/color_e0b4b4";
import { setBackgroundColor_fff6f6 } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_fff6f6";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";
import { setColor_9f3a38 } from "theme/setter/setColorsValues/setColor_9f3a38";
import { setFontSize_14px } from "theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight500 } from "theme/setter/setFontWeightValues/setFontWeight500";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";
import { setColor_rgba_0_0_0_87 } from "theme/setter/setOpacityColorsValues/setColor_rgba_0_0_0_87";

type SUsersChangeRowInputInputProps = {
  valid: boolean;
};

const validSet = setProps<SUsersChangeRowInputInputProps>(({ valid }) => !valid)(css`
  border: 1px solid ${color_e0b4b4};

  ${setColor_9f3a38};
  ${setBackgroundColor_fff6f6};
`);

export const SUsersChangeRowInputInput = styled.input<SUsersChangeRowInputInputProps & React.InputHTMLAttributes<HTMLInputElement>>`
  width: 200px;
  height: 26px;
  padding: 5px 25px 5px 5px;
  appearance: none;

  border: 1px solid ${color_a0b9d2};

  ${setFontSize_14px};
  ${setFontWeight600};
  ${setColor_rgba_0_0_0_87};
  ${setBorderRadius_4px};
  ${validSet};
`;

export const SUsersChangeRowInputInputQi = styled.input<SUsersChangeRowInputInputProps & React.InputHTMLAttributes<HTMLInputElement>>`
  width: 100%;
  height: 40px;
  padding: 10px 12px;
  appearance: none;
  border: 1px solid #e8e8e9;
  position: "relative";
  ${setFontSize_14px};
  ${setFontWeight500};
  ${setBorderRadius_8px};
  ${validSet};
`;
