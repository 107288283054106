/* eslint-disable prettier/prettier */
import { initialHeaderCellKey } from "appConstants/initialHeaderCellKey";
import { tableWidths } from "appConstants/tableWidths";
import { PaymentsInfoDetailsCell } from "detailsCell/PaymentsDetailsCell/PaymentsDetailsCell.component";
import { t } from "i18n";
import { SetPageTableHandlerType } from "types/business/SetPageTableHandlerType";
import { isQi } from "utils/business/environment";
import { getInitialHeaderCell } from "utils/business/getInitialHeaderCell";

export const rewardDetailsPage: SetPageTableHandlerType = () => {
  return isQi
    ? [
      getInitialHeaderCell(initialHeaderCellKey.details, t("invoiceDetailsPageConfig.details"), "invoiceDetailsPageConfig.details", {
        isDetails: true,
        Element: PaymentsInfoDetailsCell,
        width: tableWidths.width120,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.amount, t("rewardDetailsPageConfig.amount"), "rewardDetailsPageConfig.amount", {
        width: tableWidths.width180,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.authId, t("rewardDetailsPageConfig.authId"), "rewardDetailsPageConfig.authId", {
        width: tableWidths.width180,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.fee, t("rewardDetailsPageConfig.fee"), "rewardDetailsPageConfig.fee", {
        width: tableWidths.width180,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.operTime, t("rewardDetailsPageConfig.operTime"), "rewardDetailsPageConfig.operTime", {
        width: tableWidths.width230,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.pan, t("rewardDetailsPageConfig.pan"), "rewardDetailsPageConfig.pan", {
        width: tableWidths.width180,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.rrn, t("rewardDetailsPageConfig.rrn"), "rewardDetailsPageConfig.rrn", {
        width: tableWidths.width180,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.settlement, t("rewardDetailsPageConfig.settlement"), "rewardDetailsPageConfig.settlement", {
        width: tableWidths.width180,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.terminalId, t("rewardDetailsPageConfig.terminalId"), "rewardDetailsPageConfig.terminalId", {
        width: tableWidths.width180,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.terminalName, t("rewardDetailsPageConfig.terminalName"), "rewardDetailsPageConfig.terminalName", {
        width: tableWidths.width180,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.paymentId, t("rewardDetailsPageConfig.paymentId"), "rewardDetailsPageConfig.paymentId", {
        width: tableWidths.width350,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
    ]
    : [
      getInitialHeaderCell(initialHeaderCellKey.details, t("invoiceDetailsPageConfig.details"), "invoiceDetailsPageConfig.details", {
        isDetails: true,
        // Element: PaymentsDetailsCell,
        Element: PaymentsInfoDetailsCell,
        width: tableWidths.width120,
      }),
      getInitialHeaderCell(initialHeaderCellKey.amount, t("rewardDetailsPageConfig.amount"), "rewardDetailsPageConfig.amount", {
        width: tableWidths.width180,
      }),
      getInitialHeaderCell(initialHeaderCellKey.authId, t("rewardDetailsPageConfig.authId"), "rewardDetailsPageConfig.authId", {
        width: tableWidths.width180,
      }),
      getInitialHeaderCell(initialHeaderCellKey.fee, t("rewardDetailsPageConfig.fee"), "rewardDetailsPageConfig.fee", { width: tableWidths.width180 }),
      getInitialHeaderCell(initialHeaderCellKey.operTime, t("rewardDetailsPageConfig.operTime"), "rewardDetailsPageConfig.operTime", {
        width: tableWidths.width230,
      }),
      getInitialHeaderCell(initialHeaderCellKey.pan, t("rewardDetailsPageConfig.pan"), "rewardDetailsPageConfig.pan", { width: tableWidths.width180 }),
      getInitialHeaderCell(initialHeaderCellKey.rrn, t("rewardDetailsPageConfig.rrn"), "rewardDetailsPageConfig.rrn", { width: tableWidths.width180 }),
      getInitialHeaderCell(initialHeaderCellKey.settlement, t("rewardDetailsPageConfig.settlement"), "rewardDetailsPageConfig.settlement", {
        width: tableWidths.width180,
      }),
      getInitialHeaderCell(initialHeaderCellKey.terminalId, t("rewardDetailsPageConfig.terminalId"), "rewardDetailsPageConfig.terminalId", {
        width: tableWidths.width180,
      }),
      getInitialHeaderCell(initialHeaderCellKey.terminalName, t("rewardDetailsPageConfig.terminalName"), "rewardDetailsPageConfig.terminalName", {
        width: tableWidths.width180,
      }),
      getInitialHeaderCell(initialHeaderCellKey.paymentId, t("rewardDetailsPageConfig.paymentId"), "rewardDetailsPageConfig.paymentId", {
        width: tableWidths.width350,
      }),
    ];
};