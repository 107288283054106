import { SRowTitleQi } from "components/Row/RowStyled/SRowTitle.styled";
import { useRolesFormContext } from "context/RolesForm.context";
import { useTranslation } from "react-i18next";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { SUsersChangeRowCommonQi } from "../../../UsersChangeRow/UsersChangeRowCommon/UsersChangeRowCommonStyled/SUsersChangeRowCommon.styled";
import { SUsersChangeRowRolesCommon } from "./UsersChangeRowRolesCommonStyled/SUsersChangeRowRolesCommon.styled";
import { SUsersChangeRowRolesCommonTitle } from "./UsersChangeRowRolesCommonStyled/SUsersChangeRowRolesCommonTitle.styled";
import { SUsersChangeRowRolesCommonValue } from "./UsersChangeRowRolesCommonStyled/SUsersChangeRowRolesCommonValue.styled";

export const UsersChangeRowRolesCommon = createComponent("UsersChangeRowRolesCommon", () => {
  const { t, i18n } = useTranslation();
  const { service } = useRolesFormContext();
  const isArabic = i18n.language === "ar";

  return isQi ? (
    <SUsersChangeRowCommonQi isArabic={isArabic}>
      <SRowTitleQi>{t("usersDetailsPage.roles")}</SRowTitleQi>
      <SRowTitleQi>{service.state.titledValue.value}</SRowTitleQi>
    </SUsersChangeRowCommonQi>
  ) : (
    <SUsersChangeRowRolesCommon isArabic={isArabic}>
      <SUsersChangeRowRolesCommonTitle>{t("usersDetailsPage.roles")}</SUsersChangeRowRolesCommonTitle>
      <SUsersChangeRowRolesCommonValue>{service.state.titledValue.value}</SUsersChangeRowRolesCommonValue>
    </SUsersChangeRowRolesCommon>
  );
});
