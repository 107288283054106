import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableStringArray } from "observables/StringArrayObservable";
import { useTranslation } from "react-i18next";
import { signInFormService } from "service/shared/singletones/signInFormService/signInForm.service";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { SSignInErrorForm } from "./SignInErrorFormStyled/SSignInErrorForm.styled";
import { SSignInErrorFormTitle } from "./SignInErrorFormStyled/SSignInErrorFormTitle.styled";

const LENGTH_ERROR = "signInPage.lengthError";

export const SignInErrorForm = createComponent("SignInErrorForm", () => {
  const { t, i18n } = useTranslation();
  const errorTextArray = useDefaultObservableStringArray(signInFormService.state.errorTextArray);
  const showErrors = useDefaultObservableBoolean(signInFormService.state.showErrors);
  const minLength = signInFormService.state.passwordConfig.minLength!;
  const maxLength = signInFormService.state.passwordConfig.maxLength!;
  const hideForm = !(showErrors && errorTextArray.length !== 0);

  if (hideForm) {
    return null;
  }

  const renderErrorText: RenderFunctionType<string> = (errorText, index) => {
    const text = errorText === LENGTH_ERROR ? t(errorText, { minLength, maxLength }) : t(errorText);

    return <SSignInErrorFormTitle key={index}>{text}</SSignInErrorFormTitle>;
  };

  return <SSignInErrorForm $isArabic={i18n.language === "ar"}>{errorTextArray.map(renderErrorText)}</SSignInErrorForm>;
});
