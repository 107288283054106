/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { styled } from "theme/default/styled";
import { setBackgroundColor_rgba_0_0_0_05 } from "theme/setter/setBackgroundOpacityColorsValues/setBackgroundColor_rgba_0_0_0_05";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";
import { setFlexJCC } from "theme/setter/setFlexValues/setFlexJCC";
import { setColor_rgba_0_0_0_95 } from "theme/setter/setOpacityColorsValues/setColor_rgba_0_0_0_95";

export const SHeaderMenuContainer = styled.button.attrs((props) => ({
  type: "submit",
})) <React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 153px;
  height: 36px;
  ${setFlexDFAIC};
  ${setFlexJCC};
  ${setBorderRadius_4px};
  &:hover {
    ${setColor_rgba_0_0_0_95};
    ${setBackgroundColor_rgba_0_0_0_05};
  }
`;

export const SHeaderMenuContainerQi = styled.button.attrs((props) => ({
  type: "submit",
})) <React.ButtonHTMLAttributes<HTMLButtonElement> & { isArabic: boolean } & { open: boolean }>`
  height: 36px;
  ${setFlexDFAIC};
  ${setFlexJCC};
  gap: 4px;
  ${(props) => props.isArabic && `flex-direction: row-reverse`};
  ${(props) =>
    props.isArabic
      ? props.open
        ? `margin-left: 88px;`
        : `margin-left: 4px;`
      : props.open
        ? `margin-left: 6px;`
        : `margin-left: 6px;`};
  margin-bottom: 16px;
`;

export const AccountSettingsTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
`;