import React from "react";
import { setColor_rgba_0_0_0_87, setFontSize_20px, setFontWeight700, setTextRightArabic, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

export const SComparisonSectionTitle = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-bottom: 24px;
  ${setFontSize_20px};
  ${setFontWeight700};
  ${setColor_rgba_0_0_0_87};
  ${setTextRightArabic()};
`;
