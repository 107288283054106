import React from "react";
import { color_a0b9d2, setBorderRadius_8px, setFlexDFFDC, setTextRightArabic, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

export const SCompareGroup = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding: 24px;
  gap: 24px;

  border: 1px solid ${color_a0b9d2};

  ${setFlexDFFDC};
  ${setBorderRadius_8px};
  ${setTextRightArabic()};
`;

export const SCompareGroupQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setTextRightArabic()};
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const STitleSpanQi = styled.span`
  color: #14181f;
  font-weight: 500;
  font-size: 16px;
`;
