/* eslint-disable prettier/prettier */
import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { t } from "i18next";
import { FilterModel } from "model/Filter.model";
import { OptionModel } from "model/Option.model";

export const multipleMerchantFilterModel = new FilterModel({
    id: 27,
    type: "fetched-multiple-select",
    title: t("merchantFilterModel.title"),
    name: "MERCHANT_FILTERS",
    options: {
        optionListCallBack: async (): Promise<OptionModel[]> => {
            const { data } = await client.post(apiPaths.merchantList);

            return data.map((element: any) => {
                const merchantName = element.merchantName || "";
                const merchantId = element.merchantId || "";
                return new OptionModel(`${merchantId} - ${merchantName}`, `${merchantId}`);
            });
        },
    },
    langPath: "merchantFilterModel.title",
});
