import { styled } from "theme/default/styled";
import { setFlexDFF1 } from "theme/setter/setFlexValues/setFlexDFF1";

export const SGroupTableHeaderContainer = styled.div`
  width: fit-content;

  ${setFlexDFF1};
`;

export const SGroupTableHeaderContainerQi = styled.div`
  width: fit-content;
  ${setFlexDFF1};
`;
