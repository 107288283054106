import { SvgImage } from "components/SvgImage/SvgImage.component";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { SwitchedRowProps } from "./SwitchedRow.props";
import { SSwitchedRow } from "./SwitchedRowStyled/SSwitchedRow.styled";
import { SSwitchedRowTitle, SSwitchedRowTitleQi } from "./SwitchedRowStyled/SSwitchedRowTitle.styled";
import { SSwitchedRowValue, SSwitchedRowValueQi } from "./SwitchedRowStyled/SSwitchedRowValue.styled";

export type RowType = "medium" | "intermediate" | "big";

export const SwitchedRow = createComponent<SwitchedRowProps>("SwitchedRow", (props) => {
  const { title, value, type = "medium" } = props;

  return (
    <SSwitchedRow isMedium={type === "medium"} isBig={type === "big"}>
      {isQi ? (
        <>
          <SSwitchedRowTitleQi>{title}</SSwitchedRowTitleQi>
          <SSwitchedRowValueQi>{value ? "True" : "False"}</SSwitchedRowValueQi>
        </>
      ) : (
        <>
          <SSwitchedRowTitle>{title}</SSwitchedRowTitle>
          <SSwitchedRowValue>
            <SvgImage name={value ? "Check" : "Close"} />
          </SSwitchedRowValue>
        </>
      )}
    </SSwitchedRow>
  );
});
