import { css } from "styled-components";
import { styled } from "theme/default/styled";
import { isFinOn, isQi } from "utils/business/environment";

const finOnStyled = css`
  margin: 10px 0 30vh 0;
`;
const sngbStyled = css`
  margin: auto 0;
`;

const qiStyled = css`
  margin: 20px 0 25vh 0;
  width: 400px;
  @media (max-width: 768px) {
    width: 100%;
    margin: 24px 0;
  }
`;
export const SForm = styled.form`
  ${isFinOn ? finOnStyled : isQi ? qiStyled : sngbStyled};
`;
