/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { css } from "styled-components";
import { styled } from "theme/default/styled";
import { setFlexDFAICJCSB } from "theme/setter/setFlexValues/setFlexDFAICJCSB";
import { SPropsIsArabic, SPropsIsOpen } from "types/business/SPropsType";

export const SHeader = styled.div<{ isArabic?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 100vw;
  padding: 14px;

  ${setFlexDFAICJCSB};
  ${(props) => props.isArabic && `flex-direction: row-reverse`};
`;

const setMarginStyles = ({ isArabic, open }: { isArabic: boolean; open: boolean }) => css`
  ${isArabic
    ? open
      ? css`
          margin-right: 270px;
        `
      : css`
          margin-right: 80px;
        `
    : open
      ? css`
        margin-left: 270px;
      `
      : css`
        margin-left: 80px;
      `}
`;

export const SHeaderQi = styled.div<{ isArabic?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 100%;
  padding: 32px;
  ${setFlexDFAICJCSB};
  ${(props) => props.isArabic && `flex-direction: row-reverse`};
  position: absolute;
`;

export const SHeaderTitleQi = styled.p<SPropsIsOpen & SPropsIsArabic & React.HTMLAttributes<HTMLElement>>`
  font-size: 28px;
  font-weight: 500;
  width: auto;
  ${setMarginStyles}
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const STitleSpanQi = styled.span`
  font-size: 20px;
  font-weight: 400;
  margin-left: 10px;
  margin-right: 10px;
`;
