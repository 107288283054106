/* eslint-disable prettier/prettier */
import { Link } from "components";
import { setFontSize_20px, setFontWeight400, setTextRightArabic, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";
import { isQi } from "utils/business/environment";

export const SDetailsLayoutHeader = styled(Link) <SPropsIsArabic>`
  text-decoration: none;
  color: ${isQi ? "#14181F" : "#4183c4"};
  ${setFontSize_20px};
  ${setFontWeight400};
  ${setTextRightArabic()};
`;
