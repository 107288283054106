/* eslint-disable prettier/prettier */
import React from "react";
import { css } from "styled-components";
import { setFontSize_14px, setProps } from "theme";
import { styled } from "theme/default/styled";
import { setFontWeight500 } from "theme/setter/setFontWeightValues/setFontWeight500";

interface SFilterInputTitleProps {
  isArabic?: boolean;
}

const isArabicLang = setProps<SFilterInputTitleProps>(({ isArabic }) => isArabic)(css`
  text-align: right;
  flex-direction: row-reverse;
`);
const isArabicLangQi = setProps<SFilterInputTitleProps>(({ isArabic }) => isArabic)(css`
    text-align: end;
`);
const isNotArabicLangQi = setProps<SFilterInputTitleProps>(({ isArabic }) => !isArabic)(css`
    text-align: start;
`);


export const SInputTitleQi = styled.span<SFilterInputTitleProps & React.HTMLAttributes<HTMLSpanElement>>`
  margin-bottom: 8px;
  margin-top: 6px;
  color: #707a8f;
  ${setFontSize_14px};
  ${setFontWeight500};
  ${isArabicLangQi};
  ${isNotArabicLangQi}
`;

export const SInputTitleContainerQi = styled.div<SFilterInputTitleProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  display: flex;
  align-items: center;

  ${isArabicLang}
`;
