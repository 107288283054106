import React from "react";
import { css, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  margin-left: 12px;
`);
const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  margin-right: 12px;
`);
export const SNavigationMenuTogglerArrow = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${isArabicLang};
  ${isNotArabicLang};
`;
