import { routerPaths } from "appConstants/routerPaths";
import { DetailsCell } from "detailsCell/DetailsCell/DetailsCell.component";
import { PaymentsDetailsCellProps } from "detailsCell/PaymentsDetailsCell/PaymentsDetailsCell.props";
import { PaymentsDetailsRefundCellProps } from "detailsCell/PaymentsDetailsRefundCell/PaymentsDetailsRefundCell.props";
import { replacePath } from "utils/commonExtend/replacePath";
import { createComponent } from "utils/libExtend/createComponent";
import { useParams } from "utils/libReplace/useParams";
import { STATUS_STYLES, StatusColor, StatusWrapper } from "utils/styled/SStatusWrapperQi";

export const PaymentsDetailsRefundCell = createComponent<PaymentsDetailsRefundCellProps>("PaymentsDetailsRefundCell", (props) => {
  const { cellData } = props;

  const params = useParams<{ paymentId: string }>();

  const to = replacePath(routerPaths.paymentRefundDetails, {
    refundId: cellData.refundId,
    paymentId: params.paymentId,
  });

  return <DetailsCell to={to} />;
});

const getStatusStyle = (status: string): StatusColor => {
  return STATUS_STYLES[status] || STATUS_STYLES.PENDING;
};

export const PaymentsDetailsRefundStatus = createComponent<PaymentsDetailsCellProps>("PaymentsStatus", (props) => {
  const { cellData } = props;

  const color = getStatusStyle(cellData.status);

  return <StatusWrapper color={color}>{cellData.status}</StatusWrapper>;
});
