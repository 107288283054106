/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { signInFormService } from "service/shared/singletones/signInFormService/signInForm.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { SLabel } from "utils/styled/SLabel.styled";

import { EyeIcon, EyeOffIcon, SSignInPasswordInput, SToggleVisibilityButton } from "./SignInPasswordInputStyled/SSignInPasswordInput.styled";

export const SignInPasswordInput = createComponent("SignInPasswordInput", () => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const { t, i18n } = useTranslation();

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  return (
    <>
      {isQi && (
        <SLabel htmlFor="password" $isArabic={i18n.language === "ar"}>
          {t("signInPage.passwordTitle")}
        </SLabel>
      )}

      <div style={{ position: "relative" }}>
        <SSignInPasswordInput
          $isArabic={i18n.language === "ar"}
          dataTestId="password"
          type={isPasswordVisible ? "text" : "password"}
          name="password"
          placeholder={t("signInPage.placeholderPassword")}
          service={signInFormService.state.passwordService}
          id="password"
        />
        {isQi && (
          <SToggleVisibilityButton onClick={togglePasswordVisibility} type="button" $isArabic={i18n.language === "ar"}>
            {isPasswordVisible ? <EyeOffIcon /> : <EyeIcon />}
          </SToggleVisibilityButton>
        )}
      </div>
    </>
  );
});
