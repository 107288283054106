import React from "react";
import { setFlexDFJCSB, setRowReverse, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

export const SCompareYear = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 400px;
  ${setFlexDFJCSB};
  ${setRowReverse()};
`;

export const SCompareYearQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 100%;
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
`;
