/* eslint-disable prettier/prettier */
import { css } from "styled-components";
import { setBackgroundColor_ffffff } from "theme";
import { styled } from "theme/default/styled";
import { color_47a0ef } from "theme/setter/colorsValues/color_47a0ef";
import { setBackgroundColor_f0fbff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_f0fbff";
import { isFinOn, isQi } from "utils/business/environment";

import { Input } from "../Input/Input.component";

const finOn = css`
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 18px;
  outline: none;
`;
const sngb = css`
  margin-top: 20px;
  margin-bottom: 5px;
  outline: none;
`;
export const qi = css`
  margin-bottom: 5px;
  border: 1px solid #e8e8e9;
  height: 40px;
  border-radius: 8px;
  width: 100%;
`;

export const SForgotPasswordFormEmail = styled(Input) <{ $isArabic: boolean }>`
  ${isFinOn ? finOn : isQi ? qi : sngb};
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0 10px;
  text-align: ${({ $isArabic }: { $isArabic?: boolean }): string | undefined => ($isArabic ? "right" : undefined)};

  &:focus {
    border: 1px solid ${isQi ? "#ffd400" : color_47a0ef};
    ${isQi ? setBackgroundColor_ffffff : setBackgroundColor_f0fbff};
  }
`;
