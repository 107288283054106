import React from "react";
import { css } from "styled-components";
import { setProps } from "theme";
import { styled } from "theme/default/styled";

type SNavigationMenuProps = {
  open: boolean;
};

const openSet = setProps<SNavigationMenuProps>(({ open }) => open)(css``);

const openNotSet = setProps<SNavigationMenuProps>(({ open }) => !open)(css`
  visibility: hidden;
`);

export const SHeaderLogo = styled.div<{ isFinOnDelivery?: boolean; isArabic?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: ${({ isArabic }): string => (isArabic ? "190px" : "119px")};
  height: ${({ isFinOnDelivery }): string => (isFinOnDelivery ? "50px" : "38px")};
`;

export const SHeaderLogoQi = styled.div<{ isFinOnDelivery?: boolean; isArabic?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 18px 0px 24px;
  flex-direction: ${({ isArabic }): string => (isArabic ? "row-reverse" : "row")};
`;

export const StyledParagraph = styled.p<SNavigationMenuProps & React.HTMLAttributes<HTMLElement>>`
  font-size: 16px;
  font-weight: 600;
  ${openSet};
  ${openNotSet};
`;
