import React from "react";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: -10%;
`;

export const QrImage = styled.img<React.ImgHTMLAttributes<HTMLImageElement>>`
  min-width: 200px;
  min-height: 200px;
  max-width: 200px;
  max-height: 200px;
  border: 1px solid gray;
  border-radius: 8px;
  margin-bottom: 24px;
`;

export const SActionInfoContainer = styled.div`
  position: relative;
`;

export const SActionInfoMessage = styled.p`
  background-color: #fff;
  color: #6c757d;
  border-radius: 12px;
  padding: 12px;
  font-size: 14px;
  line-height: 15px;
  white-space: wrap;
  z-index: 10;
  width: 360px;
  font-weight: 400;
  margin-bottom: 8px;
`;
