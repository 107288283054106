import React from "react";
import styled, { css } from "styled-components";
import { setBorderRadius_8px, setFontSize_14px, setFontWeight600, setProps } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
  margin-left: 0px;
`);

export const SCanselButtonQi = styled.button<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  color: #707a8f;
  display: flex;
  align-items: center;
  border: 1px solid #e8e8e9;
  gap: 4px;
  padding: 10px 20px;
  width: auto;
  height: 40px;
  ${setFontSize_14px};
  ${setFontWeight600};
  ${setBorderRadius_8px};
  ${isArabicLang};
`;
