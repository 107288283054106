import React from "react";
import { css } from "styled-components";
import { setProps } from "theme";
import { styled } from "theme/default/styled";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  align-items: end;
`);

export const SSupportPageFeedbackFormForm = styled.form`
  width: 500px;
  ${setFlexDFFDC};
`;

export const SSupportPageFeedbackFormFormQi = styled.form<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 50%;
  ${setFlexDFFDC};
  ${isArabicLang}
`;
