import React from "react";
import { css, setBorderRadius_8px, setColor_0083f5, setFontSize_13px, setFontSize_14px, setFontWeight600, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  text-align: left;
`);

export const SChangeStatusButton = styled.button<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-top: 32px;
  ${setFontSize_13px};
  ${setFontWeight600};
  ${setColor_0083f5};
  ${isNotArabicLang};
  ${isArabicLang};
`;

export const SChangeStatusButtonQi = styled.button<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${isNotArabicLang};
  ${isArabicLang};
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 10px 20px;
  color: black;
  background-color: #ffd400;
  width: auto;
  height: 40px;
  ${setFontSize_14px};
  ${setFontWeight600};
  ${setBorderRadius_8px};
  ${isArabicLang};
`;
