import React from "react";
import { css, setFontSize_14px, setFontWeight600, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  text-align: left;
`);

export const SResetPasswordButtonQi = styled.button<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  text-align: left;
  color: #1c75d7;
  display: flex;
  align-items: center;
  gap: 4px;
  border: 2px dashed #1c75d7;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: auto;
  height: 40px;
  padding: 10px 14px;
  ${setFontSize_14px};
  ${setFontWeight600};
  ${isNotArabicLang};
  ${isArabicLang};
`;
