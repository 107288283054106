/* eslint-disable react/no-array-index-key */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mfaController } from "service/shared/singletones/mfaService/mfa.controller";
import { useDefaultObservable } from "utils/libExtend/useDefaultObservable";

import {
  SDescription,
  SForm,
  SFormContainer,
  SInput,
  SInputContainer,
} from "./VerificationFormStyled/SVerificationForm.styled";

interface VerificationFormProps {
  onVerifyClick?: (value: string) => void;
  stapMessage: string;
  children?: React.ReactNode;
}

export const VerificationForm: React.FC<VerificationFormProps> = ({
  onVerifyClick,
  stapMessage,
  children,
}) => {
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const [otp, setOtp] = useState(mfaController.otp);
  const isError = useDefaultObservable(mfaController.state.isError);

  useEffect(() => {
    return () => {
      mfaController.dispose();
    };
  }, []);

  useEffect(() => {
    mfaController.resetError();
  }, [otp]);

  useEffect(() => {
    mfaController.focusInput(isArabic);
  }, [isArabic, otp.length]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ): void => {
    mfaController.handleInputChange(e.target.value, index);
    setOtp([...mfaController.otp]);
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    const otpCode = mfaController.getOtp();
    if (otpCode) {
      onVerifyClick?.(otpCode);
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>): void => {
    mfaController.handlePaste(e);
    setOtp([...mfaController.otp]);
  };

  return (
    <SForm onSubmit={handleSubmit}>
      <SFormContainer isArabic={isArabic}>
        <SDescription>{stapMessage}</SDescription>
        <SInputContainer>
          {otp.map((digit, index) => (
            <SInput
              key={index}
              id={`otp-input-${index}`}
              type="text"
              maxLength={1}
              value={digit}
              onChange={(e): void => handleChange(e, index)}
              onKeyDown={(e): void =>
                mfaController.handleInputBackspace(e, index)
              }
              onPaste={handlePaste}
              isError={isError}
            />
          ))}
        </SInputContainer>
      </SFormContainer>
      {children}
    </SForm>
  );
};
