import React from "react";
import styled, { css } from "styled-components";
import { setFlexDFFDC, setProps } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

export const SAccountDetailsContainer = styled.div<SPropsIsArabic & React.HTMLAttributes<HTMLDivElement>>`
  width: 100%;
  height: auto;
  padding: 20px 0px;
  gap: 10px;
  ${setFlexDFFDC};
  ${isArabicLang};
`;

const isArabicLangQi = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
  margin-left: 0px;
`);

export const SButtonEditQi = styled.button<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 50%;
  ${isArabicLangQi};
`;
