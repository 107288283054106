/* eslint-disable prettier/prettier */

import { createIcon } from "utils/libExtend/createIcon";

export const EyeOpenIcon = createIcon("EyeOpenIcon", (props) => {
    const { width = 24, height = 24, color = "#00346E" } = props;
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2072_9528)">
                <path
                    // eslint-disable-next-line max-len
                    d="M8.33398 10C8.33398 10.4421 8.50958 10.866 8.82214 11.1786C9.1347 11.4911 9.55862 11.6667 10.0007 11.6667C10.4427 11.6667 10.8666 11.4911 11.1792 11.1786C11.4917 10.866 11.6673 10.4421 11.6673 10C11.6673 9.55801 11.4917 9.13409 11.1792 8.82153C10.8666 8.50897 10.4427 8.33337 10.0007 8.33337C9.55862 8.33337 9.1347 8.50897 8.82214 8.82153C8.50958 9.13409 8.33398 9.55801 8.33398 10Z"
                    stroke="#707A8F"
                    strokeWidth="1.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.5 10C15.5 13.3333 13 15 10 15C7 15 4.5 13.3333 2.5 10C4.5 6.66667 7 5 10 5C13 5 15.5 6.66667 17.5 10Z"
                    stroke="#707A8F"
                    strokeWidth="1.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2072_9528">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
});
