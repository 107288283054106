import React from "react";
import { css, setProps, styled } from "theme";
import { setColor_0083f5 } from "theme/setter/setColorsValues/setColor_0083f5";
import { setFontSize_13px } from "theme/setter/setFontSizeValues/setFontSize_13px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  align-self: flex-end;
`);

export const SCreateFormButtonsButton = styled.button<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFontSize_13px};
  ${setFontWeight600};
  ${setColor_0083f5};
`;

export const SButtonsContainerQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e8e8e9;
  padding-top: 24px;
  ${isArabicLang};
`;
