import React from "react";
import { setFlexDFJCSB, setRowReverse, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

export const SCompareMonth = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 400px;
  ${setFlexDFJCSB};
  ${setRowReverse()};
`;

export const SCompareMonthQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 100%;
  ${setRowReverse()};
  display: flex;
  flex-direction: column;
`;
