/* eslint-disable prettier/prettier */
import { initialHeaderCellKey } from "appConstants/initialHeaderCellKey";
import { tableWidths } from "appConstants/tableWidths";
import { PaymentsStatus } from "detailsCell/PaymentsDetailsCell/PaymentsDetailsCell.component";
import { t } from "i18n";
import { HeaderCellModel } from "model/HeaderCell.model";
import { isQi } from "utils/business/environment";
import { getInitialHeaderCell } from "utils/business/getInitialHeaderCell";

export const paymentDetailsPageConfirm = (): HeaderCellModel[] => {
  return isQi
    ? [
      getInitialHeaderCell(initialHeaderCellKey.refundId, t("paymentDetailsPageCancelConfig.refundId"), "paymentDetailsPageCancelConfig.refundId", {
        width: tableWidths.width300,
        borderRight: "1px solid #939598",
        height: "100%",
      }),
      getInitialHeaderCell(
        initialHeaderCellKey.creationDate,
        t("paymentDetailsPageConfirmConfig.creationDate"),
        "paymentDetailsPageConfirmConfig.creationDate",
        {
          width: tableWidths.width200,
          borderRight: "1px solid #939598",
          height: "100%",
        }
      ),
      getInitialHeaderCell(initialHeaderCellKey.amount, t("paymentDetailsPageConfirmConfig.amount"), "paymentDetailsPageConfirmConfig.amount", {
        width: tableWidths.width150,
        borderRight: "1px solid #939598",
        height: "100%",
      }),
      getInitialHeaderCell(initialHeaderCellKey.status, t("paymentDetailsPageConfirmConfig.status"), "paymentDetailsPageConfirmConfig.status", {
        width: tableWidths.width150,
        height: "100%",
        isStatus: true,
        Element: PaymentsStatus,
      }),
    ]
    : [
      getInitialHeaderCell(initialHeaderCellKey.refundId, t("paymentDetailsPageCancelConfig.refundId"), "paymentDetailsPageCancelConfig.refundId", {
        width: tableWidths.width300,
      }),
      getInitialHeaderCell(
        initialHeaderCellKey.creationDate,
        t("paymentDetailsPageConfirmConfig.creationDate"),
        "paymentDetailsPageConfirmConfig.creationDate",
        {
          width: tableWidths.width200,
        }
      ),
      getInitialHeaderCell(initialHeaderCellKey.status, t("paymentDetailsPageConfirmConfig.status"), "paymentDetailsPageConfirmConfig.status", {
        width: tableWidths.width150,
      }),
      getInitialHeaderCell(initialHeaderCellKey.amount, t("paymentDetailsPageConfirmConfig.amount"), "paymentDetailsPageConfirmConfig.amount", {
        width: tableWidths.width150,
      }),
    ];
};
