import { styled } from "theme/default/styled";
import { color_rgba_183_200_220_80 } from "theme/setter/opacityColorsValues/color_rgba_183_200_220_80";
import { setBackgroundColor_ffffff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_ffffff";
import { setBorderRadius_8px } from "theme/setter/setBorderRadiusValues/setBorderRadius_8px";

export const SStatisticErrorMessageContainer = styled.div`
  padding: 12px;
  box-shadow: 0 0 7px ${color_rgba_183_200_220_80};
  ${setBorderRadius_8px};
  ${setBackgroundColor_ffffff};
`;

export const SStatisticErrorMessageContainerQi = styled.div`
  padding: 12px;
  border-radius: 12px;
  border: 1px solid #ffcccf;
  ${setBackgroundColor_ffffff};
`;
