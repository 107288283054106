/* eslint-disable prettier/prettier */
import { routerPaths } from "appConstants/routerPaths";
import { DetailsLayout } from "components/DetailsLayout/DetailsLayout.component";
import HeaderSecondary from "components/HeaderSecondary/HeaderSecondary.component";
import {
  SDetailsBox,
  SDetailsLayoutContainerQi,
} from "components/HeaderSecondary/HeaderSecondaryStyled/SHeaderSecondary.styled";
import { Loader } from "components/Loader/Loader.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";
import { useParams } from "utils/libReplace/useParams";

import { PaymentDetailsPageContent } from "./PaymentDetailsPageContent/PaymentDetailsPageContent.component";
import { SPaymentDetailsPageLoader } from "./PaymentDetailsPageStyled/SPaymentDetailsPageLoader.styled";

export const PaymentDetailsPage = createComponent("PaymentDetailsPage", () => {
  const { t, i18n } = useTranslation();
  const { paymentId } = useParams<{ paymentId: string }>();
  const isArabic = i18n.language === "ar";

  useEffect(async () => {
    paymentDetailsPageService.controller.setPaymentId(paymentId);
    await paymentDetailsPageService.controller.getPayment();
    return (): void => {
      paymentDetailsPageService.controller.unMounted();
    };
  }, []);

  const loaded: boolean = useDefaultObservableBoolean(
    paymentDetailsPageService.state.loaded
  );

  return isQi ? (
    <SDetailsLayoutContainerQi isArabic={isArabic}>
      <SDetailsBox>
        <HeaderSecondary
          isArabic={isArabic}
          headerTitle={t("paymentDetailsPage.headerTitle")}
          id={paymentId || ""}
        />
        {loaded ? (
          <PaymentDetailsPageContent />
        ) : (
          <SPaymentDetailsPageLoader>
            <Loader />
          </SPaymentDetailsPageLoader>
        )}
      </SDetailsBox>
    </SDetailsLayoutContainerQi>
  ) : (
    <DetailsLayout
      header={[
        {
          title: t("paymentDetailsPage.headerTitle"),
          src: routerPaths.payments,
        },
      ]}
      title={`# ${paymentId}`}
    >
      {loaded ? (
        <PaymentDetailsPageContent />
      ) : (
        <SPaymentDetailsPageLoader>
          <Loader />
        </SPaymentDetailsPageLoader>
      )}
    </DetailsLayout>
  );
});
