/* eslint-disable prettier/prettier */
import { initialHeaderCellKey } from "appConstants/initialHeaderCellKey";
import { tableWidths } from "appConstants/tableWidths";
import { PaymentsDetailsCell, PaymentsStatus } from "detailsCell/PaymentsDetailsCell/PaymentsDetailsCell.component";
import { t } from "i18n";
import { SetPageTableHandlerType } from "types/business/SetPageTableHandlerType";
import { isQi } from "utils/business/environment";
import { getInitialHeaderCell } from "utils/business/getInitialHeaderCell";

export const paymentsPage: SetPageTableHandlerType = (sort, desc, setNewSort) => {
  return isQi
    ? [
      getInitialHeaderCell(initialHeaderCellKey.paymentId, t("paymentsPageConfig.paymentId"), "paymentsPageConfig.paymentId", {
        width: tableWidths.width350,
        borderRight: "1px solid #939598",
        height: "100%",
        isDetails: true,
        isId: true,
        Element: PaymentsDetailsCell,
        callBack: () => setNewSort("PAYMENT_ID"),
        showAsc: sort.fieldName === "PAYMENT_ID" && !desc,
        showDesc: sort.fieldName === "PAYMENT_ID" && desc,
      }),
      getInitialHeaderCell(initialHeaderCellKey.maskedPan, t("paymentsPageConfig.maskedPan"), "paymentsPageConfig.maskedPan", {
        width: tableWidths.width300,
        borderRight: "1px solid #939598",
        height: "100%",
        callBack: () => setNewSort("PAN"),
        showAsc: sort.fieldName === "PAN" && !desc,
        showDesc: sort.fieldName === "PAN" && desc,
      }),

      getInitialHeaderCell(initialHeaderCellKey.rrn, t("paymentsPageConfig.rrn"), "paymentsPageConfig.rrn", {
        width: tableWidths.width150,
        borderRight: "1px solid #939598",
        height: "100%",
      }),
      getInitialHeaderCell(initialHeaderCellKey.terminalId, t("paymentsPageConfig.terminalId"), "paymentsPageConfig.terminalId", {
        width: tableWidths.width150,
        borderRight: "1px solid #939598",
        height: "100%",
      }),

      getInitialHeaderCell(initialHeaderCellKey.amount, t("paymentsPageConfig.amount"), "paymentsPageConfig.amount", {
        width: tableWidths.width120,
        callBack: () => setNewSort("AMOUNT"),
        showAsc: sort.fieldName === "AMOUNT" && !desc,
        showDesc: sort.fieldName === "AMOUNT" && desc,
        borderRight: "1px solid #939598",
        height: "100%",
      }),
      getInitialHeaderCell(initialHeaderCellKey.creationDate, t("paymentsPageConfig.creationDate"), "paymentsPageConfig.creationDate", {
        width: tableWidths.width180,
        callBack: () => setNewSort("CREATION_DATE"),
        showAsc: sort.fieldName === "CREATION_DATE" && !desc,
        showDesc: sort.fieldName === "CREATION_DATE" && desc,
        borderRight: "1px solid #939598",
        height: "100%",
      }),
      getInitialHeaderCell(initialHeaderCellKey.status, t("paymentsPageConfig.status"), "paymentsPageConfig.status", {
        width: tableWidths.width150,
        height: "100%",
        callBack: () => setNewSort("STATUS"),
        showAsc: sort.fieldName === "STATUS" && !desc,
        showDesc: sort.fieldName === "STATUS" && desc,
        Element: PaymentsStatus,
        isStatus: true,
      }),
    ]
    : [
      getInitialHeaderCell(initialHeaderCellKey.details, t("paymentsPageConfig.details"), "paymentsPageConfig.details", {
        isDetails: true,
        Element: PaymentsDetailsCell,
        width: tableWidths.width120,
      }),
      getInitialHeaderCell(initialHeaderCellKey.rrn, t("paymentsPageConfig.rrn"), "paymentsPageConfig.rrn", { width: tableWidths.width150 }),
      getInitialHeaderCell(initialHeaderCellKey.terminalId, t("paymentsPageConfig.terminalId"), "paymentsPageConfig.terminalId", {
        width: tableWidths.width150,
      }),
      getInitialHeaderCell(initialHeaderCellKey.maskedPan, t("paymentsPageConfig.maskedPan"), "paymentsPageConfig.maskedPan", {
        width: tableWidths.width300,
        callBack: () => setNewSort("PAN"),
        showAsc: sort.fieldName === "PAN" && !desc,
        showDesc: sort.fieldName === "PAN" && desc,
      }),
      getInitialHeaderCell(initialHeaderCellKey.paymentId, t("paymentsPageConfig.paymentId"), "paymentsPageConfig.paymentId", {
        width: tableWidths.width350,
        callBack: () => setNewSort("PAYMENT_ID"),
        showAsc: sort.fieldName === "PAYMENT_ID" && !desc,
        showDesc: sort.fieldName === "PAYMENT_ID" && desc,
      }),
      getInitialHeaderCell(initialHeaderCellKey.amount, t("paymentsPageConfig.amount"), "paymentsPageConfig.amount", {
        width: tableWidths.width120,
        callBack: () => setNewSort("AMOUNT"),
        showAsc: sort.fieldName === "AMOUNT" && !desc,
        showDesc: sort.fieldName === "AMOUNT" && desc,
      }),
      getInitialHeaderCell(initialHeaderCellKey.creationDate, t("paymentsPageConfig.creationDate"), "paymentsPageConfig.creationDate", {
        width: tableWidths.width180,
        callBack: () => setNewSort("CREATION_DATE"),
        showAsc: sort.fieldName === "CREATION_DATE" && !desc,
        showDesc: sort.fieldName === "CREATION_DATE" && desc,
      }),
      getInitialHeaderCell(initialHeaderCellKey.status, t("paymentsPageConfig.status"), "paymentsPageConfig.status", {
        width: tableWidths.width230,
        callBack: () => setNewSort("STATUS"),
        showAsc: sort.fieldName === "STATUS" && !desc,
        showDesc: sort.fieldName === "STATUS" && desc,
      }),
    ];
};
