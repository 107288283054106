import React from "react";
import { css, setFlexDFAIC, setProps, styled } from "theme";

type SAddFilterButtonProps = {
  filterListIsEmpty: boolean;
  isArabic: boolean;
};

const filterListIsEmptySet = setProps<SAddFilterButtonProps>(({ filterListIsEmpty }) => filterListIsEmpty)(css`
  cursor: default;
  opacity: 0.45;
`);

const filterListIsEmptySetQi = setProps<SAddFilterButtonProps>(({ filterListIsEmpty }) => filterListIsEmpty)(css`
  cursor: default;
  opacity: 0.45;
  margin-top: 36px;
`);

const filterListIsNotEmptySetQi = setProps<SAddFilterButtonProps>(({ filterListIsEmpty }) => !filterListIsEmpty)(css`
  margin-top: 36px;
`);

const isArabicLang = setProps<SAddFilterButtonProps>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
  margin-right: 16px;
`);

export const SAddFilterButton = styled.button<SAddFilterButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-top: 12px;
  ${setFlexDFAIC};
  ${filterListIsEmptySet};
  ${isArabicLang};
`;

export const SAddFilterButtonQi = styled.button<SAddFilterButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFlexDFAIC};
  ${filterListIsEmptySetQi};
  ${isArabicLang};
  ${filterListIsNotEmptySetQi};
  display: flex;
  align-items: center;
  padding: 10px 12px;
  background-color: white;
  color: #139d6c;
  border: 2px dashed #139d6c;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 143px;
  height: 40px;
  gap: 4px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
