/* eslint-disable prettier/prettier */
import { initialHeaderCellKey } from "appConstants/initialHeaderCellKey";
import { tableWidths } from "appConstants/tableWidths";
import { TransfersDetailsCell, TransfersStatus } from "detailsCell/TransfersDetailsCell/TransfersDetailsCell.component";
import { t } from "i18n";
import { SetPageTableHandlerType } from "types/business/SetPageTableHandlerType";
import { isQi } from "utils/business/environment";
import { getInitialHeaderCell } from "utils/business/getInitialHeaderCell";

export const transfersPage: SetPageTableHandlerType = (sort, desc, setNewSort) => {
  return isQi
    ? [
      getInitialHeaderCell(initialHeaderCellKey.transferId, t("transfersPageConfig.transferId"), "transfersPageConfig.transferId", {
        width: tableWidths.width350,
        height: "100%",
        borderRight: "1px solid #939598",
        callBack: () => setNewSort("TRANSFER_ID"),
        showAsc: sort.fieldName === "TRANSFER_ID" && !desc,
        showDesc: sort.fieldName === "TRANSFER_ID" && desc,
        isDetails: true,
        Element: TransfersDetailsCell,
        isId: true,
      }),
      getInitialHeaderCell(initialHeaderCellKey.terminalId, t("transfersPageConfig.terminalId"), "transfersPageConfig.terminalId", {
        width: tableWidths.width150,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.type, t("transfersPageConfig.type"), "transfersPageConfig.type", {
        width: tableWidths.width150,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.requestId, t("transfersPageConfig.requestId"), "transfersPageConfig.requestId", {
        width: tableWidths.width350,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.amount, t("transfersPageConfig.amount"), "transfersPageConfig.amount", {
        width: tableWidths.width150,
        height: "100%",
        borderRight: "1px solid #939598",
        callBack: () => setNewSort("AMOUNT"),
        showAsc: sort.fieldName === "AMOUNT" && !desc,
        showDesc: sort.fieldName === "AMOUNT" && desc,
      }),
      getInitialHeaderCell(initialHeaderCellKey.creationDate, t("transfersPageConfig.creationDate"), "transfersPageConfig.creationDate", {
        width: tableWidths.width180,
        height: "100%",
        borderRight: "1px solid #939598",
        callBack: () => setNewSort("CREATION_DATE"),
        showAsc: sort.fieldName === "CREATION_DATE" && !desc,
        showDesc: sort.fieldName === "CREATION_DATE" && desc,
      }),
      getInitialHeaderCell(initialHeaderCellKey.status, t("transfersPageConfig.status"), "transfersPageConfig.status", {
        width: tableWidths.width300,
        height: "100%",
        callBack: () => setNewSort("STATUS"),
        showAsc: sort.fieldName === "STATUS" && !desc,
        showDesc: sort.fieldName === "STATUS" && desc,
        Element: TransfersStatus,
        isStatus: true,
      }),
    ]
    : [
      getInitialHeaderCell(initialHeaderCellKey.details, t("transfersPageConfig.details"), "transfersPageConfig.details", {
        isDetails: true,
        Element: TransfersDetailsCell,
        width: tableWidths.width120,
      }),
      getInitialHeaderCell(initialHeaderCellKey.terminalId, t("transfersPageConfig.terminalId"), "transfersPageConfig.terminalId", {
        width: tableWidths.width150,
      }),
      getInitialHeaderCell(initialHeaderCellKey.type, t("transfersPageConfig.type"), "transfersPageConfig.type", { width: tableWidths.width150 }),
      getInitialHeaderCell(initialHeaderCellKey.requestId, t("transfersPageConfig.requestId"), "transfersPageConfig.requestId", {
        width: tableWidths.width350,
      }),
      getInitialHeaderCell(initialHeaderCellKey.transferId, t("transfersPageConfig.transferId"), "transfersPageConfig.transferId", {
        width: tableWidths.width350,
        callBack: () => setNewSort("TRANSFER_ID"),
        showAsc: sort.fieldName === "TRANSFER_ID" && !desc,
        showDesc: sort.fieldName === "TRANSFER_ID" && desc,
      }),
      getInitialHeaderCell(initialHeaderCellKey.amount, t("transfersPageConfig.amount"), "transfersPageConfig.amount", {
        width: tableWidths.width150,
        callBack: () => setNewSort("AMOUNT"),
        showAsc: sort.fieldName === "AMOUNT" && !desc,
        showDesc: sort.fieldName === "AMOUNT" && desc,
      }),
      getInitialHeaderCell(initialHeaderCellKey.creationDate, t("transfersPageConfig.creationDate"), "transfersPageConfig.creationDate", {
        width: tableWidths.width180,
        callBack: () => setNewSort("CREATION_DATE"),
        showAsc: sort.fieldName === "CREATION_DATE" && !desc,
        showDesc: sort.fieldName === "CREATION_DATE" && desc,
      }),
      getInitialHeaderCell(initialHeaderCellKey.status, t("transfersPageConfig.status"), "transfersPageConfig.status", {
        width: tableWidths.width300,
        callBack: () => setNewSort("STATUS"),
        showAsc: sort.fieldName === "STATUS" && !desc,
        showDesc: sort.fieldName === "STATUS" && desc,
      }),
    ];
};
