import { RolesDisplayQi } from "components/RolesDisplayQi/RolesDisplayQi";
import { SRowTitleQi } from "components/Row/RowStyled/SRowTitle.styled";
import { RolesSelectOptionModel } from "model/RolesSelectOption.model";
import { useTranslation } from "react-i18next";
import { createUserRolesSelectFormService } from "service/shared/singletones/createUserRolesSelectFormService/createUserRolesSelectForm.service";
import { createUserServiceFormService } from "service/shared/singletones/createUserServiceFormService/createUserServiceForm.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { useDefaultObservable } from "utils/libExtend/useDefaultObservable";

import { SCreateUserFetchedSelectMessageQi } from "../CreateUserFetchedSelect/CreateUserFetchedSelectStyled/SCreateUserFetchedSelectMessage.styled";
import {
  SCreateUserFetchedMultipleSelect,
  SCreateUserFetchedMultipleSelectQi,
} from "./CreateUserFetchedMultipleSelectStyled/SCreateUserFetchedMultipleSelect.styled";
import {
  SCreateUserFetchedMultipleSelectContainer,
  SCreateUserFetchedMultipleSelectContainerQi,
} from "./CreateUserFetchedMultipleSelectStyled/SCreateUserFetchedMultipleSelectContainer.styled";
import { SCreateUserFetchedMultipleSelectMessage } from "./CreateUserFetchedMultipleSelectStyled/SCreateUserFetchedMultipleSelectMessage.styled";
import { SCreateUserFetchedMultipleSelectTitle } from "./CreateUserFetchedMultipleSelectStyled/SCreateUserFetchedMultipleSelectTitle.styled";

export const CreateUserFetchedMultipleSelect = createComponent("CreateUserFetchedMultipleSelect", () => {
  const { t } = useTranslation();
  const showError = useDefaultObservable<boolean>(createUserRolesSelectFormService.state.showError);
  const selectedOptions = useDefaultObservable<RolesSelectOptionModel[]>(createUserServiceFormService.state.roles);
  const titles = selectedOptions.map((option) => option.title);

  return isQi ? (
    <SCreateUserFetchedMultipleSelectQi>
      <SRowTitleQi>{t("usersInfoPage.createMultipleSelectTitle")}</SRowTitleQi>
      <SCreateUserFetchedMultipleSelectContainerQi onClick={createUserRolesSelectFormService.controller.onClickContainer}>
        <RolesDisplayQi roles={titles} />
      </SCreateUserFetchedMultipleSelectContainerQi>
      {showError && <SCreateUserFetchedSelectMessageQi>{t("usersInfoPage.createMultipleSelectMessage")}</SCreateUserFetchedSelectMessageQi>}
    </SCreateUserFetchedMultipleSelectQi>
  ) : (
    <SCreateUserFetchedMultipleSelect>
      <SCreateUserFetchedMultipleSelectTitle>{t("usersInfoPage.createMultipleSelectTitle")}</SCreateUserFetchedMultipleSelectTitle>
      <SCreateUserFetchedMultipleSelectContainer onClick={createUserRolesSelectFormService.controller.onClickContainer}>
        {`${t("usersInfoPage.createMultipleSelectContainer")} ${selectedOptions.length}`}
      </SCreateUserFetchedMultipleSelectContainer>
      {showError && <SCreateUserFetchedMultipleSelectMessage>{t("usersInfoPage.createMultipleSelectMessage")}</SCreateUserFetchedMultipleSelectMessage>}
    </SCreateUserFetchedMultipleSelect>
  );
});
