import { TerminalModel } from "model/Terminal.model";
import { useDefaultObservableTerminalModelArray } from "observables/TerminalModelArrayObservable";
import { terminalsPageService } from "service/shared/singletones/terminalsPageService/terminalsPage.service";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { SDividerVertical } from "utils/styled/SDivider";

import { STerminalsListTableBody } from "./TerminalsListTableBodyStyled/STerminalsListTableBody.styled";
import { STerminalsListTableBodyRow, STerminalsListTableBodyRowQi } from "./TerminalsListTableBodyStyled/STerminalsListTableBodyRow.styled";
import { STerminalsListTableBodyTitle, STerminalsListTableBodyTitleQi } from "./TerminalsListTableBodyStyled/STerminalsListTableBodyTitle.styled";

export const TerminalsListTableBody = createComponent("TerminalsListTableBody", () => {
  const filteredTerminalsList = useDefaultObservableTerminalModelArray(terminalsPageService.state.filteredTerminalsList);

  const renderTerminalRow: RenderFunctionType<TerminalModel> = (terminalRow, index) => {
    return isQi ? (
      <STerminalsListTableBodyRowQi key={index}>
        <STerminalsListTableBodyTitleQi>{terminalRow.terminalId}</STerminalsListTableBodyTitleQi>
        <SDividerVertical />
        <STerminalsListTableBodyTitleQi>{terminalRow.merchantName}</STerminalsListTableBodyTitleQi>
      </STerminalsListTableBodyRowQi>
    ) : (
      <STerminalsListTableBodyRow key={index}>
        <STerminalsListTableBodyTitle>{terminalRow.terminalId}</STerminalsListTableBodyTitle>
        <STerminalsListTableBodyTitle>{terminalRow.merchantName}</STerminalsListTableBodyTitle>
      </STerminalsListTableBodyRow>
    );
  };

  return <STerminalsListTableBody>{filteredTerminalsList.map(renderTerminalRow)}</STerminalsListTableBody>;
});
