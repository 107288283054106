import React from "react";
import { css, setFlexDFFDC, setProps, styled } from "theme";

type SNavigationMenuContainerProps = {
  open: boolean;
  isArabic: boolean;
};

const isArabicLang = setProps<SNavigationMenuContainerProps>(({ isArabic }) => isArabic)(css`
  padding: 10px 0 10px 10px;
`);
const isNotArabicLang = setProps<SNavigationMenuContainerProps>(({ isArabic }) => !isArabic)(css`
  padding: 10px 10px 10px 0;
`);
const openSet = setProps<SNavigationMenuContainerProps>(({ open }) => open)(css`
  width: 220px;
`);

const openNotSet = setProps<SNavigationMenuContainerProps>(({ open }) => !open)(css`
  width: 62px;
`);

const openSetQi = setProps<SNavigationMenuContainerProps>(({ open }) => open)(css`
  width: 248px;
`);

const openNotSetQi = setProps<SNavigationMenuContainerProps>(({ open }) => !open)(css`
  width: 48px;
`);

export const SNavigationMenuContainer = styled.div<SNavigationMenuContainerProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${isArabicLang};
  ${isNotArabicLang};
  ${setFlexDFFDC};
  ${openSet}
  ${openNotSet}
`;

const isArabicLangQi = setProps<SNavigationMenuContainerProps>(({ isArabic }) => isArabic)(css`
  padding: 0px;
`);

export const SNavigationMenuContainerQi = styled.div<SNavigationMenuContainerProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFlexDFFDC};
  ${isArabicLangQi};
  ${isNotArabicLang};
  ${openSetQi}
  ${openNotSetQi}
  margin-bottom: 8px;
`;
