/* eslint-disable prettier/prettier */
import { initialHeaderCellKey } from "appConstants/initialHeaderCellKey";
import { tableWidths } from "appConstants/tableWidths";
import { AuditDetailsCell } from "detailsCell/AuditDetailsCell/AuditDetailsCell.component";
import { t } from "i18n";
import { SetPageTableHandlerType } from "types/business/SetPageTableHandlerType";
import { isQi } from "utils/business/environment";
import { getInitialHeaderCell } from "utils/business/getInitialHeaderCell";

export const auditPage: SetPageTableHandlerType = () => {
  return isQi
    ? [
      getInitialHeaderCell(initialHeaderCellKey.details, t("auditPageConfig.details"), "auditPageConfig.details", {
        isDetails: true,
        Element: AuditDetailsCell,
        width: tableWidths.width120,
        borderRight: "1px solid #939598",
        height: "100%",
      }),
      getInitialHeaderCell(initialHeaderCellKey.created, t("auditPageConfig.created"), "auditPageConfig.created", {
        width: tableWidths.width300,
        borderRight: "1px solid #939598",
        height: "100%",
      }),
      getInitialHeaderCell(initialHeaderCellKey.executor, t("auditPageConfig.executor"), "auditPageConfig.executor", {
        width: tableWidths.width180,
        borderRight: "1px solid #939598",
        height: "100%",
      }),
      getInitialHeaderCell(initialHeaderCellKey.requestType, t("auditPageConfig.requestType"), "auditPageConfig.requestType", {
        width: tableWidths.width180,
        borderRight: "1px solid #939598",
        height: "100%",
      }),
      getInitialHeaderCell(initialHeaderCellKey.sourceIp, t("auditPageConfig.sourceIp"), "auditPageConfig.sourceIp", {
        width: tableWidths.width120,
        borderRight: "1px solid #939598",
        height: "100%",
      }),
      getInitialHeaderCell(initialHeaderCellKey.successfully, t("auditPageConfig.successfully"), "auditPageConfig.successfully", {
        width: tableWidths.width120,
        height: "100%",
      }),
    ]
    : [
      getInitialHeaderCell(initialHeaderCellKey.details, t("auditPageConfig.details"), "auditPageConfig.details", {
        isDetails: true,
        Element: AuditDetailsCell,
        width: tableWidths.width120,
      }),
      getInitialHeaderCell(initialHeaderCellKey.created, t("auditPageConfig.created"), "auditPageConfig.created", { width: tableWidths.width300 }),
      getInitialHeaderCell(initialHeaderCellKey.executor, t("auditPageConfig.executor"), "auditPageConfig.executor", { width: tableWidths.width180 }),
      getInitialHeaderCell(initialHeaderCellKey.requestType, t("auditPageConfig.requestType"), "auditPageConfig.requestType", {
        width: tableWidths.width180,
      }),
      getInitialHeaderCell(initialHeaderCellKey.sourceIp, t("auditPageConfig.sourceIp"), "auditPageConfig.sourceIp", { width: tableWidths.width120 }),
      getInitialHeaderCell(initialHeaderCellKey.successfully, t("auditPageConfig.successfully"), "auditPageConfig.successfully", {
        width: tableWidths.width120,
      }),
    ];
};
