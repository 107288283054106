import { useTranslation } from "react-i18next";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { CompareGroupProps } from "./CompareGroup.props";
import { SCompareGroup, SCompareGroupQi, STitleSpanQi } from "./CompareGroupStyled/SCompareGroup.styled";
import { CompareMonth } from "./CompareMonth/CompareMonth.component";
import { CompareTerminal } from "./CompareTerminal/CompareTerminal.component";
import { CompareYear } from "./CompareYear/CompareYear.component";

export const CompareGroup = createComponent<CompareGroupProps>("CompareGroup", (props) => {
  const { title, compareYearService, compareMonthService, compareTerminalService } = props;
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return isQi ? (
    <SCompareGroupQi isArabic={isArabic}>
      <STitleSpanQi>{title}</STitleSpanQi>
      <CompareTerminal service={compareTerminalService} />
      <CompareYear service={compareYearService} />
      <CompareMonth service={compareMonthService} />
    </SCompareGroupQi>
  ) : (
    <SCompareGroup isArabic={isArabic}>
      <span>{title}</span>
      <CompareTerminal service={compareTerminalService} />
      <CompareYear service={compareYearService} />
      <CompareMonth service={compareMonthService} />
    </SCompareGroup>
  );
});
