import React from "react";
import { borderRadius_8px, color_rgba_183_200_220_80, css, setBackgroundColor_ffffff, setProps, styled } from "theme";

interface SFilterMenuProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SFilterMenuProps>(({ isArabic }) => isArabic)(css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`);
const isArabicLangQi = setProps<SFilterMenuProps>(({ isArabic }) => isArabic)(css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 270px;
  margin-left: -32px;
`);
const isNotArabicLangQi = setProps<SFilterMenuProps>(({ isArabic }) => !isArabic)(css`
  margin-left: 270px;
`);

export const SFilterMenu = styled.div<SFilterMenuProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding: 12px;
  border-top-right-radius: ${borderRadius_8px};
  border-bottom-right-radius: ${borderRadius_8px};
  border-bottom-left-radius: ${borderRadius_8px};
  box-shadow: 0 0 7px ${color_rgba_183_200_220_80};
  ${setBackgroundColor_ffffff};
  ${isArabicLang};
`;

export const SFilterMenuQi = styled.div<SFilterMenuProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding: 15px;
  border-radius: 12px;
  ${setBackgroundColor_ffffff};
  ${isArabicLangQi};
  ${isNotArabicLangQi}
  border: 1px solid #e8e8e9;
  margin-bottom: -6px;
`;
