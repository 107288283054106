/* eslint-disable prettier/prettier */
import { css } from "packages/styled-components";
import { Input } from "pages/SignInPage/Input/Input.component";
import { styled } from "theme/default/styled";
import { isFinOn, isQi } from "utils/business/environment";
import { qi } from "utils/styled/SInput.styled";

const finOn = css`
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 18px;
`;
const sngb = css`
  margin-top: 20px;
  margin-bottom: 5px;
`;

export const SSignInEmailInput = styled(Input) <{ $isArabic?: boolean; isError?: boolean }>`
  ${isFinOn ? finOn : isQi ? qi : sngb};
  text-align: ${({ $isArabic }: { $isArabic?: boolean }): string | undefined => ($isArabic ? "right" : undefined)};
`;
