import React from "react";
import { setFlexDF, setRowReverse, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

export const SComparisonSectionContainer = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-bottom: 24px;
  ${setFlexDF};
  ${setRowReverse()};
`;
export const SComparisonSectionContainerQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-bottom: 24px;
  ${setRowReverse()};
`;
