import { css } from "styled-components";
import { styled } from "theme/default/styled";
import { setBackgroundColor_f7faff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_f7faff";
import { setBorderRadius_8px } from "theme/setter/setBorderRadiusValues/setBorderRadius_8px";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";
import { isFinOn, isQi } from "utils/business/environment";

const finOnStyled = css`
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  padding-bottom: 50px;

  ${setFlexDFFDC};
  ${setBorderRadius_8px};
  ${setBackgroundColor_f7faff};
`;
const sngbStyled = css`
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  padding-bottom: 50px;

  ${setFlexDFFDC};
  ${setBorderRadius_8px};
  ${setBackgroundColor_f7faff};
`;

const qiStyled = css`
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  ${setFlexDFFDC};
  ${setBackgroundColor_f7faff};
  margin-right: 34px;
`;

export const SMainLayout = styled.div`
  ${isFinOn ? finOnStyled : isQi ? qiStyled : sngbStyled};
`;
