import { styled } from "theme/default/styled";
import { color_rgba_183_200_220_80 } from "theme/setter/opacityColorsValues/color_rgba_183_200_220_80";
import { setBackgroundColor_f7faff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_f7faff";
import { setBorderRadius_5px } from "theme/setter/setBorderRadiusValues/setBorderRadius_5px";
import { setFlexDF } from "theme/setter/setFlexValues/setFlexDF";

export const SGroupTableHeader = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  margin-top: 10px;

  box-shadow: 0 0 5px ${color_rgba_183_200_220_80};

  ${setFlexDF};
  ${setBorderRadius_5px};
  ${setBackgroundColor_f7faff};
`;

export const SGroupTableHeaderQi = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0px;
  border-bottom: 1px solid #707a8f;
  ${setFlexDF};
  position: sticky;
  top: 0;
  background-color: #fff;
  width: 100%;
`;

export const SBodyScrollContainer = styled.div`
  max-height: 64vh;
  overflow-y: auto;
  width: 100%;
`;
