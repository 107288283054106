import React from "react";
import { css } from "styled-components";
import { color_rgba_183_200_220_80, resetScrollbar, setBackgroundColor_ffffff, setBorderRadius_8px, setProps, setTextRightArabic, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

export const STerminalsPage = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  overflow-y: scroll;
  width: 1000px;
  margin-bottom: 8px;
  margin-left: 15px;
  padding: 16px 20px 20px 16px;
  box-shadow: 0 0 7px ${color_rgba_183_200_220_80};
  ${resetScrollbar};
  ${setBorderRadius_8px};
  ${setBackgroundColor_ffffff};
  ${setTextRightArabic()};
`;

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  margin-left: 34px;
  margin-right: 34px;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  margin-right: 34px;
  margin-left: 34px;
`);

export const STerminalsPageQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  overflow-y: scroll;
  width: 100%;
  margin-bottom: 16px;
  margin-top: 16px;
  padding: 16px 20px 20px 16px;
  border-radius: 12px;
  border: 1px solid #e8e8e9;
  ${resetScrollbar};
  ${setBackgroundColor_ffffff};
  ${setTextRightArabic()};
`;

export const STerminalsPageContainerQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 100%;
  margin-bottom: 8px;
  margin-left: 15px;
  ${resetScrollbar};
  height: initial;
  margin-top: 100px;

  ${setTextRightArabic()};
  ${isArabicLang};
  ${isNotArabicLang};
`;
