import React from "react";
import { css, setFlexDF, setProps, styled } from "theme";

interface SFilterMenuButtonsProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SFilterMenuButtonsProps>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);

export const SFilterMenuButtons = styled.div<SFilterMenuButtonsProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-top: 20px;
  gap: 16px;

  ${isArabicLang};
  ${setFlexDF};
`;
