import { styled } from "theme/default/styled";
import { setFlexDFJCC } from "theme/setter/setFlexValues/setFlexDFJCC";
import { isQi } from "utils/business/environment";

export const SHeaderCellDraggableContainer = styled.div`
  position: relative;
  width: ${!isQi && "1px"};

  ${setFlexDFJCC};
`;
