import { AccountSettings } from "./svgs/AccountSettings.component";
import { AddNewFilter } from "./svgs/AddNewFilter.component";
import { Apply } from "./svgs/Apply.component";
import { ArrowDown } from "./svgs/ArrowDown.component";
import { ArrowDownSelect } from "./svgs/ArrowDownSelect.component";
import { ArrowLeft } from "./svgs/ArrowLeft.component";
import { ArrowRight } from "./svgs/ArrowRight.component";
import { Asc } from "./svgs/Asc.component";
import { BlockUser } from "./svgs/BlockUser.component";
import { Check } from "./svgs/Check.component";
import { Clear } from "./svgs/Clear.component";
import { ClearIcon } from "./svgs/ClearIcon.component";
import { Close } from "./svgs/Close.component";
import { ContentCopy } from "./svgs/ContentCopy.component";
import { CopyIcon } from "./svgs/CopyIcon.component";
import { Cross } from "./svgs/Cross.component";
import { DeleteFilter } from "./svgs/DeleteFilter.component";
import { Desc } from "./svgs/Desc.component";
import { DetailsIcon } from "./svgs/DetailsIcon.component";
import { DetailsIconActive } from "./svgs/DetailsIconActive.component";
import { DropSession } from "./svgs/DropSession.component";
import { Dynamic } from "./svgs/Dynamic.component";
import { Edit } from "./svgs/Edit.component";
import { EditIcon } from "./svgs/EditIcon.component";
import { Export } from "./svgs/Export.component";
import { EyeClosedIcon } from "./svgs/EyeClosedIcon.component";
import { EyeOpenIcon } from "./svgs/EyeOpenIcon.component";
import { FileUpload } from "./svgs/FileUpload.component";
import { FilterSearch } from "./svgs/FilterSearch.component";
import { H2H } from "./svgs/H2H.component";
import { Invoices } from "./svgs/Invoices.component";
import { Logo } from "./svgs/Logo.component";
import { LogoFin } from "./svgs/LogoFin.component";
import { LogoQi } from "./svgs/LogoQi.component";
import { Logout } from "./svgs/Logout.component";
import { MinusIcon } from "./svgs/MinusIcon.component";
import { MyAccount } from "./svgs/MyAccount.component";
import { PaginationLeftArrow } from "./svgs/PaginationLeftArrow.component";
import { PaginationLeftDoubleArrow } from "./svgs/PaginationLeftDoubleArrow.component";
import { PaginationRightArrow } from "./svgs/PaginationRightArrow.component";
import { PaginationRightDoubleArrow } from "./svgs/PaginationRightDoubleArrow.component";
import { Payments } from "./svgs/Payments.component";
import { Plus } from "./svgs/Plus.component";
import { PlusIcon } from "./svgs/PlusIcon.component";
import { RefundIcon } from "./svgs/RefundIcon.component";
import { Refunds } from "./svgs/Refunds.component";
import { Reset } from "./svgs/Reset.component";
import { ResetIcon } from "./svgs/ResetIcon.component";
import { Reward } from "./svgs/Reward.component";
import { Search } from "./svgs/Search.component";
import { SendInvitation } from "./svgs/SendInvitation.component";
import { Statistic } from "./svgs/Statistic.component";
import { Support } from "./svgs/Support.component";
import { Terminals } from "./svgs/Terminals.component";
import { Transfers } from "./svgs/Transfers.component";
import { UserManagement } from "./svgs/UserManagement.component";
import { Users } from "./svgs/Users.component";
import { Warning } from "./svgs/Warning.component";
import { WhiteClear } from "./svgs/WhiteClear.component";

export const icons = {
  ArrowDown,
  ArrowLeft,
  H2H,
  ArrowRight,
  Asc,
  Check,
  Clear,
  Close,
  ContentCopy,
  DeleteFilter,
  Desc,
  DetailsIcon,
  DetailsIconActive,
  Invoices,
  Logo,
  LogoFin,
  LogoQi,
  PaginationLeftArrow,
  PaginationLeftDoubleArrow,
  PaginationRightArrow,
  PaginationRightDoubleArrow,
  Payments,
  Plus,
  Refunds,
  Search,
  Transfers,
  Warning,
  WhiteClear,
  Dynamic,
  Statistic,
  FileUpload,
  Support,
  Reward,
  EditIcon,
  EyeOpenIcon,
  EyeClosedIcon,
  AccountSettings,
  MyAccount,
  UserManagement,
  Terminals,
  Logout,
  FilterSearch,
  Export,
  Reset,
  ArrowDownSelect,
  Cross,
  AddNewFilter,
  Apply,
  ClearIcon,
  CopyIcon,
  Users,
  RefundIcon,
  ResetIcon,
  Edit,
  PlusIcon,
  MinusIcon,
  BlockUser,
  DropSession,
  SendInvitation,
};
