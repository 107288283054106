/* eslint-disable prettier/prettier */
import { CustomSelect } from "components";
import React from "react";
import { css, setBorderRadius_8px, setFlexDFFDC, setProps, styled, } from "theme";

interface SFilterInputInputProps {
  isArabic?: boolean;
}

const isArabicLang = setProps<SFilterInputInputProps>(({ isArabic }) => isArabic)(css`
  padding: 10px 12px;
  text-align: right;
`);

const isNotArabicLang = setProps<SFilterInputInputProps>(({ isArabic }) => !isArabic)(css`
  padding: 10px 12px;
`);

export const SFilterInputInputQi = styled.input<SFilterInputInputProps & React.InputHTMLAttributes<HTMLInputElement>>`
  width: 240px;
  height: 40px;
  appearance: none;
  border: 1px solid #e8e8e9;
  cursor: pointer;
  ${isNotArabicLang};
  ${isArabicLang};
  ${setBorderRadius_8px};
  &:focus {
    outline: none;
    border-color: #FFD400;
  }
`;

type SFilterSelectSelectProps = {
  isTitle: boolean;
};

export const SFilterSelectSelectQi = styled(CustomSelect) <SFilterSelectSelectProps>`
  width: 240px;
  height: 40px;
  appearance: none;
  border: 1px solid #e8e8e9;
  background-color: transparent;
  appearance: none;

  ${setBorderRadius_8px};

  & > option {
    color: initial;
  }
`;

export const SFilterSelectQi = styled.div<SFilterInputInputProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 240px;
  margin-top: 6px;
  ${setFlexDFFDC};
`;