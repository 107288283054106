import React from "react";
import { color_b7c8dc, css, setBackgroundColor_ffffff, setFlexDFAIC, setFlexJCC, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);

export const STableNavigation = styled.div<SPropsIsArabic & React.HTMLAttributes<HTMLDivElement>>`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  height: 50px;

  box-shadow: 0 0 11px ${color_b7c8dc};

  ${isArabicLang};
  ${setFlexDFAIC};
  ${setFlexJCC};
  ${setBackgroundColor_ffffff};
`;

const isArabicLangQi = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
  margin-right: 302px;
`);
const isNotArabicLangQi = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  margin: 0 32px 10px 34px;
  margin-left: 304px;
`);

export const STableNavigationQi = styled.div<SPropsIsArabic & React.HTMLAttributes<HTMLDivElement>>`
  ${isArabicLangQi};
  ${isNotArabicLangQi}
  ${setBackgroundColor_ffffff};

  padding-right: 10px;
  padding-left: 10px;
  border-top: none;
  border-left: 1px solid #939598;
  border-right: 1px solid #939598;
  border-bottom: 1px solid #939598;
  border-radius: 0 0 12px 12px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 64px;
`;
