import React from "react";
import styled from "styled-components";
import { setFontSize_20px, setTextRightArabic } from "theme";
import { setFontWeight500 } from "theme/setter/setFontWeightValues/setFontWeight500";
import { SPropsIsArabic } from "types/business/SPropsType";

export const SSecondaryDetailsTitleQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  color: #14181f;
  margin: 16px 0px;
  ${setFontSize_20px};
  ${setFontWeight500};
  ${setTextRightArabic()};
`;

export const SSecondaryDetailsFirstTitleQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  color: #14181f;
  margin: 0px 0px 8px;
  ${setFontSize_20px};
  ${setFontWeight500};
  ${setTextRightArabic()};
`;
