/* eslint-disable prettier/prettier */
import { CustomDateTime } from "components";
import styled, { css } from "styled-components";
import { setBorderRadius_8px, setProps } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  margin-right: 0px;
`);

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  .rdtPicker {
    right: 0;
  }
`);

export const SRangeInputStartPickerQi = styled(CustomDateTime)`
  ${isNotArabicLang};
  ${isArabicLang};

  & > .formControl {
    width: 240px;
    height: 40px;
    border: 1px solid #e8e8e9;
    padding: 5px;

    ${setBorderRadius_8px};
  }
`;
