/* eslint-disable prettier/prettier */
import React from "react";
import styled, { css } from "styled-components";
import { setBorderRadius_8px, setFontSize_14px, setFontWeight600, setProps } from "theme";

type SProps = {
  isArabic?: boolean;
  sendButtonValid?: boolean;
};

const isArabicLang = setProps<SProps>(({ isArabic }) => isArabic)(css`
    flex-direction: row-reverse;
    margin-left: 0px;
  `);

const sendButtonValidSet = setProps<SProps>(({ sendButtonValid }) => !sendButtonValid)(css`
    cursor: default;
    opacity: 0.45;
  `);

export const SButtonQi = styled.button<SProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: auto;
  margin-top: auto;
  padding: 10px 20px;
  color: black;
  background-color: #ffd400;
  width: auto;
  height: 40px;
  ${setFontSize_14px};
  ${setFontWeight600};
  ${setBorderRadius_8px};
  ${isArabicLang};
`;

export const SButtonRefundQi = styled.button<SProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: auto;
  padding: 10px 20px;
  color: black;
  background-color: #ffd400;
  width: auto;
  height: 40px;
  ${setFontSize_14px};
  ${setFontWeight600};
  ${setBorderRadius_8px};
  ${isArabicLang};
`;
