import React from "react";
import { setFlexDFFDC, setTextRightArabic, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

export const SFeedbackFormSelector = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 300px;
  margin-bottom: 16px;
  gap: 8px;
  ${setFlexDFFDC};
  ${setTextRightArabic()};
`;

export const SFeedbackFormSelectorQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 50%;
  margin-bottom: 16px;
  gap: 8px;
  ${setFlexDFFDC};
  ${setTextRightArabic()};
`;
