/* eslint-disable prettier/prettier */
interface ParsedPath {
    mainPath: string;
    id: string | null;
}

export const getLastPathSegment = (path: string): string => {
    const segments = path.split("/").filter(Boolean);
    return segments[segments.length - 1];
};

export const doesPathContain = (path: string, searchString: string): boolean => {
    return path.includes(searchString);
};

export const parsePathPage = (path: string): ParsedPath => {
    const segments = path.split("/").filter(Boolean);

    const mainPath = segments[0] === "user-info" ? "userInfo" :
        segments[0] === "users-info" ? "usersInfo" :
            segments[0];

    if (segments.length === 1) {
        return { mainPath, id: null };
    }

    const lastSegment = segments[segments.length - 1];
    const isUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(lastSegment);

    return {
        mainPath,
        id: isUUID ? lastSegment : null,
    };
};
