import { routerPaths } from "appConstants/routerPaths";
import { DetailsCell } from "detailsCell/DetailsCell/DetailsCell.component";
import { InvoicesDetailsCellProps } from "detailsCell/InvoicesDetailsCell/InvoicesDetailsCell.props";
import { replacePath } from "utils/commonExtend/replacePath";
import { createComponent } from "utils/libExtend/createComponent";
import { STATUS_STYLES, StatusColor, StatusWrapper } from "utils/styled/SStatusWrapperQi";

export const InvoicesDetailsCell = createComponent<InvoicesDetailsCellProps>("InvoicesDetailsCell", (props) => {
  const { cellData } = props;

  const to = replacePath(routerPaths.invoiceDetails, {
    invoiceId: cellData.invoiceId,
  });

  return <DetailsCell to={to} />;
});

const getStatusStyle = (status: string): StatusColor => {
  return STATUS_STYLES[status] || STATUS_STYLES.PENDING;
};

export const InvoicesStatus = createComponent<InvoicesDetailsCellProps>("InvoicesStatus", (props) => {
  const { cellData } = props;

  const color = getStatusStyle(cellData.status);

  return <StatusWrapper color={color}>{cellData.status}</StatusWrapper>;
});
