import { SPropsIsArabic } from "types/business/SPropsType";

import { css } from "./css";
import { setProps, Template } from "./setProps";

export const setRowReverse = (): ((props: SPropsIsArabic) => string | Template) => {
  return setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
    flex-direction: row-reverse;
  `);
};
