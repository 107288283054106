/* eslint-disable prettier/prettier */
import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableString } from "observables/StringObservable";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { menuService } from "service/shared/singletones/menuService/menu.service";
import { navigationMenuService } from "service/shared/singletones/navigationMenuService/navigationMenu.service";
import { createComponent } from "utils";
import { SNavigationMenuIconLabelQi, SNavigationMenuIconLabelTitle } from "utils/styled/SMenuLabels";

import { SUserNameQi, SUserQi } from "./UserStyled/SUser.styled";

export const User = createComponent("User", () => {
    const { i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const email = useDefaultObservableString(menuService.state.email);
    const open = useDefaultObservableBoolean(navigationMenuService.state.open);
    const [hovered, setHovered] = useState<boolean>(false);

    const onMouseOut = (): void => {
        setHovered(false);
    };
    const onMouseOver = (): void => {
        setHovered(true);
    };

    return (
        <SUserQi isArabic={isArabic} open={open} onMouseOut={onMouseOut} onMouseOver={onMouseOver}>
            <SvgImage name="MyAccount" width={44} height={34} />
            {open && <SUserNameQi>{email}</SUserNameQi>}
            {!open && hovered ? (
                <SNavigationMenuIconLabelQi isArabic={isArabic}>
                    <SNavigationMenuIconLabelTitle>{email}</SNavigationMenuIconLabelTitle>
                </SNavigationMenuIconLabelQi>
            ) : null}
        </SUserQi>
    );
});

