/* eslint-disable prettier/prettier */
import React from "react";
import { css } from "styled-components";
import { setFlexDFAIC, setProps, setRowReverse, setTextRightArabic, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

export const SUsersChangeRowGroupInput = styled.div<SPropsIsArabic & React.InputHTMLAttributes<HTMLInputElement>>`
  margin-bottom: 10px;
  ${setFlexDFAIC};
  ${setTextRightArabic()};
  ${setRowReverse()};
`;

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`

  `);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
    display: flex;
    flex-direction: column;
  `);

export const SUsersChangeRowGroupInputQi = styled.div<SPropsIsArabic & React.InputHTMLAttributes<HTMLInputElement>>`
  margin-bottom: 10px;
  width: 100%;
  ${setTextRightArabic()};
  ${setRowReverse()};
  ${isNotArabicLang};
`;
