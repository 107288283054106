import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useTranslation } from "react-i18next";
import { dynamicPageService } from "service/shared/singletones/dynamicPageService/dynamicPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { SButtonQi } from "utils/styled/SButtonQi";

import { SApplyCompareButton } from "./ApplyCompareButtonStyled/SApplyCompareButton.styled";

export const ApplyCompareButton = createComponent("ApplyCompareButton", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return isQi ? (
    <SButtonQi isArabic={isArabic} onClick={dynamicPageService.controller.onClick}>
      {t("dynamicPage.applyButton")} <SvgImage name="Check" />
    </SButtonQi>
  ) : (
    <SApplyCompareButton onClick={dynamicPageService.controller.onClick}>{t("dynamicPage.applyButton")}</SApplyCompareButton>
  );
});
