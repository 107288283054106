/* eslint-disable prettier/prettier */
import { client } from "api/client";
import { userService } from "api/userService";
import { apiPaths } from "appConstants/apiPaths";
import { authenticatedState } from "service/common/authenticatedService/authenticated.state";
import { mfaService } from "service/shared/singletones/mfaService/mfa.service";

class Controller {
  public readonly state = authenticatedState;

  public readonly logout = async (): Promise<void> => {
    this.state.userIsAuthenticated.next(false);
    userService.removeToken();

    const { mfaController } = await import(
      "service/shared/singletones/mfaService/mfa.controller"
    );
    mfaController.clearIsMfaActive();
    mfaService.state.isMfaCompleted.next(false);

    await client.post(apiPaths.logout);
  };

  public readonly authenticate = (): void => {
    this.state.userIsAuthenticated.next(true);
  };
}

export const authenticatedController = new Controller();
