import React from "react";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { setBackgroundColor_fff6f6 } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_fff6f6";
import { setColor_9f3a38 } from "theme/setter/setColorsValues/setColor_9f3a38";

type SForgotPasswordFormInputProps = {
  isError: boolean;
  $isArabic?: boolean;
};

const isErrorSet = setProps<SForgotPasswordFormInputProps>(({ isError }) => isError)(css`
  ${setColor_9f3a38};
  ${setBackgroundColor_fff6f6};
`);

export const SForgotPasswordFormInput = styled.input<SForgotPasswordFormInputProps & React.InputHTMLAttributes<HTMLInputElement>>`
  ${isErrorSet};
`;
