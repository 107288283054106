export const translationAr = {
  loading: "جار التحميل...",
  logout: {
    signout: "تسجيل الخروج",
    signoutMessage: "هل أنت متأكد أنك تريد تسجيل الخروج? ستحتاج إلى تسجيل الدخول مرة أخرى لاحقا",
  },
  h2hPage: {
    errorMessage: "لم يتم إدخال الحقول",
    errorMessageMerchant: "تاجر",
  },
  activateInvitePage: {
    activate: "تفعيل",
    activationCode: "رمز OTP من الرسائل القصيرة",
    capsLockError: "تم تفعيل الحروف الكبيرة",
    password: "كلمة المرور الجديدة",
    redirectToSignIn: "جارٍ إعادة التوجيه إلى تسجيل الدخول",
    repeatedPassword: "كرر كلمة المرور",
    successMessage: "تم بنجاح",
    title: "تفعيل المستخدم",
  },
  activateInvitePageService: {
    tokenErrorTitle: "خطأ في التفعيل، يرجى الاتصال بالمسؤول",
  },
  amountFilterModel: {
    title: "المبلغ",
  },
  auditPageConfig: {
    created: "تاريخ الإنشاء",
    details: "التفاصيل",
    executor: "المنفذ",
    requestType: "نوع الطلب",
    sourceIp: "عنوان IP",
    successfully: "الحالة",
  },
  auditUserIdModel: {
    title: "المستخدم",
  },
  authCodeFilterModel: {
    title: "رمز المصادقة",
  },
  authIdFilterModel: {
    title: "معرف المصادقة",
  },
  cardHolderFilterModel: {
    title: "اسم حامل البطاقة",
  },
  cardNumberFilterModel: {
    title: "رقم البطاقة",
  },
  changeEmailPhoneInputSection: {
    email: "البريد الإلكتروني",
    phone: "رقم الهاتف",
    title: "تغيير البريد الإلكتروني وكلمة المرور",
  },
  changePasswordSection: {
    buttonTitle: "تغيير كلمة المرور",
    capsLockError: "تم تفعيل الحروف الكبيرة",
    currentPasswordTitle: "كلمة المرور الحالية",
    digitsError: "يجب أن تحتوي كلمة المرور على أرقام",
    lengthError: "يجب أن تكون كلمة المرور من {{minLength}} إلى {{maxLength}} رمزًا",
    lowercaseLettersError: "يجب أن تحتوي كلمة المرور على حروف صغيرة",
    newPasswordTitle: "كلمة المرور الجديدة",
    repeatedNewPasswordTitle: "كرر كلمة المرور الجديدة",
    russianLetterError: "يجب أن تحتوي كلمة المرور على أحرف لاتينية فقط",
    showCurrentPasswordEqualNewPasswordError: "يجب أن تختلف كلمة المرور الجديدة عن القديمة",
    showNewPasswordNotEqualRepeatedNewPasswordError: "كلمة المرور الجديدة غير متطابقة",
    specialSymbolsError: "يجب أن تحتوي كلمة المرور على رموز خاصة (@ # $ % ^ & ; : * _ - + = ! ? [ ] { } / \\ . , < >)",
    title: "تغيير كلمة المرور",
    uppercaseLettersError: "يجب أن تحتوي كلمة المرور على حروف كبيرة",
  },
  compareMonthService: {
    aprilTitle: "أبريل",
    augustTitle: "أغسطس",
    decemberTitle: "ديسمبر",
    februaryTitle: "فبراير",
    januaryTitle: "يناير",
    julyTitle: "يوليو",
    juneTitle: "يونيو",
    marchTitle: "مارس",
    mayTitle: "مايو",
    novemberTitle: "نوفمبر",
    octoberTitle: "أكتوبر",
    septemberTitle: "سبتمبر",
  },
  controlsSelect: {
    title: "عدد الصفوف في الصفحة",
  },
  createUserFetchedSelectService: {
    initialOptionTitle: "جار التحميل...",
  },
  createUserServiceFormService: {
    email: "البريد الإلكتروني",
    merchantGroupIdMessage: "لم يتم اختيار المجموعة",
    merchantGroupIdTitle: "اختر مجموعة",
    name: "اسم المستخدم",
    phoneNumber: "رقم الهاتف",
    rolesMessage: "لم يتم اختيار الدور",
    rolesTitle: "اختر دورًا",
  },
  customDateSelect: {
    applyButtonTitle: "تطبيق",
    closeButtonTitle: "إلغاء",
  },
  customMultipleSelect: {
    allOptionTitle: "الكل",
    buttonTitle: "تم الاختيار: {{value}}",
  },
  dateFilterModel: {
    title: "التواريخ، المدى",
  },
  dynamicPage: {
    applyButton: "تطبيق",
    compareParamTitle: "معايير المقارنة",
    firstGroupTitle: "المجموعة الأولى",
    graphTitle: "المقاييس",
    monthInputTitle: "الشهر",
    notEnoughDataErrorMessageTitle: "لا توجد بيانات كافية للمقارنة",
    pageTitle: "ديناميكي",
    secondGroupTitle: "المجموعة الثانية",
    terminalInputTitle: "المحطات",
    xAxisTitle: "اليوم",
    yAxisTitle: "مبلغ الحركات، دينار عراقي",
    yearInputTitle: "السنة",
  },
  filterFetchedSelectDataModel: {
    loadedTitle: "جار التحميل...",
  },
  filters: {
    addFilterButton: "إضافة فلتر",
    applyButtonTitle: "تطبيق",
    filterButton: "الفلاتر",
    filterSelectorSelectTitle: "اختر فلترًا",
    filterSelectorTitle: "إضافة فلتر",
    rangeInputTitle: "التواريخ، الفترة",
    resetButton: "إعادة تعيين",
    downloadButtonTitle: "تصدير إلى ملف",
    downloadErrorMax: "تحاول تحميل أكثر من 5000 سجل",
    cvsNamePayments: "المدفوعات",
    cvsNameInvoices: "الفواتير",
    cvsNameTransfers: "التحويلات",
    cvsNameRefunds: "المبالغ المستردة",
    showFiltersButton: "إظهار المرشحات",
    exportButtonTitle: "تصدير",
    addNewFilterButton: "إضافة فلتر جديد",
    startDate: "تاريخ البدء",
    endDate: "تاريخ الانتهاء",
    applyTitle: "تطبيق المرشحات",
    clearButton: "مسح جميع المرشحات",
  },
  forgotPasswordFormService: {
    resetPasswordMessage: "تم إرسال بريد إلكتروني لاستعادة كلمة المرور",
  },
  forgotPasswordPage: {
    backButton: "رجوع",
    emailPlaceholder: "البريد الإلكتروني",
    sendButtonTitle: "إرسال",
    title: "استعادة كلمة المرور",
  },
  formLayout: {
    bottomTitle: "العراق، بغداد",
    topTitle: "THE INTERNATIONAL SMART CARD CO. LLC",
  },
  formOpenButton: {
    title: "فاتورة جديدة",
    titleButton: "إنشاء فاتورة",
  },
  groupTableBodyService: {
    detailsTitle: "التفاصيل",
    emailTitle: "البريد الإلكتروني",
    groupTitle: "مجموعة",
    nameTitle: "الاسم",
    phoneNumberTitle: "رقم الهاتف",
  },
  invoiceCreateForm: {
    cancelButtonTitle: "إلغاء",
    createButtonTitle: "إنشاء",
    createNewInvoiceTitle: "إنشاء فاتورة جديدة",
  },
  invoiceCreateFormService: {
    amountErrorMessage: "المبلغ غير صحيح",
    amountTitle: "المبلغ",
    oneStagePaymentTypeTitle: "مرحلة واحدة",
    twoStagePaymentTypeTitle: "مرحلتين",
    paymentTypeTitle: "نوع الدفع",
    paymentTypeErrorMessage: "تم إدخال نوع الدفع بشكل غير صحيح",
    createInvoiceMessage: "تم إنشاء الفاتورة",
    currencyErrorMessage: "العملة غير صحيحة",
    currencyTitle: "العملة",
    descriptionErrorMessage: "الوصف غير صحيح",
    descriptionTitle: "الوصف",
    emailErrorMessage: "البريد الإلكتروني غير صحيح",
    emailTitle: "البريد الإلكتروني للعميل",
    phoneErrorMessage: "رقم الهاتف غير صحيح",
    phoneTitle: "رقم الهاتف للعميل",
    terminalErrorMessage: "المحطة غير صحيحة",
    terminalTitle: "المحطة",
    titleErrorMessage: "العنوان غير صحيح",
    titleTitle: "العنوان",
  },
  invoiceDetailsPage: {
    amount: "مبلغ الفاتورة",
    copyButtonTitle: "رابط الفاتورة",
    creationDate: "تاريخ الإنشاء",
    currency: "العملة",
    deleteButton: "إلغاء",
    description: "وصف الفاتورة",
    email: "البريد الإلكتروني للعميل",
    headerTitle: "الفواتير",
    phone: "رقم الهاتف للعميل",
    sendSmsButtonTitle: "إرسال SMS",
    sendEmailButtonTitle: "إرسال البريد الإلكتروني",
    status: "الحالة",
    tableTitle: "الحركات",
    terminalId: "معرف  المحطة",
    title: "الفواتير",
  },
  invoiceDetailsPageConfig: {
    details: "تفاصيل",
    paymentId: "معرف الدفع",
    status: "الحالة",
  },
  invoiceDetailsPageService: {
    linkCopied: "تم نسخ رابط الفاتورة",
    messageSendEmailTitle: "تم إرسال الرسالة عبر البريد الإلكتروني",
    messageSendSmsTitle: "تم إرسال الرسالة عبر الرسائل القصيرة",
    paymentRemove: "تم حذف الفاتورة",
  },
  invoiceIdFilterModel: {
    title: "معرفات الفواتير",
  },
  invoicesPageConfig: {
    amount: "المبلغ",
    creationDate: "تاريخ الإنشاء",
    details: "تفاصيل",
    email: "البريد الإلكتروني للعميل",
    invoiceId: "معرفات الفواتير",
    phone: "رقم هاتف العميل",
    status: "الحالة",
    terminalId: "معرف المحطة",
  },
  mainLayout: {
    auditTitle: "التدقيق",
    h2h: "H2H",
    logoutTitle: "الملف الشخصي",
    profileTitle: "تسجيل الخروج",
    supportAdministration: "الدعم",
    terminalsTitle: "المحطات",
    title: "بوابة التاجر UI v1.1.7",
    userTitle: "إدارة المستخدم",
    myAccountTitle: "حسابي",
    accountSettingsTitle: "إعدادات الحساب",
  },
  multipleTerminalFilterModel: {
    title: "المحطات",
  },
  navigationMenuService: {
    h2h: "H2H",
    dynamic: "ديناميكي",
    invoices: "الفواتير",
    payments: "الحركات",
    refunds: "المبالغ المستردة",
    reward: "التسوية",
    statistic: "الإحصائيات",
    support: "الدعم",
    transfers: "التحويلات",
    rewards: "التسوية",
    details: "التفاصيل",
    terminals: "المحطات",
    userInfo: "تفاصيل حسابي",
    usersInfo: "إدارة مستخدمي البوابة",
  },
  notFoundPage: {
    title: "الصفحة غير موجودة",
  },
  operationStatusFilterModel: {
    title: "حالة الحركة",
    AUTHENTICATED: "تم اجتياز المصادقة الثلاثية",
    AUTHENTICATION_FAILED: "فشل المصادقة الثلاثية",
    AUTHENTICATION_REQUIRED: "يتطلب المصادقة الثلاثية",
    AUTHENTICATION_STARTED: "بدأ المصادقة الثلاثية",
    CREATED: "تم الإنشاء",
    ERROR: "خطأ",
    EXPIRED: "انتهت المهلة",
    FORM_SHOWED: "تم عرض النموذج",
    PROCESSING: "جارٍ المعالجة",
    THREE_DS_METHOD_CALL_REQUIRED: "طريقة المصادقة الثلاثية",
    FAILED: "فشل",
    SUCCESS: "نجاح",
  },
  operationTypeFilterModel: {
    MIR_PAY: "مير باي",
    APPLE_PAY: "أبل باي",
    CARD: "بطاقة",
    GOOGLE_PAY: "جوجل باي",
    PAYMENT_TOKEN: "رمز الدفع",
    SAMSUNG_PAY: "سامسونج باي",
    SBP: "نظام الدفع السريع",
    title: "نوع الحركة",
  },
  panFilterModel: {
    title: "PAN",
  },
  paymentDetailsPage: {
    backButtonButton: "رجوع",
    cancelSwitchTitle: "المبالغ المستردة",
    cancelTitle: "إلغاء",
    confirmSwitchTitle: "التسويات",
    confirmTitle: "تأكيد",
    enterCancelSumTitle: "أدخل مبلغ الإلغاء",
    enterConfirmSumTitle: "أدخل مبلغ التسوية",
    enterRefundSumTitle: "أدخل مبلغ الاسترداد",
    headerTitle: "الحركات",
    partialActionButton: "جزئي",
    paymentCancelMessage: "تم إلغاء الحركة",
    paymentConfirmMessage: "تم تأكيد الحركة",
    paymentRefundMessage: "تم استرداد الحركة",
    refundSwitchTitle: "المبالغ المستردة",
    refundTitle: "استرداد",
    additionalInfoTitle: "معلومات إضافية",
    selectCancelTitle: "اختر نوع الإلغاء",
    selectConfirmTitle: "اختر نوع التسوية",
    selectRefundTitle: "اختر نوع الاسترداد",
  },
  paymentDetailsPageCancelConfig: {
    amount: "المبلغ",
    creationDate: "تاريخ الإنشاء",
    status: "الحالة",
    refundId: "معرف الاسترداد",
  },
  paymentDetailsPageConfirmConfig: {
    amount: "المبلغ",
    creationDate: "تاريخ الإنشاء",
    status: "الحالة",
  },
  paymentDetailsPageContent: {
    phoneInputPlaceholder: "أدخل رقم الهاتف",
    actionInputPlaceholder: "أدخل المبلغ",
    additionalInfo: "معلومات إضافية",
    amount: "مبلغ الحركة",
    trxId: "TRX_ID",
    authId: "رمز المصادقة",
    cancelButtonTitle: "إلغاء",
    canceled: "ملغي",
    cardHolder: "اسم حامل البطاقة",
    confirmButtonTitle: "تأكيد",
    confirmedAmount: "المبلغ المؤكد",
    creationDate: "تاريخ ووقت الإنشاء",
    currency: "عملة الحركة",
    externalRrn: "الرقم التعريفي الخارجي RRN",
    fee: "رسوم",
    fullActionButtonTitle: "المبلغ الكامل",
    maskedPan: "PAN",
    paymentSystem: "نظام الدفع",
    refundButtonTitle: "استرداد",
    refundsAmount: "مبلغ الاسترداد",
    requestId: "معرف الطلب",
    resultCode: "رمز الاستجابة",
    rrn: "RRN",
    status: "الحالة",
    terminalId: "معرف المحطة",
    type: "نوع الحركة",
    essentialInformationTitle: "معلومات أساسية",
    additionalInformationTitle: "معلومات إضافية",
  },
  paymentDetailsPageRefundConfig: {
    amount: "المبلغ",
    creationDate: "تاريخ الإنشاء",
    details: "تفاصيل",
    status: "الحالة",
  },
  paymentIdFilterModel: {
    title: "معرف الحركة",
  },
  paymentRefundDetailsPage: {
    titlePayment: "الحركات",
    titleRefund: "المبالغ المستردة",
  },
  paymentRefundDetailsPageContent: {
    amount: "مبلغ الفاتورة",
    authId: "رمز المصادقة",
    creationDate: "تاريخ ووقت الإنشاء",
    currency: "عملة الفاتورة",
    maskedPan: "PAN",
    paymentSystem: "نظام الدفع",
    resultCode: "رمز الاستجابة",
    rrn: "RRN",
    externalId: "معرف خارجي",
    status: "الحالة",
    terminalId: "معرف المحطة",
  },
  paymentSystemTypeFilterModel: {
    mastercard: "ماستركارد",
    mir: "مير",
    title: "نظام الدفع",
    visa: "فيزا",
  },
  h2hPageConfig: {
    details: "تفاصيل",
    terminalTzOperationTime: "المنطقة الزمنية للمحطة",
    processingTzOperationTime: "المنطقة الزمنية للمضيف",
    merchantId: "معرف التاجر",
    terminalId: "معرف المحطة",
    amount: "مبلغ الحركة",
    refundsAmount: "مبلغ الاسترداد",
    messageCode: "رمز الرسالة",
    responseCode: "رمز الاستجابة",
    maskedPan: "PAN",
    rrn: "RRN",
    transactionType: "نوع الحركة",
    authId: "رمز المصادقة",
    pointOfServiceEntryMode: "وضع إدخال نقطة الخدمة",
    eci: "ECI",
    mcc: "MCC",
    terminalName: "اسم المحطة",
    terminalCity: "المدينة",
    terminalRegion: "المنطقة",
    terminalCountry: "البلد",
    currency: "رمز العملة",
    acquireId: "معرف المستحصل",
    paymentSystem: "نظام الدفع",
  },
  paymentsPageConfig: {
    amount: "المبلغ",
    creationDate: "التاريخ والوقت",
    details: "تفاصيل",
    maskedPan: "PAN (مخفي)",
    paymentId: "معرف الدفع",
    rrn: "RRN",
    status: "الحالة",
    terminalId: "معرف المحطة",
  },
  refundDetailsPage: {
    headerTitle: "المبالغ المستردة",
  },
  refundDetailsPageContent: {
    amount: "مبلغ الاسترداد",
    authId: "رمز المصادقة",
    creationDate: "تاريخ ووقت الإنشاء",
    currency: "عملة الفاتورة",
    maskedPan: "PAN",
    paymentSystem: "نظام الدفع",
    requestId: "معرف الطلب",
    resultCode: "رمز الاستجابة",
    rrn: "RRN",
    successful: "الحالة",
    tableTitle: "فاتورة",
    terminalId: "معرف المحطة",
  },
  refundDetailsPageService: {
    details: "تفاصيل",
    paymentId: "معرف الدفع",
  },
  refundIdFilterModel: {
    title: "معرف الاسترداد",
  },
  refundPaymentFilterModel: {
    title: "معرف الدفع",
  },
  refundsPageConfig: {
    amount: "المبلغ",
    creationDate: "التاريخ والوقت",
    details: "تفاصيل",
    maskedPan: "PAN (مخفي)",
    refundId: "معرف الاسترداد",
    rrn: "RRN",
    status: "الحالة",
    terminalId: "معرف المحطة",
  },
  requestIdFilterModel: {
    title: "معرف الطلب",
  },
  resetPasswordFormService: {
    changedPasswordTitle: "تم تغيير كلمة المرور بنجاح",
  },
  resetPasswordPage: {
    backButtonTitle: "رجوع",
    passwordPlaceholder: "كلمة المرور",
    repeatPasswordPlaceholder: "إعادة كلمة المرور",
    sendButtonTitle: "إرسال",
    title: "تغيير كلمة المرور",
  },
  resultCodeFilterModel: {
    title: "رمز الاستجابة",
  },
  rewardDetailsPage: {
    informationContentFeeAmountTitle: "الرسوم",
    informationContentIncomeAmountTitle: "المبلغ",
    informationContentNotFound: "البيانات غير موجودة",
    informationContentSettlementAmountTitle: "التسوية",
  },
  rewardDetailsPageConfig: {
    amount: "مبلغ الحركة",
    authId: "رمز المصادقة",
    fee: "الرسوم",
    operTime: "وقت الحركة",
    pan: "PAN",
    paymentId: "معرف الفاتورة",
    rrn: "RRN",
    settlement: "التسويات",
    terminalId: "معرف المحطة",
    terminalName: "اسم المحطة",
  },
  rewardsPageConfig: {
    date: "تاريخ التسوية",
    details: "تفاصيل",
    feeAmount: "مقدار الرسوم",
    incomeAmount: "مبلغ الحركة",
    operationsNumber: "عدد الحركات",
    settlementAmount: "مبلغ التسوية",
    transactionCurrency: "عملة الحركة",
    settlementCurrency: "التسويات",
  },
  signInFormService: {
    accessDeniedTitle: "تم رفض الوصول",
    systemUserAccessDenied: "تم رفض وصول مستخدم النظام",
  },
  signInPage: {
    capsLockError: "تبديل الأحرف الكبيرة مفعل",
    digitsError: "يجب أن تحتوي كلمة المرور على أرقام",
    emailTitle: "البريد الإلكتروني",
    forgotPasswordButton: "نسيت كلمة المرور؟",
    lengthError: "يجب أن تتراوح كلمة المرور من {{minLength}} إلى {{maxLength}} رمزًا",
    lowercaseLettersError: "يجب أن تحتوي كلمة المرور على أحرف صغيرة",
    passwordTitle: "كلمة المرور",
    russianLetterError: "يجب أن تحتوي كلمة المرور على أحرف لاتينية فقط",
    sendButton: "تسجيل الدخول",
    showPasswordNotEqualRepeatedPasswordError: "كلمة المرور الجديدة غير مطابقة",
    specialSymbolsError: "يجب أن تحتوي كلمة المرور على رموز خاصة (@ # $ % ^ & ; : * _ - + = ! ? [ ] { } / \\ . , < >)",
    title: "تسجيل الدخول إلى بوابة التاجر",
    uppercaseLettersError: "يجب أن تحتوي كلمة المرور على أحرف كبيرة",
    placeholderEmail: "...البريد الإلكتروني",
    placeholderPassword: "...كلمة المرور",
  },
  statisticPage: {
    conversionsTitle: "معدل التحويل",
    errorMessage: "الحقول المطلوبة فارغة",
    failTitle: "فشل",
    paymentSystemTitle: "أنظمة الدفع",
    successTitle: "نجاح",
    conversionRateTitle: "معدل التحويل",
    paymentSchemesTitle: "مخططات الدفع",
  },
  statisticPageConfig: {
    datePart: "التاريخ",
    failQuantity: "فشل",
    refundQuantity: "استردادات",
    successQuantity: "نجاح",
    successSum: "الإجمالي",
    terminalId: "المحطة",
  },
  statisticStepFilterModel: {
    DAY: "يوم",
    MONTH: "شهر",
    YEAR: "سنة",
    title: "خطوة التجميع",
  },
  statisticTypeFilterModel: {
    QUANTITY: "الكمية",
    SUM: "المبلغ",
    title: "نوع التجميع",
  },
  succesfullyFilterModel: {
    FAIL: "فشل",
    SUCCESS: "نجاح",
    PROCESSING: "جارٍ المعالجة",
    title: "الحالة",
  },
  supportAdministrationPage: {
    applyChangesButtonTitle: "تطبيق التغييرات",
    articleFormApplyButtonTitle: "إنشاء مقال",
    createArticleDescriptionInputPlaceholder: "أدخل الوصف",
    createArticleTypeInputPlaceholder: "أدخل النوع",
    emailInputPlaceholder: "أدخل البريد الإلكتروني",
    feedbackTitle: "التعليقات",
    openCreateSupportButtonTitle: "إنشاء مقال دعم",
  },
  supportPage: {
    feedBackFormTextFieldPlaceholder: "أدخل الرسالة",
    feedbackFormSelector: "أدخل الرسالة",
    feedbackFormSendButtonTitle: "إرسال",
    feedbackFormTitle: "التعليقات",
    listTitle: "الأسئلة الشائعة",
    pageTitle: "الدعم",
    pageSupportTitle: "طلب الدعم",
    messageTitle: "عنوان الرسالة",
    messageBody: "نص الرسالة",
    sendRequestButtonTitle: "إرسال الطلب",
  },
  tableContent: {
    notDataTitle: "الدعم",
  },
  tableNavigation: {
    totalOperationsTitle: "إجمالي الحركات",
    totalPageTitle: "إجمالي الصفحات",
    showingElements: "عرض",
    fromElements: "من عند",
  },
  terminalFilterModel: {
    title: "المحطة",
  },
  merchantFilterModel: {
    title: "التاجر",
  },
  rrnFilterModel: {
    title: "RRN",
  },
  terminalsPage: {
    countPostTitle: "سجلات من النتائج",
    countPreTitle: "المعروض",
    filterTitle: "فلتر",
    terminalListTitle: "المحطات",
    terminalsApplyButtonTitle: "تطبيق",
    terminalsClearFilterButtonTitle: "إعادة تعيين الفلاتر",
    terminalsFilterSelectTitle: "فلتر",
    terminalsListTableHeaderMerchantName: "اسم التاجر",
    terminalsListTableHeaderTerminalId: "معرف المحطة",
  },
  toastContainerService: {
    errorTitle: "خطأ",
    noInfoTitle: "لم يتم العثور على بيانات",
    successTitle: "نجاح",
  },
  transferDetailsPage: {
    cancelTransferButtonTitle: "إلغاء التحويل",
    title: "التحويلات",
  },
  transferDetailsPageConfig: {
    creationDate: "تاريخ الإنشاء",
    status: "الحالة",
  },
  transferDetailsPageContent: {
    amountTitle: "المبلغ",
    cancelButtonTitle: "إلغاء التحويل",
    canceledTitle: "تم الإلغاء",
    creationDateTitle: "تاريخ التحويل",
    currencyTitle: "العملة",
    destinationAuthId: "رمز المصادقة",
    destinationExternalRrn: "RRN خارجي",
    destinationMaskedPan: "PAN",
    destinationPaymentSystem: "نظام الدفع",
    destinationResultCode: "رمز الاستجابة",
    destinationRrn: "RRN",
    destinationTitle: "الوجهة",
    fee: "الرسوم",
    requestIdTitle: "معرف الطلب",
    sourceAuthId: "رمز المصادقة",
    sourceCardHolder: "اسم حامل البطاقة",
    sourceExternalRrn: "RRN خارجي",
    sourceMaskedPan: "PAN",
    sourcePaymentSystem: "نظام الدفع",
    sourceResultCode: "رمز الاستجابة",
    sourceRrn: "RRN",
    sourceTitle: "المصدر",
    statusTitle: "الحالة",
    terminalIdTitle: "معرف المحطة",
    typeTitle: "نوع التحويل",
  },
  transferDetailsPageService: {
    transferCancel: "تم إلغاء التحويل",
  },
  transferIdFilterModel: {
    title: "معرف التحويل",
  },
  transferTypeFilterModel: {
    AFT: "AFT",
    C2C: "C2C",
    OCT: "OCT",
    title: "نوع الحركة",
  },
  transfersPageConfig: {
    amount: "المبلغ",
    creationDate: "تاريخ الإنشاء",
    details: "تفاصيل",
    requestId: "معرف الطلب",
    status: "الحالة",
    terminalId: "معرف المحطة",
    transferId: "معرف التحويل",
    type: "نوع الحركة",
  },
  userInfoPage: {
    title: "معلومات المستخدم",
  },
  userInfoPageContent: {
    email: "البريد الإلكتروني",
    id: "معرف",
    name: "اسم المستخدم",
    phone: "رقم الهاتف",
    roles: "الأدوار",
    blocked: "محظور",
  },
  userInfoPageService: {
    changedEmailPhoneTitle: "تم تغيير رقم الهاتف والبريد الإلكتروني بنجاح",
    changedPasswordTitle: "تم تغيير كلمة المرور بنجاح",
    errorBadCredentialsTitle: "انتهت صلاحية الجلسة",
    errorEmailExistTitle: "البريد الإلكتروني مسجل بالفعل",
    errorInvitationCodeTitle: "رمز الدعوة غير صحيح",
    errorNotExistPaymentTitle: "الفاتورة غير موجودة",
    errorPasswordEqualTitle: "كلمة المرور الجديدة هي نفس السابقة",
    errorPasswordExpireTitle: "انتهت صلاحية كلمة المرور",
    errorPasswordTitle: "لم يتم تغيير كلمة المرور",
    errorPasswordUnEqualTitle: "كلمة المرور الحالية غير صحيحة",
    errorPasswordWeakTitle: "كلمة المرور الجديدة لا تفي بمتطلبات الأمان",
    errorSessionExpiredTitle: "انتهت صلاحية الجلسة",
    errorTerminalsNotAvailableTitle: "المحطات محظورة على المستخدم الحالي",
    errorUserWithEmailNotExistTitle: "البريد الإلكتروني غير صحيح",
    errorNotSuccess: "لم ينجح",
  },
  usersChangeRowRolesService: {
    administratorDescription: "الوصول إلى كافة الوظائف للتاجر أو مجموعة التجار",
    rInvoiceDescription: "الوصول لقراءة بيانات الفواتير",
    rOperationDescription: "الوصول لقراءة بيانات الحركات",
    rH2H: "الوصول لقراءة بيانات H2H",
    rRefundDescription: "الوصول لقراءة بيانات الاستردادات",
    rRewardDescription: "الوصول لقراءة بيانات التسويات",
    rStatisticsDescription: "الوصول إلى البيانات الديناميكية والإحصائيات",
    rTransferDescription: "الوصول لقراءة بيانات التحويلات",
    wInvoiceDescription: "الوصول إلى إنشاء الفواتير",
    xCancelDescription: "الوصول إلى إلغاء الحركات",
    xConfirmDescription: "الوصول إلى تأكيد الحركات",
    xInvoiceDescription: "الوصول إلى إلغاء أو إرسال الفواتير",
    xRefundDescription: "الوصول إلى تنفيذ الاستردادات",
    xTransferDescription: "الوصول إلى تنفيذ التحويلات",
  },
  usersDetailsPage: {
    blockUserButtonTitle: "حظر المستخدم",
    blockedFlag: "محظور",
    changePasswordButtonTitle: "تغيير كلمة المرور",
    changePasswordFormTitle: "تغيير كلمة المرور",
    email: "البريد الإلكتروني",
    enabled: "مفعل",
    id: "معرف",
    merchantGroupName: "مجموعة التجار",
    name: "اسم المستخدم",
    phone: "رقم الهاتف",
    resetPasswordApplyButton: "تطبيق",
    resetPasswordBackButton: "رجوع",
    resetPasswordButtonTitle: "إعادة تعيين كلمة المرور",
    roles: "الأدوار",
    rolesFormApplyButton: "تطبيق",
    rolesFormBackButton: "رجوع",
    rolesSelectFormTitle: "الأدوار",
    sessionDropButtonTitle: "إنهاء جلسات المستخدم",
    unBlockUserButtonTitle: "فك حظر المستخدم",
    userResetPasswordFormTitle: "هل أنت متأكد أنك تريد إعادة تعيين كلمة المرور؟",
    userAccountInfo: "معلومات حساب المستخدم",
  },
  usersDetailsPageService: {
    currentPasswordTitle: "كلمة المرور الحالية",
    newPasswordTitle: "كلمة المرور الجديدة",
    passwordWasReset: "تم إعادة تعيين كلمة المرور",
    sessionIsNotExist: "لا توجد جلسة نشطة",
    sessionWasBreak: "تم قطع الجلسة",
    title: "معلومات المستخدم",
  },
  usersInfoPage: {
    createUserFormTitle: "دعوة إنشاء مستخدم",
    createUserInviteButtonTitle: "إنشاء دعوة",
    createUserSelectErrorMessage: "يرجى إدخال قيمة صحيحة",
    openUserFormButton: "إضافة",
    createMultipleSelectTitle: "اختر دورًا",
    createMultipleSelectContainer: "محدد: ",
    createMultipleSelectMessage: "الأدوار غير مدخلة",
    openAddUserFormButton: "إضافة مستخدم",
  },
  customerEmailFilterModel: {
    title: "البريد الإلكتروني",
  },
  customerPhoneNumberFilterModel: {
    title: "رقم الهاتف",
  },
  mfa: {
    button: {
      submit: "إرسال",
    },
    enterQrCodeFirstStap: "الخطوة 1: مسح رمز مع التطبيق المصادقة لإعداد المصادقة 2 عامل",
    enterCodeSecondStap: "الخطوة 2: لإكمال الإعداد ، أدخل الرمز المكون من 6 أرقام الموضح في تطبيق المصادقة",
    enterCodeThirdStap: "لتسجيل الدخول إلى البوابة ، أدخل الرمز المكون من 6 أرقام الموضح في تطبيق المصادقة الخاص بك",
    verification: "التحقق",
    ready: {
      toastError: "MFA خطأ في التحقق من الحالة",
    },
    validate: {
      toastError: "رمز غير صالح. يرجى المحاولة مرة أخرى أو إعادة توليده في تطبيق المصادقة الخاص بك",
      toastSuccess: "تم إكمال إعداد المصادقة الثنائية!",
    },
    generate: {
      toastError: "خطأ عند إنشاء رمز الاستجابة السريعة",
    },
    verify: {
      toastError: "رمز غير صالح. يرجى المحاولة مرة أخرى أو إعادة توليده في تطبيق المصادقة الخاص بك",
    },
  },
};
