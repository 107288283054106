import React from "react";
import { setColor_2d3033, setFontSize_18px, setFontWeight600, setTextRightArabic, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

export const SSupportPageQuestionTitle = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  text-align: left;
  ${setFontSize_18px};
  ${setFontWeight600};
  ${setColor_2d3033};
  ${setTextRightArabic()};
`;
