/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SRowTitleQi } from "components/Row/RowStyled/SRowTitle.styled";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableString } from "observables/StringObservable";
import { useTranslation } from "react-i18next";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { SCreateUserFetchedSelectMessageQi } from "../CreateUserFetchedSelect/CreateUserFetchedSelectStyled/SCreateUserFetchedSelectMessage.styled";
import { CreateUserSelectProps } from "./CreateUserSelect.props";
import { SCreateUserSelect, SCreateUserSelectQi } from "./CreateUserSelectStyled/SCreateUserSelect.styled";
import { SCreateUserSelectInput, SCreateUserSelectInputQi } from "./CreateUserSelectStyled/SCreateUserSelectInput.styled";
import { SCreateUserSelectMessage } from "./CreateUserSelectStyled/SCreateUserSelectMessage.styled";

export const CreateUserSelect = createComponent<CreateUserSelectProps>("CreateUserSelect", (props) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const { service } = props;

  const value = useDefaultObservableString(service.state.value);
  const showError = useDefaultObservableBoolean(service.state.showError);

  const getServiceType = () => {
    if (service.state.isPhoneNumber) {
      return "Phone Number";
    }
    if (service.state.isEmail) {
      return "Email";
    }
    return "User Name";
  };

  return isQi ? (
    <SCreateUserSelectQi>
      <SRowTitleQi>{isArabic ? t(service.state.placeholder) : t(getServiceType())}</SRowTitleQi>
      <SCreateUserSelectInputQi
        type="text"
        value={value}
        onFocus={service.controller.onFocus}
        onChange={service.controller.onChange}
      // placeholder={service.state.placeholder}
      />
      {showError && <SCreateUserFetchedSelectMessageQi>{t("usersInfoPage.createUserSelectErrorMessage")}</SCreateUserFetchedSelectMessageQi>}
    </SCreateUserSelectQi>
  ) : (
    <SCreateUserSelect>
      <SCreateUserSelectInput
        type="text"
        value={value}
        onFocus={service.controller.onFocus}
        onChange={service.controller.onChange}
        placeholder={service.state.placeholder}
      />
      {showError && <SCreateUserSelectMessage>{t("usersInfoPage.createUserSelectErrorMessage")}</SCreateUserSelectMessage>}
    </SCreateUserSelect>
  );
});
