import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { statisticPageService } from "service/shared/singletones/statisticPageService/statisticPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { SStatisticErrorMessage, SStatisticErrorMessageQi } from "./StatisticErrorMessageStyled/SStatisticErrorMessage.styled";
import { SStatisticErrorMessageContainer, SStatisticErrorMessageContainerQi } from "./StatisticErrorMessageStyled/SStatisticErrorMessageContainer.styled";
import { SStatisticErrorMessageTitle } from "./StatisticErrorMessageStyled/SStatisticErrorMessageTitle.styled";

export const StatisticErrorMessage = createComponent("StatisticErrorMessage", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const showStatisticErrorMessage = useDefaultObservableBoolean(statisticPageService.state.showStatisticErrorMessage);

  if (!showStatisticErrorMessage) {
    return null;
  }

  return isQi ? (
    <SStatisticErrorMessageQi isArabic={isArabic}>
      <SStatisticErrorMessageContainerQi>
        <SStatisticErrorMessageTitle>{t("statisticPage.errorMessage")}</SStatisticErrorMessageTitle>
      </SStatisticErrorMessageContainerQi>
    </SStatisticErrorMessageQi>
  ) : (
    <SStatisticErrorMessage>
      <SStatisticErrorMessageContainer>
        <SStatisticErrorMessageTitle>{t("statisticPage.errorMessage")}</SStatisticErrorMessageTitle>
      </SStatisticErrorMessageContainer>
    </SStatisticErrorMessage>
  );
});
