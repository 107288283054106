import { styled } from "theme/default/styled";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";

export const STerminalsFilterSelect = styled.div`
  gap: 16px;
  ${setFlexDFAIC};
`;

export const STerminalsFilterSelectQi = styled.div`
  gap: 6px;
  display: flex;
  flex-direction: column;
  align-items: left;
`;
