import { Loader } from "components/Loader/Loader.component";
import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useFiltersContext } from "context/Filter.context";
import { useDefaultObservableNumber } from "observables/NumberObservable";
import { useState } from "packages/react";
import { useTranslation } from "react-i18next";
import { toastContainerService } from "service/shared/singletones/toastContainerService/toastContainer.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { SDownloadButton, SDownloadButtonQi } from "./DownloadButtonStyled/SDownloadButton.styled";
import { DownloadLink } from "./DownloadLink.component";
import { getCurrentDate } from "./getCurrentDate";

export const DownloadButton = createComponent("DownloadButton", () => {
  const { t } = useTranslation();
  const { csvName, csvCallback, totalElements } = useFiltersContext();

  if (!totalElements) {
    return null;
  }

  const currentDate = getCurrentDate();

  const totalElementsValue = useDefaultObservableNumber(totalElements);

  const [loading, setLoading] = useState<boolean>(false);
  const [fetched, setFetched] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);

  const onClick = async (): Promise<void> => {
    setLoading(true);
    const newData = await csvCallback!();
    if (newData.length <= 5000) {
      setData(newData);
      setFetched(true);
    } else {
      toastContainerService.controller.createErrorToast(t("filters.downloadErrorMax"));
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  return isQi ? (
    <>
      <SDownloadButtonQi disabled={totalElementsValue === 0} onClick={onClick}>
        <SvgImage name="Export" />
        {t("filters.exportButtonTitle")}
      </SDownloadButtonQi>
      {fetched && <DownloadLink csvName={csvName} currentDate={currentDate} data={data} />}
    </>
  ) : (
    <>
      <SDownloadButton disabled={totalElementsValue === 0} onClick={onClick}>
        {t("filters.downloadButtonTitle")}
      </SDownloadButton>
      {fetched && <DownloadLink csvName={csvName} currentDate={currentDate} data={data} />}
    </>
  );
});
