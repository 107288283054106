import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useFiltersContext } from "context/Filter.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { SResetButton, SResetButtonQi } from "./ResetButtonStyled/SResetButton.styled";

export const ResetButton = createComponent("ResetButton", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const { service } = useFiltersContext();

  const filterListIsEmpty = useDefaultObservableBoolean(service.state.filterListIsEmpty);

  return isQi ? (
    <SResetButtonQi isArabic={isArabic} filterListIsEmpty={filterListIsEmpty} onClick={service.controller.onClickReset}>
      {t("filters.clearButton")} <SvgImage name="ClearIcon" />
    </SResetButtonQi>
  ) : (
    <SResetButton filterListIsEmpty={filterListIsEmpty} onClick={service.controller.onClickReset}>
      {t("filters.resetButton")}
    </SResetButton>
  );
});
