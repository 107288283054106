/* eslint-disable prettier/prettier */
import ClipboardJS from "clipboard";
import { SvgImage } from "components/SvgImage/SvgImage.component";
import React, { useEffect, useState } from "react";
import { SvgNames } from "types/business/SvgNames";

import { SCopyButton, SDetailsContainer, SHeaderContainer, SSectionTitle } from "./HeaderSecondaryStyled/SHeaderSecondary.styled";

interface HeaderSecondaryProps {
    isArabic: boolean;
    headerTitle: string;
    id: string;
}

const HeaderSecondary: React.FC<HeaderSecondaryProps> = ({ isArabic, headerTitle, id }) => {
    const [copyStatus, setCopyStatus] = useState<"idle" | "success">("idle");

    useEffect(() => {
        const clipboard = new ClipboardJS(".copy-btn");

        clipboard.on("success", () => {
            setCopyStatus("success");
            setTimeout(() => setCopyStatus("idle"), 3000);
        });

        clipboard.on("error", (err) => {
            console.error("Failed to copy: ", err);
        });

        return () => {
            clipboard.destroy();
        };
    }, []);

    const getIconName = (): SvgNames => {
        return copyStatus === "success" ? "Check" : "CopyIcon";
    };

    return (
        <SHeaderContainer isArabic={isArabic}>
            <SSectionTitle isArabic={isArabic}>{headerTitle}</SSectionTitle>
            <SDetailsContainer isArabic={isArabic}>
                <SSectionTitle isArabic={isArabic}>#{id}</SSectionTitle>
                <SCopyButton
                    className="copy-btn"
                    isArabic={isArabic}
                    data-clipboard-text={id}
                >
                    <SvgImage name={getIconName()} />
                </SCopyButton>
            </SDetailsContainer>
        </SHeaderContainer>
    );
};

export default HeaderSecondary;
