import React from "react";
import { css, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  left: 4px;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  right: 4px;
`);

export const SFetchedFilterMultipleSelectClear = styled.button<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  ${isNotArabicLang};
  ${isArabicLang};
`;
