import React from "react";
import { css, setFlexDFFDCF1, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";
import { isQi } from "utils/business/environment";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  padding-left: 80px;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  padding-right: ${isQi ? "0px" : "80px"};
`);

export const SH2HPage = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 100%;

  ${isNotArabicLang};
  ${isArabicLang};
  ${setFlexDFFDCF1};
`;
