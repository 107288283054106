import { PopUpContainer } from "components/PopUpContainer/PopUpContainer.component";
import React from "react";
import styled, { css } from "styled-components";
import { resetScrollbar, setBackgroundColor_ffffff, setFlexDFFDC, setProps } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

export const SModalPopUp = styled(PopUpContainer)`
  background-color: transparent;
`;

export const SModalContainer = styled.div<SPropsIsArabic & React.HTMLAttributes<HTMLDivElement>>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  z-index: 1000;
  overflow: auto;
  width: 560px;
  height: auto;
  padding: 20px 24px;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  ${setFlexDFFDC};
  ${resetScrollbar};
  ${setBackgroundColor_ffffff};
  ${isArabicLang};
`;
