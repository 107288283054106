import { styled } from "theme/default/styled";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

export const SCreateUserFetchedSelect = styled.div`
  width: 200px;

  ${setFlexDFFDC};
`;

export const SCreateUserFetchedSelectQi = styled.div`
  width: 100%;

  ${setFlexDFFDC};
`;
