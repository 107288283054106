import { Table } from "components/Table/Table.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableTableService } from "observables/TableServiceObservable";
import { useTranslation } from "react-i18next";
import { rewardDetailsPageService } from "service/shared/singletones/rewardDetailsPageService/rewardDetailsPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { SRewardDetailsTable, SRewardDetailsTableQi } from "./RewardDetailsTableStyled/SRewardDetailsTable.styled";
import { SRewardDetailsTableContainer, SRewardDetailsTableContainerQi } from "./RewardDetailsTableStyled/SRewardDetailsTableContainer.styled";

export const RewardDetailsTable = createComponent("RewardDetailsTable", () => {
  const loaded = useDefaultObservableBoolean(rewardDetailsPageService.state.loaded);
  const tableService = useDefaultObservableTableService(rewardDetailsPageService.tableService);
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return isQi ? (
    <SRewardDetailsTableQi isArabic={isArabic}>
      <SRewardDetailsTableContainerQi>
        <Table loaded={loaded} service={tableService} />
      </SRewardDetailsTableContainerQi>
    </SRewardDetailsTableQi>
  ) : (
    <SRewardDetailsTable>
      <SRewardDetailsTableContainer>
        <Table loaded={loaded} service={tableService} />
      </SRewardDetailsTableContainer>
    </SRewardDetailsTable>
  );
});
