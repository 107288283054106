/* eslint-disable prettier/prettier */
/* eslint-disable max-len */

import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const SendInvitation = createIcon("SendInvitation", (props) => {
    const { width = 13, height = 13, color = props.isFinOn ? colorsValuesFin.color_0083f5 : "#8BC3F5" } = props;

    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2072_4232)">
                <path
                    d="M3.52363 3.02511L15.7501 8.99961L3.52363 14.9741C3.45384 15.0009 3.37765 15.0064 3.30474 14.9899C3.23183 14.9733 3.16549 14.9354 3.11413 14.8811C3.06127 14.8253 3.02487 14.756 3.00899 14.6808C2.9931 14.6056 2.99834 14.5275 3.02413 14.4551L4.87513 8.99961L3.02413 3.54411C2.99834 3.47172 2.9931 3.3936 3.00899 3.31841C3.02487 3.24322 3.06127 3.17389 3.11413 3.11811C3.16549 3.06378 3.23183 3.02593 3.30474 3.00937C3.37765 2.99281 3.45384 2.99829 3.52363 3.02511Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M4.875 9H15.75" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2072_4232">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
});
