import React from "react";
import { css, setFlexDFFDC, setProps, styled } from "theme";

interface SFiltersProps {
  isArabic: boolean;
  isOpen?: boolean;
}

const isArabicLang = setProps<SFiltersProps>(({ isArabic }) => isArabic)(css`
  align-items: flex-end;
`);
const isArabicLangQi = setProps<SFiltersProps>(({ isArabic }) => isArabic)(css`
  align-items: flex-end;
  margin-left: -62px;
  margin-right: 304px;
`);

const isNotArabicLangQi = setProps<SFiltersProps>(({ isArabic }) => !isArabic)(css`
  margin-left: 304px;
  margin-right: 32px;
`);

export const SFilters = styled.div<SFiltersProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin: 12px 8px 24px 12px;

  ${setFlexDFFDC};
  ${isArabicLang};
`;

export const SFiltersQi = styled.div<SFiltersProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin: 0px 32px 24px 32px;
  ${isArabicLang};
  border-radius: 12px;
`;

export const FilterButtonContainerQi = styled.div<SFiltersProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-bottom: ${({ isOpen }) => (!isOpen ? "-10px" : "12px")};
  display: flex;
  justify-content: space-between;
  ${isArabicLangQi};
  ${isNotArabicLangQi}
`;
