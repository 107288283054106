import { setFontSize_14px } from "theme";
import { styled } from "theme/default/styled";
import { setColor_69809a } from "theme/setter/setColorsValues/setColor_69809a";
import { setFontSize_12px } from "theme/setter/setFontSizeValues/setFontSize_12px";
import { setFontWeight500 } from "theme/setter/setFontWeightValues/setFontWeight500";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";

export const STableNavigationTotalPagesDescription = styled.div`
  ${setFontSize_12px};
  ${setFontWeight600};
  ${setColor_69809a};
`;

export const STableNavigationTotalPagesDescriptionQi = styled.div`
  ${setFontSize_14px};
  ${setFontWeight500};
  color: #707a8f;
`;
