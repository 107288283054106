/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prettier/prettier */
// import { ResetPasswordButton } from "components/AccountDetailsModal/ResetPasswordButton/ResetPasswordButton.component";
import { Header } from "components/HeaderModal/HeaderModal.component";
import { UserModel } from "model/User.model";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableUserModelNull } from "observables/UserModelNullObservable";
import { ChangeStatusButton } from "pages/UsersDetailsPage/UsersDetailsContent/ChangeStatusButton/ChangeStatusButton.component";
import { ResetPasswordButton } from "pages/UsersDetailsPage/UsersDetailsContent/ResetPasswordButton/ResetPasswordButton.component";
import { SessionDropButton } from "pages/UsersDetailsPage/UsersDetailsContent/SessionDropButton/SessionDropButton.component";
import { UsersDetailsContent } from "pages/UsersDetailsPage/UsersDetailsContent/UsersDetailsContent.component";
import { useTranslation } from "react-i18next";
import { modalChangeUserInfoService } from "service/shared/singletones/modalChangeUserInfoService/modalChangeUserInfo.service";
import { usersDetailsPageService } from "service/shared/singletones/usersDetailsPageService/usersDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";
import { SModalContainer, SModalPopUp } from "utils/styled/SModalQi";

import { ButtonsModal } from "../ButtonsModal/ButtonsModal";

export const ChangeUserInfoModal = createComponent("ChangeUserInfoModal", () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const open = useDefaultObservableBoolean(modalChangeUserInfoService.state.open);
    const user: UserModel = useDefaultObservableUserModelNull(usersDetailsPageService.state.user)!;

    if (!open) {
        return null;
    }

    return (
        <SModalPopUp closeCallBack={modalChangeUserInfoService.controller.closeModal}>
            <SModalContainer isArabic={isArabic}>
                <Header title={`${t("usersDetailsPage.userAccountInfo")} (${user.email})`} onClose={modalChangeUserInfoService.controller.closeModal} />
                <UsersDetailsContent />
                <ButtonsModal>
                    <ChangeStatusButton />
                    <ResetPasswordButton />
                    <SessionDropButton />
                </ButtonsModal>
            </SModalContainer>
        </SModalPopUp>
    );
});