import { styled } from "theme/default/styled";
import { setColor_e74c3c } from "theme/setter/setColorsValues/setColor_e74c3c";

export const SCreateUserFetchedSelectMessage = styled.div`
  ${setColor_e74c3c};
`;

export const SCreateUserFetchedSelectMessageQi = styled.div`
  ${setColor_e74c3c};
  font-size: 14px;
`;
