/* eslint-disable prettier/prettier */
/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const Cross = createIcon("Cross", (props) => {
    const { width = 14, height = 8, color = "#69809A" } = props;

    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2072_1298)">
                <path d="M9 3L3 9" stroke="#FE5B65" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3 3L9 9" stroke="#FE5B65" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2072_1298">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
});
