import React from "react";
import { css, setFlexDFFDCF1, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  padding-left: 80px;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  padding-right: 80px;
`);

const isArabicLangQi = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  padding-left: 34px;
  margin-right: -272px;
`);

const isNotArabicLangQi = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  padding-right: 0px;
  margin-left: -272px;
`);

export const SPaymentsPage = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 100%;
  ${isNotArabicLang};
  ${isArabicLang};
  ${setFlexDFFDCF1};
`;

export const SPaymentsPageQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 100%;
  ${isNotArabicLangQi};
  ${isArabicLangQi};
  ${setFlexDFFDCF1};
  overflow: auto;
  margin-top: 100px;
`;
