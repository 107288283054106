/* eslint-disable prettier/prettier */
/* eslint-disable max-len */

import { colorsValues } from "theme/values/colorsValues";
import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const DropSession = createIcon("DropSession", (props) => {
    const { width = 20, height = 20, color = props.isFinOn ? colorsValuesFin.main.icon_fill : colorsValues.main.icon_fill } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2072_10200)">
                <path d="M2 2L14 14" stroke="#E7535C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.66797 4.66699H4.66797M7.33464 4.66699H13.3346" stroke="#E7535C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.66797 7.33301V11.333" stroke="#E7535C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.33203 9.33301V11.333" stroke="#E7535C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path
                    d="M3.33203 4.66699L3.9987 12.667C3.9987 13.0206 4.13917 13.3598 4.38922 13.6098C4.63927 13.8598 4.97841 14.0003 5.33203 14.0003H10.6654C11.019 14.0003 11.3581 13.8598 11.6082 13.6098C11.8582 13.3598 11.9987 13.0206 11.9987 12.667L12.05 12.0517"
                    stroke="#E7535C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M12.2578 9.58233L12.6685 4.66699" stroke="#E7535C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path
                    d="M6 3.33333V2.66667C6 2.48986 6.07024 2.32029 6.19526 2.19526C6.32029 2.07024 6.48986 2 6.66667 2H9.33333C9.51014 2 9.67971 2.07024 9.80474 2.19526C9.92976 2.32029 10 2.48986 10 2.66667V4.66667"
                    stroke="#E7535C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2072_10200">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
});
