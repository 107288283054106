import React from "react";
import { css, setColor_69809a, setFontSize_14px, setFontWeight700, setProps, styled } from "theme";
import { setFontWeight500 } from "theme/setter/setFontWeightValues/setFontWeight500";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  margin-right: 10px;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  margin-left: 10px;
`);

export const STableNavigationTotalPagesValue = styled.div<SPropsIsArabic & React.HTMLAttributes<HTMLDivElement>>`
  ${isNotArabicLang};
  ${isArabicLang};
  ${setFontSize_14px};
  ${setFontWeight700};
  ${setColor_69809a};
`;

export const STableNavigationTotalPagesValueQi = styled.div<SPropsIsArabic & React.HTMLAttributes<HTMLDivElement>>`
  ${isNotArabicLang};
  ${isArabicLang};
  ${setFontSize_14px};
  ${setFontWeight500};
  color: #707a8f;
  margin-left: 0;
`;

export const STitleQi = styled.div<SPropsIsArabic & React.HTMLAttributes<HTMLDivElement>>`
  ${isNotArabicLang};
  ${isArabicLang};
  ${setFontSize_14px};
  ${setFontWeight500};
  color: #707a8f;
  margin-left: 0;
`;
