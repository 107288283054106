import React from "react";
import { css, setFlexDFFDC, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  align-items: flex-end;
`);

export const SSupportPageQuestionList = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-bottom: 24px;
  gap: 8px;
  ${setFlexDFFDC};
  ${isArabicLang};
`;
