import React from "react";
import { setFlexDFAIC, setRowReverse, setTextRightArabic, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

export const SUsersChangeRowGroupCommon = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-bottom: 10px;
  ${setFlexDFAIC};
  ${setTextRightArabic()};
  ${setRowReverse()};
`;
