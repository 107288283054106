import React from "react";
import { css, setColor_00346e, setFlexDF, setFontSize_14px, setFontWeight400, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
  text-align: right;
`);

const isNotArabicLang = setProps<Props>(({ isArabic }) => !isArabic)(css`
  text-align: left;
`);

export const SGroupTitle = styled.button<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  gap: 8px;
  ${setFlexDF};
  ${setFontSize_14px};
  ${setFontWeight400};
  ${setColor_00346e};
  ${isNotArabicLang};
  ${isArabicLang};
`;

const isArabicLangQi = setProps<Props>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

const isNotArabicLangQi = setProps<Props>(({ isArabic }) => !isArabic)(css`
  text-align: left;
`);

export const SGroupTitleQi = styled.button<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  gap: 12px;
  ${setFontSize_14px};
  ${setFontWeight400};
  ${setColor_00346e};
  ${isNotArabicLangQi};
  ${isArabicLangQi};
  ${setFlexDF};
  align-items: center;
  height: 78px;
`;
