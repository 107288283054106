import { styled } from "theme/default/styled";
import { setFlexF1 } from "theme/setter/setFlexValues/setFlexF1";
import { isQi } from "utils/business/environment";

export const SGroupTableBodyContainer = styled.div`
  width: 100%;
  margin-top: ${isQi ? "" : "16px"};
  margin-bottom: ${isQi ? "" : "16px"};
  ${setFlexF1};
`;
