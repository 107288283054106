/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const UserManagement = createIcon("UserManagement", (props) => {
    const { width = 11, height = 11, color = props.isFinOn ? colorsValuesFin.color_0083f5 : "#8BC3F5" } = props;

    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2072_3265)">
                <path
                    d="M9.16602 11.9163C9.16602 12.4026 9.35917 12.8689 9.70299 13.2127C10.0468 13.5565 10.5131 13.7497 10.9993 13.7497C11.4856 13.7497 11.9519 13.5565 12.2957 13.2127C12.6395 12.8689 12.8327 12.4026 12.8327 11.9163C12.8327 11.4301 12.6395 10.9638 12.2957 10.62C11.9519 10.2762 11.4856 10.083 10.9993 10.083C10.5131 10.083 10.0468 10.2762 9.70299 10.62C9.35917 10.9638 9.16602 11.4301 9.16602 11.9163Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.33398 19.25V18.3333C7.33398 17.8471 7.52714 17.3808 7.87096 17.037C8.21477 16.6932 8.68109 16.5 9.16732 16.5H12.834C13.3202 16.5 13.7865 16.6932 14.1303 17.037C14.4742 17.3808 14.6673 17.8471 14.6673 18.3333V19.25"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.75 4.58333C13.75 5.06956 13.9432 5.53588 14.287 5.8797C14.6308 6.22351 15.0971 6.41667 15.5833 6.41667C16.0696 6.41667 16.5359 6.22351 16.8797 5.8797C17.2235 5.53588 17.4167 5.06956 17.4167 4.58333C17.4167 4.0971 17.2235 3.63079 16.8797 3.28697C16.5359 2.94315 16.0696 2.75 15.5833 2.75C15.0971 2.75 14.6308 2.94315 14.287 3.28697C13.9432 3.63079 13.75 4.0971 13.75 4.58333Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.584 9.16699H17.4173C17.9035 9.16699 18.3699 9.36015 18.7137 9.70396C19.0575 10.0478 19.2507 10.5141 19.2507 11.0003V11.917"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.58398 4.58333C4.58398 5.06956 4.77714 5.53588 5.12096 5.8797C5.46477 6.22351 5.93109 6.41667 6.41732 6.41667C6.90355 6.41667 7.36986 6.22351 7.71368 5.8797C8.0575 5.53588 8.25065 5.06956 8.25065 4.58333C8.25065 4.0971 8.0575 3.63079 7.71368 3.28697C7.36986 2.94315 6.90355 2.75 6.41732 2.75C5.93109 2.75 5.46477 2.94315 5.12096 3.28697C4.77714 3.63079 4.58398 4.0971 4.58398 4.58333Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.75 11.917V11.0003C2.75 10.5141 2.94315 10.0478 3.28697 9.70396C3.63079 9.36015 4.0971 9.16699 4.58333 9.16699H6.41667"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2072_3265">
                    <rect width="22" height="22" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
});
