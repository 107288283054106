import { Link } from "components/Link/Link.component";
import React from "react";
import { styled } from "theme/default/styled";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";

export const SDetailsCell = styled(Link)`
  padding-left: 8px;

  ${setFlexDFAIC};
`;

export const SDetailsCellQi = styled.button<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding-left: 8px;
  ${setFlexDFAIC};
`;
