/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ChangePasswordSectionQi } from "components/AccountDetailsModal/ChangePasswordSectionQi/ChangePasswordSectionQi";
import { ButtonsModal } from "components/ButtonsModal/ButtonsModal";
import { Header } from "components/HeaderModal/HeaderModal.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usersDetailsPageService } from "service/shared/singletones/usersDetailsPageService/usersDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { ResetPasswordApplyButtonQi } from "./ResetPasswordApplyButton/ResetPasswordApplyButton.component";
import { ResetPasswordBackButtonQi } from "./ResetPasswordBackButton/ResetPasswordBackButton.component";
import { SUserResetPasswordForm } from "./UserResetPasswordFormStyled/SUserResetPasswordForm.styled";
import { SUserResetPasswordFormPopUp } from "./UserResetPasswordFormStyled/SUserResetPasswordFormPopUp.styled";

export const UserResetPasswordForm = createComponent("UserResetPasswordForm", () => {
  const { t } = useTranslation();
  const open = useDefaultObservableBoolean(usersDetailsPageService.state.open);

  useEffect(() => {
    return () => {
      usersDetailsPageService.controller.closeCallBack();
    };
  }, []);

  if (!open) {
    return null;
  }

  return (
    <SUserResetPasswordFormPopUp closeCallBack={usersDetailsPageService.controller.closeCallBack}>
      <SUserResetPasswordForm>
        <Header title={`${t("usersDetailsPage.resetPasswordButtonTitle")}`} onClose={usersDetailsPageService.controller.closeCallBack} />
        <ChangePasswordSectionQi />
        <ButtonsModal>
          <ResetPasswordBackButtonQi />
          <ResetPasswordApplyButtonQi />
        </ButtonsModal>
      </SUserResetPasswordForm>
    </SUserResetPasswordFormPopUp>
  );
});
