import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useTranslation } from "react-i18next";
import { createUserServiceFormService } from "service/shared/singletones/createUserServiceFormService/createUserServiceForm.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { SButtonQi } from "utils/styled/SButtonQi";

import { SCreateUserInviteButton } from "./CreateUserInviteButtonStyled/SCreateUserInviteButton.styled";

export const CreateUserInviteButton = createComponent("CreateUserInviteButton", () => {
  const { t } = useTranslation();
  return isQi ? (
    <SButtonQi onClick={createUserServiceFormService.controller.onClickCreateUserInviteButton}>
      {t("usersInfoPage.createUserInviteButtonTitle")} <SvgImage name="SendInvitation" />
    </SButtonQi>
  ) : (
    <SCreateUserInviteButton onClick={createUserServiceFormService.controller.onClickCreateUserInviteButton}>
      {t("usersInfoPage.createUserInviteButtonTitle")}
    </SCreateUserInviteButton>
  );
});
