import React from "react";
import { css } from "styled-components";
import { setProps } from "theme";
import { styled } from "theme/default/styled";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";
import { SPropsIsArabic } from "types/business/SPropsType";

export const STerminalsListContainer = styled.div`
  margin-left: 16px;
  padding: 16px 0;

  ${setFlexDFFDC};
`;

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  text-align: right;
  flex-direction: row-reverse;
  margin-right: -14px;
`);
const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  text-align: left;
`);

export const STerminalsListContainerQi = styled.div<SPropsIsArabic & React.HTMLAttributes<HTMLDivElement>>`
  padding: 16px 0 0;
  display: flex;
  gap: 16px;
  ${isArabicLang};
  ${isNotArabicLang};
`;
