import { SHeaderLogoQi, StyledParagraph } from "components/MainLayout/Header/HeaderStyled/SHeaderLogo.styled";
import { Menu } from "components/MainLayout/Header/Menu/Menu.component";
import { SvgImage } from "components/SvgImage/SvgImage.component";
import { MenuIconModel } from "model/MenuIcon.model";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableMenuIconModelArray } from "observables/MenuIconModelArrayObservable";
import { useTranslation } from "react-i18next";
import { navigationMenuService } from "service/shared/singletones/navigationMenuService/navigationMenu.service";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { MenuIcon } from "../MenuIcon/MenuIcon.component";
import { Toggler } from "../Toggler/Toggler.component";
import { SNavigationMenu, SNavigationMenuQi } from "./NavigationMenuContentStyled/SNavigationMenu.styled";
import { SNavigationMenuContainer, SNavigationMenuContainerQi } from "./NavigationMenuContentStyled/SNavigationMenuContainer.styled";
import { SNavigationMenuList, SNavigationMenuListQi } from "./NavigationMenuContentStyled/SNavigationMenuList.styled";

export const NavigationMenuContent = createComponent("NavigationMenuContent", () => {
  const { i18n } = useTranslation();
  const open = useDefaultObservableBoolean(navigationMenuService.state.open);
  const menuIconList = useDefaultObservableMenuIconModelArray(navigationMenuService.state.menuIconList);

  const renderMenuIcon: RenderFunctionType<MenuIconModel> = (menuIcon) => {
    return <MenuIcon menuIcon={menuIcon} key={menuIcon.id} />;
  };

  const isArabic = i18n.language === "ar";

  return isQi ? (
    <SNavigationMenuQi open={open} isArabic={isArabic}>
      <div>
        <SHeaderLogoQi isFinOnDelivery={isQi} isArabic={i18n.language === "ar"}>
          {open ? (
            <>
              <SvgImage name="LogoQi" /> <StyledParagraph open={open}>Payment Gateway</StyledParagraph>
              <Toggler />
            </>
          ) : (
            <>
              <SvgImage name="LogoQi" />
              <Toggler />
            </>
          )}
        </SHeaderLogoQi>
        <SNavigationMenuContainerQi open={open} isArabic={isArabic}>
          <SNavigationMenuListQi isArabic={isArabic}>{menuIconList.map(renderMenuIcon)}</SNavigationMenuListQi>
        </SNavigationMenuContainerQi>
      </div>
      <Menu />
    </SNavigationMenuQi>
  ) : (
    <SNavigationMenu open={open} isArabic={isArabic}>
      <SNavigationMenuContainer open={open} isArabic={isArabic}>
        <Toggler />
        <SNavigationMenuList isArabic={isArabic}>{menuIconList.map(renderMenuIcon)}</SNavigationMenuList>
      </SNavigationMenuContainer>
    </SNavigationMenu>
  );
});
