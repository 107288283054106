/* eslint-disable prettier/prettier */
import React from "react";
import { css, setFlexDF, setFlexDFFDC, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  align-items: flex-end;
`);

export const SDetailsPageContentQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFlexDFFDC};
  ${isArabicLang};
  margin-top: 24px;
`;

export const SDetailsPageContentRowQi = styled.div`
  gap: 20px;
  ${setFlexDF};
  width: 100%;
   @media (max-width: 768px) {
    ${setFlexDFFDC};
  }
`;

export const SDetailsPageContentColumnQi = styled.div`
  ${setFlexDFFDC};
  width: 100%;
`;

export const STitleDetailsQi = styled.div`
  color: #14181f;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  border-bottom: 1px solid #e8e8e9;
  padding: 16px 0px;
  margin-bottom: 16px;
`;
