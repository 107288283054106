import React from "react";
import { css } from "styled-components";
import { setProps } from "theme";
import { styled } from "theme/default/styled";
import { setBackgroundColor_ffffff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_ffffff";
import { setBorderRadius_8px } from "theme/setter/setBorderRadiusValues/setBorderRadius_8px";
import { setFlexDF } from "theme/setter/setFlexValues/setFlexDF";

export const STable = styled.div`
  position: relative;
  overflow-x: scroll;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;

  ${setFlexDF};
  ${setBorderRadius_8px};
  ${setBackgroundColor_ffffff};
`;

type SProps = {
  isArabic: boolean;
  isNotStatistic?: boolean;
  open?: boolean;
};

const isArabicLang = setProps<SProps>(({ isArabic }) => isArabic)(css`
  margin-left: 10px;
  margin-right: 280px;
`);

const isNotArabicLang = setProps<SProps>(({ isArabic }) => !isArabic)(css`
  margin-left: 270px;
  margin-right: 10px;
`);

const isStatistics = setProps<SProps>(({ isNotStatistic }) => isNotStatistic)(css`
  border-top: 1px solid #939598;
  border-left: 1px solid #939598;
  border-right: 1px solid #939598;
  border-bottom: 1px solid #f1f2f4;
  border-radius: 12px 12px 0 0;
`);
const isNotStatistics = setProps<SProps>(({ isNotStatistic }) => !isNotStatistic)(css`
  border: 1px solid #939598;
  border-radius: 12px;
`);

export const STableQi = styled.div<SProps & React.HTMLAttributes<HTMLDivElement>>`
  position: relative;
  overflow-x: scroll;
  width: 100%;
  ${setFlexDF};
  ${setBackgroundColor_ffffff};
  ${isArabicLang};
  ${isNotArabicLang};
  ${isStatistics};
  ${isNotStatistics};

  min-height: 100px;
`;
