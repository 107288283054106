/* eslint-disable no-lone-blocks */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { RolesDisplayQi } from "components/RolesDisplayQi/RolesDisplayQi";
import { SRowTitleQi } from "components/Row/RowStyled/SRowTitle.styled";
import { UserModel } from "model/User.model";
import { useDefaultObservableUserModelNull } from "observables/UserModelNullObservable";
import { useTranslation } from "react-i18next";
import { userInfoPageService } from "service/shared/singletones/userInfoPageService/userInfoPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SAccountDetailsContainer } from "./AccountDetailsStyled/AccountDetails.styled";
import { AccountRow } from "./AccountRow/AccountRow.component";

export const AccountDetails = createComponent("AccountDetails", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const user: UserModel = useDefaultObservableUserModelNull(userInfoPageService.state.user)!;

  return (
    <SAccountDetailsContainer isArabic={isArabic}>
      <AccountRow title={t("userInfoPageContent.id")} value={user.id} />
      <AccountRow title={t("userInfoPageContent.blocked")} value={user.blockedFlag} />

      <SRowTitleQi>{t("userInfoPageContent.roles")}</SRowTitleQi>
      <RolesDisplayQi roles={user.roles} />
      <AccountRow title={t("usersDetailsPage.merchantGroupName")} value={user.merchantGroupName} />
      <AccountRow title={t("userInfoPageContent.name")} value={user.name} />
      <AccountRow title={t("userInfoPageContent.email")} value={user.email} />
      <AccountRow title={t("userInfoPageContent.phone")} value={user.phoneNumber} />
    </SAccountDetailsContainer>
  );
});
