/* eslint-disable prettier/prettier */
/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const Edit = createIcon("Edit", (props) => {
    const { width = 24, height = 24, color = "#00346E" } = props;

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2072_6261)">
                <path
                    d="M5.83398 5.83301H5.00065C4.55862 5.83301 4.1347 6.0086 3.82214 6.32116C3.50958 6.63372 3.33398 7.05765 3.33398 7.49967V14.9997C3.33398 15.4417 3.50958 15.8656 3.82214 16.1782C4.1347 16.4907 4.55862 16.6663 5.00065 16.6663H12.5007C12.9427 16.6663 13.3666 16.4907 13.6792 16.1782C13.9917 15.8656 14.1673 15.4417 14.1673 14.9997V14.1663"
                    stroke="#626469"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.9875 5.48759C17.3157 5.15938 17.5001 4.71424 17.5001 4.25009C17.5001 3.78594 17.3157 3.34079 16.9875 3.01259C16.6593 2.68438 16.2142 2.5 15.75 2.5C15.2858 2.5 14.8407 2.68438 14.5125 3.01259L7.5 10.0001V12.5001H10L16.9875 5.48759Z"
                    stroke="#626469"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M13.334 4.16699L15.834 6.66699" stroke="#626469" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2072_6261">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
});
