import { SApplyButtonQi } from "components/Filters/FilterMenu/ApplyButton/ApplyButtonStyled/SApplyButton.styled";
import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useTranslation } from "react-i18next";
import { terminalsPageService } from "service/shared/singletones/terminalsPageService/terminalsPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { STerminalsApplyButton } from "./TerminalsApplyButtonStyled/STerminalsApplyButton.styled";

export const TerminalsApplyButton = createComponent("TerminalsApplyButton", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return isQi ? (
    <SApplyButtonQi isArabic={isArabic} onClick={terminalsPageService.controller.onClickApplyButton}>
      {t("filters.applyTitle")}
      <SvgImage name="Apply" />
    </SApplyButtonQi>
  ) : (
    <STerminalsApplyButton type="submit" onClick={terminalsPageService.controller.onClickApplyButton}>
      {t("terminalsPage.terminalsApplyButtonTitle")}
    </STerminalsApplyButton>
  );
});
