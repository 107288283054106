import React from "react";
import { borderRadius_5px, css, setFlexDFAICJCSB, setProps, styled } from "theme";
import { isQi } from "utils/business/environment";

type Props = {
  isFirst: boolean;
  isLast: boolean;
  isArabic: boolean;
  isCallBack: boolean;
};

const isFirstSet = setProps<Props>(({ isFirst }) => isFirst)(css`
  border-top-right-radius: ${!isQi && borderRadius_5px};
  border-bottom-right-radius: ${!isQi && borderRadius_5px};
`);

const isLastSet = setProps<Props>(({ isLast }) => isLast)(css`
  border-top-left-radius: ${!isQi && borderRadius_5px};
  border-bottom-left-radius: ${!isQi && borderRadius_5px};
`);

const isArabicAndCallbackLang = setProps<Props>(({ isArabic, isCallBack }) => isArabic && isCallBack)(css`
  flex-direction: row-reverse;
`);

const isArabicLang = setProps<Props>(({ isArabic, isCallBack }) => isArabic && !isCallBack)(css`
  justify-content: flex-end;
`);

export const SHeaderCellContainer = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  height: 30px;
  padding-right: 24px;
  padding-left: 14px;
  text-align: left;

  ${setFlexDFAICJCSB};
  ${isFirstSet};
  ${isLastSet};
  ${isArabicLang};
  ${isArabicAndCallbackLang};

  color: ${isQi ? "#707a8f" : "#25476d"};
  font-size: ${isQi ? "14px" : "13px"};
  font-weight: ${isQi ? 700 : 600};
`;
