import React from "react";
import { css, setFlexDFFDC, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  margin-right: 10px;
`);
const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  margin-left: 10px;
`);

export const SNavigationMenuList = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  gap: 12px;

  ${setFlexDFFDC};
  ${isNotArabicLang};
  ${isArabicLang};
`;

export const SNavigationMenuListQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  gap: 12px;

  ${setFlexDFFDC};
  ${isArabicLang};
`;
