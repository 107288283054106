/* eslint-disable prettier/prettier */
import { Row } from "components/Row/Row.component";
import { ReactNode } from "react";
import { createComponent } from "utils/libExtend/createComponent";

export type RowProps = {
    title: string;
    value: ReactNode;
};

export const AccountRow = createComponent<RowProps>("AccountRow", (props) => {
    const { title, value } = props;

    return <Row title={title} value={value} type="intermediate" />;
});
