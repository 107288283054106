import { SPropsIsArabic } from "types/business/SPropsType";

import { css } from "./css";
import { setProps, Template } from "./setProps";

export const setTextRightArabic = (): ((props: SPropsIsArabic) => string | Template) => {
  return setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
    text-align: right;
  `);
};
