import React from "react";
import { css, setFlexDF, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  justify-content: flex-end;
`);

export const SPaymentCancelTableButtons = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-top: 32px;
  margin-bottom: 14px;
  margin-left: 12px;
  ${setFlexDF};
  ${isArabicLang};
`;
