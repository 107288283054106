import React from "react";
import { setBorderRadius_8px } from "theme";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { color_47a0ef } from "theme/setter/colorsValues/color_47a0ef";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { color_e0b4b4 } from "theme/setter/colorsValues/color_e0b4b4";
import { setBackgroundColor_f0fbff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_f0fbff";
import { setBackgroundColor_fff6f6 } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_fff6f6";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";
import { setColor_9f3a38 } from "theme/setter/setColorsValues/setColor_9f3a38";

type SCreateFormInputInputProps = {
  isError: boolean;
};

const isErrorSet = setProps<SCreateFormInputInputProps>(({ isError }) => isError)(css`
  border: 1px solid ${color_e0b4b4};

  ${setColor_9f3a38};
  ${setBackgroundColor_fff6f6};
`);

export const SCreateFormInputInput = styled.input<SCreateFormInputInputProps & React.InputHTMLAttributes<HTMLInputElement>>`
  height: 26px;
  padding: 5px;

  border: 1px solid ${color_a0b9d2};

  ${setBorderRadius_4px};
  ${isErrorSet};

  &:focus {
    border: 1px solid ${color_47a0ef};

    ${setBackgroundColor_f0fbff};
  }
`;

export const SCreateFormInputInputQi = styled.input<SCreateFormInputInputProps & React.InputHTMLAttributes<HTMLInputElement>>`
  height: 34px;
  padding: 5px;
  border: 1px solid #e8e8e9;
  ${setBorderRadius_8px};
  ${isErrorSet};

  &:focus {
    border: 1px solid #ffd400;
  }
`;
