/* eslint-disable prettier/prettier */
import React from "react";
import {
  css,
  resetScrollbar,
  setBackgroundColor_ffffff,
  setFlexDFFDC,
  setFontSize_18px,
  setFontSize_20px,
  setFontWeight400,
  setProps,
  setTextRightArabic,
  styled,
} from "theme";
import { setFontWeight500 } from "theme/setter/setFontWeightValues/setFontWeight500";

type SProps = {
  isArabic: boolean;
};

const isArabicLang = setProps<SProps>(({ isArabic }) => isArabic)(css`
  justify-content: flex-start;
  flex-direction: row-reverse;
`);

export const SHeaderContainer = styled.div<
  SProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>`
  display: flex;
  ${setFontSize_18px};
  ${setFontWeight400};
  ${isArabicLang};
`;

export const SSectionTitle = styled.div<
  SProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>`
  color: #14181f;
  ${setFontSize_20px};
  ${setFontWeight500};
  ${setTextRightArabic()};
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const SDetailsContainer = styled.div<
  SProps & React.HTMLAttributes<HTMLDivElement>
>`
  display: flex;
  alignitems: center;
  margin-left: 8px;
  margin-right: 8px;
  ${isArabicLang};
`;

export const SCopyButton = styled.button<
  SProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>`
  margin-left: 8px;
  margin-right: 8px;
`;

const isArabicLangQi = setProps<SProps>(({ isArabic }) => isArabic)(css`
  margin-right: 34px;
  margin-left: 34px;
`);

const isNotArabicLangQi = setProps<SProps>(({ isArabic }) => !isArabic)(css`
  margin-left: 34px;
  margin-right: 34px;
`);

export const SDetailsLayoutContainerQi = styled.div<
  SProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>`
  overflow-y: scroll;
  padding: 24px;
  ${resetScrollbar};
  ${setBackgroundColor_ffffff};
  ${isArabicLangQi};
  ${isNotArabicLangQi};
  width: 55%;
  margin-bottom: 34px;
  border-radius: 12px;
  margin-top: 100px;
  @media (max-width: 768px) {
    width: 70%;
  }
  position: relative;
`;

export const SDetailsBox = styled.div`
  height: fit-content;
  ${setFlexDFFDC};
`;
