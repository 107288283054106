import React from "react";
import { setBorderRadius_8px, setFontSize_14px } from "theme";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { setColor_0083f5 } from "theme/setter/setColorsValues/setColor_0083f5";
import { setFontSize_15px } from "theme/setter/setFontSizeValues/setFontSize_15px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";

type SFeedbackFormSendButtonProps = {
  isArabic?: boolean;
  sendButtonValid?: boolean;
};

const sendButtonValidSet = setProps<SFeedbackFormSendButtonProps>(({ sendButtonValid }) => !sendButtonValid)(css`
  cursor: default;
  opacity: 0.45;
`);

export const SFeedbackFormSendButton = styled.button<SFeedbackFormSendButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-top: 24px;

  ${setFontSize_15px};
  ${setFontWeight600};
  ${setColor_0083f5};
  ${sendButtonValidSet};
`;

const isArabicLang = setProps<SFeedbackFormSendButtonProps>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);

export const SSendButtonQi = styled.button<SFeedbackFormSendButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: auto;
  padding: 10px 14px;
  color: black;
  background-color: #ffd400;
  width: 148px;
  height: 40px;
  ${setFontSize_14px};
  ${setFontWeight600};
  ${setBorderRadius_8px};
  ${isArabicLang};
  ${sendButtonValidSet};
`;
