import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useFiltersContext } from "context/Filter.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { SFilterButton, SFilterButtonQi } from "./FilterButtonStyled/SFilterButton.styled";
import { SFilterButtonButton, SFilterButtonButtonQi } from "./FilterButtonStyled/SFilterButtonButton.styled";
import { SFilterButtonClear, SFilterButtonClearQi } from "./FilterButtonStyled/SFilterButtonClear.styled";
import { SFilterButtonHider } from "./FilterButtonStyled/SFilterButtonHider.styled";
import { SFilterButtonTitle, SFilterButtonTitleQi } from "./FilterButtonStyled/SFilterButtonTitle.styled";

export const FilterButton = createComponent("FilterButton", () => {
  const { t } = useTranslation();
  const { service } = useFiltersContext();

  const open = useDefaultObservableBoolean(service.state.open);
  const isArabic = useDefaultObservableBoolean(service.state.isArabic);

  return isQi ? (
    <SFilterButtonQi open={open} isArabic={isArabic}>
      {open ? (
        <SFilterButtonClearQi isArabic={isArabic} open={open} disabled={!open} onClick={service.controller.onClickCross}>
          {/* {service.state.showCloseIcon && ( */}
          <>
            <SvgImage name="Clear" color="#ffd400" />
            <SFilterButtonTitleQi isArabic={isArabic}>{t("filters.filterButton")}</SFilterButtonTitleQi>
          </>
          {/* )} */}
        </SFilterButtonClearQi>
      ) : (
        <SFilterButtonButtonQi onClick={service.controller.onClickButton} isArabic={isArabic}>
          <SFilterButtonTitle isArabic={isArabic}>{t("filters.showFiltersButton")}</SFilterButtonTitle>
          <SvgImage name="FilterSearch" />
        </SFilterButtonButtonQi>
      )}
    </SFilterButtonQi>
  ) : (
    <SFilterButton open={open} isArabic={isArabic}>
      <SFilterButtonButton onClick={service.controller.onClickButton} isArabic={isArabic}>
        <SvgImage name="Search" />
        <SFilterButtonTitle isArabic={isArabic}>{t("filters.filterButton")}</SFilterButtonTitle>
      </SFilterButtonButton>
      <SFilterButtonClear isArabic={isArabic} open={open} disabled={!open} onClick={service.controller.onClickCross}>
        {service.state.showCloseIcon && <SvgImage name="Clear" />}
      </SFilterButtonClear>
      {open ? <SFilterButtonHider /> : null}
    </SFilterButton>
  );
});
