import { modalChangeUserInfoController } from "./modalChangeUserInfo.controller";
import { modalChangeUserInfoState } from "./modalChangeUserInfo.state";

class Service {
  public readonly state = modalChangeUserInfoState;

  public readonly controller = modalChangeUserInfoController;
}

export const modalChangeUserInfoService = new Service();
