import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useTranslation } from "react-i18next";
import { usersDetailsPageService } from "service/shared/singletones/usersDetailsPageService/usersDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";
import { SCanselButtonQi } from "utils/styled/SCanselButtonQi";

export const ResetPasswordBackButtonQi = createComponent("ResetPasswordBackButtonQi", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return (
    <SCanselButtonQi isArabic={isArabic} onClick={usersDetailsPageService.controller.onClickResetBackButton}>
      {t("usersDetailsPage.resetPasswordBackButton")} <SvgImage name="Close" color="#707a8f" />
    </SCanselButtonQi>
  );
});
