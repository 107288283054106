import { AccountDetailsModal } from "components/AccountDetailsModal/AccountDetailsModal";
import { LogoutModal } from "components/LogoutModal/LogoutModal";
import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableString } from "observables/StringObservable";
import { useTranslation } from "react-i18next";
import { menuService } from "service/shared/singletones/menuService/menu.service";
import { navigationMenuService } from "service/shared/singletones/navigationMenuService/navigationMenu.service";
import { userInfoPageService } from "service/shared/singletones/userInfoPageService/userInfoPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";
import { SDivider } from "utils/styled/SDivider";

import { User } from "../User/User.component";
import { List } from "./List/List.component";
import { SHeaderMenu, SHeaderMenuQi } from "./MenuStyled/SHeaderMenu.styled";
import { AccountSettingsTitle, SHeaderMenuContainer, SHeaderMenuContainerQi } from "./MenuStyled/SHeaderMenuContainer.styled";
import { SHeaderMenuTitle } from "./MenuStyled/SHeaderMenuTitle.styled";

export const Menu = createComponent("Menu", () => {
  const { t, i18n } = useTranslation();
  useEffect(async () => {
    await menuService.controller.onMount();
    await userInfoPageService.controller.mount();
  }, []);

  const email = useDefaultObservableString(menuService.state.email);
  const open = useDefaultObservableBoolean(navigationMenuService.state.open);

  return isQi ? (
    <SHeaderMenuQi>
      <SHeaderMenuContainerQi
        onClick={menuService.controller.toggleList}
        isArabic={i18n.language === "ar"}
        open={open}
        style={{ width: open ? "162px" : "40px" }}
      >
        <SvgImage name="AccountSettings" />
        {open && <AccountSettingsTitle>{t("mainLayout.accountSettingsTitle")}</AccountSettingsTitle>}
      </SHeaderMenuContainerQi>
      <List />
      <SDivider />
      <User />
      <AccountDetailsModal />
      <LogoutModal />
    </SHeaderMenuQi>
  ) : (
    <SHeaderMenu>
      <SHeaderMenuContainer onClick={menuService.controller.toggleList}>
        <SHeaderMenuTitle>{email}</SHeaderMenuTitle>
        <div>
          <SvgImage name="ArrowDown" />
        </div>
      </SHeaderMenuContainer>
      <List />
    </SHeaderMenu>
  );
});
