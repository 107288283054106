/* eslint-disable prettier/prettier */
/* eslint-disable max-len */

import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const PlusIcon = createIcon("PlusIcon", (props) => {
    const { width = 11, height = 11, color = props.isFinOn ? colorsValuesFin.color_0083f5 : "#8BC3F5" } = props;

    return (
        <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2486_737)">
                <path d="M6.42188 11H17.4219" stroke="#43464C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 5L12 16" stroke="#43464C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <rect x="0.5" y="0.5" width="23.5822" height="21" rx="5.5" stroke="#43464C" />
            <defs>
                <clipPath id="clip0_2486_737">
                    <rect width="20.1127" height="18" fill="white" transform="translate(2.23438 2)" />
                </clipPath>
            </defs>
        </svg>
    );
});





// <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <g clipPath="url(#clip0_2486_709)">
//         <path d="M6.42578 11H18.1582" stroke="#43464C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
//     </g>
//     <rect x="0.5" y="0.5" width="23.5822" height="21" rx="5.5" stroke="#43464C" />
//     <defs>
//         <clipPath id="clip0_2486_709">
//             <rect width="20.1127" height="18" fill="white" transform="translate(2.23438 2)" />
//         </clipPath>
//     </defs>
// </svg>