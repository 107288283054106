import React from "react";
import { css } from "styled-components";
import { setProps } from "theme";
import { styled } from "theme/default/styled";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";
import { isQi } from "utils/business/environment";

export const SFilterInput = styled.div`
  width: ${isQi ? "236px" : "240px"};
  margin-top: ${isQi ? "6px" : "4px"};
  ${setFlexDFFDC};
`;
interface SRangeInputStartPickerProps {
  isArabic?: boolean;
}

const isNotArabicLang = setProps<SRangeInputStartPickerProps>(({ isArabic }) => !isArabic)(css`
  margin-right: 16px;
`);

const isArabicLang = setProps<SRangeInputStartPickerProps>(({ isArabic }) => isArabic)(css`
  margin-right: 16px;
`);

export const SFilterInputQi = styled.div`
  width: 240px;
  margin-top: 6px;
  ${setFlexDFFDC};
`;

export const SInputContainerQi = styled.div<SRangeInputStartPickerProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${isNotArabicLang}
  ${isArabicLang}
`;
