import React from "react";
import { setFontSize_14px } from "theme";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { setColor_0083f5 } from "theme/setter/setColorsValues/setColor_0083f5";
import { setColor_ffffff } from "theme/setter/setColorsValues/setColor_ffffff";
import { setFontSize_13px } from "theme/setter/setFontSizeValues/setFontSize_13px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";

type SDownloadButtonProps = {
  disabled: boolean;
  isArabic?: boolean;
};

const isFormValidSet = setProps<SDownloadButtonProps>(({ disabled }) => disabled)(css`
  cursor: default;
  opacity: 0.45;
`);

export const SDownloadButton = styled.button<SDownloadButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  text-decoration: none;

  ${setFontSize_13px};
  ${setFontWeight600};
  ${setColor_0083f5};
  ${isFormValidSet};
`;

const isArabicLang = setProps<SDownloadButtonProps>(({ isArabic }) => isArabic)(css`
  justify-content: flex-start;
  flex-direction: row-reverse;
`);

export const SDownloadButtonQi = styled.button<SDownloadButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFontSize_14px};
  ${setFontWeight600};
  ${setColor_ffffff};
  ${isFormValidSet};
  ${isArabicLang}
  background-color: #ff7500;
  padding: 10px 14px;
  width: 96px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
`;
