/* eslint-disable prettier/prettier */
/* eslint-disable max-len */

import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const RefundIcon = createIcon("RefundIcon", (props) => {
    const { width = 11, height = 11, color = props.isFinOn ? colorsValuesFin.color_0083f5 : "#8BC3F5" } = props;

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2072_8542)">
                <path
                    d="M10 15.8334H5C4.33696 15.8334 3.70107 15.57 3.23223 15.1011C2.76339 14.6323 2.5 13.9964 2.5 13.3334V6.66669C2.5 6.00365 2.76339 5.36776 3.23223 4.89892C3.70107 4.43008 4.33696 4.16669 5 4.16669H15C15.663 4.16669 16.2989 4.43008 16.7678 4.89892C17.2366 5.36776 17.5 6.00365 17.5 6.66669V10.4167"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M2.5 8.33331H17.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.83398 12.5H5.84232" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.16602 12.5H10.8327" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.334 15.8333H18.334" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.834 13.3333L13.334 15.8333L15.834 18.3333" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2072_8542">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
});
