import { modalLogoutState } from "./modalLogout.state";

class Controller {
  public readonly state = modalLogoutState;

  public openModal = (): void => {
    this.state.open.next(true);
  };

  public closeModal = (): void => {
    this.state.open.next(false);
  };
}

export const modalLogoutController = new Controller();
