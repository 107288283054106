import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useTranslation } from "react-i18next";
import { supportPageService } from "service/shared/singletones/supportPageService/supportPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { useDefaultObservable } from "utils/libExtend/useDefaultObservable";

import { SFeedbackFormSendButton, SSendButtonQi } from "./FeedbackFormSendButtonStyled/SFeedbackFormSendButton.styled";

export const FeedbackFormSendButton = createComponent("FeedbackFormSendButton", () => {
  const { t, i18n } = useTranslation();
  const sendButtonValid = useDefaultObservable<boolean>(supportPageService.state.sendButtonValid);
  const isArabic = i18n.language === "ar";

  return isQi ? (
    <SSendButtonQi isArabic={isArabic} sendButtonValid={sendButtonValid} disabled={!sendButtonValid} onClick={supportPageService.controller.onClickSendButton}>
      {t("supportPage.sendRequestButtonTitle")} <SvgImage name="Check" />
    </SSendButtonQi>
  ) : (
    <SFeedbackFormSendButton sendButtonValid={sendButtonValid} disabled={!sendButtonValid} onClick={supportPageService.controller.onClickSendButton}>
      {t("supportPage.feedbackFormSendButtonTitle")}
    </SFeedbackFormSendButton>
  );
});
