import { useDefaultObservableStatisticDataItemArray } from "observables/StatisticDataItemArrayObservable";
import ReactECharts from "packages/echarts-for-react";
import { useTranslation } from "react-i18next";
import { dynamicPageService } from "service/shared/singletones/dynamicPageService/dynamicPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { SComparisonSectionTitleQi } from "utils/styled/SCompareInput";

import { getOptions } from "./getOptions";
import { getStatisticData } from "./getStatisticData/getStatisticData";
import { SGraphSectionContentTitle } from "./GraphSectionContentStyled/SGraphSectionContentTitle.styled";

export const GraphSectionContent = createComponent("GraphSectionContent", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const firstStatistics = useDefaultObservableStatisticDataItemArray(dynamicPageService.state.firstStatistics);
  const secondStatistics = useDefaultObservableStatisticDataItemArray(dynamicPageService.state.secondStatistics);

  const optionsParams = getStatisticData(firstStatistics, secondStatistics);

  const options = getOptions(optionsParams);

  return (
    <SGraphSectionContentTitle>
      {isQi ? (
        <SComparisonSectionTitleQi isArabic={isArabic}>{t("dynamicPage.graphTitle")}</SComparisonSectionTitleQi>
      ) : (
        <span>{t("dynamicPage.graphTitle")}</span>
      )}

      <ReactECharts option={options} />
    </SGraphSectionContentTitle>
  );
});
