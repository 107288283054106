import { styled } from "theme/default/styled";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

export const SFetchedFilterMultipleSelect = styled.div`
  width: 240px;
  ${setFlexDFFDC};
`;

export const SFetchedFilterMultipleSelectQi = styled.div`
  width: 240px;
  ${setFlexDFFDC};
  margin-top: 6px;

  margin-right: 16px;
`;
