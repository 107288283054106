import React from "react";
import { color_a0b9d2, css, setBorderRadius_4px, setBorderRadius_8px, setFlexDFFDC, setProps, setTextRightArabic, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

export const SFeedbackFormTextField = styled.textarea<SPropsIsArabic & React.TextareaHTMLAttributes<HTMLTextAreaElement>>`
  padding: 8px;
  border: 1px solid ${color_a0b9d2};
  ${setBorderRadius_4px};
  ${isArabicLang};
`;
export const SFeedbackFormTextFieldQi = styled.textarea<SPropsIsArabic & React.TextareaHTMLAttributes<HTMLTextAreaElement>>`
  padding: 8px;
  border: 1px solid #e8e8e9;
  margin-top: -10px;
  ${setBorderRadius_8px};
  ${isArabicLang};
  cursor: pointer;
  &:focus {
    border-color: #e8e8e9;
    outline: none;
  }
`;

export const SFeedbackFormTextQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 100%;
  margin-bottom: 16px;
  gap: 8px;
  ${setFlexDFFDC};
  ${setTextRightArabic()};
`;
