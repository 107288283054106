import { userRolesConfig } from "appConstants/userRolesConfig";
import { SvgImage } from "components/SvgImage/SvgImage.component";
import { UserModel } from "model/User.model";
import { useDefaultObservableUserModelNull } from "observables/UserModelNullObservable";
import { useTranslation } from "react-i18next";
import { usersDetailsPageService } from "service/shared/singletones/usersDetailsPageService/usersDetailsPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { SChangeStatusButton, SChangeStatusButtonQi } from "./ChangeStatusButtonStyled/SChangeStatusButton.styled";

export const ChangeStatusButton = createComponent("ChangeStatusButton", () => {
  const { t, i18n } = useTranslation();
  const user: UserModel = useDefaultObservableUserModelNull(usersDetailsPageService.state.user)!;

  const isSystemUser = user.roles.includes(userRolesConfig.SYSTEM);
  const isArabic = i18n.language === "ar";

  if (isSystemUser) {
    return null;
  }

  const showUnBlockButton = user.blockedFlag === "BLOCKED_BY_ADMIN";

  if (showUnBlockButton) {
    return isQi ? (
      <SChangeStatusButtonQi isArabic={isArabic} onClick={usersDetailsPageService.controller.onClickUnBlockUserButton}>
        {t("usersDetailsPage.unBlockUserButtonTitle")}
        <SvgImage name="BlockUser" />
      </SChangeStatusButtonQi>
    ) : (
      <SChangeStatusButton isArabic={isArabic} onClick={usersDetailsPageService.controller.onClickUnBlockUserButton}>
        {t("usersDetailsPage.unBlockUserButtonTitle")}
      </SChangeStatusButton>
    );
  }

  return isQi ? (
    <SChangeStatusButtonQi isArabic={isArabic} onClick={usersDetailsPageService.controller.onClickBlockUserButton}>
      {t("usersDetailsPage.blockUserButtonTitle")}
      <SvgImage name="BlockUser" />
    </SChangeStatusButtonQi>
  ) : (
    <SChangeStatusButton isArabic={isArabic} onClick={usersDetailsPageService.controller.onClickBlockUserButton}>
      {t("usersDetailsPage.blockUserButtonTitle")}
    </SChangeStatusButton>
  );
});
