/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prettier/prettier */
import { Header } from "components/HeaderModal/HeaderModal.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableString } from "observables/StringObservable";
import { AccountDetails } from "pages/UserInfoPage/AccountDetails/AccountDetails.component";
import { useTranslation } from "react-i18next";
import { menuService } from "service/shared/singletones/menuService/menu.service";
import { usersInfoPageService } from "service/shared/singletones/usersInfoPageService/usersInfoPage.service";
import { createComponent } from "utils/libExtend/createComponent";
import { SModalContainer, SModalPopUp } from "utils/styled/SModalQi";

import { ButtonsModal } from "../ButtonsModal/ButtonsModal";
import { ResetPasswordButton } from "./ResetPasswordButton/ResetPasswordButton.component";

export const AccountDetailsModal = createComponent("AccountDetailsModal", () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const open = useDefaultObservableBoolean(usersInfoPageService.state.isModalOpen);
    const email = useDefaultObservableString(menuService.state.email);

    if (!open) {
        return null;
    }

    return (
        <SModalPopUp closeCallBack={usersInfoPageService.controller.closeModal}>
            <SModalContainer isArabic={isArabic}>
                <Header title={`${t("navigationMenuService.userInfo")} (${email})`} onClose={usersInfoPageService.controller.closeModal} />
                <AccountDetails />
                <ButtonsModal>
                    <ResetPasswordButton />
                </ButtonsModal>
            </SModalContainer>
        </SModalPopUp>
    );
});