/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { t } from "i18n";
import Cookies from "packages/js-cookie";
import React from "react";

import { signInFormController } from "../signInFormService/signInForm.controller";
import { UserInfoResponseType } from "../signInFormService/types/UserInfoResponseType";
import { toastContainerService } from "../toastContainerService/toastContainer.service";
import { createMfaState } from "./mfa.state";

class Controller {
  public readonly state;

  public constructor() {
    this.state = createMfaState(this);
  }

  public readonly otp = Array(6).fill("");

  private readonly otpErrors: boolean[] = Array(6).fill(false);

  private readonly getUserInfoResponse =
    async (): Promise<UserInfoResponseType> => {
      const userInfoResponse = await client.get(apiPaths.userInfo);
      return userInfoResponse.data;
    };

  // Метод проверки настройки Мфа
  public readonly checkMfaReady = async (): Promise<void> => {
    try {
      const { data } = await client.get(apiPaths.mfaReady);
      const isMfaEnabled = data?.mfaEnabled ?? false;
      this.state.isActive.next(isMfaEnabled);
    } catch (error) {
      toastContainerService.controller.createErrorToast(
        t("mfa.ready.toastError")
      );
      this.state.isActive.next(false);
    }
  };

  // Метод для подтверждения OTP при входе в приложение
  public readonly validateOtp = async (otpCode: string): Promise<boolean> => {
    try {
      const { data } = await client.post(apiPaths.mfaValidate, { otpCode });

      if (data?.valid) {
        this.state.isMfaCompleted.next(true);
        toastContainerService.controller.createSuccessToast(
          t("mfa.validate.toastSuccess")
        );

        const [userInfo] = await Promise.all([this.getUserInfoResponse()]);

        signInFormController.setUserServiceData(
          userInfo.roles,
          userInfo.email,
          userInfo.id
        );
        signInFormController.redirectByRole(userInfo.roles);

        this.clearIsMfaActive();
        this.state.isError.next(false);
        return true;
      }

      this.state.isError.next(true);
      return false;
    } catch (error) {
      this.state.isError.next(true);
      toastContainerService.controller.createErrorToast(
        t("mfa.validate.toastError")
      );
      return false;
    }
  };

  // Метод для генерации QR-кода при настройке MFA
  public readonly generateQrCode = async (): Promise<void> => {
    try {
      const { data } = await client.post(apiPaths.mfaGenerate);
      this.state.qrCode.next(data?.qrCode ?? null);
    } catch (error) {
      toastContainerService.controller.createErrorToast(
        t("mfa.generate.toastError")
      );
      this.state.setError(t("mfa.generate.toastError"));
    }
  };

  // Метод для подтверждения OTP при настройке МФА
  public readonly verifyOtp = async (otpCode: string): Promise<void> => {
    try {
      const { data } = await client.post(apiPaths.mfaVerify, { otpCode });

      if (data?.valid) {
        this.state.isActive.next(true);
      } else {
        this.state.isError.next(true);
      }
    } catch (error) {
      toastContainerService.controller.createErrorToast(
        t("mfa.verify.toastError")
      );
      this.state.isError.next(true);
    }
  };

  public updateOtp(index: number, value: string): void {
    if (value.length > 1 || !/^\d*$/.test(value)) return;

    this.otp[index] = value;
  }

  public resetOtp(): void {
    this.otp.fill("");
    this.otpErrors.fill(false);
  }

  public getOtp(): string {
    return this.otp.join("");
  }

  public handleInputChange(value: string, index: number): void {
    const sanitizedValue = value.replace(/[^0-9]/g, "");
    this.updateOtp(index, sanitizedValue);

    if (sanitizedValue && index < this.otp.length - 1) {
      this.focusNextInput(index + 1);
    }
  }

  public handleInputBackspace(
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ): void {
    if (event.key === "Backspace" && !this.otp[index] && index > 0) {
      this.focusNextInput(index - 1);
    }
  }

  public handlePaste(event: React.ClipboardEvent<HTMLInputElement>): void {
    event.preventDefault();
    const pasteData = event.clipboardData
      .getData("text")
      .replace(/[^0-9]/g, "");
    for (let i = 0; i < pasteData.length && i < this.otp.length; i++) {
      this.updateOtp(i, pasteData[i]);
    }
  }

  public focusNextInput(index: number): void {
    const input = document.getElementById(`otp-input-${index}`);
    if (input) input.focus();
  }

  public focusInput(isArabic: boolean): void {
    const focusIndex = isArabic ? this.otp.length - 1 : 0;
    const inputToFocus = document.getElementById(`otp-input-${focusIndex}`);
    if (inputToFocus) inputToFocus.focus();
  }

  public resetError(): void {
    this.state.isError.next(false);
  }

  public readonly setIsMfaActive = (value: boolean): string | undefined => {
    const newValue = value ? "TRUE" : "FALSE";
    return Cookies.set("isMfaActive", newValue);
  };

  public readonly getIsMfaActive = (): boolean => {
    const mfaActive = Cookies.get("isMfaActive");

    if (mfaActive) {
      return mfaActive === "TRUE";
    }

    return false;
  };

  public readonly clearIsMfaActive = (): void => {
    this.setIsMfaActive(false);
  };

  public dispose(): void {
    this.otp.fill("");
  }

  public readonly unMount = async (): Promise<void> => {
    this.state.isMfaCompleted.next(false);
  };
}

export const mfaController = new Controller();
