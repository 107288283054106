// import { userRolesConfig } from "appConstants/userRolesConfig";
import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useTranslation } from "react-i18next";
import { usersDetailsPageService } from "service/shared/singletones/usersDetailsPageService/usersDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SResetPasswordButtonQi } from "./ResetPasswordButtonStyled/SResetPasswordButton.styled";
import { UserResetPasswordForm } from "./UserResetPasswordForm/UserResetPasswordForm.component";

export const ResetPasswordButton = createComponent("ResetPasswordButton", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return (
    <>
      <SResetPasswordButtonQi isArabic={isArabic} onClick={usersDetailsPageService.controller.onClickResetPasswordButton}>
        {t("usersDetailsPage.resetPasswordButtonTitle")}
        <SvgImage name="ResetIcon" />
      </SResetPasswordButtonQi>
      <UserResetPasswordForm />
    </>
  );
});
