/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prettier/prettier */
import { ButtonsModal } from "components/ButtonsModal/ButtonsModal";
import { Header } from "components/HeaderModal/HeaderModal.component";
import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { menuService } from "service/shared/singletones/menuService/menu.service";
import { modalLogoutService } from "service/shared/singletones/modalLogoutService/modalLogout.service";
import { createComponent } from "utils/libExtend/createComponent";
import { SCanselButtonQi } from "utils/styled/SCanselButtonQi";
import { SModalPopUp } from "utils/styled/SModalQi";

import {
  SLogoutButtonQi,
  SModalDescription,
  SModalLogoutContainer,
} from "./LogoutModalStyled/LogoutModal.styled";

export const LogoutModal = createComponent("LogoutModal", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const open = useDefaultObservableBoolean(modalLogoutService.state.open);

  useEffect(() => {
    return () => {
      modalLogoutService.controller.closeModal();
    };
  }, []);

  if (!open) {
    return null;
  }

  const handleLogout = async () => {
    try {
      await menuService.controller.logout();
      modalLogoutService.controller.closeModal();
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <SModalPopUp closeCallBack={modalLogoutService.controller.closeModal}>
      <SModalLogoutContainer isArabic={isArabic}>
        <Header
          title={`${t("logout.signout")}`}
          onClose={modalLogoutService.controller.closeModal}
          icon="Logout"
        />

        <SModalDescription>{t("logout.signoutMessage")}</SModalDescription>
        <ButtonsModal>
          <SCanselButtonQi
            isArabic={isArabic}
            onClick={modalLogoutService.controller.closeModal}
          >
            {t("invoiceCreateForm.cancelButtonTitle")}
            <SvgImage name="Close" color="#707a8f" />
          </SCanselButtonQi>
          <SLogoutButtonQi isArabic={isArabic} onClick={handleLogout}>
            <SvgImage name="Logout" color="white" />
            {t("logout.signout")}
          </SLogoutButtonQi>
        </ButtonsModal>
      </SModalLogoutContainer>
    </SModalPopUp>
  );
});
