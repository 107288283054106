import { userService } from "api/userService";
import { Row } from "components/Row/Row.component";
import { Table } from "components/Table/Table.component";
import { InvoiceDetailsModel } from "model/InvoiceDetails.model";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableInvoiceDetailsModelNull } from "observables/InvoiceDetailsModelNullObservable";
import { useDefaultObservableTableService } from "observables/TableServiceObservable";
import { useTranslation } from "react-i18next";
import { invoiceDetailsPageService } from "service/shared/singletones/invoiceDetailsPageService/invoiceDetailsPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { SSecondaryDetailsTitleQi } from "utils/styled/SSecondaryDetailsTitle.styled";

import { CopyButton } from "./CopyButton/CopyButton.component";
import { DeleteButton } from "./DeleteButton/DeleteButton.component";
import { SInvoiceDetailsPageContent } from "./InvoiceDetailsPageContentStyled/SInvoiceDetailsPageContent.styled";
import { SInvoiceDetailsPageContentButtons } from "./InvoiceDetailsPageContentStyled/SInvoiceDetailsPageContentButtons.styled";
import { SInvoiceDetailsPageContentTable } from "./InvoiceDetailsPageContentStyled/SInvoiceDetailsPageContentTable.styled";
import { SInvoiceDetailsPageContentTableHeader } from "./InvoiceDetailsPageContentStyled/SInvoiceDetailsPageContentTableHeader.styled";
import { SendEmailButton } from "./SendEmailButton/SendEmailButton.component";
import { SendSmsButton } from "./SendSmsButton/SendSmsButton.component";

export const InvoiceDetailsPageContent = createComponent("InvoiceDetailsPageContent", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const canExecuteInvoice = userService.canExecuteInvoice();

  const invoice: InvoiceDetailsModel = useDefaultObservableInvoiceDetailsModelNull(invoiceDetailsPageService.state.invoice)!;

  const tableService = useDefaultObservableTableService(invoiceDetailsPageService.state.tableService);

  const loaded: boolean = useDefaultObservableBoolean(invoiceDetailsPageService.state.loaded);

  const isEmpty = useDefaultObservableBoolean(tableService.state.isEmpty);

  return isQi ? (
    <SInvoiceDetailsPageContent isArabic={isArabic}>
      <Row title={t("invoiceDetailsPage.title")} value={invoice.title} />
      <Row title={t("invoiceDetailsPage.description")} value={invoice.description} />
      <Row title={t("invoiceDetailsPage.terminalId")} value={invoice.terminalId} />
      <Row title={t("invoiceDetailsPage.creationDate")} value={invoice.creationDate} />
      <Row title={t("invoiceDetailsPage.status")} value={invoice.status} />
      <Row title={t("invoiceDetailsPage.email")} value={invoice.email} />
      <Row title={t("invoiceDetailsPage.phone")} value={invoice.phone} />
      <Row title={t("invoiceDetailsPage.amount")} value={invoice.amount} />
      <Row title={t("invoiceDetailsPage.currency")} value={invoice.currency} />

      <SInvoiceDetailsPageContentButtons>
        {canExecuteInvoice && invoice.hasSendAction ? <SendEmailButton /> : null}
        {canExecuteInvoice && invoice.hasDeleteAction ? <DeleteButton /> : null}
        {invoice.hasCopyAction ? <CopyButton /> : null}
      </SInvoiceDetailsPageContentButtons>

      {isEmpty ? null : (
        <SInvoiceDetailsPageContentTable isArabic={isArabic}>
          {isQi ? (
            <SSecondaryDetailsTitleQi isArabic={isArabic}>{t("invoiceDetailsPage.tableTitle")}</SSecondaryDetailsTitleQi>
          ) : (
            <SInvoiceDetailsPageContentTableHeader>{t("invoiceDetailsPage.tableTitle")}</SInvoiceDetailsPageContentTableHeader>
          )}

          <Table loaded={loaded} service={tableService} />
        </SInvoiceDetailsPageContentTable>
      )}
    </SInvoiceDetailsPageContent>
  ) : (
    <SInvoiceDetailsPageContent isArabic={isArabic}>
      <Row title={t("invoiceDetailsPage.title")} value={invoice.title} />
      <Row title={t("invoiceDetailsPage.description")} value={invoice.description} />
      <Row title={t("invoiceDetailsPage.terminalId")} value={invoice.terminalId} />
      <Row title={t("invoiceDetailsPage.creationDate")} value={invoice.creationDate} />
      <Row title={t("invoiceDetailsPage.status")} value={invoice.status} />
      <Row title={t("invoiceDetailsPage.email")} value={invoice.email} />
      <Row title={t("invoiceDetailsPage.phone")} value={invoice.phone} />
      <Row title={t("invoiceDetailsPage.amount")} value={invoice.amount} />
      <Row title={t("invoiceDetailsPage.currency")} value={invoice.currency} />

      <SInvoiceDetailsPageContentButtons>
        {canExecuteInvoice && invoice.hasSendAction ? <SendEmailButton /> : null}
        {canExecuteInvoice && invoice.hasSendAction ? <SendSmsButton /> : null}
        {canExecuteInvoice && invoice.hasDeleteAction ? <DeleteButton /> : null}
        {invoice.hasCopyAction ? <CopyButton /> : null}
      </SInvoiceDetailsPageContentButtons>

      {isEmpty ? null : (
        <SInvoiceDetailsPageContentTable isArabic={isArabic}>
          <SInvoiceDetailsPageContentTableHeader>{t("invoiceDetailsPage.tableTitle")}</SInvoiceDetailsPageContentTableHeader>
          <Table loaded={loaded} service={tableService} />
        </SInvoiceDetailsPageContentTable>
      )}
    </SInvoiceDetailsPageContent>
  );
});
