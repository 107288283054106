import { useDefaultObservableString } from "observables/StringObservable";
import { useTranslation } from "react-i18next";
import { terminalsPageService } from "service/shared/singletones/terminalsPageService/terminalsPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { STerminalsFilterSelect, STerminalsFilterSelectQi } from "./TerminalsFilterSelectStyled/STerminalsFilterSelect.styled";
import { STerminalsFilterSelectInput, STerminalsFilterSelectInputQi } from "./TerminalsFilterSelectStyled/STerminalsFilterSelectInput.styled";
import { STerminalsFilterSelectTitle } from "./TerminalsFilterSelectStyled/STerminalsFilterSelectTitle.styled";

export const TerminalsFilterSelect = createComponent("TerminalsFilterSelect", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const filterValue = useDefaultObservableString(terminalsPageService.state.filterValue);

  return isQi ? (
    <STerminalsFilterSelectQi>
      <STerminalsFilterSelectTitle>{t("terminalsPage.terminalsListTableHeaderTerminalId")}</STerminalsFilterSelectTitle>
      <STerminalsFilterSelectInputQi isArabic={isArabic} value={filterValue} onChange={terminalsPageService.controller.onChangeFilter} />
    </STerminalsFilterSelectQi>
  ) : (
    <STerminalsFilterSelect>
      <STerminalsFilterSelectTitle>{t("terminalsPage.terminalsFilterSelectTitle")}</STerminalsFilterSelectTitle>
      <STerminalsFilterSelectInput value={filterValue} onChange={terminalsPageService.controller.onChangeFilter} />
    </STerminalsFilterSelect>
  );
});
