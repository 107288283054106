import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { ControlCellProps } from "./ControlCell.props";
import { SControlCell, SControlCellQi } from "./ControlCellStyled/SControlCell.styled";

export const ControlCell = createComponent<ControlCellProps>("ControlCell", (props) => {
  const { cell, service } = props;

  const onClick = (): void => {
    service.controller.switchCell(cell);
  };
  return isQi ? (
    <SControlCellQi active={cell.active} onClick={onClick}>
      {cell.title}
    </SControlCellQi>
  ) : (
    <SControlCell active={cell.active} onClick={onClick}>
      {cell.title}
    </SControlCell>
  );
});
