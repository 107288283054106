/* eslint-disable prettier/prettier */
import React from "react";
import { useTranslation } from "react-i18next";
import { isFinOn, isQi } from "utils/business/environment";

import { createComponent } from "../utils";
import { SLangAppSelect, SSelectLangApp, SSelectWrapper } from "./AppStyled/SAppSelectLang.styled";

interface Native {
    nativeName: "Русский" | "English" | "العربية";
}
interface Langs {
    ru: Native;
    en: Native;
    ar: Native;
}
const langs: Langs = {
    ru: { nativeName: "Русский" },
    en: { nativeName: "English" },
    ar: { nativeName: "العربية" },
};

const langsKeys: Array<keyof typeof langs> = ["ru", "en", "ar"];

export const AppSelectLang = createComponent("AppSwitchLang", () => {
    const { i18n } = useTranslation();

    if (isFinOn || isQi) {
        const handlerChangeLang = (event: React.ChangeEvent<HTMLSelectElement>): void => {
            const lng = event.target.value as keyof typeof langs;
            i18n.changeLanguage(lng);
        };

        return (
            <SSelectLangApp>
                <SSelectWrapper>
                    <SLangAppSelect onChange={handlerChangeLang} value={i18n.resolvedLanguage}>
                        {langsKeys.map((lng) => (
                            <option key={lng} value={lng} hidden={isQi && lng === "ru"}>
                                {langs[lng].nativeName}
                            </option>
                        ))}
                    </SLangAppSelect>
                </SSelectWrapper>
            </SSelectLangApp>
        );
    }
    return null;
});
