import { UserTableContext } from "context/UserTable.context";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { GroupTableProps } from "./export GroupTable.props";
import { GroupTableBody } from "./GroupTableBody/GroupTableBody.component";
import { GroupTableHeader } from "./GroupTableHeader/GroupTableHeader.component";
import { SBodyScrollContainer } from "./GroupTableHeader/GroupTableHeaderStyled/SGroupTableHeader.styled";
import { SGroupTable, SGroupTablQi } from "./GroupTableStyled/SGroupTable.styled";
import { SGroupTableContainer } from "./GroupTableStyled/SGroupTableContainer.styled";

export const GroupTable = createComponent<GroupTableProps>("GroupTable", (props) => {
  const { userData } = props;

  return (
    <UserTableContext.Provider value={{ userData }}>
      {isQi ? (
        <SGroupTablQi>
          <SGroupTableContainer>
            <GroupTableHeader />
            <SBodyScrollContainer>
              <GroupTableBody />
            </SBodyScrollContainer>
          </SGroupTableContainer>
        </SGroupTablQi>
      ) : (
        <SGroupTable>
          <SGroupTableContainer>
            <GroupTableHeader />
            <GroupTableBody />
          </SGroupTableContainer>
        </SGroupTable>
      )}
    </UserTableContext.Provider>
  );
});
