import React from "react";
import { css } from "styled-components";
import { setFontSize_14px, setProps } from "theme";
import { styled } from "theme/default/styled";
import { setColor_0083f5 } from "theme/setter/setColorsValues/setColor_0083f5";
import { setFontSize_13px } from "theme/setter/setFontSizeValues/setFontSize_13px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";

export const SApplyButton = styled.button<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFontSize_13px};
  ${setFontWeight600};
  ${setColor_0083f5};
`;
type SProps = {
  isArabic?: boolean;
};

const isArabicLang = setProps<SProps>(({ isArabic }) => isArabic)(css`
  margin-right: 16px;
  flex-direction: row-reverse;
`);

const isNotArabicLangQi = setProps<SProps>(({ isArabic }) => !isArabic)(css`
  margin-left: 0px;
`);

export const SApplyButtonQi = styled.button<SProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFontSize_14px};
  ${setFontWeight600};
  ${isArabicLang};
  ${isNotArabicLangQi};
  display: flex;
  align-items: center;
  gap: 4px;
  width: 138px;
  background-color: #ffd400;
  padding: 10px 18px;
  border-radius: 8px;
`;
