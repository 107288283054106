import { SFilterButtonButtonQi } from "components/Filters/FilterButton/FilterButtonStyled/SFilterButtonButton.styled";
import { SFilterButtonClearQi } from "components/Filters/FilterButton/FilterButtonStyled/SFilterButtonClear.styled";
import { SFilterButtonTitle, SFilterButtonTitleQi } from "components/Filters/FilterButton/FilterButtonStyled/SFilterButtonTitle.styled";
import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { terminalsPageService } from "service/shared/singletones/terminalsPageService/terminalsPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";

import { TerminalsFilters } from "./TerminalsFilters/TerminalsFilters.component";
import { TerminalsList } from "./TerminalsList/TerminalsList.component";
import { TerminalsListTable } from "./TerminalsList/TerminalsListTable/TerminalsListTable.component";
import { STerminalsPage, STerminalsPageContainerQi, STerminalsPageQi } from "./TerminalsPageStyled/STerminalsPage.styled";
import { STerminalsPageContainer } from "./TerminalsPageStyled/STerminalsPageContainer.styled";

export const TerminalsPage = createComponent("TerminalsPage", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const [open, setOpen] = useState(false);

  useEffect(async () => {
    await terminalsPageService.controller.onMount();
  }, []);

  return isQi ? (
    <STerminalsPageContainerQi isArabic={isArabic}>
      {open ? (
        <>
          <SFilterButtonClearQi isArabic={isArabic} onClick={(): void => setOpen(false)}>
            <>
              <SvgImage name="Clear" color="#ffd400" />
              <SFilterButtonTitleQi isArabic={isArabic}>{t("filters.filterButton")}</SFilterButtonTitleQi>
            </>
          </SFilterButtonClearQi>
          <STerminalsPageQi isArabic={isArabic}>
            <STerminalsPageContainer>
              <TerminalsFilters />
              <TerminalsList />
            </STerminalsPageContainer>
          </STerminalsPageQi>
        </>
      ) : (
        <SFilterButtonButtonQi onClick={(): void => setOpen(true)} isArabic={isArabic}>
          <SFilterButtonTitle isArabic={isArabic}>{t("filters.showFiltersButton")}</SFilterButtonTitle>
          <SvgImage name="FilterSearch" />
        </SFilterButtonButtonQi>
      )}
      <TerminalsListTable />
    </STerminalsPageContainerQi>
  ) : (
    <STerminalsPage isArabic={isArabic}>
      <STerminalsPageContainer>
        <TerminalsFilters />
        <TerminalsList />
      </STerminalsPageContainer>
    </STerminalsPage>
  );
});
