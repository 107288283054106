/* eslint-disable max-len */

import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const Invoices = createIcon("Invoices", (props) => {
  const { width = 18, height = 21, color = props.isFinOn ? colorsValuesFin.color_0083f5 : "#82B8E7" } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2072_1073)">
        <path
          d="M9 7H15M9 11H15M13 15H15M5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21L16 19L14 21L12 19L10 21L8 19L5 21Z"
          stroke="#505766"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 7H15M9 11H15M13 15H15M5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21L16 19L14 21L12 19L10 21L8 19L5 21Z"
          stroke="black"
          strokeOpacity="0.2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2072_1073">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});

// TODO: Старая иконка
// <svg width={width} height={height} viewBox="0 0 18 21" fill="none">
//   <path
//     d="M1.20312 2.72119C1.20312 2.16891 1.65084 1.72119 2.20312 1.72119H15.2634C15.8157 1.72119 16.2634 2.16891 16.2634 2.72119V19.22C16.2634 19.7723 15.8157 20.22 15.2634 20.22H2.20313C1.65084 20.22 1.20312 19.7723 1.20312 19.22V2.72119Z"
//     fill="white"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M0.453125 2.72119C0.453125 1.75469 1.23663 0.971191 2.20312 0.971191H15.2634C16.2299 0.971191 17.0134 1.75469 17.0134 2.72119V19.22C17.0134 20.1865 16.2299 20.97 15.2634 20.97H2.20313C1.23663 20.97 0.453125 20.1865 0.453125 19.22V2.72119ZM2.20312 2.47119C2.06505 2.47119 1.95312 2.58312 1.95312 2.72119V19.22C1.95312 19.3581 2.06505 19.47 2.20313 19.47H15.2634C15.4015 19.47 15.5134 19.3581 15.5134 19.22V2.72119C15.5134 2.58312 15.4015 2.47119 15.2634 2.47119H2.20312Z"
//     fill={color}
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M3.67578 5.99561C3.67578 5.71946 3.89964 5.49561 4.17578 5.49561H13.2913C13.5675 5.49561 13.7913 5.71946 13.7913 5.99561C13.7913 6.27175 13.5675 6.49561 13.2913 6.49561H4.17578C3.89964 6.49561 3.67578 6.27175 3.67578 5.99561Z"
//     fill={color}
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M3.67578 8.56824C3.67578 8.29209 3.89964 8.06824 4.17578 8.06824H13.2913C13.5675 8.06824 13.7913 8.29209 13.7913 8.56824C13.7913 8.84438 13.5675 9.06824 13.2913 9.06824H4.17578C3.89964 9.06824 3.67578 8.84438 3.67578 8.56824Z"
//     fill={color}
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M3.67578 11.1409C3.67578 10.8647 3.89964 10.6409 4.17578 10.6409H13.2913C13.5675 10.6409 13.7913 10.8647 13.7913 11.1409C13.7913 11.417 13.5675 11.6409 13.2913 11.6409H4.17578C3.89964 11.6409 3.67578 11.417 3.67578 11.1409Z"
//     fill={color}
//   />
//   <path
//     d="M10.5034 14.4362C10.5034 14.16 10.7273 13.9362 11.0034 13.9362H13.2913C13.5674 13.9362 13.7913 14.16 13.7913 14.4362V15.8077C13.7913 16.0838 13.5674 16.3077 13.2913 16.3077H11.0034C10.7273 16.3077 10.5034 16.0838 10.5034 15.8077V14.4362Z"
//     fill={color}
//   />
// </svg>
