import { SRowTitleQi } from "components/Row/RowStyled/SRowTitle.styled";
import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableOptionModelArray } from "observables/OptionModelArrayObservable";
import { useDefaultObservableOptionModel } from "observables/OptionModelObservable";
import { useDefaultObservableSvgNames } from "observables/SvgNamesObservable";
import { useTranslation } from "react-i18next";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";

import { UsersChangeRowGroupInputProps } from "./UsersChangeRowGroupInput.props";
import { SUsersChangeRowGroupInput, SUsersChangeRowGroupInputQi } from "./UsersChangeRowGroupInputStyled/SUsersChangeRowGroupInput.styled";
import { SUsersChangeRowGroupInputIcon, SUsersChangeRowGroupInputIconQi } from "./UsersChangeRowGroupInputStyled/SUsersChangeRowGroupInputIcon.styled";
import { SUsersChangeRowGroupInputInput, SUsersChangeRowGroupInputInputQi } from "./UsersChangeRowGroupInputStyled/SUsersChangeRowGroupInputInput.styled";
import { SUsersChangeRowGroupInputTitle } from "./UsersChangeRowGroupInputStyled/SUsersChangeRowGroupInputTitle.styled";
import { SUsersChangeRowGroupInputValue, SUsersChangeRowGroupInputValueQi } from "./UsersChangeRowGroupInputStyled/SUsersChangeRowGroupInputValue.styled";

export const UsersChangeRowGroupInput = createComponent<UsersChangeRowGroupInputProps>("UsersChangeRowGroupInput", (props) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const { service } = props;

  useEffect(async () => {
    await service.controller.onMount();
  }, []);

  const active = useDefaultObservableBoolean(service.state.active);
  const value = useDefaultObservableOptionModel(service.state.value);
  const icon = useDefaultObservableSvgNames(service.state.icon);
  const showError = useDefaultObservableBoolean(service.state.showError);
  const optionList = useDefaultObservableOptionModelArray(service.state.optionList);

  if (active) {
    return isQi ? (
      <SUsersChangeRowGroupInputQi isArabic={isArabic}>
        <SRowTitleQi>{t("usersDetailsPage.merchantGroupName")}</SRowTitleQi>
        <div style={{ width: "100%", display: "flex", alignItems: "center", marginTop: "4px" }}>
          <SUsersChangeRowGroupInputInputQi showError={showError} value={value} onChange={service.controller.onChange} optionList={optionList} />
          <SUsersChangeRowGroupInputIconQi isArabic={isArabic} onClick={service.controller.onClickEdit}>
            <SvgImage name={icon} />
          </SUsersChangeRowGroupInputIconQi>
        </div>
      </SUsersChangeRowGroupInputQi>
    ) : (
      <SUsersChangeRowGroupInput isArabic={isArabic}>
        <SUsersChangeRowGroupInputTitle>{t("usersDetailsPage.merchantGroupName")}</SUsersChangeRowGroupInputTitle>
        <SUsersChangeRowGroupInputInput showError={showError} value={value} onChange={service.controller.onChange} optionList={optionList} />
        <SUsersChangeRowGroupInputIcon onClick={service.controller.onClickEdit}>
          <SvgImage name={icon} />
        </SUsersChangeRowGroupInputIcon>
      </SUsersChangeRowGroupInput>
    );
  }

  return isQi ? (
    <SUsersChangeRowGroupInputQi isArabic={isArabic}>
      <SRowTitleQi>{t("usersDetailsPage.merchantGroupName")}</SRowTitleQi>
      <div style={{ width: "100%", display: "flex", alignItems: "center", marginTop: "4px" }}>
        <SUsersChangeRowGroupInputValueQi>{value.title}</SUsersChangeRowGroupInputValueQi>
        <SUsersChangeRowGroupInputIconQi isArabic={isArabic} onClick={service.controller.onClickEdit}>
          <SvgImage name={icon} />
        </SUsersChangeRowGroupInputIconQi>
      </div>
    </SUsersChangeRowGroupInputQi>
  ) : (
    <SUsersChangeRowGroupInput isArabic={isArabic}>
      <SUsersChangeRowGroupInputTitle>{t("usersDetailsPage.merchantGroupName")}</SUsersChangeRowGroupInputTitle>
      <SUsersChangeRowGroupInputValue>{value.title}</SUsersChangeRowGroupInputValue>
      <SUsersChangeRowGroupInputIcon onClick={service.controller.onClickEdit}>
        <SvgImage name={icon} />
      </SUsersChangeRowGroupInputIcon>
    </SUsersChangeRowGroupInput>
  );
});
