import { styled } from "theme/default/styled";
import { setFlexDFFDCF1 } from "theme/setter/setFlexValues/setFlexDFFDCF1";

export const STableContainer = styled.div`
  ${setFlexDFFDCF1};
`;

export const STableContainerEmptyQi = styled.div`
  ${setFlexDFFDCF1};
  min-height: 25vh;
`;
