import React from "react";
import { css, setColor_0083f5, setFlexDFAIC, setFontSize_14px, setFontWeight600, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  left: 16px;
  flex-direction: row-reverse;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  right: 16px;
`);

export const SFormOpenButton = styled.button<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  position: absolute;
  top: 0;
  margin-top: 15px;
  padding: 4px 0;
  gap: 10px;

  ${setFlexDFAIC};
  ${setFontSize_14px};
  ${setFontWeight600};
  ${setColor_0083f5};
  ${isArabicLang};
  ${isNotArabicLang};
`;

export const SFormOpenButtonQi = styled.button<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  gap: 10px;
  ${setFlexDFAIC};
  ${setFontSize_14px};
  ${setFontWeight600};
  ${isArabicLang};
  ${isNotArabicLang};
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffd400;
  color: black;
  border-radius: 8px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: auto;
    padding: 10px 4px;
    gap: 4px;
  }
`;
