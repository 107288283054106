/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const Check = createIcon("Check", (props) => {
  const { width = 24, height = 22, color = "#000000" } = props;

  return (
    <svg width={width} viewBox="0 0 24 24" height={height} fill={color}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
    </svg>
  );
});
