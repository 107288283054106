import { useTranslation } from "react-i18next";
import { signInFormService } from "service/shared/singletones/signInFormService/signInForm.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { SLabel } from "utils/styled/SLabel.styled";

import { SSignInEmailInput } from "./SignInEmailInputStyled/SSignInEmailInput.styled";

export const SignInEmailInput = createComponent("SignInEmailInput", () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      {isQi && (
        <SLabel htmlFor="email" $isArabic={i18n.language === "ar"}>
          {t("signInPage.emailTitle")}
        </SLabel>
      )}
      <SSignInEmailInput
        $isArabic={i18n.language === "ar"}
        dataTestId="email"
        autoFocus
        type="email"
        id="email"
        name="email"
        placeholder={t("signInPage.placeholderEmail")}
        service={signInFormService.state.emailService}
      />
    </>
  );
});
