import { t } from "i18n";

export const initialConfigList = [
  { title: t("groupTableBodyService.groupTitle"), name: "group", width: 230, initialWidth: 200, langPath: "groupTableBodyService.groupTitle" },
  { title: t("groupTableBodyService.nameTitle"), name: "name", width: 200, initialWidth: 200, langPath: "groupTableBodyService.nameTitle" },
  {
    title: t("groupTableBodyService.phoneNumberTitle"),
    name: "phoneNumber",
    width: 200,
    initialWidth: 200,
    langPath: "groupTableBodyService.phoneNumberTitle",
  },
  { title: t("groupTableBodyService.emailTitle"), name: "email", width: 200, initialWidth: 200, langPath: "groupTableBodyService.emailTitle" },
  { title: t("groupTableBodyService.detailsTitle"), name: "details", width: 200, initialWidth: 200, langPath: "groupTableBodyService.detailsTitle" },
];
