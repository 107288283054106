import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { navigationMenuService } from "service/shared/singletones/navigationMenuService/navigationMenu.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { useLocation } from "utils/libReplace/useLocation";
import { useState } from "utils/libReplace/useState";
import { SNavigationMenuIconLabelQi, SNavigationMenuIconLabelTitle } from "utils/styled/SMenuLabels";

import { MenuIconProps } from "./MenuIcon.props";
import { SNavigationMenuIcon, SNavigationMenuIconQi } from "./MenuIconStyled/SNavigationMenuIcon.styled";
import { SNavigationMenuIconImg } from "./MenuIconStyled/SNavigationMenuIconImg.styled";
import { SNavigationMenuIconLabel } from "./MenuIconStyled/SNavigationMenuIconLabel.styled";
import { SNavigationMenuIconTitle, SNavigationMenuIconTitleQi } from "./MenuIconStyled/SNavigationMenuIconTitle.styled";

export const MenuIcon = createComponent<MenuIconProps>("MenuIcon", (props) => {
  const { t, i18n } = useTranslation();
  const { menuIcon } = props;

  const open = useDefaultObservableBoolean(navigationMenuService.state.open);
  const isArabic = i18n.language === "ar";

  const [hovered, setHovered] = useState<boolean>(false);

  const onMouseOut = (): void => {
    setHovered(false);
  };
  const onMouseOver = (): void => {
    setHovered(true);
  };
  const { pathname } = useLocation();

  const active = pathname.includes(menuIcon.link);

  return isQi ? (
    <SNavigationMenuIconQi open={open} active={active} onMouseOut={onMouseOut} onMouseOver={onMouseOver} to={menuIcon.link} isArabic={isArabic}>
      <SNavigationMenuIconImg>
        <SvgImage name={menuIcon.name} />
      </SNavigationMenuIconImg>
      {open && <SNavigationMenuIconTitleQi active={active}>{t(menuIcon.langPath)}</SNavigationMenuIconTitleQi>}
      {!open && hovered ? (
        <SNavigationMenuIconLabelQi isArabic={isArabic}>
          <SNavigationMenuIconLabelTitle>{t(menuIcon.langPath)}</SNavigationMenuIconLabelTitle>
        </SNavigationMenuIconLabelQi>
      ) : null}
    </SNavigationMenuIconQi>
  ) : (
    <SNavigationMenuIcon open={open} active={active} onMouseOut={onMouseOut} onMouseOver={onMouseOver} to={menuIcon.link} isArabic={isArabic}>
      <SNavigationMenuIconImg>
        <SvgImage name={menuIcon.name} />
      </SNavigationMenuIconImg>
      {!open && hovered ? (
        <SNavigationMenuIconLabel isArabic={isArabic}>
          <SNavigationMenuIconLabelTitle>{t(menuIcon.langPath)}</SNavigationMenuIconLabelTitle>
        </SNavigationMenuIconLabel>
      ) : null}
      {open ? <SNavigationMenuIconTitle active={active}>{t(menuIcon.langPath)}</SNavigationMenuIconTitle> : null}
    </SNavigationMenuIcon>
  );
});
