import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { usersInfoPageState } from "service/shared/singletones/usersInfoPageService/usersInfoPage.state";

class Controller {
  public readonly state = usersInfoPageState;

  public readonly onMount = async (): Promise<void> => {
    this.state.loaded.next(false);
    const { data } = await client.post(apiPaths.adminGroupInfo);
    this.state.userData.next(data);
    this.state.loaded.next(true);
  };

  public openModal = (): void => {
    this.state.isModalOpen.next(true);
  };

  public closeModal = (): void => {
    this.state.isModalOpen.next(false);
  };
}

export const usersInfoPageController = new Controller();
