import { routerPaths } from "appConstants/routerPaths";
import { DetailsCell } from "detailsCell/DetailsCell/DetailsCell.component";
import { TransfersDetailsCellProps } from "detailsCell/TransfersDetailsCell/TransfersDetailsCell.props";
import { replacePath } from "utils/commonExtend/replacePath";
import { createComponent } from "utils/libExtend/createComponent";
import { STATUS_STYLES, StatusColor, StatusWrapper } from "utils/styled/SStatusWrapperQi";

export const TransfersDetailsCell = createComponent<TransfersDetailsCellProps>("TransfersDetailsCell", (props) => {
  const { cellData } = props;

  const to = replacePath(routerPaths.transferDetails, {
    transferId: cellData.transferId,
  });

  return <DetailsCell to={to} />;
});

const getStatusStyle = (status: string): StatusColor => {
  return STATUS_STYLES[status] || STATUS_STYLES.PENDING;
};

export const TransfersStatus = createComponent<TransfersDetailsCellProps>("TransfersStatus", (props) => {
  const { cellData } = props;

  const color = getStatusStyle(cellData.status);

  return <StatusWrapper color={color}>{cellData.status}</StatusWrapper>;
});
