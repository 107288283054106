import { useFiltersContext } from "context/Filter.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { FormOpenButton } from "pages/InvoicesPage/InvoicesHeader/FormOpenButton/FormOpenButton.component";
import { useLocation } from "react-router-dom";
import { isQi } from "utils/business/environment";
import { doesPathContain } from "utils/commonExtend/doesPathContain";
import { createComponent } from "utils/libExtend/createComponent";

import { FilterButton } from "../FilterButton/FilterButton.component";
import { DownloadButton } from "../FilterMenu/DownloadButton/DownloadButton.component";
import { FilterMenu } from "../FilterMenu/FilterMenu.component";
import { FilterButtonContainerQi, SFilters, SFiltersQi } from "./FiltersContentStyled/SFilters.styled";

export const FiltersContent = createComponent("FiltersContent", () => {
  const { service } = useFiltersContext();

  const open = useDefaultObservableBoolean(service.state.open);
  const isArabic = useDefaultObservableBoolean(service.state.isArabic);
  const location = useLocation();

  const isInvoicesPage = doesPathContain(location.pathname, "invoices");

  return isQi ? (
    <>
      <FilterButtonContainerQi isArabic={isArabic} isOpen={open}>
        <FilterButton />
        <div style={{ display: "flex", gap: "20px" }}>
          {isQi && isInvoicesPage && <FormOpenButton />}
          <DownloadButton />
        </div>
      </FilterButtonContainerQi>
      <SFiltersQi isOpen={open} isArabic={isArabic}>
        {open ? <FilterMenu /> : null}
      </SFiltersQi>
    </>
  ) : (
    <SFilters isArabic={isArabic}>
      <FilterButton />
      {open ? <FilterMenu /> : null}
    </SFilters>
  );
});
