import { styled } from "theme/default/styled";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

export const SRefundDetailsPageContentTable = styled.div`
  width: fit-content;

  ${setFlexDFFDC};
`;

export const SRefundDetailsPageContentTableQi = styled.div`
  width: auto;
  ${setFlexDFFDC};
`;
