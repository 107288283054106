import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableOptionModel } from "observables/OptionModelObservable";
import { useTranslation } from "react-i18next";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { SCompareInputQi } from "utils/styled/SCompareInput";
import { SInputTitleQi } from "utils/styled/SInputTitle.styled";

import { CompareYearProps } from "./CompareYear.props";
import { SCompareYear, SCompareYearQi } from "./CompareYearStyled/SCompareYear.styled";
import { SCompareYearSelector } from "./CompareYearStyled/SCompareYearSelector.styled";

export const CompareYear = createComponent<CompareYearProps>("CompareYear", (props) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const { service } = props;

  const value = useDefaultObservableOptionModel(service.state.value);
  const valid = useDefaultObservableBoolean(service.state.valid);
  const showError = useDefaultObservableBoolean(service.state.showError);

  return isQi ? (
    <SCompareYearQi isArabic={isArabic}>
      <SInputTitleQi isArabic={isArabic}>{t("dynamicPage.yearInputTitle")}</SInputTitleQi>
      <SCompareInputQi isError={showError && !valid} onChange={service.controller.onChange} value={value} optionList={service.state.optionList} />
    </SCompareYearQi>
  ) : (
    <SCompareYear isArabic={isArabic}>
      <span>{t("dynamicPage.yearInputTitle")}</span>
      <SCompareYearSelector isError={showError && !valid} onChange={service.controller.onChange} value={value} optionList={service.state.optionList} />
    </SCompareYear>
  );
});
