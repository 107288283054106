import { useDefaultObservableStatisticPartArray } from "observables/StatisticPartArrayObservable";
import ReactECharts from "packages/echarts-for-react";
import { SStatisticConversionsContentQi } from "pages/StatisticPage/StatisticConversions/StatisticConversionsContent/StatisticConversionsContentStyled/SStatisticConversionsContent.styled";
import { useTranslation } from "react-i18next";
import { statisticPageService } from "service/shared/singletones/statisticPageService/statisticPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { getChartOptions } from "./getChartOptions";
import { produceChartStatistic } from "./produceChartStatistic/produceChartStatistic";
import { SStatisticChartContent } from "./StatisticChartContentStyled/SStatisticChartContent.styled";
import { SStatisticChartContentTitle, SStatisticChartContentTitleQi } from "./StatisticChartContentStyled/SStatisticChartContentTitle.styled";

export const StatisticChartContent = createComponent("StatisticChartContent", () => {
  const { t, i18n } = useTranslation();
  const statistics = useDefaultObservableStatisticPartArray(statisticPageService.state.statistics);

  const { mirValue, visaValue, masterCardValue } = produceChartStatistic(statistics);
  const option = getChartOptions(mirValue, visaValue, masterCardValue);
  const isArabic = i18n.language === "ar";

  return isQi ? (
    <SStatisticConversionsContentQi isArabic={isArabic}>
      <SStatisticChartContentTitleQi isArabic={isArabic}>{t("statisticPage.paymentSchemesTitle")}</SStatisticChartContentTitleQi>
      <ReactECharts option={option} style={{ height: 400 }} opts={{ renderer: "svg" }} />
    </SStatisticConversionsContentQi>
  ) : (
    <SStatisticChartContent>
      <SStatisticChartContentTitle>{t("statisticPage.paymentSystemTitle")}</SStatisticChartContentTitle>
      <ReactECharts option={option} style={{ height: 400 }} opts={{ renderer: "svg" }} />
    </SStatisticChartContent>
  );
});
