import React from "react";
import { css, setFlexDFAIC, setProps, styled } from "theme";

interface SFilterInputContainerProps {
  isArabic?: boolean;
}

const isArabicLang = setProps<SFilterInputContainerProps>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);

const isArabicLangQi = setProps<SFilterInputContainerProps>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);

export const SFilterInputContainer = styled.div<SFilterInputContainerProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFlexDFAIC};
  ${isArabicLang};
`;

export const SFilterInputContainerQi = styled.div<SFilterInputContainerProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFlexDFAIC};
  ${isArabicLangQi};
`;
