import React from "react";
import { css, setFlexDFFDC, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";
import { isQi } from "utils/business/environment";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

export const SInvoiceDetailsPageContentTable = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFlexDFFDC};
  ${isArabicLang};
  width: ${isQi && "100%"};
`;
