/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { styled } from "theme/default/styled";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";
import { isQi } from "utils/business/environment";

type SProps = {
  isArabic?: boolean;
};

export const STransferDetailsPageContent = styled.div<SProps & React.HTMLAttributes<HTMLDivElement>>`
  ${setFlexDFFDC};
  margin-top: ${isQi ? "16px" : "0"};
  align-items: ${(props) => (props.isArabic ? "flex-end" : "initial")};
`;
