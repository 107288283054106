import { getSource } from "./getSource";

export const getRatioOptions = (dateArray: string[], successQuantityArray: number[], failQuantityArray: number[]): any => {
  const source = getSource(dateArray, successQuantityArray, failQuantityArray);

  return {
    legend: {
      orient: "horizontal",
      left: "center",
      textStyle: {
        color: "#333",
        fontSize: 14,
      },
    },
    tooltip: {},
    dataset: { source },
    xAxis: {
      type: "category",
      axisLine: {
        lineStyle: {
          color: "#888",
        },
      },
      axisLabel: {
        color: "#666",
        fontSize: 12,
      },
    },
    yAxis: {
      axisLine: {
        lineStyle: {
          color: "#888",
        },
      },
      axisLabel: {
        color: "#666",
        fontSize: 12,
      },
    },
    series: [
      {
        type: "bar",
        itemStyle: {
          color: "#15AC77",
          borderRadius: [4, 4, 0, 0],
        },
      },
      {
        type: "bar",
        itemStyle: {
          color: "#F9C146",
          borderRadius: [4, 4, 0, 0],
        },
      },
    ],
    dataZoom: [
      {
        type: "inside",
        throttle: 50,
        zoomLock: false,
        start: 0,
        end: 100,
      },
    ],
  };
};
