import React from "react";
import { css, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  margin-right: 8px;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  margin-left: 8px;
`);

export const SFetchedFilterSelectDelete = styled.button<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  height: 20px;
  margin-left: 8px;
  ${isArabicLang};
  ${isNotArabicLang};
`;
