import React from "react";
import styled, { css } from "styled-components";
import { setProps } from "theme";
import { SPropsIsArabic, SPropsIsOpen } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);
const isOpen = setProps<SPropsIsOpen>(({ open }) => open)(css`
  width: 240px;
  padding: 12px;
`);
const isNotOpen = setProps<SPropsIsOpen>(({ open }) => !open)(css`
  padding: 2px;
`);

export const SUserQi = styled.div<SPropsIsArabic & SPropsIsOpen & React.HTMLAttributes<HTMLDivElement>>`
  height: 60px;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #e8e8e9;
  border-radius: 8px;
  position: relative;
  ${isArabicLang};
  ${isOpen}
  ${isNotOpen}
`;

export const SUserNameQi = styled.p<React.HTMLAttributes<HTMLElement>>`
  color: #14181f;
  font-size: 14px;
  font-weight: 500;
`;
