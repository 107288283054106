import { routerPaths } from "appConstants/routerPaths";
import { useTranslation } from "react-i18next";
import { forgotPasswordFormService } from "service/shared/singletones/forgotPasswordFormService/forgotPasswordForm.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { SForm } from "utils/styled/SForm.styled";
import { SFormHeader } from "utils/styled/SFormHeader.styled";
import { SLabel } from "utils/styled/SLabel.styled";

import { SForgotPasswordFormEmail } from "./ForgotPasswordPageStyled/SForgotPasswordFormEmail.styled";
import { SForgotPasswordFormLink } from "./ForgotPasswordPageStyled/SForgotPasswordFormLink.styled";
import { SendButton } from "./SendButton/SendButton.component";

export const ForgotPasswordPage = createComponent("ForgotPasswordPage", () => {
  const { t, i18n } = useTranslation();
  return (
    <SForm>
      <SFormHeader>{t("forgotPasswordPage.title")}</SFormHeader>
      <>
        {isQi && (
          <SLabel htmlFor="email" $isArabic={i18n.language === "ar"}>
            {t("signInPage.emailTitle")}
          </SLabel>
        )}
        <SForgotPasswordFormEmail
          dataTestId="email"
          type="email"
          name="email"
          placeholder={t("forgotPasswordPage.emailPlaceholder")}
          service={forgotPasswordFormService.state.emailService}
          $isArabic={i18n.language === "ar"}
        />
      </>
      <SForgotPasswordFormLink data-test-id="back" to={routerPaths.signIn}>
        {t("forgotPasswordPage.backButton")}
      </SForgotPasswordFormLink>
      <SendButton />
    </SForm>
  );
});
