/* eslint-disable max-len */

import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const Transfers = createIcon("Transfers", (props) => {
  const { width = 23, height = 17, color = props.isFinOn ? colorsValuesFin.color_0083f5 : "#82B8E7" } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 8C3 7.20435 3.31607 6.44129 3.87868 5.87868C4.44129 5.31607 5.20435 5 6 5H18C18.7956 5 19.5587 5.31607 20.1213 5.87868C20.6839 6.44129 21 7.20435 21 8V16C21 16.7956 20.6839 17.5587 20.1213 18.1213C19.5587 18.6839 18.7956 19 18 19H6C5.20435 19 4.44129 18.6839 3.87868 18.1213C3.31607 17.5587 3 16.7956 3 16V8Z"
        stroke="#505766"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 8C3 7.20435 3.31607 6.44129 3.87868 5.87868C4.44129 5.31607 5.20435 5 6 5H18C18.7956 5 19.5587 5.31607 20.1213 5.87868C20.6839 6.44129 21 7.20435 21 8V16C21 16.7956 20.6839 17.5587 20.1213 18.1213C19.5587 18.6839 18.7956 19 18 19H6C5.20435 19 4.44129 18.6839 3.87868 18.1213C3.31607 17.5587 3 16.7956 3 16V8Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3 10H21" stroke="#505766" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 10H21" stroke="black" strokeOpacity="0.2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 15H7.01" stroke="#505766" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 15H7.01" stroke="black" strokeOpacity="0.2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 15H13" stroke="#505766" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 15H13" stroke="black" strokeOpacity="0.2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
});

// <svg width={width} height={height} viewBox="0 0 23 17" fill="none">
//   <path
//     d="M8.41357 8.07617C8.41357 7.52389 8.86129 7.07617 9.41357 7.07617H20.9994C21.5517 7.07617 21.9994 7.52389 21.9994 8.07617V15.0028C21.9994 15.5551 21.5517 16.0028 20.9994 16.0028H9.41358C8.86129 16.0028 8.41357 15.5551 8.41357 15.0028V8.07617Z"
//     fill="white"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M7.66357 8.07617C7.66357 7.10967 8.44708 6.32617 9.41357 6.32617H20.9994C21.9659 6.32617 22.7494 7.10967 22.7494 8.07617V15.0028C22.7494 15.9693 21.9659 16.7528 20.9994 16.7528H9.41358C8.44708 16.7528 7.66357 15.9693 7.66357 15.0028V8.07617ZM9.41357 7.82617C9.2755 7.82617 9.16357 7.9381 9.16357 8.07617V15.0028C9.16357 15.1409 9.2755 15.2528 9.41358 15.2528H20.9994C21.1375 15.2528 21.2494 15.1409 21.2494 15.0028V8.07617C21.2494 7.9381 21.1375 7.82617 20.9994 7.82617H9.41357Z"
//     fill={color}
//   />
//   <path fillRule="evenodd" clipRule="evenodd" d="M21.9997 13.6366H8.27539V12.6366H21.9997V13.6366Z" fill={color} />
//   <path
//     d="M9.98242 9.69653C9.98242 9.42039 10.2063 9.19653 10.4824 9.19653H12.4484C12.7246 9.19653 12.9484 9.42039 12.9484 9.69653V10.8359C12.9484 11.112 12.7246 11.3359 12.4484 11.3359H10.4824C10.2063 11.3359 9.98242 11.112 9.98242 10.8359V9.69653Z"
//     fill={color}
//   />
//   <path
//     d="M0.796387 1.90332C0.796387 1.35103 1.2441 0.90332 1.79639 0.90332H14.9084C15.4607 0.90332 15.9084 1.35104 15.9084 1.90332V9.83275C15.9084 10.385 15.4607 10.8327 14.9084 10.8327H1.79639C1.2441 10.8327 0.796387 10.385 0.796387 9.83275V1.90332Z"
//     fill="white"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M0.0463867 1.90332C0.0463867 0.936819 0.82989 0.15332 1.79639 0.15332H14.9084C15.8749 0.15332 16.6584 0.936821 16.6584 1.90332V9.83275C16.6584 10.7992 15.8749 11.5827 14.9084 11.5827H1.79639C0.82989 11.5827 0.0463867 10.7992 0.0463867 9.83275V1.90332ZM1.79639 1.65332C1.65831 1.65332 1.54639 1.76525 1.54639 1.90332V9.83275C1.54639 9.97082 1.65832 10.0827 1.79639 10.0827H14.9084C15.0465 10.0827 15.1584 9.97082 15.1584 9.83275V1.90332C15.1584 1.76525 15.0465 1.65332 14.9084 1.65332H1.79639Z"
//     fill={color}
//   />
//   <path fillRule="evenodd" clipRule="evenodd" d="M15.9086 8.20068H0.642578V7.20068H15.9086V8.20068Z" fill={color} />
//   <path
//     d="M2.5415 3.76196C2.5415 3.48582 2.76536 3.26196 3.0415 3.26196H5.34068C5.61683 3.26196 5.84069 3.48582 5.84069 3.76196V5.14165C5.84069 5.41779 5.61683 5.64164 5.34069 5.64164H3.0415C2.76536 5.64164 2.5415 5.41779 2.5415 5.14164V3.76196Z"
//     fill={color}
//   />
// </svg>
