/* eslint-disable prettier/prettier */
import { Link } from "components";
import { css, setBackgroundColor_c4edff, setBorderRadius_4px, setBorderRadius_8px, setFlexDFAIC, setFlexJCC, setProps, styled } from "theme";

type SNavigationMenuIconProps = {
  open: boolean;
  active: boolean;
  isArabic: boolean;
};

const activeSet = setProps<SNavigationMenuIconProps>(({ active }) => active)(css`
  ${setBackgroundColor_c4edff};
`);

const isArabicLang = setProps<SNavigationMenuIconProps>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);

const openSet = setProps<SNavigationMenuIconProps>(({ open }) => open)(css`
  width: auto;
  justify-content: flex-start;
`);

export const SNavigationMenuIcon = styled(Link) <SNavigationMenuIconProps>`
  position: relative;
  width: 40px;
  height: 40px;
  text-decoration: none;

  ${setFlexDFAIC};
  ${setFlexJCC};
  ${setBorderRadius_4px};
  ${openSet};
  ${activeSet};
  ${isArabicLang};
`;

export const SNavigationMenuIconQi = styled(Link) <SNavigationMenuIconProps>`
  height: 50px;
  width: 50px;
  text-decoration: none;
  pading: 8px 10px;
  bacground-color: #ffd400;
  ${setFlexDFAIC}
  ${setBorderRadius_8px};
  ${openSet};
  ${activeSet};
  ${isArabicLang};
  position: relative;
`;

