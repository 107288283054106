import React from "react";
import { css, setFlexDFFDC, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  padding-left: 80px;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  padding-right: "80px;
`);

export const SStatisticPage = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  overflow: auto;
  width: 100%;
  margin-bottom: -16px;
  padding-bottom: 16px;
  ${isNotArabicLang};
  ${isArabicLang};
  ${setFlexDFFDC};
`;

const isArabicLangQi = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  padding-left: 34px;
  margin-right: -272px;
`);

const isNotArabicLangQi = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  padding-right: 0px;
  margin-left: -272px;
`);

export const SStatisticPageQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  overflow: auto;
  margin-bottom: -16px;
  padding-bottom: 34px;
  ${isNotArabicLangQi};
  ${isArabicLangQi};
  margin-top: 100px;
`;
