import { styled } from "theme/default/styled";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

export const SCreateUserSelect = styled.div`
  gap: 8px;

  ${setFlexDFFDC};
`;

export const SCreateUserSelectQi = styled.div`
  gap: 4px;
  ${setFlexDFFDC};
  margin-top: 10px;
  margin-bottom: 6px;
`;
