import { SwitchedRow } from "components/SwitchedRow/SwitchedRow.component";
import { createComponent } from "utils/libExtend/createComponent";

import { TransferSwitchedRowProps } from "./TransferSwitchedRow.props";

export const TransferSwitchedRow = createComponent<TransferSwitchedRowProps>("TransferSwitchedRow", (props) => {
  const { title, value } = props;

  return <SwitchedRow title={title} value={value} type="medium" />;
});
