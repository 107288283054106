import React from "react";
import { setFlexDFAIC, setRowReverse, setTextRightArabic, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

export const SUsersChangeRowCommon = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-bottom: 10px;
  ${setFlexDFAIC};
  ${setTextRightArabic()};
  ${setRowReverse()};
`;

export const SUsersChangeRowCommonQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-bottom: 10px;
  ${setFlexDFAIC};
  justify-content: space-between;
  ${setTextRightArabic()};
  ${setRowReverse()};
  width: 70%;
`;
