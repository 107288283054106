import { setFontSize_14px } from "theme";
import { styled } from "theme/default/styled";
import { setColor_25476d } from "theme/setter/setColorsValues/setColor_25476d";
import { setFontSize_13px } from "theme/setter/setFontSizeValues/setFontSize_13px";
import { setFontWeight500 } from "theme/setter/setFontWeightValues/setFontWeight500";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";

export const SCreateFormInputTitle = styled.div`
  margin-bottom: 6px;
  ${setFontSize_13px};
  ${setFontWeight600};
  ${setColor_25476d};
`;

export const SCreateFormInputTitleQi = styled.div`
  margin-bottom: 6px;
  color: #22211f;
  ${setFontSize_14px};
  ${setFontWeight500};
`;
