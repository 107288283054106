import { setBorderRadius_8px, setFontWeight400 } from "theme";
import { styled } from "theme/default/styled";
import { setFontSize_14px } from "theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";
import { setColor_rgba_0_0_0_87 } from "theme/setter/setOpacityColorsValues/setColor_rgba_0_0_0_87";

export const SUsersChangeRowGroupInputValue = styled.div`
  width: 200px;

  ${setFontSize_14px};
  ${setFontWeight600};
  ${setColor_rgba_0_0_0_87};
`;

export const SUsersChangeRowGroupInputValueQi = styled.div`
  width: 100%;
  height: 40px;
  padding: 10px 12px;
  appearance: none;
  border: 1px solid #e8e8e9;
  ${setFontSize_14px};
  ${setFontWeight400};
  ${setBorderRadius_8px};
`;
