import React from "react";
import { styled } from "theme/default/styled";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";

export const SGroupHeaderCell = styled.div<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding: 6px 6px 6px 12px;
  ${setFlexDFAIC};
`;

export const SGroupHeaderCellQi = styled.div<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding: 0px 16px 16px 16px;
  ${setFlexDFAIC};
`;
