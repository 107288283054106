import { userRolesConfig } from "appConstants/userRolesConfig";
import { UserModel } from "model/User.model";
import { useDefaultObservableUserModelNull } from "observables/UserModelNullObservable";
import { SAccountDetailsContainer } from "pages/UserInfoPage/AccountDetails/AccountDetailsStyled/AccountDetails.styled";
import { AccountRow } from "pages/UserInfoPage/AccountDetails/AccountRow/AccountRow.component";
import { useTranslation } from "react-i18next";
import { usersDetailsPageService } from "service/shared/singletones/usersDetailsPageService/usersDetailsPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { UsersChangeRow } from "./UsersChangeRow/UsersChangeRow.component";
import { UsersChangeRowGroup } from "./UsersChangeRowGroup/UsersChangeRowGroup.component";
import { UsersChangeRowRoles } from "./UsersChangeRowRoles/UsersChangeRowRoles.component";

export const UsersDetailsList = createComponent("UsersDetailsList", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const user: UserModel = useDefaultObservableUserModelNull(usersDetailsPageService.state.user)!;

  const enabled = user.enabled ? "TRUE" : "FALSE";

  const isSystemUser = user.roles.includes(userRolesConfig.SYSTEM);

  return isQi ? (
    <SAccountDetailsContainer isArabic={isArabic}>
      <AccountRow title={t("usersDetailsPage.id")} value={user.id} />
      <AccountRow title={t("usersDetailsPage.blockedFlag")} value={user.blockedFlag} />
      <AccountRow title={t("usersDetailsPage.enabled")} value={enabled} />
      <UsersChangeRowRoles hasChange={!isSystemUser} service={usersDetailsPageService.state.rolesService} />
      <UsersChangeRowGroup hasChange={!isSystemUser} service={usersDetailsPageService.state.merchantGroupNameService} />

      <UsersChangeRow hasChange={!isSystemUser} title={t("usersDetailsPage.name")} service={usersDetailsPageService.state.nameService} value={user.name} />
      <UsersChangeRow hasChange={!isSystemUser} title={t("usersDetailsPage.email")} service={usersDetailsPageService.state.emailService} value={user.email} />
      <UsersChangeRow
        hasChange={!isSystemUser}
        title={t("usersDetailsPage.phone")}
        service={usersDetailsPageService.state.phoneNumberService}
        value={user.phoneNumber}
      />
    </SAccountDetailsContainer>
  ) : (
    <>
      <UsersChangeRow hasChange={false} title={t("usersDetailsPage.id")} value={user.id} />
      <UsersChangeRow hasChange={false} title={t("usersDetailsPage.blockedFlag")} value={user.blockedFlag} />
      <UsersChangeRow hasChange={false} title={t("usersDetailsPage.enabled")} value={enabled} />

      <UsersChangeRowRoles hasChange={!isSystemUser} service={usersDetailsPageService.state.rolesService} />

      <UsersChangeRowGroup hasChange={!isSystemUser} service={usersDetailsPageService.state.merchantGroupNameService} />
      <UsersChangeRow hasChange={!isSystemUser} title={t("usersDetailsPage.name")} service={usersDetailsPageService.state.nameService} value={user.name} />
      <UsersChangeRow hasChange={!isSystemUser} title={t("usersDetailsPage.email")} service={usersDetailsPageService.state.emailService} value={user.email} />
      <UsersChangeRow
        hasChange={!isSystemUser}
        title={t("usersDetailsPage.phone")}
        service={usersDetailsPageService.state.phoneNumberService}
        value={user.phoneNumber}
      />
    </>
  );
});
