/* eslint-disable prettier/prettier */
import { EyeClosedIcon } from "components/SvgImage/svgs/EyeClosedIcon.component";
import { EyeOpenIcon } from "components/SvgImage/svgs/EyeOpenIcon.component";
import { Input } from "pages/SignInPage/Input/Input.component";
import { styled } from "theme/default/styled";
import { isFinOn, isQi } from "utils/business/environment";
import { finOn, qi, sngb } from "utils/styled/SInput.styled";

export const SSignInPasswordInput = styled(Input) <{ $isArabic?: boolean }>`
  ${isFinOn ? finOn : isQi ? qi : sngb};
  text-align: ${({ $isArabic }): any => $isArabic && "right"};
`;

export const SToggleVisibilityButton = styled.button<{ $isArabic?: boolean; children: any; onClick: () => void; type: string }>`
  position: absolute;
  top: 50%;
  ${({ $isArabic }): string => ($isArabic ? "left: 10px;" : "right: 10px;")}
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EyeIcon = styled(EyeOpenIcon)`
  width: 20px;
  height: 20px;
  color: #6c757d;
`;

export const EyeOffIcon = styled(EyeClosedIcon)`
  width: 20px;
  height: 20px;
  color: #6c757d;
`;
