import { useTranslation } from "react-i18next";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { SDividerVerticalQi } from "utils/styled/SDivider";

import { STerminalsListTableHeader, STerminalsListTableHeaderQi } from "./TerminalsListTableHeaderStyled/STerminalsListTableHeader.styled";
import { STerminalsListTableHeaderTitle, STerminalsListTableHeaderTitleQi } from "./TerminalsListTableHeaderStyled/STerminalsListTableHeaderTitle.styled";

export const TerminalsListTableHeader = createComponent("TerminalsListTableHeader", () => {
  const { t } = useTranslation();
  return isQi ? (
    <STerminalsListTableHeaderQi>
      <STerminalsListTableHeaderTitleQi>{t("terminalsPage.terminalsListTableHeaderTerminalId")}</STerminalsListTableHeaderTitleQi>
      <SDividerVerticalQi />
      <STerminalsListTableHeaderTitleQi>{t("terminalsPage.terminalsListTableHeaderMerchantName")}</STerminalsListTableHeaderTitleQi>
    </STerminalsListTableHeaderQi>
  ) : (
    <STerminalsListTableHeader>
      <STerminalsListTableHeaderTitle>{t("terminalsPage.terminalsListTableHeaderTerminalId")}</STerminalsListTableHeaderTitle>
      <STerminalsListTableHeaderTitle>{t("terminalsPage.terminalsListTableHeaderMerchantName")}</STerminalsListTableHeaderTitle>
    </STerminalsListTableHeader>
  );
});
