/* eslint-disable prettier/prettier */
/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const ArrowDownSelect = createIcon("ArrowDownSelect", (props) => {
    const { width = 14, height = 8, color = "#69809A" } = props;

    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2072_1304)">
                <path d="M5.19922 7.5L10.1992 12.5L15.1992 7.5" stroke="#707A8F" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2072_1304">
                    <rect width="20" height="20" fill="white" transform="translate(0.199219)" />
                </clipPath>
            </defs>
        </svg>
    );
});
