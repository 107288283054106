/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { routerPaths } from "appConstants/routerPaths";
import { DetailsCell } from "detailsCell/DetailsCell/DetailsCell.component";
import { PaymentsDetailsCellProps } from "detailsCell/PaymentsDetailsCell/PaymentsDetailsCell.props";
import { replacePath } from "utils/commonExtend/replacePath";
import { createComponent } from "utils/libExtend/createComponent";
import { STATUS_STYLES, StatusColor, StatusWrapper } from "utils/styled/SStatusWrapperQi";

const getStatusStyle = (status: string): StatusColor => {
  return STATUS_STYLES[status] || STATUS_STYLES.PENDING;
};

export const PaymentsDetailsCell = createComponent<PaymentsDetailsCellProps>("PaymentsDetailsCell", (props) => {
  const { cellData } = props;

  const to = replacePath(routerPaths.paymentDetails, {
    paymentId: cellData.paymentId,
  });

  return <DetailsCell to={to} />;
});
//  переход по payment Info Id
export const PaymentsInfoDetailsCell = createComponent<PaymentsDetailsCellProps>("PaymentsDetailsCell", (props) => {
  const { cellData } = props;

  const to = replacePath(routerPaths.paymentDetails, {
    paymentId: cellData.paymentId,
  });

  return cellData.paymentId && <DetailsCell to={to} />;
});

export const PaymentsStatus = createComponent<PaymentsDetailsCellProps>("PaymentsStatus", (props) => {
  const { cellData } = props;

  const color = getStatusStyle(cellData.status);

  return <StatusWrapper color={color}>{cellData.status}</StatusWrapper>;
});
