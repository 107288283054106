import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableNumber } from "observables/NumberObservable";
import { useEffect } from "packages/react";
import { useTranslation } from "react-i18next";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { Controls } from "./Controls/Controls.component";
import { ControlsSelect } from "./ControlsSelect/ControlsSelect.component";
import { TableNavigationProps } from "./TableNavigation.props";
import { STableNavigation, STableNavigationQi } from "./TableNavigationStyled/STableNavigation.styled";
import { STableNavigationTotalPages } from "./TableNavigationStyled/STableNavigationTotalPages.styled";
import { STableNavigationTotalPagesDescription } from "./TableNavigationStyled/STableNavigationTotalPagesDescription.styled";
import { STableNavigationTotalPagesValue, STableNavigationTotalPagesValueQi, STitleQi } from "./TableNavigationStyled/STableNavigationTotalPagesValue.styled";

export const TableNavigation = createComponent<TableNavigationProps>("TableNavigation", (props) => {
  const { t, i18n } = useTranslation();
  const { service } = props;

  useEffect(() => {
    service.controller.onChangeArabic(i18n.language === "ar");
  }, [i18n.language]);

  const totalPages = useDefaultObservableNumber(service.state.totalPages);

  const totalElements = useDefaultObservableNumber(service.state.totalElements);

  const isArabic = useDefaultObservableBoolean(service.state.isArabic);

  return isQi ? (
    <STableNavigationQi isArabic={isArabic}>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <STitleQi isArabic={isArabic}>{t("tableNavigation.showingElements")}</STitleQi>
        <ControlsSelect service={service} />
        <STitleQi isArabic={isArabic}>{t("tableNavigation.fromElements")}</STitleQi>
        {service.state.showCountElements && (
          <STableNavigationTotalPages isArabic={isArabic}>
            <STableNavigationTotalPagesValueQi isArabic={isArabic}>{totalElements}</STableNavigationTotalPagesValueQi>
          </STableNavigationTotalPages>
        )}
      </div>

      <Controls service={service} />
    </STableNavigationQi>
  ) : (
    <STableNavigation isArabic={isArabic}>
      {service.state.showCountElements && (
        <STableNavigationTotalPages isArabic={isArabic}>
          <STableNavigationTotalPagesDescription>{t("tableNavigation.totalPageTitle")}</STableNavigationTotalPagesDescription>
          <STableNavigationTotalPagesValue isArabic={isArabic}>{totalPages}</STableNavigationTotalPagesValue>
        </STableNavigationTotalPages>
      )}
      {service.state.showCountElements && (
        <STableNavigationTotalPages isArabic={isArabic}>
          <STableNavigationTotalPagesDescription>{t("tableNavigation.totalOperationsTitle")}</STableNavigationTotalPagesDescription>
          <STableNavigationTotalPagesValue isArabic={isArabic}>{totalElements}</STableNavigationTotalPagesValue>
        </STableNavigationTotalPages>
      )}
      <Controls service={service} />
      <ControlsSelect service={service} />
    </STableNavigation>
  );
});
