import { styled } from "theme/default/styled";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

export const SUserResetPasswordForm = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 560px;
  height: 480px;
  padding: 24px;
  transform: translate(-50%, -50%);
  background-color: white;
  ${setFlexDFFDC};
`;
