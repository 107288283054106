import React from "react";
import { css, setColor_rgba_0_0_0_87, setFontSize_20px, setFontWeight700, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

export const SDynamicPageTitle = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-bottom: 24px;

  ${setFontSize_20px};
  ${setFontWeight700};
  ${setColor_rgba_0_0_0_87};
  ${isArabicLang};
`;
