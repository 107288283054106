import { useTranslation } from "react-i18next";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { RowProps } from "./Row.props";
import { SRow, SRowQi } from "./RowStyled/SRow.styled";
import { SRowTitle, SRowTitleQi } from "./RowStyled/SRowTitle.styled";
import { SRowValue, SRowValueQi } from "./RowStyled/SRowValue.styled";

export type RowType = "medium" | "big";

export const Row = createComponent<RowProps>("Row", (props) => {
  const { i18n } = useTranslation();
  const { title, value, type = "medium" } = props;
  const isArabic = i18n.language === "ar";

  if (!value) {
    return null;
  }

  return isQi ? (
    <SRowQi isArabic={isArabic} isMedium={type === "medium"} isIntermediate={type === "intermediate"} isBig={type === "big"}>
      <SRowTitleQi>{title}</SRowTitleQi>
      <SRowValueQi>{value}</SRowValueQi>
    </SRowQi>
  ) : (
    <SRow isArabic={isArabic} isMedium={type === "medium"} isIntermediate={type === "intermediate"} isBig={type === "big"}>
      <SRowTitle>{title}</SRowTitle>
      <SRowValue>{value}</SRowValue>
    </SRow>
  );
});
