/* eslint-disable prettier/prettier */
/* eslint-disable max-len */

import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const Reset = createIcon("Reset", (props) => {
    const { width = 26, height = 14, color = props.isFinOn ? colorsValuesFin.color_0083f5 : "#82B8E7" } = props;

    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2072_1241)">
                <path d="M14.1992 14L10.1992 10" stroke="#707A8F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path
                    d="M2.37695 8.02867C2.66826 8.97742 3.25376 9.80894 4.0488 10.403C4.84384 10.9971 5.80721 11.3229 6.79962 11.3333C7.94761 11.3475 9.06066 10.9387 9.9266 10.1849C10.7925 9.43109 11.3508 8.38499 11.495 7.246C11.6368 6.10701 11.3539 4.9557 10.7003 4.01214C10.0468 3.06859 9.06843 2.39899 7.95229 2.13133C6.83589 1.86129 5.65918 2.01116 4.64612 2.55242C3.63306 3.09368 2.85438 3.98853 2.45829 5.06666"
                    stroke="#707A8F"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M2.19922 2.66669V5.33335H4.86589" stroke="#707A8F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2072_1241">
                    <rect width="16" height="16" fill="white" transform="translate(0.199219)" />
                </clipPath>
            </defs>
        </svg>
    );
});
