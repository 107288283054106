export const getChartOptions = (mirValue: number, visaValue: number, masterCardValue: number): any => {
  return {
    title: {
      left: "center",
      top: "center",
      textAlign: "center",
      textStyle: {
        fontSize: 18,
        fontWeight: "bold",
        lineHeight: 30,
      },
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
      top: "bottom",
      itemGap: 20,
    },
    series: [
      {
        type: "pie",
        radius: ["60%", "75%"],
        center: ["50%", "50%"],
        data: [
          { value: mirValue, name: "MIR" },
          { value: visaValue, name: "VISA" },
          { value: masterCardValue, name: "MASTER_CARD" },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
};
