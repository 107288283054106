import styled from "styled-components";

export const SChangePasswordSectionQi = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 0px 24px;
  background-color: #fff;
`;

export const SChangePasswordSectionTitleQi = styled.h2`
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
`;
