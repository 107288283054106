/* eslint-disable prettier/prettier */
import { initialHeaderCellKey } from "appConstants/initialHeaderCellKey";
import { tableWidths } from "appConstants/tableWidths";
import { RewardsDetailsCell } from "detailsCell/RewardsDetailsCell/RewardsDetailsCell.component";
import { t } from "i18n";
import { SetPageTableHandlerType } from "types/business/SetPageTableHandlerType";
import { isQi } from "utils/business/environment";
import { getInitialHeaderCell } from "utils/business/getInitialHeaderCell";

export const rewardsPage: SetPageTableHandlerType = () => {
  return isQi
    ? [
      getInitialHeaderCell(initialHeaderCellKey.details, t("rewardsPageConfig.details"), "rewardsPageConfig.details", {
        isDetails: true,
        Element: RewardsDetailsCell,
        width: tableWidths.width120,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.date, t("rewardsPageConfig.date"), "rewardsPageConfig.date", {
        width: tableWidths.width180,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.operationsNumber, t("rewardsPageConfig.operationsNumber"), "rewardsPageConfig.operationsNumber", {
        width: tableWidths.width200,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.transactionCurrency, t("rewardsPageConfig.transactionCurrency"), "rewardsPageConfig.transactionCurrency", {
        width: tableWidths.width200,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.incomeAmount, t("rewardsPageConfig.incomeAmount"), "rewardsPageConfig.incomeAmount", {
        width: tableWidths.width180,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.settlementCurrency, t("rewardsPageConfig.settlementCurrency"), "rewardsPageConfig.settlementCurrency", {
        width: tableWidths.width180,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.settlementAmount, t("rewardsPageConfig.settlementAmount"), "rewardsPageConfig.settlementAmount", {
        width: tableWidths.width180,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.feeAmount, t("rewardsPageConfig.feeAmount"), "rewardsPageConfig.feeAmount", {
        width: tableWidths.width180,
        height: "100%",
      }),
    ]
    : [
      getInitialHeaderCell(initialHeaderCellKey.details, t("rewardsPageConfig.details"), "rewardsPageConfig.details", {
        isDetails: true,
        Element: RewardsDetailsCell,
        width: tableWidths.width120,
      }),
      getInitialHeaderCell(initialHeaderCellKey.date, t("rewardsPageConfig.date"), "rewardsPageConfig.date", { width: tableWidths.width180 }),
      getInitialHeaderCell(initialHeaderCellKey.operationsNumber, t("rewardsPageConfig.operationsNumber"), "rewardsPageConfig.operationsNumber", {
        width: tableWidths.width200,
      }),
      getInitialHeaderCell(initialHeaderCellKey.transactionCurrency, t("rewardsPageConfig.transactionCurrency"), "rewardsPageConfig.transactionCurrency", {
        width: tableWidths.width200,
      }),
      getInitialHeaderCell(initialHeaderCellKey.incomeAmount, t("rewardsPageConfig.incomeAmount"), "rewardsPageConfig.incomeAmount", {
        width: tableWidths.width180,
      }),
      getInitialHeaderCell(initialHeaderCellKey.settlementCurrency, t("rewardsPageConfig.settlementCurrency"), "rewardsPageConfig.settlementCurrency", {
        width: tableWidths.width180,
      }),
      getInitialHeaderCell(initialHeaderCellKey.settlementAmount, t("rewardsPageConfig.settlementAmount"), "rewardsPageConfig.settlementAmount", {
        width: tableWidths.width180,
      }),
      getInitialHeaderCell(initialHeaderCellKey.feeAmount, t("rewardsPageConfig.feeAmount"), "rewardsPageConfig.feeAmount", { width: tableWidths.width180 }),
    ];
};