/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import moment from "packages/moment";
import Datetime from "packages/react-datetime";
import React, { useState } from "react";
import { createComponent } from "utils";

import { CustomDateTimeProps } from "./CustomDateTime.props";
import { SCustomDateTimeInputQi } from "./CustomDateTimeStyled/SCustomDateTimeValue.styled";

export const CustomDateTime = createComponent<CustomDateTimeProps>("CustomDateTime", (props) => {
  const { dateFormat, onChange, timeFormat, value } = props;

  const [inputValue, setInputValue] = useState(moment(value).format("DD-MM-YYYY HH:mm"));
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value.replace(/[^\d]/g, "");

    if (newValue.length >= 2) newValue = `${newValue.slice(0, 2)}-${newValue.slice(2)}`;
    if (newValue.length >= 5) newValue = `${newValue.slice(0, 5)}-${newValue.slice(5)}`;
    if (newValue.length >= 10) newValue = `${newValue.slice(0, 10)} ${newValue.slice(10)}`;
    if (newValue.length >= 13) newValue = `${newValue.slice(0, 13)}:${newValue.slice(13)}`;

    if (newValue.length > 16) newValue = newValue.slice(0, 16);

    setInputValue(newValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === "Backspace" || e.key === "Delete") && inputValue) {
      const cursorPosition = e.currentTarget.selectionStart ?? inputValue.length;

      if (cursorPosition > 0 && /[-: ]/.test(inputValue[cursorPosition - 1])) {
        e.preventDefault();
        setInputValue((prev) => prev.slice(0, cursorPosition - 1) + prev.slice(cursorPosition));
      }
    }
  };

  const handleInputBlur = () => {
    const parsedDate = moment(inputValue, "DD-MM-YYYY HH:mm", true);
    if (parsedDate.isValid()) {
      onChange(parsedDate);
    } else {
      setInputValue(moment(value).format("DD-MM-YYYY HH:mm"));
    }
    setIsInputFocused(false);
  };

  return (
    <Datetime
      dateFormat="DD-MM-YYYY"
      timeFormat="HH:mm"
      value={value}
      onChange={(date) => {
        if (moment.isMoment(date)) {
          setInputValue(date.format("DD-MM-YYYY HH:mm"));
          onChange(date);
        }
      }}
      renderInput={(props, openCalendar) => (
        <SCustomDateTimeInputQi
          {...props}
          value={isInputFocused ? inputValue : moment(value).format("DD-MM-YYYY HH:mm")}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onBlur={handleInputBlur}
          onFocus={() => setIsInputFocused(true)}
          onClick={openCalendar}
        />
      )}
    />
  );
});
