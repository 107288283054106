import React from "react";
import { css, setFlexDF, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
  padding-right: 8px;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  padding-left: 8px;
`);

export const SFilterButtonButton = styled.button<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${isNotArabicLang};
  ${isArabicLang};
  ${setFlexDF};
`;

export const SFilterButtonButtonQi = styled.button<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${isNotArabicLang};
  ${isArabicLang};
  ${setFlexDF};
  align-items: center;
  gap: 4px;
  width: 138px;
  background-color: #ffd400;
  padding: 10px 14px;
  border-radius: 8px;
`;
