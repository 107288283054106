import React from "react";
import { setBorderRadius_8px } from "theme";
import { styled } from "theme/default/styled";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";
import { setFontSize_14px } from "theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight400 } from "theme/setter/setFontWeightValues/setFontWeight400";
import { setColor_rgba_0_0_0_87 } from "theme/setter/setOpacityColorsValues/setColor_rgba_0_0_0_87";

export const SCreateUserFetchedMultipleSelectContainer = styled.button<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 375px;
  height: 26px;
  padding: 5px 25px 5px 8px;
  gap: 8px;
  appearance: none;

  border: 1px solid ${color_a0b9d2};

  ${setFlexDFAIC};
  ${setBorderRadius_4px};
  ${setFontSize_14px};
  ${setFontWeight400};
  ${setColor_rgba_0_0_0_87};
`;

export const SCreateUserFetchedMultipleSelectContainerQi = styled.button<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFlexDFAIC};
  flex-direction: column;
  width: 100%;
  min-height: 40px;
  height: auto;
  padding: 10px 12px;
  appearance: none;
  border: 1px solid #e8e8e9;
  margin-top: 4px;
  ${setBorderRadius_8px};
  ${setFontSize_14px};
  ${setFontWeight400};
`;
