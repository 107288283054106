/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const LogoQi = createIcon("LogoQi", (props) => {
  const { width = 244, height = 50, color = "#00346E" } = props;

  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6701 27.7296C16.8735 28.147 18.9641 27.988 21.012 27.2267C21.2071 27.1542 21.5605 27.1912 21.6962 27.3244C23.0638 28.6663 24.4012 30.0389 25.7568 31.4127C20.2944 35.1252 10.744 35.2465 4.46378 28.4564C-1.69341 21.7993 -1.44832 11.4848 5.01468 4.90528C11.1479 -1.33848 21.6025 -1.65491 28.1564 4.14679C35.1985 10.3807 35.2108 20.0605 31.5068 25.6214C31.3315 25.469 31.1508 25.3322 30.9936 25.1723C29.8465 24.0052 28.7161 22.8212 27.5523 21.671C27.2235 21.3461 27.19 21.0735 27.3358 20.644C29.1217 15.3814 27.0696 10.0401 22.2268 7.2888C16.5828 4.08236 8.98715 6.93252 6.72558 13.0026C4.87021 17.9825 6.69711 23.7001 11.5104 26.482C12.4614 27.0317 13.5709 27.3071 14.6701 27.7296Z"
        fill="#FDC905"
      />
      <path
        d="M30.2239 26.1689C31.196 27.1462 32.1385 28.0754 33.0596 29.0254C34.2785 30.2826 34.3139 31.972 33.1661 33.1282C31.9928 34.3101 30.2472 34.2951 28.9986 33.0542C26.1274 30.201 23.2649 27.3392 20.413 24.4668C19.1424 23.1872 19.1256 21.4042 20.3328 20.2405C21.4655 19.1486 23.2965 19.2395 24.518 20.4582C26.41 22.3458 28.2962 24.2391 30.2239 26.1689Z"
        fill="#FDC905"
      />
      <path
        d="M19.8406 15.2906C20.5755 16.8813 20.2738 18.3601 19.0385 19.4224C18.0111 20.306 16.476 20.4055 15.2741 19.6664C14.0115 18.8899 13.3961 17.3758 13.8376 15.9572C14.2218 14.7225 15.076 13.8961 16.3577 13.6834C17.8136 13.4417 19.0134 13.9343 19.8406 15.2906Z"
        fill="#FDC906"
      />
    </svg>
  );
});
