import { Loader } from "components/Loader/Loader.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { rewardDetailsPageService } from "service/shared/singletones/rewardDetailsPageService/rewardDetailsPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { RewardDetailsInformationContent } from "./RewardDetailsInformationContent/RewardDetailsInformationContent.component";
import { SRewardDetailsInformation, SRewardDetailsInformationQi } from "./RewardDetailsInformationStyled/SRewardDetailsInformation.styled";
import {
  SRewardDetailsInformationContainer,
  SRewardDetailsInformationContainerQi,
} from "./RewardDetailsInformationStyled/SRewardDetailsInformationContainer.styled";
import { SRewardDetailsInformationLoader } from "./RewardDetailsInformationStyled/SRewardDetailsInformationLoader.styled";

export const RewardDetailsInformation = createComponent("RewardDetailsInformation", () => {
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const loaded = useDefaultObservableBoolean(rewardDetailsPageService.state.loaded);

  return isQi ? (
    <SRewardDetailsInformationQi isArabic={isArabic}>
      <SRewardDetailsInformationContainerQi isArabic={i18n.language === "ar"}>
        {loaded ? (
          <RewardDetailsInformationContent />
        ) : (
          <SRewardDetailsInformationLoader>
            <Loader />
          </SRewardDetailsInformationLoader>
        )}
      </SRewardDetailsInformationContainerQi>
    </SRewardDetailsInformationQi>
  ) : (
    <SRewardDetailsInformation>
      <SRewardDetailsInformationContainer isArabic={i18n.language === "ar"}>
        {loaded ? (
          <RewardDetailsInformationContent />
        ) : (
          <SRewardDetailsInformationLoader>
            <Loader />
          </SRewardDetailsInformationLoader>
        )}
      </SRewardDetailsInformationContainer>
    </SRewardDetailsInformation>
  );
});
