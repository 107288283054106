/* eslint-disable prettier/prettier */
import { ChangePasswordInput } from "pages/UserInfoPage/ChangePasswordSection/ChangePasswordInput/ChangePasswordInput.component";
import { ChangePasswordSectionCapsLockErrorForm } from "pages/UserInfoPage/ChangePasswordSection/ChangePasswordSectionCapsLockErrorForm/ChangePasswordSectionCapsLockErrorForm.component";
import { ChangePasswordSectionErrorForm } from "pages/UserInfoPage/ChangePasswordSection/ChangePasswordSectionErrorForm/ChangePasswordSectionErrorForm.component";
import { ChangePasswordSectionRussianLetterErrorForm } from "pages/UserInfoPage/ChangePasswordSection/ChangePasswordSectionRussianLetterErrorForm/ChangePasswordSectionRussianLetterErrorForm.component";
import { useTranslation } from "react-i18next";
import { userInfoPageService } from "service/shared/singletones/userInfoPageService/userInfoPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SChangePasswordSectionQi } from "./ChangePasswordSectionStyled/ChangePasswordSection.styled";

export const ChangePasswordSectionQi = createComponent("ChangePasswordSectionQi", () => {
    const { t } = useTranslation();

    return (
        <SChangePasswordSectionQi>
            <ChangePasswordInput service={userInfoPageService.state.currentPassword} title={t("changePasswordSection.currentPasswordTitle")} />
            <ChangePasswordInput service={userInfoPageService.state.newPassword} title={t("changePasswordSection.newPasswordTitle")} />
            <ChangePasswordInput service={userInfoPageService.state.repeatedNewPassword} title={t("changePasswordSection.repeatedNewPasswordTitle")} />

            <ChangePasswordSectionCapsLockErrorForm />
            <ChangePasswordSectionRussianLetterErrorForm />
            <ChangePasswordSectionErrorForm />
        </SChangePasswordSectionQi>
    );
});
