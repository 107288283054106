import React from "react";
import { styled } from "theme/default/styled";
import { setBackgroundColor_ffffff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_ffffff";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";
import { setFlexJCC } from "theme/setter/setFlexValues/setFlexJCC";

export const SMenuListLogOut = styled.button<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  height: 50px;

  ${setFlexDFAIC};
  ${setFlexJCC};
  ${setBackgroundColor_ffffff};
`;

export const SMenuListLogOutQi = styled.div<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  height: 50px;
  border-radius: 12px;
  color: #e7535c;

  ${setFlexDFAIC};
  ${setFlexJCC};
  ${setBackgroundColor_ffffff};
`;
