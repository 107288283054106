import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useFiltersContext } from "context/Filter.context";
import { useTranslation } from "react-i18next";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { SApplyButton, SApplyButtonQi } from "./ApplyButtonStyled/SApplyButton.styled";

export const ApplyButton = createComponent("ApplyButton", () => {
  const { t, i18n } = useTranslation();
  const { service } = useFiltersContext();
  const isArabic = i18n.language === "ar";

  return isQi ? (
    <SApplyButtonQi isArabic={isArabic} onClick={service.controller.onClickApply}>
      {t("filters.applyTitle")} <SvgImage name="Apply" />
    </SApplyButtonQi>
  ) : (
    <SApplyButton onClick={service.controller.onClickApply}>{t("filters.applyButtonTitle")}</SApplyButton>
  );
});
