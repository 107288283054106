import React from "react";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
`;

export const PositionedDiv = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  position: absolute;
  top: 34px;
  right: 34px;
`;
