import React from "react";
import { css } from "styled-components";
import { setProps } from "theme";
import { styled } from "theme/default/styled";
import { color_rgba_183_200_220_80 } from "theme/setter/opacityColorsValues/color_rgba_183_200_220_80";
import { setBackgroundColor_ffffff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_ffffff";
import { setBorderRadius_8px } from "theme/setter/setBorderRadiusValues/setBorderRadius_8px";
import { setFlexDFFDCF1 } from "theme/setter/setFlexValues/setFlexDFFDCF1";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  margin-right: 302px;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  margin-right: 34px;
  margin-left: 304px;
`);

export const SStatisticConversionsContent = styled.div`
  margin-right: 16px;
  margin-left: 12px;
  padding: 10px;
  box-shadow: 0 0 7px ${color_rgba_183_200_220_80};
  ${setFlexDFFDCF1};
  ${setBorderRadius_8px};
  ${setBackgroundColor_ffffff};
`;

export const SStatisticConversionsContentQi = styled.div<SPropsIsArabic & React.HTMLAttributes<HTMLDivElement>>`
  padding: 10px;
  padding-bottom: 24px;
  border: 1px solid #e8e8e9;
  margin-top: 16px;
  border-radius: 12px;
  ${setFlexDFFDCF1};
  ${setBackgroundColor_ffffff};
  ${isArabicLang};
  ${isNotArabicLang};
`;
