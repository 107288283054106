/* eslint-disable max-len */

import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const Reward = createIcon("Reward", (props) => {
  const { width = 22, height = 22, color = props.isFinOn ? colorsValuesFin.color_0083f5 : "#90C5F5" } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2072_1096)">
        <path
          d="M10.0007 3.19998C8.4364 3.56262 6.99761 4.33718 5.83358 5.44332C4.66954 6.54946 3.82264 7.94689 3.38072 9.49066C2.93881 11.0344 2.91798 12.6683 3.32039 14.2229C3.72279 15.7774 4.53378 17.196 5.66924 18.3314C6.8047 19.4669 8.22327 20.2779 9.77781 20.6803C11.3323 21.0827 12.9662 21.0619 14.51 20.62C16.0538 20.178 17.4512 19.3311 18.5574 18.1671C19.6635 17.0031 20.4381 15.5643 20.8007 14C20.8007 13.7348 20.6953 13.4804 20.5078 13.2929C20.3203 13.1053 20.0659 13 19.8007 13H13.0007C12.4703 13 11.9616 12.7893 11.5865 12.4142C11.2114 12.0391 11.0007 11.5304 11.0007 11V3.99998C10.9883 3.88151 10.9525 3.76668 10.8953 3.66215C10.8382 3.55763 10.7608 3.46548 10.6678 3.39107C10.5748 3.31665 10.4679 3.26144 10.3534 3.22864C10.2389 3.19584 10.119 3.1861 10.0007 3.19998Z"
          stroke="#505766"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.0007 3.19998C8.4364 3.56262 6.99761 4.33718 5.83358 5.44332C4.66954 6.54946 3.82264 7.94689 3.38072 9.49066C2.93881 11.0344 2.91798 12.6683 3.32039 14.2229C3.72279 15.7774 4.53378 17.196 5.66924 18.3314C6.8047 19.4669 8.22327 20.2779 9.77781 20.6803C11.3323 21.0827 12.9662 21.0619 14.51 20.62C16.0538 20.178 17.4512 19.3311 18.5574 18.1671C19.6635 17.0031 20.4381 15.5643 20.8007 14C20.8007 13.7348 20.6953 13.4804 20.5078 13.2929C20.3203 13.1053 20.0659 13 19.8007 13H13.0007C12.4703 13 11.9616 12.7893 11.5865 12.4142C11.2114 12.0391 11.0007 11.5304 11.0007 11V3.99998C10.9883 3.88151 10.9525 3.76668 10.8953 3.66215C10.8382 3.55763 10.7608 3.46548 10.6678 3.39107C10.5748 3.31665 10.4679 3.26144 10.3534 3.22864C10.2389 3.19584 10.119 3.1861 10.0007 3.19998Z"
          stroke="black"
          strokeOpacity="0.2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 3.5C16.2697 3.94708 17.423 4.6733 18.3748 5.62516C19.3267 6.57702 20.0529 7.73028 20.5 9H16C15.7348 9 15.4804 8.89464 15.2929 8.70711C15.1054 8.51957 15 8.26522 15 8V3.5Z"
          stroke="#505766"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 3.5C16.2697 3.94708 17.423 4.6733 18.3748 5.62516C19.3267 6.57702 20.0529 7.73028 20.5 9H16C15.7348 9 15.4804 8.89464 15.2929 8.70711C15.1054 8.51957 15 8.26522 15 8V3.5Z"
          stroke="black"
          strokeOpacity="0.2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2072_1096">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});

// <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M11 18.4331C14.3137 18.4331 17 17.09 17 15.4331V12.4331H11H5V15.4331C5 17.09 7.68629 18.4331 11 18.4331Z"
//     fill="white"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M15.499 15.4413C15.4999 15.437 15.5 15.4347 15.5 15.4331V13.9331H6.5V15.4331C6.5 15.4347 6.5001 15.437 6.50096 15.4413C6.50186 15.4457 6.50416 15.455 6.51014 15.4696C6.52226 15.4992 6.55125 15.5547 6.61672 15.6321C6.75353 15.7938 7.01029 16.0038 7.42818 16.2128C8.26479 16.6311 9.52552 16.9331 11 16.9331C12.4745 16.9331 13.7352 16.6311 14.5718 16.2128C14.9897 16.0038 15.2465 15.7938 15.3833 15.6321C15.4487 15.5547 15.4777 15.4992 15.4899 15.4696C15.4958 15.455 15.4981 15.4457 15.499 15.4413ZM17 15.4331C17 17.09 14.3137 18.4331 11 18.4331C7.68629 18.4331 5 17.09 5 15.4331V12.4331H17V15.4331Z"
//     fill={color}
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M11 15.4331C14.3137 15.4331 17 14.09 17 12.4331V9.43311H11H5V12.4331C5 14.09 7.68629 15.4331 11 15.4331Z"
//     fill="white"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M15.499 12.4413C15.4999 12.437 15.5 12.4347 15.5 12.4331V10.9331H6.5V12.4331C6.5 12.4347 6.5001 12.437 6.50096 12.4413C6.50186 12.4457 6.50416 12.455 6.51014 12.4696C6.52226 12.4992 6.55125 12.5547 6.61672 12.6321C6.75353 12.7938 7.01029 13.0038 7.42818 13.2128C8.26479 13.6311 9.52552 13.9331 11 13.9331C12.4745 13.9331 13.7352 13.6311 14.5718 13.2128C14.9897 13.0038 15.2465 12.7938 15.3833 12.6321C15.4487 12.5547 15.4777 12.4992 15.4899 12.4696C15.4958 12.455 15.4981 12.4457 15.499 12.4413ZM17 12.4331C17 14.09 14.3137 15.4331 11 15.4331C7.68629 15.4331 5 14.09 5 12.4331V9.43311H17V12.4331Z"
//     fill={color}
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M11 12.4331C14.3137 12.4331 17 11.09 17 9.43311V6.43311H5V9.43311C5 11.09 7.68629 12.4331 11 12.4331Z"
//     fill="white"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M15.499 9.44127C15.4999 9.43702 15.5 9.4347 15.5 9.43311V7.93311H6.5V9.43311C6.5 9.4347 6.5001 9.43702 6.50096 9.44127C6.50186 9.4457 6.50416 9.45496 6.51014 9.46956C6.52226 9.49918 6.55125 9.55469 6.61672 9.63208C6.75353 9.79381 7.01029 10.0038 7.42818 10.2128C8.26479 10.6311 9.52552 10.9331 11 10.9331C12.4745 10.9331 13.7352 10.6311 14.5718 10.2128C14.9897 10.0038 15.2465 9.79381 15.3833 9.63208C15.4487 9.55469 15.4777 9.49918 15.4899 9.46956C15.4958 9.45496 15.4981 9.4457 15.499 9.44127ZM17 9.43311C17 11.09 14.3137 12.4331 11 12.4331C7.68629 12.4331 5 11.09 5 9.43311V6.43311H17V9.43311Z"
//     fill={color}
//   />
//   <path
//     d="M17 6.43311C17 8.08996 14.3137 9.43311 11 9.43311C7.68629 9.43311 5 8.08996 5 6.43311C5 4.77625 7.68629 3.43311 11 3.43311C14.3137 3.43311 17 4.77625 17 6.43311Z"
//     fill="white"
//   />
//   <path
//     fillRule="evenodd"
//     clipRule="evenodd"
//     d="M15.499 6.44127C15.4999 6.43702 15.5 6.4347 15.5 6.43311C15.5 6.43151 15.4999 6.42919 15.499 6.42494C15.4981 6.42051 15.4958 6.41126 15.4899 6.39665C15.4777 6.36703 15.4487 6.31152 15.3833 6.23413C15.2465 6.0724 14.9897 5.86237 14.5718 5.65343C13.7352 5.23512 12.4745 4.93311 11 4.93311C9.52552 4.93311 8.26479 5.23512 7.42818 5.65343C7.01029 5.86237 6.75353 6.0724 6.61672 6.23413C6.55125 6.31152 6.52226 6.36703 6.51014 6.39665C6.50416 6.41126 6.50186 6.42051 6.50096 6.42494C6.5001 6.42919 6.5 6.43151 6.5 6.43311C6.5 6.4347 6.5001 6.43702 6.50096 6.44127C6.50186 6.4457 6.50416 6.45496 6.51014 6.46956C6.52226 6.49918 6.55125 6.55469 6.61672 6.63208C6.75353 6.79381 7.01029 7.00384 7.42818 7.21279C8.26479 7.63109 9.52552 7.93311 11 7.93311C12.4745 7.93311 13.7352 7.63109 14.5718 7.21279C14.9897 7.00384 15.2465 6.79381 15.3833 6.63208C15.4487 6.55469 15.4777 6.49918 15.4899 6.46956C15.4958 6.45496 15.4981 6.4457 15.499 6.44127ZM11 9.43311C14.3137 9.43311 17 8.08996 17 6.43311C17 4.77625 14.3137 3.43311 11 3.43311C7.68629 3.43311 5 4.77625 5 6.43311C5 8.08996 7.68629 9.43311 11 9.43311Z"
//     fill={color}
//   />
// </svg>
