/* eslint-disable prettier/prettier */
import React from "react";
import { styled } from "theme/default/styled";

import { SGroupUserCellTitle } from "./SGroupUserCellTitle.styled";

export const SGroupUserCellTitleButton = styled(SGroupUserCellTitle) <React.ButtonHTMLAttributes<HTMLButtonElement>>`
  cursor: pointer;
`;

export const SGroupUserCellTitleButtonQi = styled(SGroupUserCellTitle) <React.ButtonHTMLAttributes<HTMLButtonElement>>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;