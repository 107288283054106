import React from "react";
import { css, setProps, styled } from "theme";
import { isQi } from "utils/business/environment";

type SGroupImageProps = {
  isOpen: boolean;
  isArabic: boolean;
};

const isOpenSet = setProps<SGroupImageProps>(({ isOpen }) => isOpen)(css`
  transform: rotate(0deg);
`);

const isArabicLang = setProps<SGroupImageProps>(({ isArabic, isOpen }) => isArabic && !isOpen)(css`
  ${!isQi && "rotate(-90deg)"};
`);

export const SGroupImage = styled.div<SGroupImageProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  transform: ${!isQi && "rotate(-90deg)"};

  ${isOpenSet};
  ${isArabicLang};
`;
