import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useTranslation } from "react-i18next";
import { userInfoPageService } from "service/shared/singletones/userInfoPageService/userInfoPage.service";
import { createComponent } from "utils/libExtend/createComponent";
import { SButtonQi } from "utils/styled/SButtonQi";

export const ResetPasswordApplyButtonQi = createComponent("ResetPasswordApplyButtonQi", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return (
    <SButtonQi isArabic={isArabic} onClick={userInfoPageService.controller.changePasswordHandler}>
      {t("usersDetailsPage.resetPasswordApplyButton")} <SvgImage name="Check" />
    </SButtonQi>
  );
});
