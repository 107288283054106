/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
/* eslint-disable react/no-array-index-key */
import React from "react";

type RolesDisplayProps = {
    roles: string[];
};

export const RolesDisplayQi: React.FC<RolesDisplayProps> = ({ roles }) => (
    <div style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "8px",
        width: "100%",
        justifyContent: "flex-start",
        marginBottom: "10px",
    }}>
        {roles.map((role, index) => (
            <span
                key={index}
                style={{
                    backgroundColor: "#E0F3FF",
                    color: "#006FBF",
                    padding: "4px 8px",
                    borderRadius: "12px",
                    fontSize: "14px",
                }}
            >
                {role}
            </span>
        ))}
    </div>
);

