import React from "react";
import { css } from "styled-components";
import { setBorderRadius_8px, setProps } from "theme";
import { styled } from "theme/default/styled";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";
import { SPropsIsArabic } from "types/business/SPropsType";

export const STerminalsFilterSelectInput = styled.input<React.InputHTMLAttributes<HTMLInputElement>>`
  padding: 10px;

  border: 1px solid ${color_a0b9d2};

  ${setBorderRadius_4px};
`;

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  padding: 10px 12px;
  text-align: right;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  padding: 10px 12px;
`);

export const STerminalsFilterSelectInputQi = styled.input<SPropsIsArabic & React.InputHTMLAttributes<HTMLInputElement>>`
  width: 240px;
  height: 40px;
  appearance: none;
  border: 1px solid #e8e8e9;
  cursor: pointer;
  ${isNotArabicLang};
  ${isArabicLang};
  ${setBorderRadius_8px};
  &:focus {
    outline: none;
    border-color: #ffd400;
  }
`;
