import { PopUpContainer } from "components/PopUpContainer/PopUpContainer.component";
import React from "react";
import styled, { css } from "styled-components";
import { resetScrollbar, setBackgroundColor_ffffff, setBorderRadius_8px, setFlexDFFDC, setFontSize_14px, setFontWeight600, setProps } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

export const SModalLogoutContainer = styled.div<SPropsIsArabic & React.HTMLAttributes<HTMLDivElement>>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  z-index: 1000;
  overflow: auto;
  width: 472px;
  height: auto;
  padding: 20px 24px;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  ${setFlexDFFDC};
  ${resetScrollbar};
  ${setBackgroundColor_ffffff};
  ${isArabicLang};
`;

export const SModalLogout = styled(PopUpContainer)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

export const SModalLogoutTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;
`;

export const SModalDescription = styled.h2`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  padding: 8px 24px 24px;
`;

const isArabicLangQi = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
  margin-left: 0px;
`);

export const SLogoutButtonQi = styled.button<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  color: #707a8f;
  display: flex;
  align-items: center;
  border: 1px solid #fe5b65;
  background-color: #fe5b65;
  color: white;
  gap: 4px;
  padding: 10px 20px;
  width: auto;
  height: 40px;
  ${setFontSize_14px};
  ${setFontWeight600};
  ${setBorderRadius_8px};
  ${isArabicLangQi};
`;
