/* eslint-disable prettier/prettier */
import { userService } from "api/userService";
import { initialHeaderCellKey } from "appConstants/initialHeaderCellKey";
import { tableWidths } from "appConstants/tableWidths";
import { PaymentsDetailsRefundCell, PaymentsDetailsRefundStatus } from "detailsCell/PaymentsDetailsRefundCell/PaymentsDetailsRefundCell.component";
import { t } from "i18n";
import { HeaderCellModel } from "model/HeaderCell.model";
import { isQi } from "utils/business/environment";
import { getInitialHeaderCell } from "utils/business/getInitialHeaderCell";

export const paymentDetailsPageRefund = (): HeaderCellModel[] => {
  const arr = isQi
    ? [
      getInitialHeaderCell(initialHeaderCellKey.refundId, t("paymentDetailsPageCancelConfig.refundId"), "paymentDetailsPageCancelConfig.refundId", {
        width: tableWidths.width300,
        borderRight: "1px solid #939598",
        height: "100%",
      }),
      getInitialHeaderCell(
        initialHeaderCellKey.creationDate,
        t("paymentDetailsPageRefundConfig.creationDate"),
        "paymentDetailsPageRefundConfig.creationDate",
        {
          width: tableWidths.width200,
          borderRight: "1px solid #939598",
          height: "100%",
        }
      ),
      getInitialHeaderCell(initialHeaderCellKey.amount, t("paymentDetailsPageRefundConfig.amount"), "paymentDetailsPageRefundConfig.amount", {
        width: tableWidths.width150,
        height: "100%",
        borderRight: "1px solid #939598",
      }),
      getInitialHeaderCell(initialHeaderCellKey.status, t("paymentDetailsPageRefundConfig.status"), "paymentDetailsPageRefundConfig.status", {
        width: tableWidths.width150,
        height: "100%",
        isStatus: true,
        Element: PaymentsDetailsRefundStatus,
      }),
    ]
    : [
      getInitialHeaderCell(initialHeaderCellKey.refundId, t("paymentDetailsPageCancelConfig.refundId"), "paymentDetailsPageCancelConfig.refundId", {
        width: tableWidths.width300,
      }),
      getInitialHeaderCell(
        initialHeaderCellKey.creationDate,
        t("paymentDetailsPageRefundConfig.creationDate"),
        "paymentDetailsPageRefundConfig.creationDate",
        {
          width: tableWidths.width200,
        }
      ),
      getInitialHeaderCell(initialHeaderCellKey.status, t("paymentDetailsPageRefundConfig.status"), "paymentDetailsPageRefundConfig.status", {
        width: tableWidths.width150,
      }),
      getInitialHeaderCell(initialHeaderCellKey.amount, t("paymentDetailsPageRefundConfig.amount"), "paymentDetailsPageRefundConfig.amount", {
        width: tableWidths.width150,
      }),
    ];

  const canGetRefundInfo = userService.canGetRefundInfo();

  if (canGetRefundInfo && !isQi) {
    arr.unshift(
      getInitialHeaderCell(initialHeaderCellKey.details, t("paymentDetailsPageRefundConfig.details"), "paymentDetailsPageRefundConfig.details", {
        isDetails: true,
        Element: PaymentsDetailsRefundCell,
        width: tableWidths.width120,
      })
    );
  }
  if (canGetRefundInfo && isQi) {
    arr.unshift(
      getInitialHeaderCell(initialHeaderCellKey.details, t("paymentDetailsPageRefundConfig.details"), "paymentDetailsPageRefundConfig.details", {
        isDetails: true,
        Element: PaymentsDetailsRefundCell,
        width: tableWidths.width120,
        borderRight: "1px solid #939598",
        height: "100%",
      })
    );
  }

  return arr;
};
