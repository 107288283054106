import { Table } from "components/Table/Table.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableTableService } from "observables/TableServiceObservable";
import { useTranslation } from "react-i18next";
import { transfersPageService } from "service/shared/singletones/transfersPageService/transfersPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { STableComponentQi, STableContainerQi } from "utils/styled/STableQi.styled";

import { STransfersTable } from "./TransfersTableStyled/STransfersTable.styled";
import { STransfersTableContainer } from "./TransfersTableStyled/STransfersTableContainer.styled";

export const TransfersTable = createComponent("TransfersTable", () => {
  const loaded = useDefaultObservableBoolean(transfersPageService.state.loaded);
  const tableService = useDefaultObservableTableService(transfersPageService.tableService);
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return isQi ? (
    <STableComponentQi isArabic={isArabic}>
      <STableContainerQi isArabic={isArabic}>
        <Table loaded={loaded} service={tableService} />
      </STableContainerQi>
    </STableComponentQi>
  ) : (
    <STransfersTable>
      <STransfersTableContainer>
        <Table loaded={loaded} service={tableService} />
      </STransfersTableContainer>
    </STransfersTable>
  );
});
