/* eslint-disable prettier/prettier */
import { Link } from "components/Link/Link.component";
import React from "react";
import { setBackgroundColor_ffffff } from "theme";
import { styled } from "theme/default/styled";
import { color_rgba_0_0_0_08 } from "theme/setter/opacityColorsValues/color_rgba_0_0_0_08";
import { color_rgba_34_36_38_15 } from "theme/setter/opacityColorsValues/color_rgba_34_36_38_15";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

export const SMenuList = styled.div<{ isArabic?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  position: absolute;
  right: 0;
  ${(props): string => (props.isArabic ? `left: 0` : `right: 0`)};
  width: 200px;
  border: 1px solid ${color_rgba_34_36_38_15};
  box-shadow: 0 1px 3px 0 ${color_rgba_0_0_0_08};
  ${setFlexDFFDC};
  ${setBorderRadius_4px};
`;

export const SMenuListQi = styled.div<{ isArabic?: boolean } & { open?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  position: absolute;
  top: -240px;
  ${(props): string => (props.isArabic ? `right: 50px;` : `left: 50px;`)};
  width: 220px;
  border-radius: 12px;
  box-shadow: 0px 8px 10px 0px #4d546426;
  border: 1px solid #4d546426;
  ${setFlexDFFDC};
  ${setBackgroundColor_ffffff};
`;

export const SBoxListItemQi = styled(Link) <{ isArabic?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  padding: 0px 15px;
  cursor: pointer;
  text-decoration: none;
  ${setBackgroundColor_ffffff};
  ${(props): any => props.isArabic && `flex-direction: row-reverse`};
`;

export const SBoxListItemButtonQi = styled.button<{ isArabic?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  padding: 0px 15px;
  cursor: pointer;
  ${setBackgroundColor_ffffff};
  ${(props): any => props.isArabic && `flex-direction: row-reverse`};
`;
