import { Row } from "components/Row/Row.component";
import { UserModel } from "model/User.model";
import { useDefaultObservableUserModelNull } from "observables/UserModelNullObservable";
import { useTranslation } from "react-i18next";
import { userInfoPageService } from "service/shared/singletones/userInfoPageService/userInfoPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { ChangePasswordSection } from "../ChangePasswordSection/ChangePasswordSection.component";

export const UserInfoContent = createComponent("UserInfoContent", () => {
  const { t } = useTranslation();
  const user: UserModel = useDefaultObservableUserModelNull(userInfoPageService.state.user)!;

  return (
    <>
      <Row title={t("userInfoPageContent.id")} value={user.id} />
      <Row title={t("userInfoPageContent.name")} value={user.name} />
      <Row title={t("userInfoPageContent.roles")} value={user.roles.join(" ")} />
      <Row title={t("userInfoPageContent.email")} value={user.email} />
      <Row title={t("userInfoPageContent.phone")} value={user.phoneNumber} />
      <ChangePasswordSection />
    </>
  );
});
