import { BehaviorSubjectBoolean } from "observables/BooleanObservable";

class State {
  public readonly open: BehaviorSubjectBoolean;

  public constructor() {
    this.open = new BehaviorSubjectBoolean(false);
  }
}

export const modalChangeUserInfoState = new State();
