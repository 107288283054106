import { STitleQi } from "components/TableNavigation/TableNavigationStyled/STableNavigationTotalPagesValue.styled";
import { useDefaultObservableStartNumberEndNumber } from "observables/StartNumberEndNumberObservable";
import { useTranslation } from "react-i18next";
import { terminalsPageService } from "service/shared/singletones/terminalsPageService/terminalsPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { STerminalsListCountQi } from "../TerminalsListStyled/STerminalsListCount.styled";
import { STerminalsListCountBolderQi } from "../TerminalsListStyled/STerminalsListCountBolder.styled";
import { TerminalsListTableBody } from "./TerminalsListTableBody/TerminalsListTableBody.component";
import { TerminalsListTableHeader } from "./TerminalsListTableHeader/TerminalsListTableHeader.component";
import {
  ScrollableTableBodyQi,
  STerminalsListTable,
  STerminalsListTableFooterQi,
  STerminalsListTableQi,
} from "./TerminalsListTableStyled/STerminalsListTable.styled";

export const TerminalsListTable = createComponent("TerminalsListTable", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const terminalsCount = useDefaultObservableStartNumberEndNumber(terminalsPageService.state.terminalsCount);

  return isQi ? (
    <STerminalsListTableQi>
      <TerminalsListTableHeader />
      <ScrollableTableBodyQi>
        <TerminalsListTableBody />
      </ScrollableTableBodyQi>
      <STerminalsListTableFooterQi>
        <STerminalsListCountQi>
          <STitleQi isArabic={isArabic}>{t("tableNavigation.showingElements")}</STitleQi>
          <STerminalsListCountBolderQi>{` ${terminalsCount.start}-${terminalsCount.end} `}</STerminalsListCountBolderQi>
        </STerminalsListCountQi>
      </STerminalsListTableFooterQi>
    </STerminalsListTableQi>
  ) : (
    <STerminalsListTable>
      <TerminalsListTableHeader />
      <TerminalsListTableBody />
    </STerminalsListTable>
  );
});
