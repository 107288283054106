import React from "react";
import { css } from "styled-components";
import { setProps } from "theme";
import { styled } from "theme/default/styled";
import { setFlexDFF1 } from "theme/setter/setFlexValues/setFlexDFF1";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  margin-right: 32px;
  margin-left: -20px;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  margin-right: 32px;
  margin-left: 24px;
`);

export const SRewardDetailsTable = styled.div`
  overflow: hidden;
  margin-top: -8px;
  margin-left: 2px;
  padding-right: 16px;
  padding-bottom: 8px;
  ${setFlexDFF1};
`;

export const SRewardDetailsTableQi = styled.div<SPropsIsArabic & React.HTMLAttributes<HTMLDivElement>>`
  overflow: hidden;

  ${isArabicLang};
  ${isNotArabicLang};
  min-height: 100px;
`;
