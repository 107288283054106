import { setBorderRadius_8px } from "theme";
import { styled } from "theme/default/styled";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { setBackgroundColor_a0b9d2 } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_a0b9d2";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";

export const SCompareTerminalSkeleton = styled.div`
  width: 180px;

  border: 1px solid ${color_a0b9d2};

  ${setBorderRadius_4px};
  ${setBackgroundColor_a0b9d2};
`;

export const SCompareTerminalSkeletonQi = styled.div`
  width: 100%;
  border: 1px solid #e8e8e9;
  ${setBorderRadius_8px};
  ${setBackgroundColor_a0b9d2};
`;
