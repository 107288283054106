import React from "react";
import styled from "styled-components";

export const SSelectLangApp = styled.div`
  display: flex;
  align-items: center;
`;

export const SSelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 12px;
    // transform: translateY(-50%);
    border: solid #626469;
    border-width: 0 2px 2px 0;
    padding: 3px;
    transform: rotate(45deg);
    pointer-events: none;
  }
`;

export const SLangAppSelect = styled.select<React.SelectHTMLAttributes<HTMLSelectElement>>`
  width: 90px;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #e8e8e9;
  border-radius: 8px;
  background-color: #fff;
  appearance: none;
  cursor: pointer;
  transition: border-color 0.3s;

  &:focus {
    border-color: #ffd400;
    outline: none;
  }
`;
