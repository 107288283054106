import { styled } from "theme/default/styled";
import { setFontSize_12px } from "theme/setter/setFontSizeValues/setFontSize_12px";
import { setFontWeight400 } from "theme/setter/setFontWeightValues/setFontWeight400";

export const STerminalsListCount = styled.div`
  margin-left: 16px;

  ${setFontSize_12px};
  ${setFontWeight400};
`;

export const STerminalsListCountQi = styled.div`
  ${setFontSize_12px};
  ${setFontWeight400};
  display: flex;
  align-items: center;
`;
