import { TableNavigation } from "components/TableNavigation/TableNavigation.component";
import { useTranslation } from "react-i18next";
import { transfersPageService } from "service/shared/singletones/transfersPageService/transfersPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";

import { TransfersPageFilters } from "./TransfersPageFilters/TransfersPageFilters.component";
import { STransfersPage, STransfersPageQi } from "./TransfersPageStyled/STransfersPage.styled";
import { TransfersTable } from "./TransfersTable/TransfersTable.component";

export const TransfersPage = createComponent("TransfersPage", () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    transfersPageService.controller.mounted();

    return (): void => {
      transfersPageService.controller.unMounted();
    };
  }, []);

  return isQi ? (
    <STransfersPageQi isArabic={i18n.language === "ar"}>
      <TransfersPageFilters />
      <TransfersTable />
      <TableNavigation service={transfersPageService.state.filterNavigationService} />
    </STransfersPageQi>
  ) : (
    <STransfersPage isArabic={i18n.language === "ar"}>
      <TransfersPageFilters />
      <TransfersTable />
      <TableNavigation service={transfersPageService.state.filterNavigationService} />
    </STransfersPage>
  );
});
