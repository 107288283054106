import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { routerPaths } from "appConstants/routerPaths";
import { t } from "i18n";
import { PaymentDetailsModel } from "model/PaymentDetails.model";
import { AxiosResponse } from "packages/axios";
import { redirectService } from "service/common/redirectService/redirect.service";
import { paymentDetailsPageState } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.state";
import { toastContainerService } from "service/shared/singletones/toastContainerService/toastContainer.service";
import { PaymentCancel } from "types/business/PaymentCancel";
import { PaymentRefund } from "types/business/PaymentRefund";
import { CustomInputEvent } from "types/commonExtend/CustomInputEvent";
import { replacePath } from "utils/commonExtend/replacePath";

import { phoneRegExp } from "../../../../appConstants/regexp";
import { OptionModel } from "../../../../model/Option.model";

class Controller {
  public readonly state = paymentDetailsPageState;

  private get successCancelsAmount(): number {
    return (
      this.state.payment.value?.cancels
        .map((cancel: PaymentCancel) => {
          if (cancel.status === "SUCCESS") {
            return +cancel.amount;
          }
          return 0;
        })
        .reduce((acc, val) => acc + val, 0) || 0
    );
  }

  private get successRefundsAmount(): number {
    return (
      this.state.payment.value?.refunds
        .map((refund: PaymentRefund) => {
          if (refund.status === "SUCCESS") {
            return +refund.amount;
          }
          return 0;
        })
        .reduce((acc, val) => acc + val, 0) || 0
    );
  }

  public readonly close = (): void => {
    this.state.additionalInfoStageSelected.next(false);
    this.state.partialFormSelected.next(false);
    this.state.modalIsOpen.next(false);
  };

  public readonly onChange = (event: CustomInputEvent): void => {
    const { value } = event.target;
    this.state.value.next(value);
  };

  public readonly onChangePhone = (event: CustomInputEvent): void => {
    const { value } = event.target;
    this.state.phoneValue.next(value);
  };

  public readonly onFocusPhone = (): void => {
    if (this.state.phoneValue.value === "") {
      this.state.phoneValue.next("+964");
    }
  };

  public readonly onClickFull = async (): Promise<void> => {
    if (this.state.payment.value!.type === "SBP") {
      this.state.additionalInfoStageSelected.next(true);
    } else {
      await this.postAction();
    }
  };

  public readonly onClickPartial = (): void => {
    this.state.partialFormSelected.next(true);
  };

  public readonly closesPartialForm = (): void => {
    this.state.partialFormSelected.next(false);
  };

  public readonly closesAdditionalInfoForm = (): void => {
    this.state.additionalInfoStageSelected.next(false);
  };

  public readonly formatPhone = (value: string): string => {
    return value.replace("+", "");
  };

  public readonly onClickApplyRefund = async (): Promise<void> => {
    const hasAmount = !!this.state.value.value;
    const hasPhoneValue = !!this.state.phoneValue.value && this.state.phoneValue.value !== "+964";
    const hasSelectedOption = !!this.state.selectedOption.value.value;

    if (hasPhoneValue) {
      if (!phoneRegExp.test(this.state.phoneValue.value)) {
        this.state.phoneShowError.next(true);
        return;
      }
    }

    const data = {} as any;

    if (hasAmount) {
      data.amount = +this.state.value.value;
    }

    if (hasPhoneValue || hasSelectedOption) {
      data.extParams = {};
      if (hasPhoneValue) {
        data.extParams.phone = this.formatPhone(this.state.phoneValue.value);
      }

      if (hasSelectedOption) {
        data.extParams.recipientBankId = this.state.selectedOption.value.value;
      }
    }

    await this.postAction(data);
  };

  public readonly onClickApply = async (): Promise<void> => {
    if (this.validate()) {
      if (this.state.payment.value!.type === "SBP") {
        this.state.additionalInfoStageSelected.next(true);
      } else {
        const amount = +this.state.value.value;
        const data = { amount };
        await this.postAction(data);
      }
    } else {
      this.state.amountShowError.next(true);
    }
  };

  public readonly onClickCancel = async (): Promise<void> => {
    this.state.modalIsOpen.next(true);
    this.state.requestType.next("cancel");
  };

  public readonly onClickConfirm = async (): Promise<void> => {
    this.state.modalIsOpen.next(true);
    this.state.requestType.next("confirm");
  };

  public readonly onClickRefund = async (): Promise<void> => {
    this.state.modalIsOpen.next(true);
    this.state.requestType.next("refund");
  };

  public readonly getPayment = async (): Promise<void> => {
    this.state.loaded.next(false);
    const data: any[] = await this.fetchPayment();
    const produceData = this.producePayment(data);
    this.state.payment.next(produceData);
    this.state.loaded.next(true);
  };

  public readonly setPaymentId = (paymentId: string): void => {
    this.state.paymentId = paymentId;
  };

  public readonly unMounted = (): void => {
    this.state.payment.next(null);
    this.state.loaded.next(false);
    this.state.amountShowError.next(false);
    this.state.phoneShowError.next(false);
    this.state.value.next("");
  };

  private readonly validate = (): boolean => {
    if (this.state.payment.value === null) {
      return false;
    }

    if (this.state.value.value.trim() === "") {
      return false;
    }

    const value = +this.state.value.value;

    if (value < 0.01) {
      return false;
    }

    if (Number.isNaN(+value)) {
      return false;
    }

    const amount = +this.state.payment.value.amount;
    const confirmedAmount = +this.state.payment.value.confirmedAmount;

    if (this.state.requestType.value === "cancel") {
      return value <= amount - this.successCancelsAmount;
    }
    if (this.state.requestType.value === "confirm") {
      return value <= amount;
    }
    if (this.state.requestType.value === "refund") {
      return value <= confirmedAmount - this.successRefundsAmount;
    }
    return false;
  };

  private readonly postAction = async (data?: any): Promise<void> => {
    data = data || {};

    let response = {} as AxiosResponse;

    try {
      this.state.actionLoaded.next(false);

      if (this.state.requestType.value === "cancel") {
        response = await client.post(
          replacePath(apiPaths.paymentCancel, {
            paymentId: this.state.paymentId,
          }),
          data
        );

        toastContainerService.controller.createSuccessToast(t("paymentDetailsPage.paymentCancelMessage"));
      } else if (this.state.requestType.value === "refund") {
        response = await client.post(
          replacePath(apiPaths.paymentRefund, {
            paymentId: this.state.paymentId,
          }),
          data
        );

        toastContainerService.controller.createSuccessToast(t("paymentDetailsPage.paymentRefundMessage"));
      } else if (this.state.requestType.value === "confirm") {
        response = await client.post(
          replacePath(apiPaths.paymentConfirm, {
            paymentId: this.state.paymentId,
          }),
          data
        );

        toastContainerService.controller.createSuccessToast(t("paymentDetailsPage.paymentConfirmMessage"));
      }

      const produceData = this.producePayment(response?.data);
      this.state.payment.next(produceData);
    } catch (error: any) {
      /* empty */
    } finally {
      this.state.actionLoaded.next(true);
      this.state.amountShowError.next(false);
      this.state.phoneShowError.next(false);
      this.state.partialFormSelected.next(false);
      this.state.modalIsOpen.next(false);
      this.state.value.next("");
      this.state.phoneValue.next("");
      this.state.selectedOption.next(new OptionModel("", ""));
      this.state.partialFormSelected.next(false);
      this.state.additionalInfoStageSelected.next(false);
    }
  };

  private readonly producePayment = (dataItem: any): PaymentDetailsModel => {
    return new PaymentDetailsModel(dataItem);
  };

  private readonly fetchPayment = async (): Promise<any> => {
    try {
      const { data } = await client.get(
        replacePath(apiPaths.paymentInfoId, {
          paymentId: this.state.paymentId,
        })
      );
      return data;
    } catch (e) {
      console.log(e);
      redirectService.controller.setCurrentRedirectPage(routerPaths.rewards);
    }
  };

  public readonly onChangeSelect = (optionModel: OptionModel): void => {
    this.state.selectedOption.next(optionModel);
  };

  public readonly onClearSelect = (): void => {
    this.state.selectedOption.next(new OptionModel("", ""));
  };
}

export const paymentDetailsPageController = new Controller();
