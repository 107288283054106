import React from "react";
import styled from "styled-components";

export const SLabel = styled.label<{ $isArabic?: boolean; children?: React.ReactNode; htmlFor?: string }>`
  display: block;
  font-size: 14px;
  color: #707a8f;
  margin-top: 10px;
  text-align: ${({ $isArabic }): any => $isArabic && "right"};
`;
