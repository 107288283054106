import React from "react";
import { css, setProps, styled } from "theme";

interface SFilterSelectClearProps {
  isArabic?: boolean;
}

const isArabicLang = setProps<SFilterSelectClearProps>(({ isArabic }) => isArabic)(css`
  left: 4px;
`);

const isNotArabicLang = setProps<SFilterSelectClearProps>(({ isArabic }) => !isArabic)(css`
  right: 4px;
`);
const isArabicLangQi = setProps<SFilterSelectClearProps>(({ isArabic }) => isArabic)(css`
  left: 12px;
`);

const isNotArabicLangQi = setProps<SFilterSelectClearProps>(({ isArabic }) => !isArabic)(css`
  right: 12px;
`);

export const SFilterSelectClear = styled.button<SFilterSelectClearProps & React.HTMLAttributes<HTMLButtonElement>>`
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  ${isNotArabicLang};
  ${isArabicLang};
`;

export const SFilterSelectClearQi = styled.button<SFilterSelectClearProps & React.HTMLAttributes<HTMLButtonElement>>`
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  ${isNotArabicLangQi};
  ${isArabicLangQi};
`;
