/* eslint-disable prettier/prettier */
import { CustomMultipleSelect } from "components/CustomMultipleSelect/CustomMultipleSelect.component";
import { setBorderRadius_8px } from "theme";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { color_e0b4b4 } from "theme/setter/colorsValues/color_e0b4b4";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";

type SCompareTerminalSelectorProps = {
  isError: boolean;
};

const isErrorSet = setProps<SCompareTerminalSelectorProps>(({ isError }) => isError)(css`
  border: 1px solid ${color_e0b4b4};
`);

const isErrorNotSet = setProps<SCompareTerminalSelectorProps>(({ isError }) => !isError)(css`
  border: 1px solid ${color_a0b9d2};
`);
const isErrorNotSetQi = setProps<SCompareTerminalSelectorProps>(({ isError }) => !isError)(css`
  border: 1px solid #e8e8e9;
`);
export const SCompareTerminalSelector = styled(CustomMultipleSelect) <SCompareTerminalSelectorProps>`
  width: 180px;
  background-color: transparent;
  appearance: none;

  ${isErrorSet};
  ${isErrorNotSet};
  ${setBorderRadius_4px};
`;

export const SCompareTerminalSelectorQi = styled(CustomMultipleSelect) <SCompareTerminalSelectorProps>`
  width: 100%;
  background-color: transparent;
  appearance: none;
  ${isErrorSet};
  ${isErrorNotSetQi};
  ${setBorderRadius_8px};
`;
