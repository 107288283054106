import { routerPaths } from "appConstants/routerPaths";
import { DetailsCell } from "detailsCell/DetailsCell/DetailsCell.component";
import { RefundsDetailsCellProps } from "detailsCell/RefundsDetailsCell/RefundsDetailsCell.props";
import { replacePath } from "utils/commonExtend/replacePath";
import { createComponent } from "utils/libExtend/createComponent";
import { STATUS_STYLES, StatusColor, StatusWrapper } from "utils/styled/SStatusWrapperQi";

export const RefundsDetailsCell = createComponent<RefundsDetailsCellProps>("RefundsDetailsCell", (props) => {
  const { cellData } = props;

  const to = replacePath(routerPaths.refundDetails, {
    refundId: cellData.refundId,
  });

  return <DetailsCell to={to} />;
});

const getStatusStyle = (status: string): StatusColor => {
  return STATUS_STYLES[status] || STATUS_STYLES.PENDING;
};

export const RefundsStatus = createComponent<RefundsDetailsCellProps>("RefundsStatus", (props) => {
  const { cellData } = props;

  const color = getStatusStyle(cellData.status);

  return <StatusWrapper color={color}>{cellData.status}</StatusWrapper>;
});
