import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { usersDetailsPageService } from "service/shared/singletones/usersDetailsPageService/usersDetailsPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { ChangePasswordForm } from "./ChangePasswordForm/ChangePasswordForm.component";
import { ChangeStatusButton } from "./ChangeStatusButton/ChangeStatusButton.component";
import { ResetPasswordButton } from "./ResetPasswordButton/ResetPasswordButton.component";
import { SessionDropButton } from "./SessionDropButton/SessionDropButton.component";
import { SUsersDetailsContent } from "./UsersDetailsContentStyled/SUsersDetailsContent.styled";
import { UsersDetailsList } from "./UsersDetailsList/UsersDetailsList.component";

export const UsersDetailsContent = createComponent("UsersDetailsContent", () => {
  const showUserForm = useDefaultObservableBoolean(usersDetailsPageService.state.showUserForm);

  return isQi ? (
    <SUsersDetailsContent>
      <UsersDetailsList />
    </SUsersDetailsContent>
  ) : (
    <SUsersDetailsContent>
      <UsersDetailsList />
      {showUserForm && <ChangePasswordForm />}
      <ChangeStatusButton />
      <ResetPasswordButton />
      <SessionDropButton />
    </SUsersDetailsContent>
  );
});
