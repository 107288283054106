import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useTranslation } from "react-i18next";
import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { SButtonQi } from "utils/styled/SButtonQi";

import { SConfirmButton } from "./ConfirmButtonStyled/SConfirmButton.styled";

export const ConfirmButton = createComponent("ConfirmButton", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return isQi ? (
    <SButtonQi isArabic={isArabic} onClick={paymentDetailsPageService.controller.onClickConfirm}>
      {t("paymentDetailsPageContent.confirmButtonTitle")}
      <SvgImage name="Check" />
    </SButtonQi>
  ) : (
    <SConfirmButton onClick={paymentDetailsPageService.controller.onClickConfirm}>{t("paymentDetailsPageContent.confirmButtonTitle")}</SConfirmButton>
  );
});
