/* eslint-disable prettier/prettier */
import React from "react";
import styled, { css } from "styled-components";
import { setBorderRadius_8px, setFlexDFAIC, setFlexJCC, setFontSize_14px, setFontWeight400, setProps } from "theme";
import { setColor_ffffff } from "theme/setter/setColorsValues/setColor_ffffff";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
    right: 30px;
  `);
const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
    left: 30px;
  `);

export const SNavigationMenuIconLabelQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  position: absolute;
  z-index: 2;
  padding: 6px 8px;
  bottom: 40px;
  background-color: #707a8f;
  opacity: 0.8;
  ${setFlexDFAIC};
  ${setFlexJCC};
  ${setBorderRadius_8px};
  ${isArabicLang};
  ${isNotArabicLang};
`;

export const SNavigationMenuIconLabelTitle = styled.div`
  ${setFontSize_14px};
  ${setFontWeight400};
  ${setColor_ffffff};
`;