import { styled } from "theme/default/styled";
import { setFontSize_12px } from "theme/setter/setFontSizeValues/setFontSize_12px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";

export const STerminalsListCountBolder = styled.div`
  ${setFontSize_12px};
  ${setFontWeight600};
`;

export const STerminalsListCountBolderQi = styled.div`
  ${setFontSize_12px};
  ${setFontWeight600};
  margin: 0px 10px;
`;
