import React from "react";
import { color_rgba_183_200_220_80, css, resetScrollbar, setBackgroundColor_ffffff, setBorderRadius_8px, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  margin-right: 15px;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  margin-left: 15px;
`);

export const SDetailsLayout = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  overflow-y: scroll;
  margin-bottom: 8px;
  padding: 16px 20px 20px 16px;

  box-shadow: 0 0 7px ${color_rgba_183_200_220_80};

  ${resetScrollbar};
  ${setBorderRadius_8px};
  ${setBackgroundColor_ffffff};
  ${isArabicLang};
  ${isNotArabicLang};
`;
