import { modalLogoutController } from "./modalLogout.controller";
import { modalLogoutState } from "./modalLogout.state";

class Service {
  public readonly state = modalLogoutState;

  public readonly controller = modalLogoutController;
}

export const modalLogoutService = new Service();
