import React from "react";
import { css, setFlexDFFDCF1, setProps, styled } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  padding-left: "80px";
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  padding-right: "80px";
`);

export const SInvoicesPage = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 100%;
  ${isNotArabicLang};
  ${isArabicLang};
  ${setFlexDFFDCF1};
`;

const isArabicLangQi = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  padding-left: 34px;
  margin-right: -272px;
`);

const isNotArabicLanQi = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  padding-right: 0px;
  margin-left: -272px;
`);

export const SInvoicesPageQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 100%;
  ${isNotArabicLanQi};
  ${isArabicLangQi};
  ${setFlexDFFDCF1};
  overflow: auto;
  margin-top: 100px;
`;
