import { Table } from "components/Table/Table.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableTableService } from "observables/TableServiceObservable";
import { useTranslation } from "react-i18next";
import { paymentsPageService } from "service/shared/singletones/paymentsPageService/paymentsPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { STableComponentQi, STableContainerQi } from "utils/styled/STableQi.styled";

import { SPaymentsTable } from "./PaymentsTableStyled/SPaymentsTable.styled";
import { SPaymentsTableContainer } from "./PaymentsTableStyled/SPaymentsTableContainer.styled";

export const PaymentsTable = createComponent("PaymentsTable", () => {
  const loaded = useDefaultObservableBoolean(paymentsPageService.state.loaded);
  const tableService = useDefaultObservableTableService(paymentsPageService.tableService);
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return isQi ? (
    <STableComponentQi isArabic={isArabic}>
      <STableContainerQi isArabic={isArabic}>
        <Table loaded={loaded} service={tableService} />
      </STableContainerQi>
    </STableComponentQi>
  ) : (
    <SPaymentsTable>
      <SPaymentsTableContainer>
        <Table loaded={loaded} service={tableService} />
      </SPaymentsTableContainer>
    </SPaymentsTable>
  );
});
