import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useFiltersContext } from "context/Filter.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { SAddFilterButton, SAddFilterButtonQi } from "./AddFilterButtonStyled/SAddFilterButton.styled";
import { SAddFilterButtonTitle, SAddFilterButtonTitleQi } from "./AddFilterButtonStyled/SAddFilterButtonTitle.styled";
import { hideButton } from "./hideButton";

export const AddFilterButton = createComponent("AddFilterButton", () => {
  const { t } = useTranslation();
  const { service } = useFiltersContext();

  const openFilterSelector = useDefaultObservableBoolean(service.state.openFilterSelector);
  const filterListIsEmpty = useDefaultObservableBoolean(service.state.filterListIsEmpty);
  const isArabic = useDefaultObservableBoolean(service.state.isArabic);

  if (hideButton(filterListIsEmpty, openFilterSelector)) {
    return null;
  }

  return isQi ? (
    <SAddFilterButtonQi filterListIsEmpty={filterListIsEmpty} onClick={service.controller.onClickAddFilter} disabled={filterListIsEmpty} isArabic={isArabic}>
      <SAddFilterButtonTitleQi isArabic={isArabic}>{t("filters.addNewFilterButton")}</SAddFilterButtonTitleQi>
      <SvgImage name="AddNewFilter" />
    </SAddFilterButtonQi>
  ) : (
    <SAddFilterButton filterListIsEmpty={filterListIsEmpty} onClick={service.controller.onClickAddFilter} disabled={filterListIsEmpty} isArabic={isArabic}>
      <SvgImage width={14} height={14} name="Plus" />
      <SAddFilterButtonTitle isArabic={isArabic}>{t("filters.addFilterButton")}</SAddFilterButtonTitle>
    </SAddFilterButton>
  );
});
