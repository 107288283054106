import { Table } from "components/Table/Table.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableTableService } from "observables/TableServiceObservable";
import { useTranslation } from "react-i18next";
import { rewardsPageService } from "service/shared/singletones/rewardsPageService/rewardsPage.service";
import { isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";
import { STableComponentQi, STableContainerQi } from "utils/styled/STableQi.styled";

import { SRewardsTable } from "./RewardsTableStyled/SRewardsTable.styled";
import { SRewardsTableContainer } from "./RewardsTableStyled/SRewardsTableContainer.styled";

export const RewardsTable = createComponent("RewardsTable", () => {
  const loaded = useDefaultObservableBoolean(rewardsPageService.state.loaded);
  const tableService = useDefaultObservableTableService(rewardsPageService.tableService);
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return isQi ? (
    <STableComponentQi isArabic={isArabic}>
      <STableContainerQi isArabic={isArabic}>
        <Table loaded={loaded} service={tableService} />
      </STableContainerQi>
    </STableComponentQi>
  ) : (
    <SRewardsTable>
      <SRewardsTableContainer>
        <Table loaded={loaded} service={tableService} />
      </SRewardsTableContainer>
    </SRewardsTable>
  );
});
