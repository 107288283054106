import React from "react";
import styled, { css } from "styled-components";
import { setFlexDF, setProps } from "theme";
import { SPropsIsArabic } from "types/business/SPropsType";

const isArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => isArabic)(css`
  margin-left: -20px;
`);

const isNotArabicLang = setProps<SPropsIsArabic>(({ isArabic }) => !isArabic)(css`
  margin-left: 24px;
`);

export const STableComponentQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  overflow: hidden;
  margin-top: -8px;
  ${isArabicLang}
  ${isNotArabicLang}
  padding-right: 32px;
  display: flex;

  min-height: 10%;
  height: fit-content;
`;
// flex: 1;
// height: fit-content;

export const STableContainerQi = styled.div<SPropsIsArabic & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 100%;
  margin-top: 8px;
  ${setFlexDF};
  border-radius: 12px;
  margin-left: 10px;
  margin-right: -10px;
`;

export const STableHeaderQi = styled.div`
  ${setFlexDF};
  border-radius: 12px 12px 0 0;
  border-bottom: 1px solid #0000004d;
  height: 50px;
  overflow-x: hidden;
  overflow-y: hidden;
`;
